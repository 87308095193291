import React, {useCallback} from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import SyncIcon from '@mui/icons-material/Sync';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';

const Close = props => <Tooltip title="Cerrar">
  <CancelIcon color="action" onClick={props.dismiss}/>
</Tooltip>

export const SnackAction = props => {
  return <Close {...props}/>
}

export const ErrorSnackAction = props => {
  return <>
    {props.retry && <SyncIcon color="action" onClick={props.retry}/>}
    <Close {...props}/>
  </>
}

export const useCustomSnackbar = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  const eSnack = useCallback((msg, retry) => {
    console.log(msg);
    return !msg?.includes("cancelled_request") ? enqueueSnackbar(msg || 'Unexpected Error', {variant: 'error', persist:true, action: key => <ErrorSnackAction dismiss={()=>closeSnackbar(key)} retry={retry}/>}) : null
  },[enqueueSnackbar, closeSnackbar])

  const sSnack = useCallback(msg => enqueueSnackbar(msg, {variant: 'success'}),[enqueueSnackbar])
  const iSnack = useCallback(msg => enqueueSnackbar(msg, {variant: 'info', autoHideDuration: 2000}),[enqueueSnackbar])
  return {eSnack, iSnack, sSnack}
}
