import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogContentText from "@mui/material/DialogContentText";
import Button from '@mui/material/Button';
import {DialogTitle, DialogContent, DialogActions} from '../.'

export const ConfirmationDialog = ({ open, title, message, onConfirm, onDismiss }) => {
  return (
    <Dialog open={open} onClose={onDismiss}>
      <DialogTitle>{title || "Confirmar"}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message || "Esta Seguro?"}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Cancelar</Button>
        <Button color="secondary" variant="contained" onClick={onConfirm}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
