import React from "react";
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import logo from '../../../images/kentron_original.png';
import { formatDate } from "../../../helpers"



const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  detailsData: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    backgroundColor: "#3388af"
  },

  tableRow: {
    flexDirection: "row"
  },
  tableCol: {
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  header: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
  },
  descripcion: {
    // display: "flex",
    flexDirection: "row",
    fontSize: 8,
  },
  data: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: 10,
  },
  usuario: {
    height: "auto",
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 1,
    borderStyle: 'dashed',
    zIndex: 0
  }


});



export const DocuPDF = ({ date, data, cliente, fdesde, fhasta }) => {

  const hoy = new Date();
  const hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();


  console.log(data)




  return (

     
    <Document>
      <Page
      
        size="A4" style={styles.body}>
        {/*CABECERA*/}
      
        <View style={styles.header} fixed >

          <div style={{ float: 'left' }} >
            <Image src={logo} style={{ maxWidth: "150" }} />
          </div>

          <div style={{ float: 'left', width: 600, padding: 20 }}>
            <View style={styles.table}> {/* TableHeader */}
              {/* Inicio columnas */}

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={{ marginLeft: 50, color: "black", fontStyle: "italic", fontSize: "12px", fontWeight: "bold" }}>
                    DETALLE DE TICKETS REPORTADOS
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={{ marginLeft: 33, color: "black", fontStyle: "italic", fontSize: "10px", fontWeight: "bold" }} render={({ pageNumber, totalPages }) => (
                    `Página ${pageNumber} / ${totalPages}`
                  )} fixed />
                </View>

              </View>
              {/* Fin columnas */}
              {/* Inicio datos */}

              <View style={styles.tableRow} fixed >
                <View style={styles.tableCol}>

                </View>
                <View style={styles.tableCol}>
                  <Text style={{ marginLeft: 295, color: "black", fontStyle: "italic", fontSize: "10px", fontWeight: "bold" }}>
                    Fecha: {date}
                  </Text>
                </View>

              </View>
              <View style={styles.tableRow} fixed>
                <View style={styles.tableCol}>

                </View>
                <View style={styles.tableCol}>
                  <Text style={{ marginLeft: 295, color: "black", fontStyle: "italic", fontSize: "10px", fontWeight: "bold" }} >
                    Hora: {hora}
                  </Text>
                </View>

              </View>
            </View>


          </div>

        </View>
        {/*FIN CABECERA*/}

        <Text style={{ fontStyle: "italic", fontSize: "12px", marginBottom: 5 }} fixed>Cliente: {cliente}</Text>
        <View style={styles.header} fixed>
          <div style={{ float: 'left', width: 200 }} >
            <Text style={{ fontStyle: "italic", fontSize: "12px" }} fixed>Desde: {formatDate(new Date(fdesde))}</Text>
          </div>
          <div style={{ float: 'left', marginLeft: 20, width: 200 }} >
            <Text style={{ fontStyle: "italic", fontSize: "12px" }} fixed>Hasta: {formatDate(new Date(fhasta))}</Text>
          </div>
        </View>

     

        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }} fixed>
          <View style={{ flex: 1, height: 1, backgroundColor: 'black' }} />
        </View>

        <View style={styles.data} >
          {data ? data.map((a, index) => {               
            return (
              <View key={index} >
                <View break>
                  <View style={styles.detailsData}  >
                    <div style={{ float: 'left', marginLeft: 20, width: 100 }} >
                      <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}>Ticket: {a.idinc}</Text>
                    </div>
                    <div style={{ float: 'left', width: 100 }} >
                      {a.fecinginc
                        ? <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}>{formatDate(new Date(a.fecinginc))}</Text>

                        : <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}>{ }</Text>
                      }

                    </div>
                    <div style={{ float: 'left', marginLeft: 20, width: 100 }} >
                      <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}>Tipo: {a.h_type}</Text>
                    </div>
                    <div style={{ float: 'left', marginLeft: 20, width: 100 }} >
                      <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}>Estado: {a.h_status}</Text>
                    </div>
                  </View>


                  <View style={styles.usuario}  >
                    <View style={styles.descripcion} >
                      <div style={{ float: 'left', width: 100 }} >
                        <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}>Descripción:</Text>
                      </div>
                      <div style={{ float: 'left', marginLeft: 10, width: 500 }} >
                        <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}> {a.problema}</Text>
                      </div>
                    </View>
                  </View>

                  <View style={styles.usuario} >
                    <View style={styles.header} >
                      <div style={{ float: 'left', width: 100 }} >
                        <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}>Respuesta:</Text>
                      </div>
                      <div style={{ float: 'left', marginLeft: 10, width: 500 }} >

                        <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}> {a.respuesta}</Text>
                      </div>
                    </View>
                    {a.fecresp
                      ? <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}>Fecha: {formatDate(new Date(a.fecresp))}  Usuario: {a.codpersresp}</Text>
                      
                      
                      
                      : <Text style={{ padding: 5, fontStyle: "italic", fontSize: "10px" }}>{ }</Text>
                    }
                   {/*  <Text style={{ marginBottom: 5, padding: 5, fontStyle: "italic", fontSize: "10px" }}>Usuario: {a.codpersresp}</Text>   */}           
                  </View>

               { data[index].conversation.map((elem)=> 
                  <View style={styles.usuario} >
                    <View style={styles.header} >
                      <div style={{ float: 'left', width: 100 }} >
                        <Text style={{ padding: 5, fontStyle: "italic", fontSize: "8px" }}>Comentario:</Text>
                      </div>

                      <div style={{ float: 'left', marginLeft: 10, width: 500 }} >

                        <Text style={{ padding: 5, fontStyle: "italic", fontSize: "8px" }}> {elem.mensaje}</Text>
                      </div>
                    </View>
                    <Text style={{ padding: 5, fontStyle: "italic", fontSize: "8px" }}>Fecha: {formatDate(new Date(elem.updated_at))}   Usuario: {elem.profile.name}</Text>
     {/*                <Text style={{ padding: 5, fontStyle: "italic", fontSize: "8px" }}>Usuario: {elem.profile.name}</Text> */}

                  </View>
               )}

                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                </View>

              </View>
            );
          }) : ""}

        </View>
      </Page>
    </Document>
  );
};