import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  Typography,
  Card,
  Avatar,
  CardMedia,
  Button,
  Grid,
  Input,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import logo from '../../../images/2.png';
import { getErrorMsg, useBackdrop, useCurrentUser, useCustomSnackbar, useRequests } from "../../../helpers";
import { ChangeEmail } from "./change_email";
import { ChangePasswordForm } from "./change_password_form";
import { RecentActivity } from "./tickets";
import PersonIcon from "@mui/icons-material/Person";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    margin-top: -${theme.spacing(9)};
    margin-left: ${theme.spacing(2)};

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);


const CardCover = styled(Card)(
  ({ theme }) => `
    position: relative;

    .MuiCardMedia-root {
      height: ${theme.spacing(26)};
    }
`
);



export const Profile = () => {
  const currentUser = useCurrentUser()
  /*   const { dialogOpen: OtNew, openD: openOtNew, closeD: closeOtNew } = useDialogTools() */
  const [changeEmail, setchangeEmail] = useState(false);
  const changeEmailData = useRef({})
  const [changepasww, setchangepasww] = useState(false);
  const changepaswwData = useRef({})
  const {show, off} = useBackdrop()
  const { post } = useRequests()
  const { eSnack, sSnack } = useCustomSnackbar()

  const [filePreview, setFilePreview] = useState(null);
  const onChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = handleFileReaderLoad;
    reader.readAsDataURL(file);
  };

  useEffect(()=>{
    if (currentUser)
    setFilePreview(currentUser.avatar)

  },[ currentUser])
 
  
  const handleClickOpenEmail = () => {
    setchangeEmail(true);
    
  }
  const handleClickOpenPassww = () => {
    setchangepasww(true);
    
  }

  const handleClose = () => {
    setchangeEmail(false)
    setchangepasww(false)
  };
  const handleFileReaderLoad = (event) => {
    setFilePreview(event.target.result);
    updateAvatar(event.target.result); // llamar a updateAvatar cuando se establece el valor de filePreview
  };
  
  const updateAvatar = async (p) => {
 
    show('Cambiando avatar')
    try {
      const data = { avatar: p };
      const response = await post('/users/update_avatar', data);
      if (response?.ok) {
        sSnack("Avatar Actualizado");
   
      } else {
        eSnack(`Error actualizando avatar: ${await getErrorMsg(response)}`);
      }
    } catch (e) {
      eSnack("Error inesperado actualizando avatar");
      console.log("Error updating avatar", e);
    }finally { off() }
  };

  return (

    <>
      <Grid container spacing={1} mt={0}>
        <Grid item md={9} xs={12}>
          <Box display="flex" mb={0} >

          </Box>
          <CardCover>
            <CardMedia image={logo} />
    
          </CardCover>
          <AvatarWrapper>
            <div
              style={{
                position: "relative",
                backgroundColor: "#92A7C0",
                height: "120px",
                width: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {filePreview ? (
                <img
                  alt=""
                  src={filePreview}
                  style={{ width: "120px", height: "120px" }}
                />
              ) : (
                <PersonIcon
                  style={{
                    color: "#FFFFFF",
                    width: "35px",
                    height: "35px",
                   
                  }}
                />
              )}

              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                  height: "34px",
                  width: "34px",
                }}
              >
                <input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChange}
                />

                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={{
                    backgroundColor: "#007CD6",
                    width: "34px",
                    height: "34px",
                    
                  }}
                >
                  <label htmlFor="icon-button-file">
                    <CameraAltOutlinedIcon
                      style={{
                        color: "#FFFFFF",
                        marginTop:"7",
                        borderRadius: "50%" /* backgroundColor: "#007CD6" */,
                      }}
                    />
                  </label>
                </IconButton>
              </div>
            </div>

          </AvatarWrapper>
          <Box py={2} pl={2} mb={3}>
            <Typography gutterBottom variant="h4">
              {currentUser.name}
            </Typography>
            <Typography sx={{ py: 2 }} variant="subtitle2" color="text.primary">
              {currentUser.client} | {currentUser.role} | {currentUser.email}
            </Typography>
            <Box
              display={{ xs: 'flex', md: 'flex' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Button size="small" sx={{ mr: 2 }} variant="contained" color="secondary" onClick={() => handleClickOpenPassww()} >
                  Cambiar Clave
                </Button>
                <Button size="small" variant="outlined" color="secondary" onClick={() => handleClickOpenEmail()}>
                  Cambiar Correo
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <RecentActivity />
        </Grid>
      </Grid>
      <ChangeEmail open={changeEmail} handleClose={handleClose} data={changeEmailData.current} />
      <ChangePasswordForm open={changepasww} handleClose={handleClose} data={changepaswwData.current} />


    </>











    /*     <div className="profile-container">
          <Typography component="h1" variant="h5">
            Usuario
          </Typography>
          <UserDetails/>
          <Divider variant="middle" />
          <br/>
          <Typography component="h1" variant="h5">
            Cambiar Password
          </Typography>
          <ChangePasswordForm />
        </div> */
  )
}
