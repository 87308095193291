import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useRequests, getErrorMsg, useCustomSnackbar, useCurrentUser, useConfirmationDialog, formatDate, useDialogTools, useBackdrop } from '../../../helpers';
import { PriorityDisplay, SkeletonList, ConditionalWrapper, Autocomplete, SkeletonDetailTitle, SkeletonDetailContent } from '../../layout';
import { TicketFollowUp, FilesDialog, AssignTicketDialog, ResolveTicketDialog, CauseDialog } from "../."
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import {
  Paper,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Breadcrumbs,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  SvgIcon,
  Card,
  CardHeader,
  CardContent,
  Box,
  Hidden,
  Fab,
  Container
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
//import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
//import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { ContentState, EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { NewIssueDialog } from "../../jira"
import { styled } from '@mui/material/styles';
import TaskIcon from '@mui/icons-material/Task';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import '../../../stylesheets/activity.scss'
import { AutoActivityDialog } from '../list/auto_activity_dialog';
import PersonIcon from "@mui/icons-material/Person";
import { CardCollapse } from '../../layout/basic/CardCollapse';

const useStyles = makeStyles(theme => ({

  root: {

    "& .MuiContainer-root": {
      paddingLeft: 10,
      overflowY: null,
    },
    flexDirection: "column", //change to row for horizontal layout
    "& .MuiCardHeader-root": {
      backgroundColor: "#64b5f6"
    },
    "& .MuiCardHeader-title": {
      fontSize: 18
      //could also be placed inside header class

    },
    "& .MuiCardHeader-subheader": {
      /* backgroundImage: "linear-gradient(to bottom right, #090977, #00d4ff);" */
    },
    "& .MuiCardContent-root": {
      fontSize: 13
      /*       backgroundImage: "linear-gradient(to bottom right, #00d4ff, #00ff1d);" */
    }
  },

  footer: {
    fontSize: 14,
    backgroundImage: "linear-gradient(to bottom right, #8c9d9b, #bdcdbf);"
  },

  header: {
    backgroundColor: theme.palette.primary.light,
    padding: "1%",
    color: theme.palette.primary.contrastText,
    fontSize: 14
  },
  issueLinks: {
    margin: "1%"
  },
  contentItem: {
    flex: "calc(50% - 4px)",
    "@media (max-width: 500px)": {
      flex: "100%"
    }
  }
}));

export const TicketDetail = ({ match }) => {
  const currentUser = useCurrentUser()
  const classes = useStyles();
  const { dialogOpen: issueOpen, openD: openIssue, closeD: closeIssue } = useDialogTools()
  const { dialogOpen: openFiles, openD: openFilesDialog, closeD: closeFilesDialog } = useDialogTools()
  const { dialogOpen: assignOpen, openD: assignTicketOpen, closeD: assignTicketClose } = useDialogTools()
  const { dialogOpen: resolveOpen, openD: resolveTicketOpen, closeD: resolveTicketClose } = useDialogTools()
  const { dialogOpen: causeOpen, closeD: closeCause } = useDialogTools()
  const { dialogOpen: autoActivityOpen, openD: openAutoActivity, closeD: hideAutoActivity,
  } = useDialogTools()
  const dialogData = useRef({});
  const [ticket, setTicket] = useState({})
  const [attachments, setAttachments] = useState([])
  const { show, off } = useBackdrop()
  const { eSnack, sSnack } = useCustomSnackbar()
  const { get, form, post, controller } = useRequests()
  const [statuses, setStatuses] = useState([])
  const [priorities, setPriorities] = useState([])
  const [types, setTypes] = useState([])
  const [descriptionState, setDescriptionState] = useState(() => EditorState.createEmpty())
  const { getConfirmation } = useConfirmationDialog()
  const [loadingTicket, setLoadingTicket] = useState(false)
  //const [loadingTicketr, setLoadingTicketr] = useState(false)
  const [loadingTypes, setLoadingTypes] = useState(false)
  const [loadingPriorities, setLoadingPriorities] = useState(false)
  const [loadingStatuses, setLoadingStatuses] = useState(false)

  const [referencia, setReferencia] = useState({})

  const [causes, setCauses] = useState([]);
  const [cause, setCause] = useState(null);
  const [loadingAC, setloadingAC] = useState(false)

  const getCauses = useCallback(async () => {
    try {
      setloadingAC(true)
      var response = await get("/tickets/causes");
      if (response?.ok) {
        const data = await response.json();
        setCauses(data);
      } else {
        eSnack(await getErrorMsg(response), getCauses);
        console.error("Error Retrieving Causes");
      }
    } catch (e) {
      eSnack("Error inesperado cargando causas", getCauses);
      console.error("Retrieving Causes:", e);
    } finally { setloadingAC(false) }
  }, [get, eSnack]);

  useEffect(() => {
    if (!causes.length) {
      getCauses()
    }
  }, [causes, getCauses])

  useEffect(() => {
    if (ticket.ticket_cause) {
      setCause(ticket.codcausa)
    }
    else {
      setCause(cause)
    }
  }, [ticket, cause])

  useEffect(() => {
    const conversation = ticket.conversation || []
    const ticketLinks = ticket.file_links || []
    const newAttachments = conversation.map(entry => [...entry.file_links])
    newAttachments.push(...ticketLinks)
    setAttachments(newAttachments.flat(1))
  }, [ticket])

  useEffect(() => {
    if (ticket.fproblema) {
      setDescriptionState(EditorState.createWithContent(convertFromRaw(JSON.parse(ticket.fproblema))))
    } else if (ticket.problema) {
      setDescriptionState(EditorState.createWithContent(ContentState.createFromText(ticket.problema)))
    }
  }, [ticket])

  const getTicket = useCallback(async () => {
    try {
      setLoadingTicket(true)
      const response = await get(`/tickets/${match.params.id}`)
      if (response?.ok) {
        const ticket = await response.json()
        setTicket(ticket)
      } else {
        eSnack(await getErrorMsg(response), getTicket)
        /* console.log("error getting ticket"); */
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo ticket")
      /*    console.log("Error getting Ticket", e); */
    } finally { setLoadingTicket(false) }
  }, [get, match.params.id, eSnack])

  const getTicketRef = useCallback(async () => {
    try {
      //  setLoadingTicketr(true)
      const response = await get(`/tickets/referencia`, {
        id: match.params.id
      })
      if (response?.ok) {
        const referencia = await response.json()
        setReferencia(referencia)
      } else {
        eSnack(await getErrorMsg(response), getTicketRef)
        /*        console.log("error getting ticket"); */
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo ticket")
      /*  console.log("Error getting Ticket", e); */
    } finally { /*setLoadingTicketr(false) */ }
  }, [get, match.params.id, eSnack])

  useEffect(() => {
    getTicketRef()
  }, [getTicketRef])

  const getStatuses = useCallback(async () => {
    // iSnack("Cargando Estados")
    try {
      setLoadingStatuses(true)
      const response = await get(`/statuses`, {
        type: ticket.tipinc
      })
      if (response?.ok) {
        const data = await response.json()
        setStatuses(data)
      } else {
        eSnack(await getErrorMsg(response), getStatuses)
        /*       console.log("error getting statuses"); */
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo estados", getStatuses)
      /*   console.log("Error getting Statuses", e); */
    } finally { setLoadingStatuses(false) }
  }, [get, eSnack, ticket.tipinc])

  const getPriorities = useCallback(async () => {
    // iSnack("Obteniendo Prioridades")
    try {
      setLoadingPriorities(true)
      const response = await get('/priorities')
      if (response?.ok) {
        const priorities = await response.json()
        setPriorities(priorities)
      } else {
        eSnack(`${await getErrorMsg(response)}`, getPriorities)
        /*         console.log("Error getting priorities"); */
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo prioridades", getPriorities)
      /*       console.log("Error getting priorities", e); */
    } finally { setLoadingPriorities(false) }
  }, [eSnack, get])

  const getTypes = useCallback(async () => {
    // iSnack("Obteniendo Tipos")
    try {
      setLoadingTypes(true)
      const response = await get('/types')
      if (response?.ok) {
        const types = await response.json()
        setTypes(types)
      } else {
        eSnack(`${await getErrorMsg(response)}`, getTypes)
        /*         console.log("Error getting types"); */
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo tipos", getTypes)
      /*       console.log("Error getting types", e); */
    } finally { setLoadingTypes(false) }
  }, [eSnack, get])

  const sendMessage = async e => {
    show("Respondiendo")
    try {
      const data = new FormData(e.target)
      data.append('ticket_id', match.params.id)
      const response = await form(`/journals`, data)
      if (response?.ok) {
        const conversation = await response.json()
        setTicket({ ...ticket, conversation: conversation })
        sSnack("Mensaje Enviado")
        return true
      } else {
        eSnack(await getErrorMsg(response))
        /*       console.log("error sending message"); */
      }
    } catch (e) {
      eSnack("Error inesperado enviando mensaje")
      /*       console.log("Error sending message", e); */
    } finally {
      off()
    }
    return false
  }


  useEffect(() => {
    getTicketRef()
  }, [getTicketRef])

  const changeStatus = async (newStatus, cause) => {
    if (ticket.codcausa != null)
      cause = ticket.codcausa
    show("Cambiando Estado")
    try {
      const response = await post(`/tickets/change_status`, {
        id: ticket.idinc,
        new_status: newStatus,
        cause: cause,
        codcli: ticket.codcli
      })
      if (response?.ok) {
        const ticket = await response.json()
        setTicket(ticket)
        if (causeOpen) {
          closeCause()
        }
      } else {
        eSnack(await getErrorMsg(response))
        /*         console.log("error changing status"); */
      }
    } catch (e) {
      eSnack("Error inesperado cambiando estado")
      /*       console.log("Error changing status", e); */
    } finally {
      off()
    }
  }

  const changePriority = async newPriority => {
    show("Cambiando Prioridad")
    try {
      const response = await post(`/tickets/change_priority`, {
        id: ticket.idinc,
        new_priority: newPriority
      })
      if (response?.ok) {
        const ticket = await response.json()
        setTicket(ticket)
      } else {
        eSnack(await getErrorMsg(response))
        /*         console.log("error changing priority"); */
      }
    } catch (e) {
      eSnack("Error inesperado cambiando prioridad")
      /*       console.log("Error changing priority", e); */
    } finally {
      off()
    }
  }

  const changeType = async newType => {
    show("Cambiando Tipo")
    try {
      const response = await post(`/tickets/change_type`, {
        id: ticket.idinc,
        new_type: newType
      })
      if (response?.ok) {
        const ticket = await response.json()
        setTicket(ticket)
      } else {
        eSnack(await getErrorMsg(response))
        /*         console.log("error changing type"); */
      }
    } catch (e) {
      eSnack("Error inesperado cambiando tipo")
      /*       console.log("Error changing type", e); */
    } finally {
      off()
    }
  }
  const updateCausa = async cause => {
    show("Cambiando Causa")
    try {
      const response = await post(`/tickets/update_causa`, {
        id: ticket.idinc,
        cause: cause
      })
      if (response?.ok) {
        const ticket = await response.json()
        setTicket(ticket)
      } else {
        eSnack(await getErrorMsg(response))
        /*         console.log("error changing type"); */
      }
    } catch (e) {
      eSnack("Error inesperado cambiando causa")
      /*       console.log("Error changing type", e); */
    } finally {
      off()
    }
  }

  const showAutoActivity = () => {

    dialogData.current = ticket;
    openAutoActivity();


  };

  const resolveTicket = useCallback(async e => {
    e.preventDefault()
    const data = new FormData(e.target)
    data.append("id", ticket.idinc)
    data.append("codcli", ticket.codcli)
    show("Marcando Ticket como resuelto")
    try {
      const response = await form(`/tickets/resolve`, data)
      if (response?.ok) {
        const ticket = await response.json()
        resolveTicketClose()
        setTicket(ticket)
      } else {
        eSnack(await getErrorMsg(response))
        /*         console.log("error resolving ticket"); */
      }
    } catch (e) {
      eSnack("Error inesperado resolviendo ticket")
      /*       console.log("Error changing status to resolved", e); */
    } finally {
      off()
    }
  }, [ticket, eSnack, form, show, off, resolveTicketClose])

  const notifyTicket = async newStatus => {
    show("Marcando Ticket como notificado")
    try {
      const response = await post(`/tickets/notify`, {
        id: ticket.idinc,
        codcli: ticket.codcli
      })
      if (response?.ok) {
        const ticket = await response.json()
        setTicket(ticket)
      } else {
        eSnack(await getErrorMsg(response))
        /*         console.log("error notifying ticket"); */
      }
    } catch (e) {
      eSnack("Error inesperado marcando como notificado el ticket")
      /*       console.log("Error changing status top notified", e); */
    } finally {
      off()
    }
  }

  const checkChangeStatus = async (_, value) => {
    if (await getConfirmation({ title: "Cambiar Estado", message: `Cambiar estado de ${ticket.stsinc} -> ${value.stsinc}?` })) {
      changeStatus(value.stsinc)
    }
  }
  const sendCausa = async (_, value) => {
    if (await getConfirmation({ title: "Causa", message: `Desea Asignar la causa ${value.codcausa}?` })) {
      // changeStatus("ENV", value.codcausa)
      updateCausa(value.codcausa)

    }
  }
  const getTitle = useCallback(() => `[Ticket #${ticket.idinc}] ${ticket.descprobinc}`, [ticket])

  useEffect(() => {
    getTicket()
  }, [getTicket])

  useEffect(() => {
    if (currentUser.can?.change_status && ticket.tipinc) getStatuses()
  }, [getStatuses, currentUser.can, ticket.tipinc])

  useEffect(() => {
    if (currentUser.can?.change_priority) getPriorities()
  }, [getPriorities, currentUser.can])

  useEffect(() => {
    if (currentUser.can?.change_type) getTypes()
  }, [getTypes, currentUser.can])

  useEffect(() => () => controller.abort(), [controller])

  const Item = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.7),
    textAlign: 'center',
    color: theme.palette.text.primary,
  }));

  const referenciaTicket = () => {
    const myJSON = JSON.stringify(referencia);
    localStorage.setItem("testJSON", myJSON);
    let text = localStorage.getItem("testJSON");
    let obj = JSON.parse(text);
    if (obj && obj.referencia) {
      return (
        <>
          Referencia: <b>{obj.referencia}</b>
        </>
      );
    } else {
      return null; // O puedes retornar un valor por defecto si no hay referencia
    }
  }

  const resolverTicket = () => {
    if (ticket.stsinc !== "RES") {
      return (
        <Grid item xs={12} md={12}>
          <Item>
            <Fab size="small" color="secondary" aria-label="add" title="Resolver">
              <TaskIcon color='white' onClick={resolveTicketOpen} type="submit" />
            </Fab>
          </Item>
        </Grid>
      )
    }

  }
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      Detalle Ticket {ticket.idinc}
    </Typography>,
  ];

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth={false}
        style={{
          width: '100%',
          paddingTop: "20px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
        disableGutters
      >
        <Grid container spacing={2} style={{ height: "82vh" }}> {/* Para sreferenciaTicketeparar el paper de la izquierda con la derecha */}
          <Grid item xs={12} lg={3.5} style={{ height: "100%" }}> {/* lado Izquierdo Detalles del ticket */}
            <ConditionalWrapper condition={loadingTicket} wrapper={children => <SkeletonList number={10}>{children}</SkeletonList>}>
              <Paper
                elevation={24}
                sx={{
                  boxShadow: "none",
                  overflowX: "scroll",
                  overflowY: "scroll",
                  height: "100%",

                }}
              >
                <Card style={{ width: '100%', maxWidth: '100%', margin: 0, }}>
                  <CardContent style={{ height: "100%" }}>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {ticket.h_avatar ? (
                        <img
                          alt=""
                          src={ticket.h_avatar}
                          style={{ width: "120px", height: "120px" }}
                        />
                      ) : (
                        <PersonIcon
                          style={{
                            color: "#009AFE",
                            width: "120px",
                            height: "120px",

                          }}
                        />
                      )}
                    </div>
                    <Typography variant="h6" align="center" sx={{ marginTop: 2, color: "#009AFE" }}>
                      <b>{ticket.codcli} </b>
                    </Typography>

                    <Typography align="left" sx={{ fontSize: "14px" }}>
                      Registrado por: <b> {ticket.h_pers} </b>
                    </Typography>
                  </CardContent>
                  <CardCollapse title={"Detalles"} >
                    <Container>
                      <br />
                      {currentUser.can?.change_type && ticket.can?.change_type ? (
                        <div>
                          <Autocomplete
                            disableClearable={true}
                            loading={loadingTypes}
                            options={types}
                            size="small"
                            value={ticket.tipinc}
                            valueKey={"tipinc"}
                            nullable={false}
                            onChange={async (_, value) => {
                              if (value.tipinc && (await getConfirmation({ title: "Cambiar Tipo", message: `Cambiar tipo de ${ticket.h_type} -> ${value.desctipinc}?` }))) {
                                changeType(value.tipinc)
                              }
                            }}
                            getOptionLabel={(option) => `${option.desctipinc}`}
                            renderInput={(params) => <TextField {...params} required fullWidth label="Tipo" />}
                          />
                        </div>
                      ) : (
                        <Typography align="left" sx={{ fontSize: "14px" }}>
                          Tipo: <b>{ticket.h_type ? ticket.h_type : ticket.tipinc}</b>
                        </Typography>
                      )}

                      {currentUser.can?.change_status && ticket.can?.change_status ? (
                        <div>
                          <Grid container spacing={0}>
                            <Grid item xs={12} md={12} mt={1}>
                              <Autocomplete
                                loading={loadingStatuses}
                                disableClearable={true}
                                options={statuses}
                                size="small"
                                value={ticket.stsinc}
                                valueKey={"stsinc"}
                                nullable={false}
                                onChange={checkChangeStatus}
                                getOptionLabel={(option) => `${option.descstatus}`}
                                renderInput={(params) => <TextField {...params} required fullWidth label="Estado" />}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        <Typography align="left" sx={{ fontSize: "14px" }}>
                          Estado: <b>{ticket.h_status ? ticket.h_status : ticket.stsinc}</b>
                        </Typography>
                      )}

                      {currentUser.can?.change_status && ticket.can?.change_status ? (
                        <div>
                          <Grid container spacing={0}>
                            <Grid item xs={12} md={12} mt={1}>
                              <Autocomplete
                                fullWidth
                                name="codcausa"
                                id="codcausa"
                                options={causes}
                                size="small"
                                value={cause}
                                setValue={setCause}
                                valueKey={"codcausa"}
                                loading={loadingAC}
                                onChange={sendCausa}
                                getOptionLabel={(option) => `${option.codcausa} - ${option.desccausa} - ${option.respcausa}`}
                                renderInput={(params) => <TextField {...params} required fullWidth label="Causa" />}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        <Typography align="left" sx={{ fontSize: "14px" }}>
                          Causa: <b>{ticket.ticket_cause?.desccausa}</b>
                        </Typography>
                      )}
                      {currentUser.can?.change_priority && ticket.can?.change_priority ? (
                        <div style={{ marginTop: 8 }}>
                          <Autocomplete
                            disableClearable={true}
                            loading={loadingPriorities}
                            options={priorities}
                            size="small"
                            value={ticket.prioridad}
                            valueKey={"prioridad"}
                            nullable={false}
                            onChange={async (_, value) => {
                              if (value.prioridad && (await getConfirmation({ title: "Cambiar Prioridad", message: `Cambiar prioridad de ${ticket.h_priority} -> ${value.descpri}?` }))) {
                                changePriority(value.prioridad)
                              }
                            }}
                            getOptionLabel={(option) => `${option.descpri}`}
                            renderInput={(params) => <TextField {...params} required fullWidth label="Prioridad" />}
                          />
                        </div>)
                        : (
                          <Typography align="left" sx={{ fontSize: "14px" }}>
                            <Box className="priority-container">Prioridad:
                              <PriorityDisplay priority={ticket.prioridad} text={ticket.h_priority} /></Box>
                          </Typography>
                        )}
                      <br />
                    </Container>
                  </CardCollapse>
                  <CardCollapse title={"Referencias"}>
                    <Container>
                      <br />
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {referenciaTicket()}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px", my:1 }}>
                        Versión:<b> {ticket.version}</b>
                      </Typography>

                      <Grid container spacing={0} my={1} alignItems="center">
                        <Grid item xs>
                          <Typography align="left" sx={{ fontSize: "14px" }}>
                            Asignados:
                          </Typography>
                          {!!ticket.assigned_users?.length && (
                            <div className="status-holder">
                              <Typography align="left" sx={{ fontSize: "14px" }}>
                                <b>{ticket.assigned_users.map((user) => user.nombpers).join(", ")}</b>
                              </Typography>
                            </div>
                          )}
                        </Grid>
                        {!!currentUser.can?.assign_ticket && (
                          <Grid item>
                            <Tooltip title="Asignar">
                              <IconButton>
                                <SupervisedUserCircleIcon sx={{ fontSize: 28, right: 0, position: "absolute" }} color="secondary" onClick={assignTicketOpen} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>

                      {currentUser.can?.see_activities && !!ticket.idactividad && (
                        <Typography align="left" sx={{ fontSize: "14px" }}>
                          Actividad: <b><Link component={RouterLink} to={`/activities/${ticket.idactividad}`}>{ticket.idactividad} - {ticket.activity?.nomactividad}</Link></b>
                        </Typography>
                      )}

                      {(currentUser?.can?.see_activities && ticket["activity_auto_assignable?"]) && (
                        <Grid container spacing={0} alignItems="center">
                          <Grid item xs>
                            <Typography align="left" sx={{ fontSize: "14px" }}>
                              Actividad:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Asignación automática de actividad">
                              <InfoRoundedIcon sx={{ fontSize: 28 }} color="secondary" onClick={() => showAutoActivity(ticket)} />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={0} alignItems="center">
                        <Grid item xs>
                          {!!currentUser.can?.jira_see_issue && ticket.issues && (
                            <Typography align="left" sx={{ fontSize: "14px" }}>
                              Issues de Jira: <b>{ticket.issues.map((issue) => <a className={classes.issueLinks} href={issue.remote_url} key={`remote-issue-${issue.remote_issue_key}`}>{issue.remote_issue_key}</a>)}</b>
                            </Typography>
                          )}
                        </Grid>


                        {!!currentUser.can?.jira_create_issue && (
                          <Grid item>
                            <IconButton type="submit" onClick={openIssue} color="primary" aria-label="add to shopping cart" sx={{
                              backgroundColor: '#009AFE',
                              boxShadow: 3,
                              maxWidth: 25, maxHeight: 25,
                              "&:hover": {
                                backgroundColor: '#009AFE',
                              },

                            }}>
                              <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15.6105 28C17.931 25.6795 17.931 21.9005 15.6105 19.5469L7.82045 11.79L4.27348 15.337C3.90884 15.7016 3.90884 16.2983 4.27348 16.6961L15.6105 28Z" fill="white" />
                                  <path d="M26.9473 15.337L15.6105 4L15.5773 4.03315C13.2569 6.35359 13.2901 10.1326 15.6105 12.453L23.4004 20.2099L26.9473 16.663C27.312 16.2983 27.312 15.7016 26.9473 15.337Z" fill="white" />
                                  <path d="M15.6105 12.4531C13.2901 10.1326 13.2901 6.35364 15.5774 4.0332L7.48901 12.1216L11.699 16.3315L15.6105 12.4531Z" fill="url(#paint0_linear)" />
                                  <path d="M19.4888 15.6685L15.6104 19.5469C17.9308 21.8673 17.9308 25.6463 15.6104 27.9999L23.6988 19.9115L19.4888 15.6685Z" fill="url(#paint1_linear)" />
                                  <defs>
                                    <linearGradient id="paint0_linear" x1="15.4374" y1="8.41486" x2="10.5381" y2="13.3141" gradientUnits="userSpaceOnUse">
                                      <stop offset="0.0680226" stop-color="white" stop-opacity="0.4" />
                                      <stop offset="1" stop-color="white" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear" x1="15.9346" y1="23.4582" x2="21.2996" y2="18.0931" gradientUnits="userSpaceOnUse">
                                      <stop offset="0.0680226" stop-color="white" stop-opacity="0.4" />
                                      <stop offset="0.9077" stop-color="white" />
                                    </linearGradient>
                                  </defs>
                                </svg>
                              </SvgIcon>
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                      <br />
                    </Container>
                  </CardCollapse>
                  <CardCollapse title={"Fechas"}>
                    <Container>
                      <br />
                      {/* FECHAS */}
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        Fecha Ingreso:
                        {!!ticket.fecinginc && <b>{formatDate(new Date(ticket.fecinginc))}</b>}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        Fecha Atención:
                        {!!ticket.fecaten && <b>{formatDate(new Date(ticket.fecaten))}</b>}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        Fecha Cierre:
                        {!!ticket.feccieraten && <b>{formatDate(new Date(ticket.feccieraten))}</b>}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        Fecha Actualización:
                        {!!ticket.fecsts && <b>{formatDate(new Date(ticket.fecsts))}</b>}
                      </Typography>

                      {/* ARCHIVOS ANEXADOS */}
                      <Grid container spacing={0}>
                        {!!ticket.can?.be_resolved && resolverTicket()}
                        {!!ticket.can?.be_notified && (
                          <Grid item xs={12} md={12}>
                            <Item>
                              <Fab
                                size="small"
                                color="secondary"
                                aria-label="add"
                                title="Notificado"
                              >
                                <NotificationImportantIcon
                                  color="white"
                                  onClick={async () =>
                                    (await getConfirmation({
                                      title: "Notificar",
                                      message: "Marcar ticket como notificado?",
                                    }))
                                      ? notifyTicket()
                                      : null
                                  }
                                  type="submit"
                                />
                              </Fab>
                            </Item>
                          </Grid>
                        )}
                      </Grid>

                    </Container>
                    <br />
                  </CardCollapse>
                </Card>
              </Paper>
            </ConditionalWrapper>
          </Grid>
          <Grid item xs={12} lg={8.5} style={{ height: "100%" }} > {/* Lado Derecho info del ticket */}
            <ConditionalWrapper condition={loadingTicket} wrapper={children => <SkeletonDetailTitle>{children}</SkeletonDetailTitle>}>
              <Card elevation={3} className={classes.root} >
                <CardHeader
                  action={
                    <>
                      {!!attachments.length && <Tooltip title="Archivos Adjuntos">
                        <IconButton

                          color="inherit"
                          size="medium"
                          onClick={openFilesDialog}
                        >
                          <AttachFileTwoToneIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>}

                    </>
                  }
                  className={classes.header}
                  component={Typography}
                  title={getTitle()} />
                <CardContent className={classes.content}>
                  <div className={`${classes.contentItem} ${classes.textContent}`}>
                    <div className="status-holder">
                      Sistema: <b>{ticket.codsistema} - {ticket.h_system}</b>
                    </div>
                  </div>
                </CardContent>
                <CardContent className={classes.content}>
                  <b> Descripción del ticket: </b>
                  <Editor
                    title="Editor de texto enriquecido"
                    readOnly
                    toolbarHidden
                    wrapperClassName="show-wrapper-class"
                    editorClassName="show-editor-class"
                    editorState={descriptionState}
                  />
                  {!!ticket.respuesta && <div className="status-holder">
                    <div className="">
                      Respuesta: <b>{ticket.respuesta}</b>
                    </div>
                  </div>}
                </CardContent>
              </Card>
            </ConditionalWrapper>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    marginTop: 2,
                  }}>
                  <ConditionalWrapper condition={loadingTicket} wrapper={children => <SkeletonDetailContent>{children}</SkeletonDetailContent>}>
                    <div
                      style={{
                        maxHeight: '55vh',
                        overflowY: 'auto',
                      }}
                    >
                      <TicketFollowUp status={ticket.stsinc} ticket_id={match.params.id} setTicket={setTicket} ticket={ticket} reload={getTicket} conversation={ticket.conversation} sendMessage={sendMessage} />
                    </div>
                  </ConditionalWrapper>
                  <FilesDialog handleClose={closeFilesDialog} open={openFiles} files={attachments} />
                  <AssignTicketDialog reload={ticket => setTicket(ticket)} assignTicketOpen={assignOpen} handleClose={assignTicketClose} ticket={ticket} />
                  <ResolveTicketDialog resolve={resolveTicket} dialogOpen={resolveOpen} closeD={resolveTicketClose} />
                  <NewIssueDialog open={issueOpen} handleClose={closeIssue} reload={getTicket} issueTitle={getTitle()} issueDescription={ticket.problema} issuableId={ticket.idinc} issuableType={"Ticket"} />
                  <CauseDialog open={causeOpen} handleClose={closeCause} changeStatus={changeStatus} ticket={ticket} />
                  {!!currentUser?.can?.see_activities && (<AutoActivityDialog reload={getTicket} autoActivityOpen={autoActivityOpen} handleClose={hideAutoActivity}
                    ticket={dialogData.current}
                  />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>)
}
