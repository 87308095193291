import React, { useEffect, useState, useCallback, useRef } from "react"
import { BaseTable as ClientsTable, BaseDialog, BaseNewForm, BaseTablePagination, BaseFilters } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, parseRailsEnum, useCurrentUser, useDialogTools, useTableTools, useParsedURLParams, useBackdrop, FIELD_TYPES } from "../../../helpers"
import { InstalledDialog, InstallDialog } from "../."
/* import ExtensionIcon from '@mui/icons-material/Extension';
import ViewListTwoToneIcon from '@mui/icons-material/ViewList'; */
import ExtensionTwoToneIcon from '@mui/icons-material/ExtensionTwoTone';
import ViewListTwoToneIcon from '@mui/icons-material/ViewListTwoTone';
import { AppBar, Breadcrumbs, Stack, Toolbar, Tooltip, Typography, Container, IconButton } from "@mui/material"
import { IconButtonMenu } from "../../layout/basic/IconButtonMenu";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import FilterListIcon from '@mui/icons-material/FilterList';

import PersonIcon from "@mui/icons-material/Person";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { ModalLogo } from "./modal_logo";
import ContactsTwoToneIcon from '@mui/icons-material/ContactsTwoTone';

export const ClientsList = props => {
  const currentUser = useCurrentUser()
  const [clients, setClients] = useState([])
  const [clientStates, setClientStates] = useState([])
  const { get, form, controller } = useRequests()
  const [appliedFilters, setAppliedFilters] = useState(useParsedURLParams())
  const clientFilters = {
    codcli: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Codigo"
    },
    nomcli: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Nombre"
    },
    stscli: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Estado"
    }
  }
  const { show, off } = useBackdrop()
  const { eSnack, sSnack } = useCustomSnackbar()
  const { dialogOpen, openD, closeD } = useDialogTools()
  const [formId] = useState("clients_form")
  const { page, rowsPerPage, total, setTotal, maxPage, setMaxPage, changePage, changeRows } = useTableTools()
  const { dialogOpen: installedOpen, openD: openID, closeD: closeID } = useDialogTools()
  const { dialogOpen: installOpen, openD: openI, closeD: closeI } = useDialogTools()
  const { dialogOpen: avatarOpen, openD: openA, closeD: closeA } = useDialogTools()
  const selectedClient = useRef(null)
  const selectedClient2 = useRef(null)
  const selectedClient3 = useRef(null)

  const [loadingTable, setLoadingTable] = useState(false)
  const [filtroOpen, setfiltroOpen] = useState(false);
  const toggle = () => setfiltroOpen(!filtroOpen)

  const [filePreview, setFilePreview] = useState(null);

  const onChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = handleFileReaderLoad;
    reader.readAsDataURL(file);
  };

  const handleFileReaderLoad = (event) => {
    setFilePreview(event.target.result);
  };

  const getClients = useCallback(async () => {
    // show("Cargando Clientes")
    try {
      setLoadingTable(true)
      const response = await get('/administration/clients', {
        page: page + 1,
        rowsPerPage: rowsPerPage,
        ...appliedFilters
      })
      if (response?.ok) {
        const data = await response.json()
        setClients(data.clients)
        setMaxPage(data.maxPage)
        setClientStates(parseRailsEnum(data.states))
        setTotal(data.total)
      } else {
        eSnack(await getErrorMsg(response), getClients)
      }
    } catch (e) {
      console.log(e);
      eSnack("Error inesperado obteniendo clientes")
    } finally { setLoadingTable(false) }
  }, [get, page, rowsPerPage, eSnack, setTotal, setMaxPage, appliedFilters])

  const createClient = useCallback(async e => {
    e.preventDefault()
    show('Creando Cliente')
    const data = new FormData(e.target)
    data.append('avatar', filePreview) // agrega la imagen a FormData
    try {
      const request = await form('/administration/clients', data)
      if (request?.ok) {
        sSnack("Cliente Creado")
        getClients()
        closeD()
      } else {
        eSnack(await getErrorMsg(request))
      }
    } catch (e) {
      eSnack("Error inesperado creando cliente")
      console.log("Error", e);
    } finally {
      off()
    }
  }, [show, sSnack, getClients, closeD, eSnack, off, form, filePreview])


  useEffect(() => {
    getClients()
  }, [getClients])

  const applyFilters = filters => {
    changePage(null, 0)
    setAppliedFilters(filters)
  }

  const openInstalled = useCallback(row => {
    selectedClient.current = row.codcli
    openID()
  }, [openID])

  const openInstall = useCallback(row => {
    selectedClient2.current = row.codcli
    openI()
  }, [openI])

  const openAvatar = useCallback(row => {
    selectedClient3.current = row.codcli
    openA()
  }, [openA])

  const closeInstalled = useCallback(() => {
    selectedClient.current = null
    closeID()
  }, [closeID])

  const closeInstall = useCallback(() => {
    selectedClient2.current = null
    closeI()
  }, [closeI])
  const closeAvatar = useCallback(() => {
    selectedClient3.current = null
    closeA()
  }, [closeA])

  useEffect(() => () => controller.abort(), [controller])

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      Clientes
    </Typography>,
  ];

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <IconButtonMenu
            open={filtroOpen}
            toggleMenu={toggle}
            size="small"
            color={"primary"}
            icon={<FilterListIcon />}
          >
            <BaseFilters filters={clientFilters} applyFilters={applyFilters} />
          </IconButtonMenu>
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container className="base-container">
        <ClientsTable
          loading={loadingTable}
          rows={clients}
          headers={["Codigo", "Nombre", "Estado", "Acciones"]}
          collapsible={{
            visible: row => [{
              content: (
                <>
                    {row.avatar ? (
                        <img src={row.avatar} alt="Avatar" style={{ width: "25px", height: "25px", marginRight: "10px", marginBottom: "-5px" }} />
                    ) : (
                        <PersonIcon style={{ color: "#888888", marginRight: "10px", marginBottom: "-5px" }} />
                    )}
                    <span>{row.codcli}</span>
                </>
            ),
            handleCollapse: true,
            align: "left"
            }, { content: row.nomcli }, { content: row.stscli },
            {
              content: <>
                <Tooltip title="Sistemas Instalados">
                  <ViewListTwoToneIcon color="secondary" onClick={() => openInstalled(row)} />
                </Tooltip>
                {currentUser.can.create_installations &&
                  <Tooltip title="Instalar Sistema">
                    <ExtensionTwoToneIcon color="secondary" onClick={() => openInstall(row)} />
                  </Tooltip>
                }
                {currentUser.can.create_installations &&
                  <Tooltip title="Colocar Avatar">
                    <ContactsTwoToneIcon color="secondary" onClick={() => openAvatar(row)} />
                  </Tooltip>
                }
              </>,
              action: () => null,
              disableTooltip: true
            }
            ],
            collapsed: row => [
              { name: "Contacto", content: row.contacto },
              { name: "Copia Fax", content: row.copiafax },
              { name: "Fax", content: row.fax },
              { name: "Fecha Creación", content: row.feccre },
              { name: "Fecha Modificación", content: row.fecmod },
              { name: "Mensaje Fax", content: row.mensajefax },
              { name: "Prioridad", content: row.prioridadcliente },
              { name: "Telefono", content: row.telefono },
              { name: "Usuario Creador", content: row.usucre },
              { name: "Usuario Actualización", content: row.usumod }
            ]
          }}
          title="Clientes"
        >
          <BaseTablePagination
            page={page}
            maxPage={maxPage}
            rowsPerPage={rowsPerPage}
            totalRows={total}
            handlePageChange={changePage}
            handleChangeRowsPerPage={changeRows}
            openDialog={currentUser.can?.create_clients ? openD : null}
          />
        </ClientsTable>
      </Container>
      <BaseDialog
        dialogOpen={dialogOpen}
        handleClose={closeD}
        title="Nuevo Cliente"
        confirmText={"Crear Cliente?"}
        cancelText={"Cerrar"}
        formId={formId}
        width="sm"
        confirm
      >
        <div
          style={{
            marginLeft: "45%",
            borderRadius: "10px",
            position: "relative",
            backgroundColor: "#92A7C0",
            height: "89px",
            width: "89px",
          }}
        >
          {filePreview ? (
            <img
              alt=""
              src={filePreview}
              style={{ width: "89px", height: "89px" }}
            />
          ) : (
            <PersonIcon
              style={{
                color: "#FFFFFF",
                width: "35px",
                height: "35px",
                marginLeft: "28px",
                marginTop: "25px",
              }}
            />
          )}

          <div
            style={{
              position: "absolute",
              top: "55px",
              right: "0",
              height: "34px",
              width: "34px",
            }}
          >
            <input
              accept="image/*"
              id="icon-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={onChange}
            />

            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              sx={{
                backgroundColor: "#007CD6",
                width: "34px",
                height: "34px",
                left: "8px",
                top: "4px",
              }}
            >
              <label htmlFor="icon-button-file">
                <CameraAltOutlinedIcon
                  style={{
                    color: "#FFFFFF",
                    borderRadius: "50%" /* backgroundColor: "#007CD6" */,
                  }}
                />
              </label>
            </IconButton>
          </div>
        </div>
        <BaseNewForm
          fields={[
            {
              title: "Codigo",
              name: "client[codcli]",
              id: "code",
              type: FIELD_TYPES.TEXT,
              value: "",
              required: true,
              upcaseValue: true,
              maxLength: 10
            },
            {
              title: "Nombre",
              name: "client[nomcli]",
              id: "name",
              type: FIELD_TYPES.TEXT,
              value: "",
              required: true,
              maxLength: 60
            },
            {
              title: "Estado",
              name: "client[stscli]",
              id: "status",
              type: FIELD_TYPES.AUTOCOMPLETE,
              nullable: false,
              required: true,
              options: clientStates,
              value: clientStates[0]
            },
            {
              title: "Contacto",
              name: "client[contacto]",
              id: "contact",
              type: FIELD_TYPES.TEXT,
              value: "",
              maxLength: 30
            },
            {
              title: "Telefono",
              name: "client[telefono]",
              id: "phone",
              type: FIELD_TYPES.TEXT,
              value: "",
              maxLength: 20
            },
            {
              title: "Fax",
              name: "client[fax]",
              id: "fax",
              type: FIELD_TYPES.TEXT,
              value: "",
              maxLength: 20
            },
            {
              title: "Nota",
              name: "client[mensajefax]",
              id: "notes",
              type: FIELD_TYPES.TEXT,
              value: "",
              maxLength: 500
            },
            {
              title: "Prioridad",
              name: "client[prioridadcliente]",
              id: "priority",
              type: "number",
              value: 1,
              maxLength: 100
            }
          ]}
          dialog={true}
          formId={formId}
          submit={createClient}
        />
      </BaseDialog>
      <InstalledDialog dialogOpen={installedOpen} handleClose={closeInstalled} currentClient={selectedClient.current} />
      <InstallDialog dialogOpen={installOpen} handleClose={closeInstall} currentClient={selectedClient2.current} />
      <ModalLogo dialogOpen={avatarOpen} handleClose={closeAvatar} currentClient={selectedClient3.current} />


    </>
  )
}
