import React from "react"
import {Typography, Divider} from '@mui/material'
import {NotificationSettings} from '../.'

export const Configurations = props => {
  return (
    <div className="notifications-container">
      <Typography component="h1" variant="h5">
        Notificaciones
      </Typography>
      <NotificationSettings/>
      <Divider variant="middle" />
      <br/>
    </div>
  )
}
