import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import '../stylesheets/App.scss';
import '../stylesheets/layout.scss';
import { Navbar, Error404 } from './layout'
import { LOGIN_STATES, Sessions } from "./sessions"
import { Tickets } from './tickets'
import { Users } from './users'
import { Administration } from './administration'
import { Maintenance } from "./maintenance"
import { Releases } from "./releases"
import { Activities } from "./activities"
import { postJson, useCustomSnackbar, UserContext, getErrorMsg, ConfirmationDialogProvider, environment, inMaintenance, useBackdrop } from "../helpers"
import { withSnackbar } from 'notistack';

const App = props => {
  const { eSnack } = useCustomSnackbar()
  const [loggedIn, setLoggedIn] = useState(LOGIN_STATES.LOADING)
  const [currentUser, setCurrentUser] = useState({})
  const { show, off } = useBackdrop() //this is the hook of backdrop


  const setLoggedStatus = useCallback(status => {
    off()
    setLoggedIn(status)
  }, [off])

  const logOut = useCallback(() => {
    localStorage.removeItem("token")
    localStorage.removeItem("email")
    setLoggedStatus(LOGIN_STATES.LOGGEDOUT)
  },[setLoggedStatus])


  useEffect(() => {
    if (Object.keys(currentUser).length > 0) setLoggedStatus(LOGIN_STATES.LOGGEDIN)
  }, [currentUser, setLoggedStatus])

  const verifyLogin = useCallback(async () => {
    console.log("Verifying Login");
    show("Verificando Login")
    if (localStorage.token && localStorage.email && !inMaintenance) {
      try {
        const response = await postJson('/verify')
        if (response?.ok) {
          console.log("Verified")
          const user = await response.json()
          setCurrentUser(user)
        } else {
          console.log("Invalid Credentials")
          eSnack(await getErrorMsg(response))
          logOut()
        }
      } catch (e) {
        console.log("Error Verifying Login")
        console.log(e)
        eSnack("Error verificando credenciales")
        setLoggedStatus(LOGIN_STATES.LOGGEDOUT)
      }
    } else {
      console.log("Empty Info")
      logOut()
    }
  }, [setLoggedStatus, show, eSnack, logOut])

  const logIn = useCallback((token, email) => {
    localStorage.setItem("token", token)
    localStorage.setItem("email", email)
    verifyLogin()
  },[verifyLogin])

  useEffect(() => {
    verifyLogin()
  }, [verifyLogin])

  return (

    <UserContext.Provider value={currentUser}>
      <ConfirmationDialogProvider>
        <Router>
          <Navbar suffix={environment}
            loggedIn={!!(loggedIn === LOGIN_STATES.LOGGEDIN)}
            log_out={logOut}
          />
          {(() => {
            if (inMaintenance) {
              return <Maintenance />
            } else {
              switch (loggedIn) {
                case LOGIN_STATES.LOGGEDIN:
                  return (
                    <div className="app-container">
                      <Switch>
                        <Route path='/tickets' component={Tickets} />
                        <Route path='/users/:default?' component={Users} />
                        <Route path='/activities' component={Activities} />
                        {currentUser?.can?.admin && <Route path='/administration' component={Administration} />}
                        <Route path="/releases" component={Releases} />
                        <Route path="/404" component={Error404} />
                        <Redirect from="/sign_in" to="/" />
                        <Redirect from="/sign_up" to="/" />
                        <Redirect from="/" to="/tickets" />
                        <Redirect to="/404" />
                      </Switch>
                    </div>
                  )
                case LOGIN_STATES.LOGGEDOUT:
                  return (
                    <div className="app-container">
                      <Switch>
                        <Route path="/releases" component={Releases} />
                        <Route path="/" render={p => <Sessions {...p} login={logIn}/>} />
                      </Switch>
                    </div>
                  )
                case LOGIN_STATES.LOADING:
                  return null
                default:
                  return (<div>Unexpected login state</div>)
              }
            }
          })()
          }
        </Router>
      </ConfirmationDialogProvider>
    </UserContext.Provider>

  );
}

export default withSnackbar(App);
