import React, { useEffect, useState, useCallback } from "react"
import { BaseTable as AdrTable, BaseTablePagination } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, useTableTools, formatDate, useParsedURLParams, useDialogTools } from "../../../helpers"
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@mui/material/Link';
import { NewAdr, ChangeStatus } from "../."
import { AppBar, Breadcrumbs, Container, Stack, Toolbar, Typography } from "@mui/material"
import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButtonMenu } from "../../layout/basic/IconButtonMenu"
import { AdrFilter } from "./AdrFilter"

export const AdrList = ({ match }) => {
  const { get, controller } = useRequests()
  const [Adr, setAdr] = useState([])
  const [statuses, setStatuses] = useState([])
  const { dialogOpen: adrOpen, openD: openAdrNew, closeD: closeAdrNew } = useDialogTools()
  const history = useHistory();
  const [appliedFilters, setAppliedFilters] = useState(useParsedURLParams())
  const [loadingStates, setLoadingStates] = useState(false)
  const [filtroOpen, setfiltroOpen] = useState(false);
  const toggle = () => setfiltroOpen(!filtroOpen)
  const { eSnack } = useCustomSnackbar()
  const { page, rowsPerPage, total, setTotal, maxPage, setMaxPage, changePage, changeRows } = useTableTools()
  const [loadingTable, setLoadingTable] = useState(false)

  const getAdr = useCallback(async () => {
    try {
      setLoadingTable(true)
      const response = await get('/administration/adr', {
        page: page + 1,
        rowsPerPage: rowsPerPage,
        ...appliedFilters
      })
      if (response?.ok) {
        const data = await response.json()
        setAdr(data.adrlist)
        setMaxPage(data.maxPage)
        setTotal(data.total)
      } else {
        eSnack(await getErrorMsg(response), getAdr)
      }
    } catch (e) {
      /*  console.log(e); */
      eSnack("Error inesperado obteniendo Adr")
      /*  console.log("Error getting Statuses", e); */
    } finally { setLoadingTable(false) }
  }, [get, page, rowsPerPage, eSnack, setTotal, setMaxPage, appliedFilters,])

  const getAdrStatuses = useCallback(async () => {
    try {
      setLoadingStates(true)
      const response = await get(`/administration/adr/states`, {
      })
      if (response?.ok) {
        const data = await response.json()
        setStatuses(data)
      } else {
        eSnack(await getErrorMsg(response), getAdrStatuses)
        /* console.log("error getting statuses"); */
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo estados", getAdrStatuses)
      /*  console.log("Error getting Statuses", e); */
    } finally { setLoadingStates(false) }
  }, [get, eSnack])

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      Análisis de Requerimientos
    </Typography>,
  ];

  useEffect(() => {
    getAdrStatuses()
  }, [getAdrStatuses])


  useEffect(() => {
    getAdr()
  }, [getAdr,])

  const applyFilters = filters => {
    //console.log("muestra", filters)
    changePage(null, 0)
    setAppliedFilters(filters)
  }

  const fecadrdesde = (row) => {
    if (row.fecadr === null) {
      return (
        { content: row.fecadr }
      )
    }
    else {
      return (
        { content: formatDate(new Date(row.fecadr)) }
      )
    }
  }

  const openAdr = ({ numadr, codcli, codproy }) => {
    history.push(`${match.path}${numadr}/${codcli}/${codproy}`)
  }

  useEffect(() => () => controller.abort(), [controller])
  return (
    <>

      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <IconButtonMenu
            open={filtroOpen}
            toggleMenu={toggle}
            size="small"
            color={"primary"}
            icon={<FilterListIcon />}
          >
            <AdrFilter
              applyFilters={applyFilters}
              statuses={statuses}
              loadingStates={loadingStates}
            />
          </IconButtonMenu>
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>
      
      <Container className="base-container">
        <AdrTable
          loading={loadingTable}
          rows={Adr}
          rowAction={row => openAdr(row)}
          headers={["Id", "Cliente", "Proyecto", "Estado", "Fecha", "Acciones"]}

          collapsible={{
            visible: row => [
              { content: row.numadr, handleCollapse: true, align: "left" },
              { content: row.codcli },
              { content: row.codproy },
              { content: row.stsadr },
              fecadrdesde(row),
              {
                content: <ChangeStatus adr={row} reload={getAdr} />,
                action: () => null,
                disableTooltip: true
              },
            ],

            collapsed: row => [
              { name: "Nombre", content: row.nombre },
              { name: "Elaborado por", content: row.usuelaboracion },
              { name: "Director", content: row.nomdirproy },
              { name: "Facturacion", content: row.stsfacturacion }
            ]
          }}
          title="ADR"
        >
          <BaseTablePagination
            page={page}
            maxPage={maxPage}
            rowsPerPage={rowsPerPage}
            totalRows={total}
            handlePageChange={changePage}
            handleChangeRowsPerPage={changeRows}
            openDialog={openAdrNew}
          />
        </AdrTable>
        <NewAdr open={adrOpen} handleClose={closeAdrNew} />
      </Container>
    </>
  )
}
