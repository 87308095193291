import { Card } from "@mui/material";
import { TitleBar } from "./MDBox/TitleBar";

export const CardWithBar = ({title, headerStartIcon, children,})=> {
  return (
    <div>
      <TitleBar startIcon={headerStartIcon}>
        {title}
      </TitleBar>
      <Card>
        {children}
      </Card>
    </div>
  )
}