import React from 'react'
import {UserList, NewUser} from '../.'
import {Route, Switch} from "react-router-dom"

export const Users = ({match}) =>{
  return (
    < >
      <Switch>
        <Route exact path={`${match.path}/`} component={UserList}/>
        <Route path={`${match.path}/new`} component={NewUser}/>
      </Switch>
    </>
  )
}
