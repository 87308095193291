import React from 'react';
import { FIELD_TYPES } from '../../../helpers';
import { BaseFilters } from '../../layout';

/**
 * 
 * @param {object} props - props de componentes
 * @param {void} props.applyFilters -
 * @param {Array} props.statuses -
 * @param {boolean} props.loadingStates -
 * @returns 
 */
export const AdrFilter = ({ applyFilters, statuses, loadingStates }) => {

  const AdrFilters = {
    numadr: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Id"
    },
    stsadr: {
      name: "Estado",
      type: FIELD_TYPES.AUTOCOMPLETE,
      value: null,
      options: statuses,
      loading: loadingStates
    },
    codcli: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Cliente"
    },
    codproy: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Proyecto"
    },
    fecadrdesde: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Desde"
    },
    fecadrhasta: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Hasta"
    }
  }

  return (
<BaseFilters filters={AdrFilters} applyFilters={applyFilters} />
      


  );
};
