import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  DialogContentText,
  Typography,
} from "@mui/material";
import { BaseDialog } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, useBackdrop } from "../../../helpers"


export const ChangeEmail = React.memo(({ reload, open, data, handleClose }) => {
  const [email, setEmail] = useState([])
  const [userEmail, setUserEmail] = useState(data.email)
  const { show, off } = useBackdrop()
  const { post, controller } = useRequests()
  const { sSnack, eSnack } = useCustomSnackbar()
  console.log(data)
  console.log(email)
  console.log(userEmail)

  useEffect(() => {
    setUserEmail(data.email || null)
  }, [data, email])

  const changeEmail = useCallback(async () => {
    show("Cambiando Correo")
    const user_id = data.idpers
    try {
      var response = await post("/administration/change_email", {
        id: user_id,
        email: email,
      })
      if (response?.ok) {
        reload()
        handleClose()
        sSnack(`Correo cambiado para usuario ${user_id}`)
      } else {
        eSnack(await getErrorMsg(response))
        console.log("Error changing user password");
      }
    } catch (e) {
      eSnack("Error cambiado Correo del usuario")
    } finally { off() }
  }, [show, post, sSnack, eSnack, off, reload, data.idpers, email, handleClose])

  const handleFields = e => {
    const value = e.target.value
    const id = e.target.id
    switch (id) {
      case "new_email":
        setEmail(value)
        break
      default:
        return null
    }
    return null
  }

  useEffect(() => () => controller.abort(), [controller])

  return (
    <BaseDialog
      dialogOpen={open}
      handleClose={handleClose}
      title={"Cambiar Correo"}
      width="sm"
      confirmText={"Cambiar"}
      cancelText={"Cancelar"}
      confirm={changeEmail}
    >
      <DialogContentText>
        Cambiar Correo para el usuario: {data.nombpers}
      </DialogContentText>
      <br />
      <Typography variant='body1'>
        Correo: <b>{data.email} </b>
      </Typography>
      <TextField

        margin="dense"
        id="new_email"
        label="Nuevo correo"
        type="email"
        value={email}
        onChange={handleFields}

        fullWidth
      />
    </BaseDialog>
  );
})
