import React, {  useState, useCallback, useEffect } from "react"
import { Grid ,TextField} from "@mui/material";
import { useRequests, useCustomSnackbar, getErrorMsg,useDialogTools,FIELD_TYPES} from "../../../helpers"

import { BaseDialog ,Autocomplete} from "../../layout";
import {Estadisticas} from "./estadisticas";




export const Filtro = ({openF, handleCloseF}) => {
    const { dialogOpen: estadisticasOpen, openD: openEstadisticas, closeD: hideEstadisticas, } = useDialogTools()
    const { get  } = useRequests()
    const { eSnack  } = useCustomSnackbar()

    const [codcli, setCodcli] = useState("")
    const [loadingClientes, setLoadingClientes] = useState(false)
    const [clientes, setClientes] = useState([])
    const [nombreCliente, setNombreCliente] = useState("")
    const [cliente, setCliente] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
  
   // const [tickets, setTickets] = useState([])
    //const [idinc, setIdinc] = useState("")
    //const [ticket, setTicket] = useState("")
    const handleForm = e => {
      const { value, id } = e.target
      switch (id) {
        
        case 'hasta':
          setEndDate(value)
          break
        case 'desde':
          setStartDate(value)
          break
       
  
        default:
          break
      }
    }
  

    const openEstadistica = useCallback(row => {
          openEstadisticas()
      },[openEstadisticas])

      const closeEstadisticas= useCallback(() => {
        hideEstadisticas()
      },[hideEstadisticas])


  

  const getClients = useCallback(async () => {
    try {
      setLoadingClientes(true)
      const response = await get('/administration/encuestas/clientes')
      if (response?.ok) {
        const data = await response.json()
        setClientes(data)

      } else {
        eSnack(await getErrorMsg(response), getClients)
      }
    } catch (e) {
      console.log(e);
      eSnack("Error inesperado obteniendo clientes")
    } finally { setLoadingClientes(false) }
  }, [get, eSnack])


  useEffect(() => {
      if(openF)
      setCliente('')
      setNombreCliente('')
    getClients()
  }, [getClients,openF])

  useEffect(() => {
    if(codcli){
    setCliente(codcli.codcli)
    setNombreCliente(codcli.nomcli)
    }else{
        setCliente('')
        setNombreCliente('')
    }
}, [codcli])
/*
useEffect(() => {
    if(idinc){
    setTicket(idinc.idinc)
    }else{
        setTicket('')
    }
}, [idinc])
*/
/*
const getTickets = useCallback(async () => {
    try {
      setLoadingClientes(true)
      const response = await get('/administration/encuestas/tickets_encuestado')
      if (response?.ok) {
        const data = await response.json()
        setTickets(data)

      } else {
        eSnack(await getErrorMsg(response), getTickets)
      }
    } catch (e) {
      console.log(e);
      eSnack("Error inesperado obteniendo tickets")
    } finally { setLoadingClientes(false) }
  }, [get, eSnack])


  useEffect(() => {
      if(openF)
      getTickets()
  }, [getTickets,openF])
*/

  
      return (
          <> 
      <BaseDialog
        dialogOpen={openF}
        handleClose={handleCloseF}
        title="Filtrar Estadisticas"
        confirmText={"Generar"}
        cancelText={"Cerrar"}
        width="sm"
        confirm={openEstadistica}
    >
<Grid container spacing={0}  direction="row">
      <Grid item lg={8} >
              <Autocomplete
                fullWidth
                name="codcli"
                id="codcli"
                options={clientes}
                size="small"
                value={codcli}
                setValue={setCodcli}
                valueKey={"codcli"}
                loading={loadingClientes}
                getOptionLabel={(option) => `${option.codcli}- ${option.nomcli}`}
                renderInput={(params) => <TextField {...params} required fullWidth label="Clientes" />}
              />
     </Grid>    
       
     </Grid>  <br/>
     <Grid container spacing={0}  direction="row">
   {/*<Grid item lg={4} >
              <Autocomplete
                fullWidth
                name="idinc"
                id="idinc"
                options={tickets}
                size="small"
                value={idinc}
                setValue={setIdinc}
                valueKey={"idinc"}
                loading={loadingClientes}
                nullable={true}
                getOptionLabel={(option) => `${Math.trunc(option.idinc)}`}
                renderInput={(params) => <TextField {...params} required fullWidth label="Incidente" />}
              />
     </Grid>    */}  
     </Grid>    

     <Grid container spacing={2}> {/* Grid que envuelve fechas */}
        <Grid item xs={4}>
          <TextField
            name="fecharespondidadesde"
            id="desde"
            type={FIELD_TYPES.DATE}
            label="Fecha de respuesta Desde"
            value={startDate}
            title="Fecha respuesta"
            size="small"
            onChange={handleForm}
            fullWidth
          //  InputProps={{ inputProps: { min: date } }}
            InputLabelProps={{
              shrink: FIELD_TYPES.DATE === "date" ? true : undefined
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="fecharespondidahasta"
            id="hasta"
            type={FIELD_TYPES.DATE}
            label="Fecha de respuesta Hasta"
            value={endDate}
            title="Fecha respuesta"
            size="small"
            onChange={handleForm}
            fullWidth
           // InputProps={{ inputProps: { min: startDate } }}
            InputLabelProps={{
              shrink: FIELD_TYPES.DATE === "date" ? true : undefined
            }}
          />
        </Grid>
     </Grid>

    
         <Estadisticas desde={startDate} hasta={endDate} openE={estadisticasOpen} handleCloseE={closeEstadisticas} codcli={cliente} nombrecli={nombreCliente}  /* idinc={ticket} */ reload={getClients}/> 

    </BaseDialog>

          </>
    )
}
