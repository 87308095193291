import React, { useEffect } from "react"

import {  Container,} from '@mui/material';
import '../../../stylesheets/activity.scss'
import { BaseTable, BaseTablePagination } from "../../layout";
import { useDialogTools, useRequests } from "../../../helpers";
import { ModalDefets } from "../../administration";
import { useHistory } from 'react-router-dom';

export const DefectsActivity = React.memo(props => {
  const { dialogOpen: OtNew, openD: openOtNew, closeD: closeOtNew } = useDialogTools()
  const { controller } = useRequests()
  const history = useHistory();

  useEffect(() => () => controller.abort(), [controller])

  const openDefects = ({ iddefecto }) => {
    history.push(`/administration/defects/${iddefecto}`)
  }
  return (
    <>
      <Container maxWidth="lg">
        <BaseTable
          loading={false}
          rows={props.actividad.defects}
          headers={["Id ", "Modulo", "Importancia", "Fecha Detectada", "Detectado"]}
           rowAction={row => openDefects(row)}
          collapsible={{
            visible: row => [
              { content: row.iddefecto, handleCollapse: true, align: "left" },
              { content: row.codmodulo },
              { content: row.codimpor },
              { content: row.fecdetec },
              { content: row.codpersdetec },
            ],
            collapsed: row => [
              { name: "Defecto", content: row.descdefec },
            ]
          }}
          title="Defectos"
        >
          <BaseTablePagination
            page={0}
            maxPage={props.actividad.defects.length}
            rowsPerPage={props.actividad.defects.length}
            totalRows={props.actividad.defects.length}
            handlePageChange={props.actividad.defects.length}
            handleChangeRowsPerPage={props.actividad.defects.length}
            openDialog={openOtNew}
          />
        </BaseTable>
      </Container>
      <ModalDefets open={OtNew} handleClose={closeOtNew} reload={props.actividad.defects} idactividad={props.actividad.idactividad}/>

    </>
  );

})
