import React, { useEffect, useState, useCallback } from "react"
import { AppBar, Breadcrumbs, Stack, Toolbar, Typography,  Container, } from "@mui/material"
import { IconButtonMenu } from "../../layout/basic/IconButtonMenu"
import { BaseTable as DefectsTable, BaseTablePagination } from '../../layout'
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@mui/material/Link';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FIELD_TYPES, getErrorMsg, useCustomSnackbar, useDialogTools, useParsedURLParams, useRequests, useTableTools ,formatDate} from "../../../helpers";
import { BaseFilters } from "../../layout";
import { ModalDefets } from "./create";

export const DefectsList = ({ match }) => {

  const [filtroOpen, setfiltroOpen] = useState(false);
  const { dialogOpen: OtNew, openD: openOtNew, closeD: closeOtNew } = useDialogTools()
  const toggle = () => setfiltroOpen(!filtroOpen)
  const { get } = useRequests()
  const [Defects, setDefects] = useState([])
  const { page, rowsPerPage, total, setTotal, maxPage, setMaxPage, changePage, changeRows } = useTableTools()
  const [loadingTable, setLoadingTable] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState(useParsedURLParams())
  const { eSnack } = useCustomSnackbar()
  const history = useHistory();

  const defectsFilters = {
    iddefecto: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "ID"
    },
    codmodulo: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Modulo"
    },
    codimpor: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Importancia"
    },
    codpersdetec: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Responsable"
    },
    idactividad: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Id Actividad"
    },
    fecdetecdesde: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Desde"
    },
    fecdetechasta: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Hasta"
    }
    

  }

  const getDefects = useCallback(async () => {
    try {
      setLoadingTable(true)
      const response = await get('/administration/defects', {
        page: page + 1,
        rowsPerPage: rowsPerPage,
        ...appliedFilters
      })
      if (response?.ok) {
        const data = await response.json()
        setDefects(data.defectslist)
        setMaxPage(data.maxPage)
        setTotal(data.total)
      } else {
        eSnack(await getErrorMsg(response), getDefects)
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo Defects")
    } finally { setLoadingTable(false) }
  }, [get, page, rowsPerPage, eSnack, setTotal, setMaxPage, appliedFilters,])

  useEffect(() => {
    getDefects()
  }, [getDefects])

  const applyFilters = filters => {
    changePage(null, 0)
    setAppliedFilters(filters)
  }
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      Defectos
    </Typography>,
  ];

  const openDefects = ({ iddefecto }) => {
    history.push(`${match.path}${iddefecto}`)
  }

  const fechaDetec = (row) => {
    if (row.fecdetec === null) {
      return { content: row.fecdetec };
    } else {
      return { content: formatDate(new Date(row.fecdetec)) };
    }
  };




  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <IconButtonMenu
            open={filtroOpen}
            toggleMenu={toggle}
            size="small"
            color={"primary"}
            icon={<FilterListIcon />}
          >
            <BaseFilters filters={defectsFilters} applyFilters={applyFilters} />
          </IconButtonMenu>
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container className="base-container">
        <DefectsTable
          loading={loadingTable}
          rows={Defects}
          headers={["Id ", "Modulo", "Importancia", "Fecha Detectada", "Detectado"]}
          rowAction={row => openDefects(row)}
          collapsible={{
            visible: row => [
              { content: row.iddefecto, handleCollapse: true, align: "left" },
              { content: row.h_modulo },
              { content: row.h_importancia },
               fechaDetec(row),
              { content: row.codpersdetec },
              /*              fechaInicioOt(row),
                           fechaFinOt(row), */
             /* {
                content: <Tooltip title={`Abrir Defecto ${row.iddefecto}`}>
                  <Link component={RouterLink} to={`/administration/defects/${row.iddefecto}`}>
                    <AssignmentRoundedIcon color="secondary" />
                  </Link>
                </Tooltip>,
                action: () => null,
                disableTooltip: true
              }*/
            ],

            collapsed: row => [
              { name: "Defecto", content: row.descdefec },
            ]
          }}
          title="Defectos"
        >
          <BaseTablePagination
            page={page}
            maxPage={maxPage}
            rowsPerPage={rowsPerPage}
            totalRows={total}
            handlePageChange={changePage}
            handleChangeRowsPerPage={changeRows}
            openDialog={openOtNew}
          />
        </DefectsTable>
      </Container>
      <ModalDefets open={OtNew} handleClose={closeOtNew} reload={getDefects} />
    </>
  )
}
