import React,{useCallback,useRef,useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
  } from '@mui/material';
import {useRequests, getErrorMsg, useCustomSnackbar,useConfirmationDialog, useDialogTools, useBackdrop, formatDateTime} from '../../../helpers';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import {ActivityUserAssigned} from "../."


const useStyles = makeStyles( theme => ({
  header: {
    backgroundColor: theme.palette.primary.light,
   // padding: "1%",
    color: theme.palette.primary.contrastText
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#83c3f7",
    color: theme.palette.primary.contrastText,
    padding: "16px",
  },
  body: {
    padding: "16px",
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);


//cabecera de table Usuarios
const headersusers = ["Código", "Fecha Asignación", "Descripción", "Listo?","Fecha Indicador"]
// const { t } = useTranslation()

const makeHeadersUsers = ()=>{
  return headersusers.map((header,index)=>{
    return <StyledTableCell key={index} align={"center"}>{header}</StyledTableCell>
  })
}


export const ActivityAssignation = React.memo(({actividad, reload}) => {
  const classes = useStyles();
  //const {dialogOpen:createModuleOpen, openD:openCM, closeD:closeCM} = useDialogTools()

  const {dialogOpen:createAssigOpen, openD:openAU, closeD:closeAU} = useDialogTools()


  const selectedIdActividad = useRef(null)
  const selectedUser = useRef(null)
  const selectedFecAsig = useRef(null)
  const selectedRow = useRef(null)

  const {getConfirmation} = useConfirmationDialog()
  const {show, off} = useBackdrop ()
  const {eSnack, sSnack} = useCustomSnackbar()
  const { post, controller} = useRequests()


  const openCreateModule = useCallback(row => {

    selectedIdActividad.current = row.idactividad
    selectedUser.current = row.codusu
    selectedFecAsig.current = row.fecasig
    selectedRow.current = row

    openAU()
  },[openAU])



  const closeCreateModule = useCallback(() => {
    closeAU()
  },[closeAU])


  const fechaIndicador= (row) =>{
    if(row === null){
      return ( <StyledTableCell align="center">{row}</StyledTableCell>)

    }else{
      return (<StyledTableCell align="center">{formatDateTime(new Date(row))}</StyledTableCell> )
    }
  }


  const getAssignedIcon = (row) =>{
    if(row.indasigact === "S"){
      return (
            <CheckCircleOutlineRoundedIcon color="primary" />
              )
    }else {
      if(actividad.stsact ==="CER"){
        return <RadioButtonUncheckedRoundedIcon color="error" onClick={async () => (await getConfirmation({title:"Indicador de Asignación", message:`Seguro desea cambiar el indicador de la asignación`})) ?  changeIndicador(row) : null }/>;
      }else{
        return (
          <RadioButtonUncheckedRoundedIcon color="error" onClick = { () => openCreateModule(row) }/>

          )
      }

    }
  }

  const changeIndicador = useCallback(async row => {
    show("Cambiando  Indicador")
    try{

      const response = await post(`/activities/change_estado_indicador`, {
        change_indicador:{
          idactividad:row.idactividad,
          codusu: row.codusu,
          fecasig: row.fecasig
        }
      })
      if(response?.ok){
        sSnack("Indicador listo")
        reload()
      }else{
        eSnack(await getErrorMsg(response))
        //console.log("error changing indicador ");
      }
    }catch(e){

      eSnack("Error inesperado cambiando indicador")
      //console.log("Error changing indicador", e);
    }finally{
      off()
    }
  },[eSnack, off, post, sSnack, show, reload])
  useEffect(() => () => controller.abort(), [controller])

  return (
// <StyledTableCell align="center">{formatDate(new Date(row.fecasig))}</StyledTableCell>

<>
        <Paper>
         {/*JSON.stringify((actividad.activity_assignations))*/}

        <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader className={classes.table}>
                <TableHead>
                <TableRow>
                {makeHeadersUsers()}
                </TableRow>
                </TableHead>
                <TableBody>

                {actividad.activity_assignations?.map((row, index) => {
                    return <StyledTableRow key={index}>
                    <StyledTableCell align="center">{row.codusu}</StyledTableCell>

                    <StyledTableCell align="center">{formatDateTime(new Date(row.fecasig))}</StyledTableCell>

                    <StyledTableCell align="center">{row.descasig}</StyledTableCell>
                    <StyledTableCell align="center">{getAssignedIcon(row)}</StyledTableCell>
                    {fechaIndicador(row.fecindicador)}

                    </StyledTableRow>
                })}
                </TableBody>
            </Table>
            </TableContainer>
        </Paper>
      <ActivityUserAssigned dialogOpen={createAssigOpen}
                            handleClose={closeCreateModule}
                            currentIdActivity={selectedIdActividad.current}
                            currentUser={selectedUser.current}
                            currentFecAsig={selectedFecAsig.current}
                            currentRow = {selectedRow.current}
                            changeIndicador = {changeIndicador}
                            reload = {reload}
        />

</>

  );
})
