import React, { useState, useEffect } from "react";
import { useRequests, getErrorMsg, useCustomSnackbar, useBackdrop, useCurrentUser } from "../../helpers";
import TextField from '@mui/material/TextField';
import { Avatar, Backdrop, Box, Button, Card } from "@mui/material";
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    p: 1,
    width: '100%',
  },
}));
export const MensajeTicketEdit = React.memo(({ id, profile, editMensajeOpen, message, handleClose, conversation, setTicket, ticket_id, ticket }) => {
  const { show, off } = useBackdrop(); //this is the hook of backdrop
  const { eSnack, sSnack } = useCustomSnackbar();
  const { patch, controller } = useRequests();
  const [mensaje, setMensaje] = useState("")

  const handleUpdate = async () => {
    show("Actualizando Mensaje")
    try {
      const response = await patch(`/journals/update_mensaje`, {
        id: id,
        message: mensaje,
        ticket_id: ticket_id
      })
      if (response?.ok) {
        const conversation = await response.json()
        setTicket({ ...ticket, conversation: conversation })
        sSnack("Mensaje Actualizado")
        handleClose()
      } else {
        eSnack(await getErrorMsg(response))
        console.log("error actualizando mensaje");
      }
    } catch (e) {
      eSnack("Error inesperado actualizando mensaje ")
      console.log("Error actualizando ", e);
    } finally {
      off()
    }
  }

  useEffect(() => {
    if (message) {
      setMensaje(message)
    }
  }, [message])

  const currentUser = useCurrentUser()
  const classes = useStyles();
  useEffect(() => () => controller.abort(), [controller]);

  return (
    <Backdrop
      className={classes.backdrop}
      open={editMensajeOpen}
      onClick={handleClose}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Card
          onClick={(e) => e.stopPropagation()}
          className="submit-holder"
          elevation={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 1,
            width: '800px',
            mt: 2,
          }}
        >
          <Avatar alt={currentUser.name} src={currentUser.avatar} sx={{ maxWidth: 30, maxHeight: 30, marginRight: 1 }} />
          <Box sx={{ flex: 1, mr: 2 }}>
            <TextField
              hiddenLabel
              multiline
              fullWidth
              name="message"
              defaultValue={message}
              value={mensaje}
              variant="filled"
              onChange={e => setMensaje(e.target.value)}
              inputProps={{
                maxLength: 3500,
              }}
              placeholder="Escribe un mensaje aqui..."
              sx={{ width: '100%', minWidth: 'unset' }}
            />
          </Box>

          <Button startIcon={<SendTwoToneIcon />}
            variant="contained"
            onClick={handleUpdate}
            color="secondary" size="small"
            sx={{ display: { xs: 'none', md: 'flex' } }}>
            Enviar
          </Button>
        </Card>
      </Box>
    </Backdrop>
  );
});
