import React, {  useState, useCallback, useEffect } from "react"
import { Grid ,Stack,Typography,Divider,Paper} from "@mui/material";
import { useRequests, useCustomSnackbar, getErrorMsg,useDialogTools,formatDate} from "../../../helpers"
import { BaseDialog, ConditionalWrapper,SkeletonDetailActivity} from "../../layout";
//import { PDFDownloadLink ,PDFViewer} from '@react-pdf/renderer';
//import {EstadisticasPDF} from "./estadisticas_pdf";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
//import {DocuPDF} from "./docu_pdf";
import {PDF} from  "./pdf";
import { styled } from '@mui/material/styles';

ChartJS.register(ArcElement, Tooltip, Legend);


export const Estadisticas = ({openE, handleCloseE, codcli,nombrecli,desde,hasta}) => {
  
  const { get } = useRequests()
  const {eSnack} = useCustomSnackbar()
  
  const [totalEncuestados, setTotalEncuestados] = useState([])
  const [resp1Preg1, setResp1Preg1] = useState([])
  const [resp2Preg1, setResp2Preg1] = useState([])
  const [resp3Preg1, setResp3Preg1] = useState([])
  const [resp4Preg1, setResp4Preg1] = useState([])

  const [resp1Preg2, setResp1Preg2] = useState([])
  const [resp2Preg2, setResp2Preg2] = useState([])
  const [resp3Preg2, setResp3Preg2] = useState([])
  const [resp4Preg2, setResp4Preg2] = useState([])

  const [resp1Preg3, setResp1Preg3] = useState([])
  const [resp2Preg3, setResp2Preg3] = useState([])
  const [resp3Preg3, setResp3Preg3] = useState([])
  const [resp4Preg3, setResp4Preg3] = useState([])

  const [resp1Preg4, setResp1Preg4] = useState([])
  const [resp2Preg4, setResp2Preg4] = useState([])
  const [resp3Preg4, setResp3Preg4] = useState([])
  const [resp4Preg4, setResp4Preg4] = useState([])

  const [resp1Preg5, setResp1Preg5] = useState([])
  const [resp2Preg5, setResp2Preg5] = useState([])
  const [resp3Preg5, setResp3Preg5] = useState([])
  const [resp4Preg5, setResp4Preg5] = useState([])

  const [loadingClientes, setLoadingClientes] = useState(false)
  const { dialogOpen: pdf, openD: openPDF, closeD: closePDF } = useDialogTools()


  //
  const getTotalEncuestados = useCallback( async () =>{
    if(openE ){
    try{
        const response = await get(`/administration/encuestas/totalEncuestados`, {
          codcli,
          desde,
          hasta
        })
        if(response?.ok){
          const data = await response.json()
          setTotalEncuestados(data)
        }else{
          eSnack(await getErrorMsg(response), getTotalEncuestados)
        }
      }catch(e){
        eSnack("Error inesperado obteniendo total encuestados", getTotalEncuestados)
     }finally{}}
    },[get, eSnack,openE,codcli,desde,hasta])
  
    useEffect(()=>{
      if(openE)
      getTotalEncuestados()
   },[getTotalEncuestados,openE,codcli,desde, hasta])

   useEffect(()=>{
    if(openE )
       getTotalEncuestados()
    },[getTotalEncuestados,openE])
 
    useEffect(()=>{
      if(handleCloseE)
         getTotalEncuestados()
      },[getTotalEncuestados,handleCloseE])
   
  //ESTADISTICAS PREGUNTA 1

    const getResp1Pregunta1 = useCallback(async () => {
     if(openE ){
      try {
        setLoadingClientes(true)
        const response = await get('/administration/encuestas/resp1Pregunta1', {
          codcli, 
          desde,
          hasta
        })
        if (response?.ok) {
          const data = await response.json()
          setResp1Preg1(data)
  
        } else {
          eSnack(await getErrorMsg(response), getResp1Pregunta1)
        }
      } catch (e) {
        console.log(e);
        eSnack("Error inesperado obteniendo respuesta 1 de la pregunta 1")
      } finally { setLoadingClientes(false) }}
    }, [get, eSnack,codcli,openE,desde,hasta])

    useEffect(()=>{
      if(openE )
         getResp1Pregunta1()
   },[getResp1Pregunta1,openE])



   const getResp2Pregunta1 = useCallback( async () =>{
    if(openE ){
    try{
        const response = await get(`/administration/encuestas/resp2Pregunta1`, {
          codcli,
          desde,
          hasta
        })
        if(response?.ok){
          const data = await response.json()
          setResp2Preg1(data)
        }else{
          eSnack(await getErrorMsg(response), getResp2Pregunta1)
        }
      }catch(e){
        eSnack("Error inesperado obteniendo respuestas 2 de la pregunta 1", getResp2Pregunta1)
     }finally{}}
    },[get, eSnack, openE,codcli,desde,hasta])
  
    useEffect(()=>{
      if(openE)
      getResp2Pregunta1()
   },[getResp2Pregunta1,openE])
  
   const getResp3Pregunta1 = useCallback( async () =>{
    if(openE ){
    try{
        const response = await get(`/administration/encuestas/resp3Pregunta1`, {
          codcli,
          desde,
          hasta
        })
        if(response?.ok){
          const data = await response.json()
          setResp3Preg1(data)
        }else{
          eSnack(await getErrorMsg(response), getResp3Pregunta1)
        }
      }catch(e){
        eSnack("Error inesperado obteniendo respuesta 3 de la pregunta 1", getResp3Pregunta1)
     }finally{}}
    },[get, eSnack, openE, codcli,desde,hasta])
  
    useEffect(()=>{
      if(openE)
      getResp3Pregunta1()
   },[getResp3Pregunta1,openE])
  
   const getResp4Pregunta1 = useCallback( async () =>{
    if(openE ){  
    try{
        const response = await get(`/administration/encuestas/resp4Pregunta1`, {
          codcli,
          desde,
          hasta
        })
        if(response?.ok){
          const data = await response.json()
          setResp4Preg1(data)
        }else{
          eSnack(await getErrorMsg(response), getResp4Pregunta1)
        }
      }catch(e){
        eSnack("Error inesperado obteniendo respuesta 4 de la pregunta 1", getResp4Pregunta1)
     }finally{}}
    },[get, eSnack,openE,codcli,desde,hasta])
  
    useEffect(()=>{
      if(openE)
      getResp4Pregunta1()
   },[getResp4Pregunta1,openE])
  
//ESTADISTICAS PREGUNTA 2
const getResp1Pregunta2 = useCallback( async () =>{
  if(openE){
try{
  
    const response = await get(`/administration/encuestas/resp1Pregunta2`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp1Preg2(data)
    }else{
      eSnack(await getErrorMsg(response), getResp1Pregunta2)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 1 de la pregunta 2", getResp1Pregunta2)
 }finally{}}
},[get, eSnack, openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
      getResp1Pregunta2()
},[getResp1Pregunta2,openE])

const getResp2Pregunta2 = useCallback( async () =>{
if(openE){  
try{
  
    const response = await get(`/administration/encuestas/resp2Pregunta2`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp2Preg2(data)
    }else{
      eSnack(await getErrorMsg(response), getResp2Pregunta2)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 2 de la pregunta 2", getResp2Pregunta2)
 }finally{}}
},[get, eSnack,openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
  getResp2Pregunta2()
},[getResp2Pregunta2,openE])

const getResp3Pregunta2 = useCallback( async () =>{
  if(openE){
try{
  
    const response = await get(`/administration/encuestas/resp3Pregunta2`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp3Preg2(data)
    }else{
      eSnack(await getErrorMsg(response), getResp3Pregunta2)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta  3 de la pregunta 2", getResp3Pregunta2)
 }finally{}}
},[get, eSnack, openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
  getResp3Pregunta2()
},[getResp3Pregunta2,openE])

const getResp4Pregunta2 = useCallback( async () =>{
if(openE){ 
try{
  
    const response = await get(`/administration/encuestas/resp4Pregunta2`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp4Preg2(data)
    }else{
      eSnack(await getErrorMsg(response), getResp4Pregunta2)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 4 de la pregunta 2", getResp4Pregunta2)
 }finally{}}
},[get, eSnack, openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
  getResp4Pregunta2()
},[getResp4Pregunta2,openE])

//ESTADISTICAS PREGUNTA 3
const getResp1Pregunta3 = useCallback( async () =>{
if(openE){  
try{
  
    const response = await get(`/administration/encuestas/resp1Pregunta3`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp1Preg3(data)
    }else{
      eSnack(await getErrorMsg(response), getResp1Pregunta3)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 1 de la pregunta 3", getResp1Pregunta3)
 }finally{}}
},[get, eSnack,openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
      getResp1Pregunta3()
},[getResp1Pregunta3,openE])

const getResp2Pregunta3= useCallback( async () =>{
if (openE){  
try{
  
    const response = await get(`/administration/encuestas/resp2Pregunta3`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp2Preg3(data)
    }else{
      eSnack(await getErrorMsg(response), getResp2Pregunta3)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 2 de la pregunta 3", getResp2Pregunta3)
 }finally{}}
},[get, eSnack, openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
  getResp2Pregunta3()
},[getResp2Pregunta3,openE])

const getResp3Pregunta3 = useCallback( async () =>{
if(openE){  
try{
  
    const response = await get(`/administration/encuestas/resp3Pregunta3`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp3Preg3(data)
    }else{
      eSnack(await getErrorMsg(response), getResp3Pregunta3)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 3 de la pregunta 3", getResp3Pregunta3)
 }finally{}}
},[get, eSnack,openE,codcli,desde,hasta])

useEffect(()=>{
  getResp3Pregunta3()
},[getResp3Pregunta3])

const getResp4Pregunta3 = useCallback( async () =>{
if(openE){  
try{
  
    const response = await get(`/administration/encuestas/resp4Pregunta3`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp4Preg3(data)
    }else{
      eSnack(await getErrorMsg(response), getResp4Pregunta3)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 4 de la pregunta 3", getResp4Pregunta3)
 }finally{}}
},[get, eSnack,openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
  getResp4Pregunta3()
},[getResp4Pregunta3,openE])



//ESTADISTICAS PREGUNTA 4
const getResp1Pregunta4 = useCallback( async () =>{
if(openE){  
try{
  
    const response = await get(`/administration/encuestas/resp1Pregunta4`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp1Preg4(data)
    }else{
      eSnack(await getErrorMsg(response), getResp1Pregunta4)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 1 de la pregunta 4", getResp1Pregunta4)
 }finally{}}
},[get, eSnack,openE,codcli,desde,hasta])

useEffect(()=>{
      getResp1Pregunta4()
},[getResp1Pregunta4,openE])

const getResp2Pregunta4= useCallback( async () =>{
if(openE){  
try{
  
    const response = await get(`/administration/encuestas/resp2Pregunta4`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp2Preg4(data)
    }else{
      eSnack(await getErrorMsg(response), getResp2Pregunta4)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 2 de la pregunta 4", getResp2Pregunta4)
 }finally{}}
},[get, eSnack,openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
  getResp2Pregunta4()
},[getResp2Pregunta4,openE])

const getResp3Pregunta4 = useCallback( async () =>{
if(openE){ 
try{
  
    const response = await get(`/administration/encuestas/resp3Pregunta4`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp3Preg4(data)
    }else{
      eSnack(await getErrorMsg(response), getResp3Pregunta4)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 3 de la pregunta 4", getResp3Pregunta4)
 }finally{}}
},[get, eSnack,openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
  getResp3Pregunta4()
},[getResp3Pregunta4,openE,codcli])

const getResp4Pregunta4 = useCallback( async () =>{
 if(openE){
try{
  
    const response = await get(`/administration/encuestas/resp4Pregunta4`, {
      codcli,
      desde,
      hasta
    })
    if(response?.ok){
      const data = await response.json()
      setResp4Preg4(data)
    }else{
      eSnack(await getErrorMsg(response), getResp4Pregunta4)
    }
  }catch(e){
    eSnack("Error inesperado obteniendo respuesta 4 de la pregunta 4", getResp4Pregunta4)
 }finally{}}
},[get, eSnack,openE,codcli,desde,hasta])

useEffect(()=>{
  if(openE)
  getResp4Pregunta4()
},[getResp4Pregunta4,openE])

//ESTADISTICAS PREGUNTA 5
const getResp1Pregunta5 = useCallback( async () =>{
  if(openE){  
  try{
    
      const response = await get(`/administration/encuestas/resp1Pregunta5`, {
        codcli,
        desde,
        hasta
      })
      if(response?.ok){
        const data = await response.json()
        setResp1Preg5(data)
      }else{
        eSnack(await getErrorMsg(response), getResp1Pregunta5)
      }
    }catch(e){
      eSnack("Error inesperado obteniendo respuesta 1 de la pregunta 5", getResp1Pregunta5)
   }finally{}}
  },[get, eSnack,openE,codcli,desde,hasta])
  
  useEffect(()=>{
        getResp1Pregunta5()
  },[getResp1Pregunta5,openE])
  
  const getResp2Pregunta5= useCallback( async () =>{
  if(openE){  
  try{
    
      const response = await get(`/administration/encuestas/resp2Pregunta5`, {
        codcli,
        desde,
        hasta
      })
      if(response?.ok){
        const data = await response.json()
        setResp2Preg5(data)
      }else{
        eSnack(await getErrorMsg(response), getResp2Pregunta5)
      }
    }catch(e){
      eSnack("Error inesperado obteniendo respuesta 2 de la pregunta 5", getResp2Pregunta5)
   }finally{}}
  },[get, eSnack,openE,codcli,desde,hasta])
  
  useEffect(()=>{
    if(openE)
    getResp2Pregunta5()
  },[getResp2Pregunta5,openE])
  
  const getResp3Pregunta5 = useCallback( async () =>{
  if(openE){ 
  try{
    
      const response = await get(`/administration/encuestas/resp3Pregunta5`, {
        codcli,
        desde,
        hasta
      })
      if(response?.ok){
        const data = await response.json()
        setResp3Preg5(data)
      }else{
        eSnack(await getErrorMsg(response), getResp3Pregunta5)
      }
    }catch(e){
      eSnack("Error inesperado obteniendo respuesta 3 de la pregunta 5", getResp3Pregunta5)
   }finally{}}
  },[get, eSnack,openE,codcli,desde,hasta])
  
  useEffect(()=>{
    if(openE)
    getResp3Pregunta5()
  },[getResp3Pregunta5,openE,codcli])
  
  const getResp4Pregunta5 = useCallback( async () =>{
   if(openE){
  try{
    
      const response = await get(`/administration/encuestas/resp4Pregunta5`, {
        codcli,
        desde,
        hasta
      })
      if(response?.ok){
        const data = await response.json()
        setResp4Preg5(data)
      }else{
        eSnack(await getErrorMsg(response), getResp4Pregunta5)
      }
    }catch(e){
      eSnack("Error inesperado obteniendo respuesta 4 de la pregunta 5", getResp4Pregunta5)
   }finally{}}
  },[get, eSnack,openE,codcli,desde,hasta])
  
  useEffect(()=>{
    if(openE)
    getResp4Pregunta5()
  },[getResp4Pregunta5,openE])


const dataPregunta1 = {
labels: [],
datasets: [
  {
    label: 'Pregunta 1',
    data: [((resp1Preg1 / totalEncuestados)*100), ((resp2Preg1 / totalEncuestados)*100), ((resp3Preg1 / totalEncuestados)*100), ((resp4Preg1 / totalEncuestados)*100)],
    backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(243, 115, 79, 0.7)',
        'rgba(255, 206, 86, 0.3)',
        'rgba(75, 192, 192, 0.3)',
      
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(243, 115, 79, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      
    ],
    borderWidth: 1,
  },
],
};

const dataPregunta2 = {
labels: [],
datasets: [
  {
    label: 'Pregunta 2',
    data: [((resp1Preg2 / totalEncuestados)*100), ((resp2Preg2/ totalEncuestados)*100), ((resp3Preg2 / totalEncuestados)*100), ((resp4Preg2 / totalEncuestados)*100)],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
        'rgba(243, 115, 79, 0.7)',
        'rgba(255, 206, 86, 0.3)',
        'rgba(75, 192, 192, 0.3)',
      
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(243, 115, 79, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      
    ],
    borderWidth: 1,
  },
],
};
const dataPregunta3 = {
labels: [],
datasets: [
  {
    label: 'Pregunta 3',
    data: [((resp1Preg3 / totalEncuestados)*100), ((resp2Preg3 / totalEncuestados)*100), ((resp3Preg3/ totalEncuestados)*100), ((resp4Preg3 / totalEncuestados)*100)],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(243, 115, 79, 0.7)',
      'rgba(255, 206, 86, 0.3)',
      'rgba(75, 192, 192, 0.3)',
      
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(243, 115, 79, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      
    ],
    borderWidth: 1,
  },
],
};
const dataPregunta4 = {
labels: [],
datasets: [
  {
    label: 'Pregunta 4',
    data: [((resp1Preg4/ totalEncuestados)*100), ((resp2Preg4 / totalEncuestados)*100), ((resp3Preg4/ totalEncuestados)*100), ((resp4Preg4 / totalEncuestados)*100)],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
        'rgba(243, 115, 79, 0.7)',
        'rgba(255, 206, 86, 0.3)',
        'rgba(75, 192, 192, 0.3)',
      
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(243, 115, 79, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      
    ],
    borderWidth: 1,
  },
],
};
const dataPregunta5 = {
  labels: [],
  datasets: [
    {
      label: 'Pregunta 4',
      data: [((resp1Preg5/ totalEncuestados)*100), ((resp2Preg5 / totalEncuestados)*100), ((resp3Preg5/ totalEncuestados)*100), ((resp4Preg5 / totalEncuestados)*100)],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(243, 115, 79, 0.7)',
        'rgba(255, 206, 86, 0.3)',
        'rgba(75, 192, 192, 0.3)',
        
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(243, 115, 79, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        
      ],
      borderWidth: 1,
    },
  ],
  };

const mostrarPregunta1 = () => {
if (totalEncuestados === 0){
  return (

         <Stack container>
          No hay estadisticas para el parametro seleccionado
        </Stack>
  )
}else{
  return( <Stack container>
             <Pie data={dataPregunta1} />
            </Stack>)
}
}

const mostrarPregunta2 = () => {
if (totalEncuestados === 0){
  return (

         <Stack container>
          No hay estadisticas para el parametro seleccionado
        </Stack>
  )
}else{
  return( <Stack container>
             <Pie data={dataPregunta2} />
            </Stack>)
}
}
const mostrarPregunta3= () => {
if (totalEncuestados === 0){
  return (

         <Stack container>
          No hay estadisticas para el parametro seleccionado
        </Stack>
  )
}else{
  return( <Stack container>
             <Pie data={dataPregunta3} />
            </Stack>)
}
}
const mostrarPregunta4 = () => {
if (totalEncuestados === 0){
  return (

         <Stack container>
          No hay estadisticas para el parametro seleccionado
        </Stack>
  )
}else{
  return( <Stack container>
             <Pie data={dataPregunta4} />
            </Stack>)
}
}
const mostrarPregunta5 = () => {
  if (totalEncuestados === 0){
    return (
  
           <Stack container>
            No hay estadisticas para el parametro seleccionado
          </Stack>
    )
  }else{
    return( <Stack container>
               <Pie data={dataPregunta5} />
              </Stack>)
  }
  }

const Item2 = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0.7),
  textAlign: 'lefth',
  color: theme.palette.text.primary,
}));

      return (
          <> 
    
      <BaseDialog
        dialogOpen={openE}
        handleClose={handleCloseE}
        title="Estadisticas"
        confirmText={"Generar PDF"}
        cancelText={"Cerrar"}
        confirm={openPDF}
        width="md"
    >

      <Typography gutterBottom>
       <b>Las siguentes estadisticas son consultadas para los siguentes parámetros</b> 
          <br/>Cliente: {nombrecli}
              
        <Grid container spacing={0} direction="row">
       
          {desde
           ?    <Grid item lg={4} >Desde: {formatDate(new Date(desde))} </Grid>
           :    <Grid item lg={4} >Desde: {} </Grid>
           }
           {hasta
           ?    <Grid item lg={4} >Hasta: {formatDate(new Date(hasta))} </Grid>
           :    <Grid item lg={4} >Hasta: {} </Grid>
           } 

       </Grid>
       Total Encuestados: {totalEncuestados}
      </Typography> 

          <Divider />

          <br/>
           
         <Grid container spacing={0} justifyContent="center" direction="row">
          <div   style={{ width: 25,  height:18 ,backgroundColor: 'rgba(255, 99, 132, 0.3)', float:'left', marginRight:5}} ></div><div style={{float:'left',  marginRight:15}}><b>Totalmente Desacuerdo</b></div>
          <div   style={{ width: 25,  height:18 ,backgroundColor: 'rgba(243, 115, 79, 0.7)', float:'left', marginRight:5}} ></div><div  style={{float:'left', marginRight:15}}><b>Desacuerdo</b></div>
          <div   style={{ width: 25,  height:18 ,backgroundColor: 'rgba(255, 206, 86, 0.5)', float:'left', marginRight:5}} ></div><div style={{float:'left', marginRight:15}}><b>De acuerdo</b></div>
          <div   style={{ width: 25,  height:18 ,backgroundColor: 'rgba( 75, 192, 192, 0.5)', float:'left', marginRight:5}} ></div><div style={{float:'left', marginRight:15}}><b>Totalmente  De acuerdo</b></div>
        </Grid>
  
  
  
  <br/>
  
  <Grid container spacing={0} justifyContent="center"  direction="row">
       <Grid item lg={3}  >
       <Paper elevation={5} >
         <Item2> ¿Mi solicitud de asistencia fue completamente atendida por el personal de KENTRON?</Item2>
       <ConditionalWrapper condition={loadingClientes} wrapper={SkeletonDetailActivity}>    
          {
            mostrarPregunta1()
          }
          
         </ConditionalWrapper>
       </Paper>
       </Grid>
       <Grid item lg={3}  ml={2}>
       <Paper elevation={5}>
       <Item2>¿El tiempo que tomó atender mi solicitud fue adecuado?</Item2> <br/>
       <ConditionalWrapper condition={loadingClientes} wrapper={SkeletonDetailActivity}>    
          {
            mostrarPregunta2()
          }
          
         </ConditionalWrapper>
       </Paper>
       </Grid>
       <Grid item lg={3}  ml={2}>
       <Paper elevation={5}>
       <Item2>¿La solución a mi solicitud fue satisfactoria?</Item2> <br/>
       <ConditionalWrapper condition={loadingClientes} wrapper={SkeletonDetailActivity}>    
          {
            mostrarPregunta3()
          }
          
         </ConditionalWrapper>
       </Paper>
       </Grid>
     
      
       </Grid>
  <br/>

  <Grid container spacing={0} justifyContent="center"  direction="row">
       <Grid item lg={3}  >
       <Paper elevation={5} >
         <Item2> ¿La atención por parte del personal de KENTRON fue amable y eficiente?</Item2>
       <ConditionalWrapper condition={loadingClientes} wrapper={SkeletonDetailActivity}>    
          {
            mostrarPregunta4()
          }
          
         </ConditionalWrapper>
       </Paper>
       </Grid>
       <Grid item lg={3}  ml={2}>
       <Paper elevation={5}>
       <Item2>¿Recibí información adecuada durante el proceso de asistencia?</Item2> <br/>
       <ConditionalWrapper condition={loadingClientes} wrapper={SkeletonDetailActivity}>    
          {
            mostrarPregunta5()
          }
          
         </ConditionalWrapper>
       </Paper>
       </Grid>
       </Grid><br/>



   <PDF openPDF={pdf} handleClosePDF={closePDF}
        clientex={nombrecli}
        desdex={desde}
        hastax={hasta}
        totalEncuestadosx={totalEncuestados}
        resp1Preg1x={resp1Preg1}
        resp2Preg1x={resp2Preg1}
        resp3Preg1x={resp3Preg1}
        resp4Preg1x={resp4Preg1}

        resp1Preg2x={resp1Preg2}
        resp2Preg2x={resp2Preg2}
        resp3Preg2x={resp3Preg2}
        resp4Preg2x={resp4Preg2}
       
        resp1Preg3x={resp1Preg3}
        resp2Preg3x={resp2Preg3}
        resp3Preg3x={resp3Preg3}
        resp4Preg3x={resp4Preg3}
       
        resp1Preg4x={resp1Preg4}
        resp2Preg4x={resp2Preg4}
        resp3Preg4x={resp3Preg4}
        resp4Preg4x={resp4Preg4}

        resp1Preg5x={resp1Preg5}
        resp2Preg5x={resp2Preg5}
        resp3Preg5x={resp3Preg5}
        resp4Preg5x={resp4Preg5}
          />

    </BaseDialog>

          </>
    )
}
