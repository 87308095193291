import { AppBar, Breadcrumbs, Toolbar, Stack, Typography, Container, Tooltip,IconButton } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  TICKET_TYPE_STATUSES,
  TICKET_TYPES,
  ExportDialog,
  NewTicket,
  FiltroPDF,
} from "../";
import {
  useRequests,
  getErrorMsg,
  useCustomSnackbar,
  useCurrentUser,
  useBackdrop,
  useDialogTools,
  useParsedURLParams,
  FIELD_TYPES
} from "../../../helpers";
import { BaseTable as TicketsTables, BaseTablePagination, BaseFilters } from '../../layout'
import { IconButtonMenu } from "../../layout/basic/IconButtonMenu";
import { useHistory } from "react-router-dom"
/* import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone'; */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
/* import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone'; */
import RadioButtonUncheckedTwoToneIcon from '@mui/icons-material/RadioButtonUncheckedTwoTone';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const getAssignedIcon = ticket => {
  if (ticket.is_assigned) {
    return (
      <Tooltip title="Ticket Asignado">
        <CheckCircleOutlineIcon color="primary" />
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title="Ticket Sin Asignar">
        <RadioButtonUncheckedTwoToneIcon color="error" />
      </Tooltip>
    )
  }
}

export const TicketList = () => {
  const currentUser = useCurrentUser();
  const { show, off } = useBackdrop(); //this is the hook of backdrop
  const { eSnack, iSnack, sSnack } = useCustomSnackbar();
  const { get, post, controller } = useRequests();
  const [tickets, setTickets] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(useParsedURLParams())
  const [selectedType, setSelectedType] = useState("ALL")
  const [filtroOpen, setfiltroOpen] = useState(false);
  const { dialogOpen: OtNew, openD: openOtNew, closeD: closeOtNew } = useDialogTools()
  const toggle = () => setfiltroOpen(!filtroOpen)
  const { dialogOpen: filtro, openD: openFiltro, closeD: closeFiltro } = useDialogTools()
  const history = useHistory();

  const ticketFilters = {
    id: {
      name: "Id",
      type: FIELD_TYPES.TEXT,
      value: ""
    },
   
    ...currentUser?.can?.see_activities ? {
      idactividad: {
        name: "Idactividad",
        type: FIELD_TYPES.TEXT,
        value: ""
      },
    } : {},
    cliente: {
      name: "Cliente",
      type: FIELD_TYPES.TEXT,
      value: ""
    },
    codpersresp: {
      name: "Responsable",
      type: FIELD_TYPES.TEXT,
      value: ""
    },
    persona: {
      name: "Persona",
      type: FIELD_TYPES.TEXT,
      value: ""
    },
    desde: {
      name: "Desde",
      type: FIELD_TYPES.DATE,
      value: ""
    },
    hasta: {
      name: "Hasta",
      type: FIELD_TYPES.DATE,
      value: ""
    },
    tipo: {
      name: "Tipo",
      type: FIELD_TYPES.AUTOCOMPLETE,
      value: null,
      options: TICKET_TYPES,
      valueKey: "tipinc",
      getLabel: option => option.desctipinc,
      onChange: value => setSelectedType(value?.tipinc || "ALL")
    },
    estatus: {
      name: "Estado",
      type: FIELD_TYPES.AUTOCOMPLETE,
      value: [],
      options: TICKET_TYPE_STATUSES[selectedType],
      valueKey: "stsinc",
      getLabel: option => option.descstatus,
      getOptionValue: option => option.stsinc, // agregar esta función
      multiple: true // permite seleccionar varias opciones
    },
  }

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [exportOpen, setExportOpen] = useState(false);
  const [tableLoading, setTableLoading] = useState(false)

  const getTickets = useCallback(async () => {
    setTableLoading(true)
    try {
      const response = await get("/tickets", {
        page: page + 1,
        rowsPerPage: rowsPerPage,
        ...appliedFilters,
      });
      if (response?.ok) {
        const newTickets = await response.json();
        setTickets(newTickets.tickets);
        setMaxPage(newTickets.maxPage);
        setTotalRows(newTickets.total);
      } else {
        eSnack(await getErrorMsg(response), getTickets);
        console.log("error getting tickets");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo tickets");
      console.log(e);
    } finally { setTableLoading(false) }
  }, [get, page, rowsPerPage, appliedFilters, eSnack]);

  const exportTickets = useCallback(async (columns) => {
    show("Exportando");
    try {
      if (!columns.length) return iSnack("Seleccione al menos una columna a exportar")
      const response = await post("/tickets/export", {
        ...appliedFilters,
        columns: columns,
      });
      if (response?.ok) {
        var data = new Blob([await response.blob()], { type: "text/csv" });
        var csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        const date = new Date();
        const filename = `tickets_ergos_${date.getDate()}_${date.getMonth() + 1
          }_${date.getFullYear()}.csv`;
        tempLink.setAttribute("download", filename);
        tempLink.click();
        sSnack("Tickets Exportados");
        console.log("exported successfully");
      } else {
        eSnack(await getErrorMsg(response), () => exportTickets(columns));
        console.log("error exporting");
      }
    } catch (e) {
      eSnack("Error inesperado exportando tickets");
      console.log("Error exporting", e);
    } finally { off() }
  }, [appliedFilters, eSnack, post, sSnack, show, off, iSnack]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  useEffect(() => () => controller.abort(), [controller]);

  const showExport = useCallback(() => setExportOpen(true), []);


  const hideExport = useCallback(() => setExportOpen(false), []);
  /* const hideAssignTicket = useCallback(() => setAssignTicketOpen(false), []); */

  const applyFilters = filters => {
    setPage(0)
    setAppliedFilters(filters)
  }

  const handlePageChange = useCallback((e, value) => {
    setPage(value);
  }, []);


  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      Inicio Tickets
    </Typography>,
  ];

  const openTicket = (row) => {
    history.push(`/tickets/${row.idinc}`)
  }

  const handleChangeRowsPerPage = useCallback((e, value) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value));
  }, []);

  const openFiltros = useCallback(row => {
    openFiltro()
  
  },[openFiltro])



  return (
    <>

      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <IconButtonMenu
            open={filtroOpen}
            toggleMenu={toggle}
            size="small"
            color={"primary"}
            icon={<FilterListIcon />}
          >
            <BaseFilters filters={ticketFilters} applyFilters={applyFilters} />
          </IconButtonMenu>
               {(currentUser.can?.see_activities) &&
                <Tooltip title="FILTRAR PDF">
                <IconButton >
                  <ManageSearchIcon /* sx={{ fontSize: 28 }} */ color="secondary" onClick={() => openFiltros() } />
                </IconButton>
                </Tooltip>
               }
         

          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>
      <Container className="base-container">
        <TicketsTables
          loading={tableLoading}
          rows={tickets}
          headers={["#", "Tipo", "Cliente", "Estado", "Descripción", "Asignado", /* "Acciones" */]}
          rowAction={row => openTicket(row)}
          collapsible={{
            visible: row => [
              { content: row.idinc, handleCollapse: true },
              { content: row.h_type },
              { content: row.codcli },
              { content: row.h_status },
              { content: row.problema },
              { content: getAssignedIcon(row), disableTooltip: true, /*...(showAssignTicket ? { action: () => showAssignTicket(row) } : {}) */ },

              /*    {
                   content: (
                     acciones(row)
                   ),
                   action: () => null,
                   disableTooltip: true,
                 }, */

            ],

            collapsed: row => [
              { name: "Usuario", content: row.nombpers },
              /*         { name: "Tipo", content: row.h_type }, */
              { name: "Fecha", content: row.fecinginc },
              { name: "Actividad", content: row.idactividad },
              { name: "ADR", content: row.numadr }
            ]
          }}
          title="Defectos"
        >
          <BaseTablePagination
            page={page}
            maxPage={maxPage}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            openDialog={openOtNew}
            exportDialog={showExport}
          >

          </BaseTablePagination>
        </TicketsTables>
      </Container>
      <NewTicket open={OtNew} handleClose={closeOtNew} reload={getTickets} />
      <ExportDialog
        exportOpen={exportOpen}
        handleClose={hideExport}
        exportTickets={exportTickets}
      />
        <FiltroPDF
       openF={filtro} handleCloseF={closeFiltro} 
       // filtroTickets={exportTickets}
      />
     {/*  {!!currentUser?.can?.assign_ticket && (
        <AssignTicketDialog
          reload={getTickets}
          assignTicketOpen={assignTicketOpen}
          handleClose={hideAssignTicket}
          ticket={dialogData.current}
        />
      )}
      {!!currentUser?.can?.see_activities && (
        <AutoActivityDialog
          reload={getTickets}
          autoActivityOpen={autoActivityOpen}
          handleClose={hideAutoActivity}
          ticket={dialogData.current}
        />
      )} */}
    </>
  );
};

