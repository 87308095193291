import React from "react"
import makeStyles from '@mui/styles/makeStyles';
import {
  Collapse,
  IconButton,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {ConditionalWrapper, StyledTableCell} from '../.'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    cursor: 'pointer'
  },
  collapsedContainer: {
    display:'flex',
    marginBottom:'2%',
    justifyContent: 'space-evenly',
    flexWrap:"wrap"
  },
  collapsedContent:{
    margin:'1%'
  }
});

export const CollapsibleRow = ({ visible, collapsed, rowAction }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const toggle = () => setOpen(!open)

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {visible.map((vRow, index) =>
          <ConditionalWrapper key={`vRow-${index}`} condition={!vRow.disableTooltip} wrapper={children => <Tooltip title={vRow.tooltip || vRow.content || ""}>{children}</Tooltip>}>
            <StyledTableCell className={vRow.className} align={vRow.align||"center"} onClick={vRow.handleCollapse ? toggle : (vRow.action || rowAction || toggle)}>
              {vRow.handleCollapse && <IconButton aria-label="expand row" size="small">
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>}
              {vRow.content}
            </StyledTableCell>
          </ConditionalWrapper>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          {Array.isArray(collapsed)?
            <div className={classes.collapsedContainer}>
              {  collapsed.map((cRow, index) =>

                {return !!cRow.content && <div key={`crow-${index}`} className={classes.collapsedContent}>{cRow.name}: <b>{cRow.content}</b></div>}
              )}
            </div>: collapsed}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
