import React, { useEffect, useState, useCallback, useRef } from "react"
import { BaseTable as ClientsTable, BaseDialog, BaseNewForm, BaseTablePagination, BaseFilters } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, useCurrentUser, useDialogTools, useTableTools, useParsedURLParams, useBackdrop, FIELD_TYPES } from "../../../helpers"
/* import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AddToPhotosRoundedIcon from '@mui/icons-material/AddToPhotosRounded'; */
import ViewModuleTwoToneIcon from '@mui/icons-material/ViewModuleTwoTone';
import AddToPhotosTwoToneIcon from '@mui/icons-material/AddToPhotosTwoTone';
import { ModulesDialog, CreateModuleDialog } from "../."
import { AppBar, Breadcrumbs, Container, Stack, Toolbar, Typography } from "@mui/material";
import { IconButtonMenu } from "../../layout/basic/IconButtonMenu";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import FilterListIcon from '@mui/icons-material/FilterList';

export const SystemsList = props => {
  const currentUser = useCurrentUser()
  const selectedSystem = useRef("")
  const [systems, setSystems] = useState([])
  const { get, form, controller } = useRequests()
  const [appliedFilters, setAppliedFilters] = useState(useParsedURLParams())
  const systemFilters = {
    codsistema: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Código"
    },
    nomsistema: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Nombre"
    }
  }
  const { show, off } = useBackdrop()
  const { eSnack, sSnack } = useCustomSnackbar()
  const { dialogOpen, openD, closeD } = useDialogTools()
  const { dialogOpen: modulesOpen, openD: openM, closeD: closeM } = useDialogTools()
  const { dialogOpen: createModuleOpen, openD: openCM, closeD: closeCM } = useDialogTools()
  const [formId] = useState("systems_form")
  const { page, rowsPerPage, total, setTotal, maxPage, setMaxPage, changePage, changeRows, tLoading, tLoaded, isLoading } = useTableTools()
  const [filtroOpen, setfiltroOpen] = useState(false);
  const toggle = () => setfiltroOpen(!filtroOpen)
  const getSystems = useCallback(async () => {
    // show("Cargando Sistemas")
    try {
      tLoading()
      const response = await get('/administration/systems', {
        page: page + 1,
        rowsPerPage: rowsPerPage,
        ...appliedFilters
      })
      if (response?.ok) {
        const data = await response.json()
        setSystems(data.systems)
        setMaxPage(data.maxPage)
        setTotal(data.total)
      } else {
        eSnack(await getErrorMsg(response), getSystems)
      }
    } catch (e) {
      console.log(e);
      eSnack("Error inesperado obteniendo sistemas")
    } finally { tLoaded() }
  }, [get, page, rowsPerPage, eSnack, setTotal, setMaxPage, appliedFilters, tLoaded, tLoading])

  const createSystem = useCallback(async e => {
    e.preventDefault()
    show('Creando Sistema')
    const data = new FormData(e.target)
    try {
      const request = await form('/administration/systems', data)
      if (request?.ok) {
        sSnack("Sistema Creado")
        getSystems()
        closeD()
      } else {
        eSnack(await getErrorMsg(request))
      }
    } catch (e) {
      eSnack("Error inesperado creando sistema")
      console.log("Error", e);
    } finally {
      off()
    }
  }, [show, sSnack, getSystems, closeD, eSnack, off, form])

  useEffect(() => {
    getSystems()
  }, [getSystems])

  const applyFilters = filters => {
    changePage(null, 0)
    setAppliedFilters(filters)
  }

  const openModules = useCallback(row => {
    selectedSystem.current = row.codsistema
    openM()
  }, [openM])

  const openCreateModule = useCallback(row => {
    selectedSystem.current = row.codsistema
    openCM()
  }, [openCM])

  const closeModules = useCallback(() => {
    selectedSystem.current = ""
    closeM()
  }, [closeM])

  const closeCreateModule = useCallback(() => {
    selectedSystem.current = ""
    closeCM()
  }, [closeCM])

  useEffect(() => () => controller.abort(), [controller])
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      Sistemas
    </Typography>,
  ];

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <IconButtonMenu
            open={filtroOpen}
            toggleMenu={toggle}
            size="small"
            color={"primary"}
            icon={<FilterListIcon />}
          >
            <BaseFilters filters={systemFilters} applyFilters={applyFilters} />
          </IconButtonMenu>
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container className="base-container">
        <ClientsTable
          loading={isLoading}
          rows={systems}
          title="Sistemas"
          extraActions={[
            {
              title: "Módulos",
              content: row => <ViewModuleTwoToneIcon color="secondary" onClick={() => openModules(row)} />
            },
            {
              title: "Agregar Módulo",
              content: row => <AddToPhotosTwoToneIcon color="secondary" onClick={() => openCreateModule(row)} />
            }
          ]}
        >
          <BaseTablePagination
            page={page}
            maxPage={maxPage}
            rowsPerPage={rowsPerPage}
            totalRows={total}
            handlePageChange={changePage}
            handleChangeRowsPerPage={changeRows}
            openDialog={currentUser.can?.create_systems ? openD : null}
          />
        </ClientsTable>
      <BaseDialog
        dialogOpen={dialogOpen}
        handleClose={closeD}
        title="Nuevo Sistema"
        confirmText={"Crear Sistema"}
        cancelText={"Cerrar"}
        confirm
        formId={formId}
        >
        <BaseNewForm
          fields={[
            {
              title: "Codigo",
              name: "system[codsistema]",
              id: "code",
              type: FIELD_TYPES.TEXT,
              value: "",
              required: true,
              upcaseValue: true,
              maxLength: 15
            },
            {
              title: "Nombre",
              name: "system[nomsistema]",
              id: "name",
              type: FIELD_TYPES.TEXT,
              value: "",
              required: true,
              maxLength: 120
            },
          ]}
          dialog={true}
          formId={formId}
          submit={createSystem}
          />
      </BaseDialog>
          </Container>
      <ModulesDialog dialogOpen={modulesOpen} handleClose={closeModules} currentSystem={selectedSystem.current} />
      <CreateModuleDialog dialogOpen={createModuleOpen} handleClose={closeCreateModule} currentSystem={selectedSystem.current} />
    </>
  )
}
