import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Link,
  Paper,
  Tooltip,
  Avatar
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import { DialogTitle } from '../../layout'

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.3),
    paddingLeft: theme.spacing(1.5),
    display: 'flex',
    /* backgroundColor: theme.palette.secondary.light, */
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2.3),
  }
}));



export const FilesDialog = ({ open, handleClose, files })=> {
  const classes = useStyles()

  return (
    <div>
      <Dialog id="files_dialog" open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title" sx={{padding:"9px"}}>Archivos</DialogTitle>
        <DialogContent sx={{padding:"18px"}}>
          {files.map((file, index) => {
            const content = (() => {
              if(file.image){
                return <Avatar alt={file.filename} src={`${file.link}`}/>
              }else{
                return <Avatar alt={file.filename}><DescriptionIcon/></Avatar>
              }
            })()
            return (
              <Paper className={classes.paper} key={`file-key-${index}` }>
                {content}
                <Typography variant="subtitle1">{file.filename}</Typography>
                <Tooltip title="Descargar">
                  <Link href={`${file.link}`} target="_blank">
                    <IconButton color="primary" size="small">
                      <GetAppRoundedIcon/>
                    </IconButton>
                  </Link>
                </Tooltip>
              </Paper>
            );
          })}
        </DialogContent>
        <DialogActions  sx={{padding:"5px"}}>
          <Button onClick={handleClose} variant="contained" color="secondary" size="small">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
