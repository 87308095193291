import {React,useState,useEffect} from "react";
import { Document, Page, Text, View, Image ,StyleSheet} from "@react-pdf/renderer";
import logo from '../../../images/kentron_original.png';
import {formatDate} from "../../../helpers"



const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  detailsData: {
    marginLeft:50,
      display: "flex",
      flexDirection: "row",
      fontSize: 8,
      backgroundColor:"#3388af"
    },

    tableRow: {
       flexDirection: "row" 
      }, 
    tableCol: { 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
     header: {
       display: "flex",
       flexDirection: "row",
       fontSize: 8,
    },
  
   data:{
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: 10,
   },
   table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #3388af',
    borderBottom: '1px solid #3388af',
    borderLeft: '1px solid #3388af',
    borderRight: '1px solid #3388af',
    paddingTop: 8,
    paddingBottom: 8,
    fontStyle: "italic", 
    fontSize: "11px",
    textAlign:"left"
  },
  headertabla: {
    borderTop: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },
  // So Declarative and unDRY 👌
  row1: {
    width: '60%',
    marginRight:3,

    
  },
  row2: {
    width: '10%',
    borderLeft: '1px solid #3388af',
      },
  row3: {
    width: '10%',
   
  },
  row4: {
    width: '10%',
    
  },
  row5: {
    width: '10%',
  
  },

   tabla:{
    display: 'table',
    },
    fila:{
    display: 'flex',
    flexDirection: 'row',
   
    },
    columna:{
    display: 'table-cell',
    marginTop:2,
    marginBottom:2,
    marginRight:1,
  
    border:'1px solid #3388af',
    textAlign:'left',
  
    },
  

});

export const  DocuPDF = ({totalEncuestados,cliente,desde,hasta, resp1Preg1,resp2Preg1,resp3Preg1,resp4Preg1,resp1Preg2,resp2Preg2,resp3Preg2,resp4Preg2
  ,resp1Preg3,resp2Preg3,resp3Preg3,resp4Preg3,resp1Preg4,resp2Preg4,resp3Preg4,resp4Preg4 ,resp1Preg5,resp2Preg5,resp3Preg5,resp4Preg5   }) => {
   const [date] = useState(new Date().toISOString().split("T")[0]);
   const hoy = new Date();
   const hora  = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
   const [mostrar, setMostrar] = useState(false)

   useEffect(()=>{
    if(totalEncuestados !== 0)
    setMostrar(true)
  },[totalEncuestados])

    return (
      <Document>
      <Page
        size="A4" style={styles.body}>
          {/*CABECERA*/}
       
           <View  style={styles.header} fixed >
           <div   style={{ float:'left'}} >
                  <Image src={logo}   style={{ maxWidth: "150"}}/>
              </div>

              <div   style={{  float:'left'  ,width:600, padding:20}}>
            <View style={styles.table}> {/* TableHeader */} 
            {/* Inicio columnas */}
           
            <View style={styles.tableRow}> 
                <View style={styles.tableCol}>  
                <Text   style={{ marginLeft:50,color: "black",fontStyle: "italic", fontSize: "12px", fontWeight:"bold"}}>
               Resumen Estadístico de Encuestas
              </Text>
                </View> 
                <View style={styles.tableCol}> 
                <Text style={{marginLeft:50,color: "black", fontStyle: "italic",fontSize: "10px",fontWeight:"bold" }} render={({ pageNumber, totalPages }) => (
                    `Página ${pageNumber} / ${totalPages}`
                )} fixed />
                </View> 
               
           </View>
           {/* Fin columnas */}
            {/* Inicio datos */} 

          <View style={styles.tableRow}  fixed > 
            <View style={styles.tableCol}>  

            </View> 
            <View style={styles.tableCol}> 
            <Text   style={{marginLeft:290,color: "black", fontStyle: "italic",fontSize: "10px",fontWeight:"bold" }}>
               Fecha: {formatDate(new Date(date))}  
            </Text>
            </View> 
           
         </View>
         <View style={styles.tableRow} fixed> 
            <View style={styles.tableCol}>  
                  
            </View> 
            <View style={styles.tableCol}> 
            <Text  style={{marginLeft:290, color: "black",fontStyle: "italic",fontSize: "10px",  fontWeight:"bold" }} >
                  Hora: {hora}
             </Text>
            </View> 
           
         </View>
         </View>
      

              </div>

      </View>
  {/*FIN CABECERA*/}

     <Text  style={{ fontStyle: "italic", fontSize: "12px", marginBottom:5}} fixed>Cliente: {cliente}</Text>
        <View style={styles.header}  fixed>
 
            {desde
           ?      <div   style={{ float:'left' , width:200}} >
                    <Text  style={{ fontStyle: "italic", fontSize: "12px"}} fixed>Desde: {formatDate(new Date(desde))}</Text>
                  </div>
           :     <div   style={{ float:'left' , width:200}} >
                    <Text  style={{ fontStyle: "italic", fontSize: "12px"}} fixed>Desde: {}</Text>
                  </div>
           }
           {hasta
           ?     <div   style={{ float:'left',marginLeft:20 ,width:200}} >
                   <Text  style={{ fontStyle: "italic", fontSize: "12px"}} fixed>Hasta:{formatDate(new Date(hasta))} </Text>
                </div>
           :    <div   style={{ float:'left',marginLeft:20 ,width:200}} >
                  <Text  style={{ fontStyle: "italic", fontSize: "12px"}} fixed>Hasta:{} </Text>
                </div>
           } 
        </View> 
        <Text  style={{ fontStyle: "italic", fontSize: "12px", marginTop:5}} fixed>Total Encuestados: {totalEncuestados}</Text>
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom:25}} fixed>
           <View style={{flex: 1, height: 1, backgroundColor: 'black'}} /> {/*linea negra*/}
        </View>  
         
  


        
         {mostrar ?
           <View style={{ width: '100%',marginBottom:25}}>
           <View style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11",}}>
             <View style={{ width: 20,  height:12 ,backgroundColor: 'rgba(255, 99, 132, 0.3)', float:'left', }} ></View><Text style={{ width: 150}} >Totalmente en desacuerdo</Text>
             <View style={{ width: 20,  height:12 ,backgroundColor: 'rgba(243, 115, 79, 0.7)', float:'left', marginRight:2}} ></View><Text style={{ width: 100}}>Desacuerdo</Text>
             <View style={{ width: 20,  height:12 ,backgroundColor: 'rgba(255, 206, 86, 0.5)', float:'left', marginRight:2}} ></View><Text style={{ width: 100}}>De acuerdo</Text>
             <View style={{ width: 20,  height:12 ,backgroundColor: 'rgba(75, 192, 192, 0.5)', float:'left', marginRight:2}}></View><Text  style={{ width: 150}}>Totalmente de acuerdo</Text>
           </View>
           </View>
         : ""

         }


 {mostrar ? 
<View >
<div style={styles.tabla}>
<div style={styles.fila}>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "320", textAlign:"center"}}>Preguntas / % Respuestas</Text></div>
  <div style={styles.columna}><View style={{ width: 50,  backgroundColor: 'rgba(255, 99, 132, 0.3)' }} ><Text style={{fontSize:"11", textAlign:"center", fontStyle:"italic"}}>%</Text></View></div>
  <div style={styles.columna}><View style={{ width: 50,  backgroundColor: 'rgba(243, 115, 79, 0.7)' }} ><Text style={{fontSize:"11", textAlign:"center", fontStyle:"italic"}}>%</Text></View></div>
  <div style={styles.columna}><View style={{ width: 50,  backgroundColor: 'rgba(255, 206, 86, 0.5)' }} ><Text style={{fontSize:"11", textAlign:"center", fontStyle:"italic"}}>%</Text></View></div>
  <div style={styles.columna}><View style={{ width: 50,  backgroundColor: 'rgba(75, 192, 192, 0.5)' }}><Text style={{fontSize:"11", textAlign:"center", fontStyle:"italic"}}>%</Text></View></div>
</div>
<div style={styles.fila}>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "320",height:"30" }}>¿Mi solicitud de asistencia fue completamente atendida por el personal de KENTRON?</Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50",  textAlign: "center"}}>{((resp1Preg1 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp2Preg1 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp3Preg1 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp4Preg1 / totalEncuestados)*100).toFixed(2)} </Text></div>
</div>
<div style={styles.fila}>
<div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "320",height:"30"}}>¿El tiempo que tomó atender mi solicitud fue adecuado?</Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp1Preg2 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp2Preg2 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp3Preg2 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp4Preg2 / totalEncuestados)*100).toFixed(2)} </Text></div>
</div>
<div style={styles.fila}>
<div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "320", height:"30"}}>¿La solución a mi solicitud fue satisfactoria?</Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp1Preg3 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp2Preg3 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp3Preg3 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp4Preg3 / totalEncuestados)*100).toFixed(2)} </Text></div>
</div>
<div style={styles.fila}>
<div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "320",height:"30"}}>¿La atención por parte del personal de KENTRON fue amable y eficiente?</Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp1Preg4 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp2Preg4 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp3Preg4 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp4Preg4 / totalEncuestados)*100).toFixed(2)} </Text></div>
</div>
<div style={styles.fila}>
<div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "320",height:"30"}}>¿Recibí información adecuada durante el proceso de asistencia?</Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp1Preg5 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp2Preg5 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp3Preg5 / totalEncuestados)*100).toFixed(2)} </Text></div>
  <div style={styles.columna}><Text  style={{display: 'flex',  flexDirection: 'row',fontStyle: "italic", fontSize: "11", width: "50", textAlign: "center"}}>{((resp4Preg5 / totalEncuestados)*100).toFixed(2)} </Text></div>
</div>
</div>
</View>
: ""}

      </Page>
    </Document>

  );
};
