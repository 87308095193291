import React from 'react';
import { BaseDialog, BaseNewForm } from '../../layout'

export const ResolveTicketDialog = React.memo(({resolve, dialogOpen, closeD}) => {
  return <BaseDialog
    dialogOpen={dialogOpen}
    handleClose={closeD}
    title="Resolver Ticket"
    confirmText={"Enviar Respuesta"}
    cancelText={"Cerrar"}
    formId={"resolve_form"}
    confirm
  >
    <BaseNewForm
      fields={[
        {
          title:"Respuesta",
          name:"ticket[respuesta]",
          id:"response",
          type:"text",
          value:"",
          required:true
        }
      ]}
      dialog={true}
      formId={"resolve_form"}
      submit={resolve}
    />
  </BaseDialog>
})
