import React, {} from 'react';
import {BaseDialog} from '../../layout'
import {  PDFViewer } from "@react-pdf/renderer";
import {DocuPDF} from  "./docu_pdf";


export const PDF = React.memo(({openPDF, handleClosePDF,data,fecha,codcliente,desde,hasta }) => {

  return (
    <BaseDialog
    dialogOpen={openPDF}
    handleClose={handleClosePDF}
    title={"PDF"}
    confirmText={"Generar PDF"}
    cancelText={"Cerrar"}
   //   confirm={pdf}
    
    > 
     <PDFViewer style={{ width: "100%", height: "90vh" }}>
         <DocuPDF  data={data} date={fecha} cliente={codcliente} fdesde={desde} fhasta={hasta} />
     </PDFViewer>

   
    </BaseDialog>
  );
})
