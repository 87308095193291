import { IconButton } from "@mui/material";
import React from "react"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import 'draft-js/dist/Draft.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export const DraftDescription = ({descriptionState, setDescriptionState}) => {

  return <div className="editor-holder">

    <Editor
      toolbar={{
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history']
      }}
      placeholder = "Descripción *"
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      editorState={descriptionState}
      onEditorStateChange={setDescriptionState} />
       <label htmlFor="file-input">
        Archivos:
                <IconButton
                  title='Adjuntar Archivos'
                  variant="contained"
                  color="primary"
                  component="span"
                  size="small"
                >
                  <AttachFileIcon />
                </IconButton> 
                
              </label>
  </div>
}
