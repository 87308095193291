export const es = {
  translation: {
    nomsistema:"Nombre",
    codsistema:"Código",
    codcli:"Código",
    nomcli:"Nombre",
    usucre:"Creador",
    feccre:"Fecha Creación",
    usumod:"Modificador",
    fecmod:"Fecha Modificación",
    stscli:"Estado",
    mensajefax:"Mensaje Fax",
    copiafax:"Copia Mensaje",
    prioridadcliente:"Prioridad",
    contacto:"Contacto",
    telefono:"Teléfono",
    fax:"Fax",
    active:"Activo",
    inactive:"Inactivo",
    pending:"Pendiente",
    nommodulo:"Nombre",
    codmodulo:"Código",
    indusoclientes:"Usa Cliente?",
    sistema:"Sistema",
    yes:"Si",
    Yes:"Si",
    ticket_new_mail:"Un ticket es creado",
    ticket_comment_mail:"Se recibe respuesta en ticket",
    ticket_assigned_mail:"Mi ticket es asignado",
    ticket_assignee_mail:"Me asignan un ticket"
  }
}
