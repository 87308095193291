import React, { useEffect, useState, useCallback } from "react"
import { Link as RouterLink, useHistory } from "react-router-dom"
import { BaseTable as OrdersTable, BaseTablePagination, BaseFilters, CheckedUnchecked } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, useTableTools, formatDate, useParsedURLParams, FIELD_TYPES, useDialogTools } from "../../../helpers"
/* import AssignmentRoundedIcon from '@mui/icons-material/Assignment'; */
/* import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone'; */
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Typography,
  AppBar,
  Toolbar,
  Breadcrumbs,
  Stack,
  Container,

} from "@mui/material"
import Link from '@mui/material/Link';
import { NewOT } from "..";
import { IconButtonMenu } from "../../layout/basic/IconButtonMenu";



export const OrdersList = ({ match }) => {
  const { get, controller } = useRequests()
  const [orders, setOrders] = useState([])
  const history = useHistory();
  const { dialogOpen: OtNew, openD: openOtNew, closeD: closeOtNew } = useDialogTools()


  const [appliedFilters, setAppliedFilters] = useState(useParsedURLParams())
  const orderFilters = {
    idot: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "ID"
    },
    nomot: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Nombre"
    },
    refot: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Referencia"
    },
    usuresp: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Responsable"
    },
    codproy: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Código proyecto"
    },
    fecinivigdesde: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Inicio Desde"
    },
    fecinivighasta: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Inicio Hasta"
    },
    fecfinvigdesde: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Fin Desde"
    },
    fecfinghasta: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Fin Hasta"
    }

  }


  const [date] = useState(new Date().toISOString().split("T")[0]);
  const { eSnack } = useCustomSnackbar()
  const { page, rowsPerPage, total, setTotal, maxPage, setMaxPage, changePage, changeRows } = useTableTools()
  const [loadingTable, setLoadingTable] = useState(false)
  const [filtroOpen, setfiltroOpen] = useState(false);
  const toggle = () => setfiltroOpen(!filtroOpen)



  const getOrders = useCallback(async () => {
    try {
      setLoadingTable(true)
      const response = await get('/administration/works_orders', {
        page: page + 1,
        rowsPerPage: rowsPerPage,
        ...appliedFilters
      })
      if (response?.ok) {
        const data = await response.json()
        setOrders(data.works_orders)
        setMaxPage(data.maxPage)
        setTotal(data.total)
      } else {
        eSnack(await getErrorMsg(response), getOrders)
      }
    } catch (e) {
      console.log(e);
      eSnack("Error inesperado obteniendo órdenes de trabajo")
    } finally { setLoadingTable(false) }
  }, [get, page, rowsPerPage, eSnack, setTotal, setMaxPage, appliedFilters])


  useEffect(() => {
    getOrders()
  }, [getOrders])

  const applyFilters = filters => {
    changePage(null, 0)
    setAppliedFilters(filters)
  }

  const fechaInicioOt = (row) => {
    if (row.fecinivig === null) {
      return (
        { content: row.fecinivig }

      )
    }
    else {
      return (
        { content: formatDate(new Date(row.fecinivig)) }
      )
    }
  }

  const fechaFinOt = (row) => {
    if (row.fecfinvig === null) {
      return (
        { content: row.fecfinvig }

      )
    }
    else {
      return (
        { content: formatDate(new Date(row.fecfinvig)) }
      )
    }
  }

  const fechaInicioProy = (fecinivig) => {
    if (fecinivig === null) {
      return (
        { name: "Fecha Inicio Proy.", content: fecinivig }

      )
    }
    else {
      return (
        {
          name: "Fecha Inicio Proy.", content: formatDate(new Date(fecinivig)
          )
        }
      )
    }
  }
  const fechaFinProy = (fecfinvig) => {
    if (fecfinvig === null) {
      return (
        { name: "Fecha Fin Proy.", content: fecfinvig }

      )
    }
    else {
      return (
        {
          name: "Fecha Fin Proy.", content: formatDate(new Date(fecfinvig)
          )
        }
      )
    }
  }

  const stsContratacion = (row) => {
    var content
    switch (row.stscontratacion) {
      case "PRO":
        content = "En Proceso"
        break;
      case "APR":
        content = "Aprobada"
        break;
      case "N/A":
        content = "No Aplica"
        break;
      default:
        content = row.stscontratacion
    }
    return { name: "Estatus de Contratación", content: content }
  }



  const vigenciaOT = (row) => <CheckedUnchecked validate={row.fecfinvig > date} />

  const tieneFechaEntrega = (row) => <CheckedUnchecked validate={row.work_order_type?.indfechaentrega === "S"} />

  const vigenciaProy = (fecfinvig) => <CheckedUnchecked validate={fecfinvig > date || fecfinvig === null} />

  const esSoporte = (row) => <CheckedUnchecked validate={row.has_wok_order_sop} />

  const soporteVigente = (row) => {
    if (row.has_wok_order_sop) {
      return <CheckedUnchecked validate={row.work_order_sop?.fecfinvig > date} />
    }
  }


  useEffect(() => () => controller.abort(), [controller])

  const openOt = ({ idot }) => {
    history.push(`${match.path}${idot}`)
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      Ordenes de Trabajo
    </Typography>,
  ];

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <IconButtonMenu
            open={filtroOpen}
            toggleMenu={toggle}
            size="small"
            color={"primary"}
            icon={<FilterListIcon />}
          >
            <BaseFilters filters={orderFilters} applyFilters={applyFilters} />
          </IconButtonMenu>
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>


      <Container className="base-container">

        <OrdersTable

          loading={loadingTable}
          rows={orders}
          headers={["Id OT", "Nombre", "Referencia", "Fecha Inicio", "Fecha Fin",/*  "Acciones" */]}
          rowAction={row => openOt(row)}

          collapsible={{
            visible: row => [
              { content: row.idot, handleCollapse: true, align: "left" },
              { content: row.nomot },
              { content: row.refot },
              fechaInicioOt(row),
              fechaFinOt(row),
             /*  {
                content: <Tooltip title={`Abrir actividades para OT ${row.idot}`}>
                  <Link component={RouterLink} to={`/activities/list?idot=${row.idot}`}>
                    <AssignmentTwoToneIcon color="secondary" />
                  </Link>
                </Tooltip>,
                action: () => null,
                disableTooltip: true
              } */
            ],

            collapsed: row => [
              { name: "Proyecto", content: row.codproy },
              { name: "Nombre Proyecto", content: row.project?.nomproy },
              { name: "Responsable Proyecto", content: row.project?.responsable },
              { name: "Cliente Proyecto", content: row.project?.codcli },
              fechaInicioProy(row.project?.fecinivig),
              fechaFinProy(row.project?.fecfinvig),
              { name: "Proyecto Vigente?", content: vigenciaProy(row.project?.fecfinvig) },

              { name: "OT Vigente?", content: vigenciaOT(row) },
              { name: "Tiene fecha de entrega?", content: tieneFechaEntrega(row) },
              { name: "Responsable OT", content: row.usuresp },
              { name: "Es Soporte?", content: esSoporte(row) },
              { name: "Soporte Vigente?", content: soporteVigente(row) },


              { name: "Área", content: row.codarea },
              { name: "Nombre Area", content: row.area?.nomarea },
              { name: "Clasificación", content: row.codclase },
              { name: "Nombre Clase", content: row.work_order_type?.nombre },
              { name: "Naturaleza", content: row.work_order_type?.naturaleza },
              { name: "Facturación", content: row.work_order_type?.modofacturacion },
              { name: "Tiene fecha de entrega?", content: tieneFechaEntrega(row) },
              stsContratacion(row),
              { name: "% Autorizado", content: row.porcejecucionautorizado },
              { name: "Descripción OT", content: row.descot }
            ]
          }}
          title="Órdenes de Trabajo"
        >
          <BaseTablePagination
            page={page}
            maxPage={maxPage}
            rowsPerPage={rowsPerPage}
            totalRows={total}
            handlePageChange={changePage}
            handleChangeRowsPerPage={changeRows}
            openDialog={openOtNew}
          />
        </OrdersTable>
        <NewOT open={OtNew} handleClose={closeOtNew} reload={getOrders} />

      </Container>
    </>
  )
}
