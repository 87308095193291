import React from 'react'
import {ActivityList, ActivityDetail} from '.'
import {Route, Switch} from "react-router-dom"

export const Activities = ({match}) =>{
  return (
    < >
      <Switch>
        <Route path={`${match.path}/list`} component={ActivityList}/>
        <Route path={`${match.path}/:id`} component={ActivityDetail}/>
      </Switch>
    </>
  )
}
export * from './list'
export * from './detail'
export * from './detail/assignation'
export * from './detail/module'
export * from './detail/object'
export * from './detail/user_assignation'
export * from './detail/defectos'
