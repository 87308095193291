export const inMaintenance = process.env.REACT_APP_MAINTENANCE;
export const environment = function getEnvironment() {
  switch (process.env.REACT_APP_BACKEND) {
    case "staging":
      return "Staging"
    case "production":
      return null
    default:
      return "Development"
  }
}()
