import React, {useEffect} from "react"
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper

  } from '@mui/material';
import {useRequests} from "../../../helpers"
import {CollapsibleRow,BaseTable} from "../../layout"


const useStyles = makeStyles( theme => ({
  header: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#83c3f7",
    color: theme.palette.primary.contrastText,
    padding: "16px",
  },
  body: {
    padding: "16px",
  },
}))(TableCell);



//cabecera de table Módulos
const headersmodules = ["Módulo", "Nombre", "Tipo"]
// const { t } = useTranslation()

const makeHeadersModules = ()=>{
  return headersmodules.map((header,index)=>{
    return <StyledTableCell key={index} align={"center"}>{header}</StyledTableCell>
  })
}



export const ActivityObject = React.memo(props => {
  const classes = useStyles();
  const {controller} = useRequests()


  useEffect(() => () => controller.abort(), [controller])


  return (
    <>
    <Paper>

     <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader className={classes.table}>
            <TableHead  >
              <TableRow>
              {makeHeadersModules()}
              </TableRow>
            </TableHead>
            <TableBody>

            {props.actividad.activity_objects?.map((row, index) => {

            return <CollapsibleRow
                   key={index}
                   visible={[
                    {content: row.codmodulo, handleCollapse:true},
                    {content: row.nomobjeto},
                    {content: row.tipoobjeto}
                   ]}
                   collapsed={<BaseTable
                                  rows={row.activity_object_changes}
                                  title="Cambios"
                             />}
                  />
            })}
            </TableBody>
          </Table>
        </TableContainer>
    </Paper>





    </>
  );

})
