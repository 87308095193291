import React, {useState, useCallback, useEffect} from 'react';
import {
  Typography,
  Grid ,
  TextField,
}  from '@mui/material';
import {BaseDialog,Autocomplete} from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg,useDialogTools,FIELD_TYPES,useBackdrop,formatDate} from "../../../helpers"
import {PDF} from  "./pdf";

export const FiltroPDF = React.memo(({openF, handleCloseF}) => {


  const { get,post  } = useRequests()
  const { eSnack  } = useCustomSnackbar()
  const { show, off } = useBackdrop()

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [date] = useState(new Date().toISOString().split("T")[0]);

  const [codcli, setCodcli] = useState("")
  const [loadingClientes, setLoadingClientes] = useState(false)
  const [clientes, setClientes] = useState([])
  const [cliente, setCliente] = useState("")
  const [nombreCliente, setNombreCliente] = useState("")

  
  const [tickets, setTickets] = useState({})

  const { dialogOpen: pdf, openD: openPDF, closeD: closePDF } = useDialogTools()

  const getClients = useCallback(async () => {
    try {
      setLoadingClientes(true)
      const response = await get('/tickets/clientes')
      if (response?.ok) {
        const data = await response.json()
        setClientes(data)

      } else {
        eSnack(await getErrorMsg(response), getClients)
      }
    } catch (e) {
      console.log(e);
      eSnack("Error inesperado obteniendo clientes")
    } finally { setLoadingClientes(false) }
  }, [get, eSnack])


  useEffect(() => {
      if(openF)
      setCliente('')
    getClients()
  }, [getClients,openF])

  useEffect(() => {
    if(codcli){
    setCliente(codcli.codcli)
    setNombreCliente(codcli.nomcli)
    }else{
        setCliente('')
        setNombreCliente('')
    }
}, [codcli])


const handleForm = e => {
    const { value, id } = e.target
    switch (id) {
      
      case 'fecfinvig':
        setEndDate(value)
        break
      case 'fecinivig':
        setStartDate(value)
        break
     

      default:
        break
    }
  }


      const filtrar = async e => {
        e.preventDefault()
        if (!cliente) return eSnack("Debe colocar un valor para filtrar")
        if (!startDate) return eSnack("Debe colocar un valor para filtrar")
        if (!endDate) return eSnack("Debe colocar un valor para filtrar")

        show('Filtrando datos')
       
        try {
          const response = await post(`/tickets/filtro_ticket`, {
          codcli: cliente,
          desde: startDate,
          hasta: endDate
       })
          if (response?.ok) {
            const tickets = await response.json()
            setTickets(tickets)
            openPDF()
           // history.push(`${history.location.pathname}/${idot}`)
         /*    reload()
            handleClose() */
            /* window.location.reload('') */

          }
          else {
            eSnack(await getErrorMsg(response))
          }
        } catch (e) {
          console.log(e)
          eSnack("Error inesperado Filtrando datos")
        } finally {
          off();
        }
      }

  return (
    <BaseDialog
    dialogOpen={openF}
    handleClose={handleCloseF}
      title={"Reporte de Incidencias"}
      confirmText={"Generar PDF"}
      cancelText={"Cerrar"}
      confirm={filtrar}
      width="xs"
    >
  

      <Typography gutterBottom>
       <b>Selecciona los parámetros para generar el PDF</b> 
      </Typography>
      <Grid container /*  direction="row" */>
      <Grid item xs={12}/*  lg={8} */ >
              <Autocomplete
                fullWidth
                name="codcli"
                id="codcli"
                options={clientes}
                size="small"
                value={codcli}
                setValue={setCodcli}
                valueKey={"codcli"}
                loading={loadingClientes}
                getOptionLabel={(option) => `${option.codcli}- ${option.nomcli}`}
                renderInput={(params) => <TextField {...params} required fullWidth label="Clientes" />}
              />
     </Grid>    
   
     </Grid>  <br/>

     <Grid container spacing={2}> {/* Grid que envuelve fechas */}
        <Grid item xs={6}>
          <TextField
            name="fecinivig"
            id="fecinivig"
            type={FIELD_TYPES.DATE}
            label="Inicio"
            value={startDate}
            title="Fecha inicio"
            size="small"
            onChange={handleForm}
            fullWidth
          //  InputProps={{ inputProps: { min: date } }}
            InputLabelProps={{
              shrink: FIELD_TYPES.DATE === "date" ? true : undefined
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="fecfinvig"
            id="fecfinvig"
            type={FIELD_TYPES.DATE}
            label="Fin"
            value={endDate}
            title="Fecha inicio"
            size="small"
            onChange={handleForm}
            fullWidth
           // InputProps={{ inputProps: { min: startDate } }}
            InputLabelProps={{
              shrink: FIELD_TYPES.DATE === "date" ? true : undefined
            }}
          />
        </Grid>
     </Grid>

     <PDF openPDF={pdf} handleClosePDF={closePDF} 
     codcliente={nombreCliente}  
     data={tickets} 
     fecha={formatDate(new Date(date))}
     desde={startDate}
     hasta={endDate}/>
    </BaseDialog>
  );
})
