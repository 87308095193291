import React, {createContext, useContext, useState, useCallback} from "react"
import {ConfirmationDialog} from  "../components/layout"
import {useParsedURLParams, mergeObjects} from  "."


export const UserContext = createContext({})
export const useCurrentUser = () => useContext(UserContext)


export const ConfirmationDialogContext = createContext({});

export const ConfirmationDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});

  const openDialog = ({ title, message, actionCallback }) => {
    // console.log('openDialog -> setDialogOpen', setDialogOpen)
    // console.log('openDialog -> dialogOpen', dialogOpen)
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    // setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }) =>
    new Promise((res) => {

      openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

export const useDialogTools = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const openD = useCallback(() => setDialogOpen(true),[])
  const closeD = useCallback(() => setDialogOpen(false),[])
  return { dialogOpen, openD, closeD }
}

export const useTableTools = () => {
  const [page, setPage] = useState(0)
  const [maxPage, setMaxPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const changePage = useCallback((e, value) => setPage(value),[setPage])
  const changeRows = useCallback((e, value) => {
    setPage(0)
    setRowsPerPage(parseInt(e.target.value))
  },[setPage, setRowsPerPage])
  const tLoading = useCallback(()=> setIsLoading(true), [])
  const tLoaded = useCallback(()=> setIsLoading(false), [])


  return { page, rowsPerPage, total, setTotal, maxPage, setMaxPage, changePage, changeRows, tLoading, tLoaded, isLoading}
}

export const useFilters = filters => {
  const urlFilters = useParsedURLParams()
  const getDefaultFilters = useCallback(() => {
    for(const key in urlFilters){
      urlFilters[key] = {value: urlFilters[key]}
    }
    return mergeObjects(filters, urlFilters,{restricted:true})
  },[filters, urlFilters])
  const [parsedFilters] = useState(getDefaultFilters())
  return parsedFilters
}

/*Check if value is true/false with 0 being true*/
export const rubyBoolCheck = value => {
  return (!!value || value === 0)
}
