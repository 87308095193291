import React from 'react'
import {QuizList} from '../.'
import {Route, Switch} from "react-router-dom"
import { FormDetail } from './form_detail'


export const Quiz = ({match}) =>{
  return (
    < >
      <Switch>
        <Route exact path={`${match.path}/`} component={QuizList}/>
        <Route exact path={`${match.path}/:idinc`} component={FormDetail}/>
      </Switch>
    </>
  )
}
