import React from "react"
import { Container, Divider, Paper, Stack } from '@mui/material'
import { FIELD_TYPES } from "../../../helpers"
import { EditableTextField } from '../../layout'
import { CardWithBar } from "../../layout/basic/CardWithBar"



export const AdrObservaciones = React.memo(({ adr, saveField }) => {

  const observacion = () => {
    return (

      <EditableTextField
        initialValue={adr.observacion}
        name="observacion"
        save={saveField}
        type={FIELD_TYPES.TEXTAREA}
        placeholder="Observación"
        

      />
    )
  }


  return (
    <>
      <CardWithBar title={"Observación"}>
        <Container sx={{ marginY: 2 }}>

          {observacion()}
        </Container>
      </CardWithBar>
    </>
  );

})
