import React, { useState, useCallback, useEffect, } from "react"
import { BaseDialog, Autocomplete } from "../../layout";
import { useHistory } from "react-router-dom"
import { useRequests, useCustomSnackbar, getErrorMsg, useBackdrop, FIELD_TYPES } from "../../../helpers"
import { Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Typography } from "@mui/material";

export const NewOT = ({ open, handleClose, reload }) => {

  const { get, post } = useRequests()
  const { eSnack, sSnack } = useCustomSnackbar()
  const { show, off } = useBackdrop()

  const [loadingproyecto, setLoadingproyecto] = useState(false)
  const [loadingarea, setLoadingarea] = useState(false)
  const [loadingclase, setLoadingclase] = useState(false)
  const [loadingAC, setloadingAC] = useState(false)

  const [projects, setProjects] = useState([])
  const [areas, setAareas] = useState([])
  const [area, setAarea] = useState("")
  const [clases, setClases] = useState([])
  const [clase, setClase] = useState("")
  const [users, setUsers] = useState([]);
  const [contratos, setContratos] = useState([]);
  const [contrato, setContrato] = useState("");
  const [user, setUser] = useState("");
  const [autorizado, setAutorizado] = useState("");
  const history = useHistory()


  const [proyecto, setProyecto] = useState("")
  const [refOt, setRefOt] = useState("")
  const [descot, setDescOt] = useState("")
  const [originalH, setOriginalH] = useState("")
  const [actualH, setActualH] = useState("")
  const [realH, setRealH] = useState("")
  const [nombreot, setnombreOt] = useState("")
  const [originalHs, setOriginalHs] = useState("")
  const [actualHs, setActualHs] = useState("")
  const [isChecked, setIsChecked] = useState(false);

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [date] = useState(new Date().toISOString().split("T")[0]);




  const createOT = async e => {
    e.preventDefault()
    show('Creando Orden de Trabajo')
    const data = {
      nomot: nombreot,
      pptohhorg: originalH,
      pptohhact: actualH,
      hhreal: realH,
      pptohhorgext: originalHs,
      pptohhactext: actualHs,
      fecfinvig: endDate,
      fecinivig: startDate,
      codclase: clase?.codclase,
      codproy: proyecto?.codproy,
      descot: descot,
      refot: refOt,
      porcejecucionautorizado: autorizado,
      usuresp: user?.codusu,
      codarea: area?.codarea,
      stscontratacion: contrato?.value,

    }
    try {
      const request = await post(`/administration/works_orders`, {
      workorder: data,
      essoporte: isChecked })
      if (request?.ok) {
        sSnack("OT Creada")
        const {idot} = await request.json()
        history.push(`${history.location.pathname}/${idot}`)
     /*    reload()
        handleClose() */
        /* window.location.reload('') */
      } else {
        eSnack(await getErrorMsg(request))
      }
    } catch (e) {
      console.log(e)
      eSnack("Error inesperado creando OT")
    } finally {
      off();
    }
  }

  const getProjects = useCallback(async () => {
    try {
      setLoadingproyecto(true)
      const response = await get('/administration/works_orders/projects')
      if (response?.ok) {
        const data = await response.json()
        setProjects(data)
      } else {
        eSnack(await getErrorMsg(response), getProjects)
      }
    } catch (e) {
      console.log(e);
    } finally { setLoadingproyecto(false) }
  }, [get, eSnack])

  useEffect(() => {
    if (open && !projects.length) {
      getProjects()
    }
  }, [getProjects, open, projects.length])

  const getAreas = useCallback(async () => {
    try {
      setLoadingarea(true)
      const response = await get('/administration/works_orders/areas')
      if (response?.ok) {
        const data = await response.json()
        setAareas(data)
      } else {
        eSnack(await getErrorMsg(response), getAreas)
      }
    } catch (e) {
      console.log(e);
    } finally { setLoadingarea(false) }
  }, [get, eSnack])

  useEffect(() => {
    if (open && !areas.length) {
      getAreas()
    }
  }, [getAreas, open, areas.length])

  const getAssignables = useCallback(async () => {
    try {
      setloadingAC(true)
      var response = await get("/administration/works_orders/responsable");
      if (response?.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        eSnack(await getErrorMsg(response), getAssignables);
        console.error("Error Retrieving Assignables");
      }
    } catch (e) {
      eSnack("Error inesperado cargando usuarios assignables", getAssignables);
      console.error("Retrieving Assignables:", e);
    } finally { setloadingAC(false) }
  }, [get, eSnack]);

  useEffect(() => {
    if (open && !users.length)
      getAssignables()
  }, [getAssignables, open, users.length])

  const getStscontratacion = useCallback(async () => {
    try {
      setloadingAC(true)
      var response = await get("/administration/works_orders/stscontratacion");
      if (response?.ok) {
        const data = await response.json();
        setContratos(data);
      } else {
        eSnack(await getErrorMsg(response), getStscontratacion);
        console.error("Error Retrieving Assignables");
      }
    } catch (e) {
      eSnack("Error inesperado cargando usuarios assignables", getStscontratacion);
      console.error("Retrieving Assignables:", e);
    } finally { setloadingAC(false) }
  }, [get, eSnack]);

  useEffect(() => {
    if (open && !contratos.length)
      getStscontratacion()
  }, [getStscontratacion, open, contratos])

  const getClases = useCallback(async () => {
    try {
      setLoadingclase(true)
      const response = await get('/administration/works_orders/clases')
      if (response?.ok) {
        const data = await response.json()
        setClases(data)
      } else {
        eSnack(await getErrorMsg(response), getClases)
      }
    } catch (e) {
      console.log(e);
    } finally { setLoadingclase(false) }
  }, [get, eSnack])

  useEffect(() => {
    if (open && !clases.length) {
      getClases()
    }
  }, [getClases, open, clases.length])

  const handleOnChange = () => { setIsChecked(!isChecked) };

  const handleForm = e => {
    const { value, id } = e.target
    switch (id) {
      case 'pptohhorg':
        setOriginalH(value)
        break
      case 'pptohhact':
        setActualH(value)
        break
      case 'hhreal':
        setRealH(value)
        break
      case 'pptohhorgext':
        setOriginalHs(value)
        break
      case 'pptohhactext':
        setActualHs(value)
        break
      case 'fecfinvig':
        setEndDate(value)
        break
      case 'fecinivig':
        setStartDate(value)
        break
      case 'descot':
        setDescOt(value)
        break
      case 'refot':
        setRefOt(value)
        break
      case 'autorizado':
        setAutorizado(value)
        break
      case 'nomot':
        setnombreOt(value)
        break

      default:
        break
    }
  }

  return <BaseDialog
    dialogOpen={open}
    handleClose={handleClose}
    title="Nueva Orden de Trabajo"
    confirmText={"Crear Ot"}
    cancelText={"Cerrar"}
    width="sm"
    confirm={createOT}
  >
    <form onSubmit={createOT}>
      <Typography align="leght" variant="subtitle1" mb={1} color="grey" >
        Datos del Proyecto
      </Typography>
      <Grid container spacing={1} > {/* Grid que envuelve proyectos */}
        <Grid item xs={6}>
          <Autocomplete
            name="codproy"
            id="codproy"
            options={projects}
            size="small"
            value={proyecto}
            setValue={setProyecto}
            valueKey={"codproy"}
            loading={loadingproyecto}
            getOptionLabel={(option) => `${option.codproy} - ${option.nomproy}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Proyecto" />}

          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            placeholder=""
            id="nomot"
            label="Nombre"
            value={nombreot}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
            onChange={handleForm}
          />
        </Grid>
        <Grid item xs={2} sm={2}  >
          <FormControlLabel
            control={<Checkbox id="idsoporte" checked={isChecked} onChange={handleOnChange} />}
            label="Soporte?"
            labelPlacement="end"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}> {/* Grid que envuelve todo de horas */}
        <Grid item xs={7} >
          <Typography align="leght" variant="subtitle1" my={1} color="grey" >
            Horas (Estimado/Real)
          </Typography>
          <Grid container spacing={1.5} >
            <Grid item xs={4}>
              <TextField
                name="pptohhorg"
                id="pptohhorg"
                label="Original"
                type={FIELD_TYPES.NUMBER}
                size="small"
                value={originalH}
                fullWidth
                onChange={handleForm}
                InputLabelProps={{
                  shrink: true,
                }} />

            </Grid>
            <Grid item xs={4}>
              <TextField
                name="pptohhact"
                id="pptohhact"
                label="Actual"
                type={FIELD_TYPES.NUMBER}
                size="small"
                value={actualH}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleForm} />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="hhreal"
                id="hhreal"
                label="Real"
                type={FIELD_TYPES.NUMBER}
                size="small"
                value={realH}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleForm} />
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={5}>
          <Typography align="leght" variant="subtitle1" my={1} color="grey" >
            Horas Sub-Contratadas
          </Typography>
          <Grid container spacing={1.5} >
            <Grid item xs={6} >
              <TextField
                name="pptohhorgext"
                id="pptohhorgext"
                label="Original"
                type={FIELD_TYPES.NUMBER}
                size="small"
                value={originalHs}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleForm} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="pptohhactext"
                id="pptohhactext"
                label="Actual"
                type={FIELD_TYPES.NUMBER}
                size="small"
                value={actualHs}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleForm} />
            </Grid>
          </Grid>
        </Grid>
      </Grid> {/* Cierra el Grid de horas */}

      <Typography align="leght" variant="subtitle1" my={1} color="grey" >
        Fechas
      </Typography>
      <Grid container spacing={2}> {/* Grid que envuelve fechas */}
        <Grid item xs={4}>
          <TextField
            name="fecinivig"
            id="fecinivig"
            type={FIELD_TYPES.DATE}
            label="Inicio"
            value={startDate}
            title="Fecha inicio"
            size="small"
            onChange={handleForm}
            fullWidth
            InputProps={{ inputProps: { min: date } }}
            InputLabelProps={{
              shrink: FIELD_TYPES.DATE === "date" ? true : undefined
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="fecfinvig"
            id="fecfinvig"
            type={FIELD_TYPES.DATE}
            label="Fin"
            value={endDate}
            title="Fecha inicio"
            size="small"
            onChange={handleForm}
            fullWidth
            InputProps={{ inputProps: { min: startDate } }}
            InputLabelProps={{
              shrink: FIELD_TYPES.DATE === "date" ? true : undefined
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            name="usuresp"
            id="usuresp"
            options={users}
            size="small"
            value={user}
            setValue={setUser}
            valueKey={"codusu"}
            loading={loadingAC}
            getOptionLabel={(option) => `${option.nombpers}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Responsable" />}
          />

        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}> {/* Grid que envuele Referencia y area */}
        <Grid item xs={6}>
          <TextField
            required
            placeholder=""
            name="refot"
            id="refot"
            label="Referencia"
            value={refOt}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
            onChange={handleForm}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            name="codarea"
            id="codarea"
            loading={loadingarea}
            options={areas}
            size="small"
            value={area}
            setValue={setAarea}
            valueKey={"codarea"}
            getOptionLabel={(option) => `${option.nomarea} -${option.codarea}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Area" />}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}> {/* Grid que envuelve clasificacion  */}
        <Grid item xs={8}>
          <Autocomplete
            name="codclase"
            id="codclase"
            loading={loadingclase}
            options={clases}
            size="small"
            value={clase}
            setValue={setClase}
            valueKey={"codclase"}
            getOptionLabel={(option) => `${option.nombre}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Clasificacion" />}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            required
            name="naturaleza"
            id="naturaleza"
            label="Naturaleza"
            value={clase?.naturaleza}
            /*  valueKey={} */
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}

          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            required
            name="modofacturacion"
            id="modofacturacion"
            label="Fact."
            value={clase?.modofacturacion}
            /*  valueKey={} */
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}

          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}> {/* Grid que envuelve estatus de contratacion */}
        <Grid item xs={4}>
          <Autocomplete
            name="stscontratacion"
            id="stscontratacion"
            loading={loadingarea}
            options={contratos}
            size="small"
            value={contrato}
            setValue={setContrato}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Estatus de contratacion" />}
          />
        </Grid>
        <Grid item xs={2.5}>
          <TextField
            id="autorizado"
            label="% Autorizado"
            value={autorizado}
            type={FIELD_TYPES.NUMBER}
            fullWidth
            size="small"
            onChange={handleForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={2.5}   >
          <FormControlLabel
            id="indpresupuesto"
            value="end"
            control={<Checkbox
              checked={clase?.indpresupuesto === "S"} />}
            label="Maneja presupuesto"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={3}   >
          <FormControlLabel
            id="indfechaentrega"
            value="end"
            control={<Checkbox
              checked={clase?.indfechaentrega === "S"} />}
            label="Tiene fecha de entrega"
            labelPlacement="end"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}> {/* Grid Descripcion */}
        <Grid item xs={12}>
          <Typography align="left" variant="subtitle1" mb={1} color="grey" >
            Descripcion
          </Typography>
          <TextField
            required
            name="descot"
            id="descot"
            label="Descripcion"
            value={descot}
            fullWidth
            size="small"
            onChange={handleForm}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

      </Grid>
    </form>
  </BaseDialog>
}
