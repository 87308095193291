import React from 'react'

import {Route, Switch} from "react-router-dom"
import { AdrList, AdrDetail } from '../.'

export const Adr = ({match}) =>{
  return (
    // <div className="base-container">
    <>
      <Switch>
        <Route exact path={`${match.path}/`} component={AdrList}/>
        <Route exact path={`${match.path}/:numadr/:codcli/:codproy`} component={AdrDetail}/>
      </Switch>
      </>
    // </div>
  )
}
