import React, {useMemo, useEffect, useCallback} from 'react'
import {Autocomplete as Au} from '@mui/material'
import { ConditionalWrapper, SkeletonAC } from '../.'
import { getSelectedOption } from '../../../helpers'

export const Autocomplete = ({loading, value, valueKey, nullable=true, setValue, onChange, ...props}) => {

  const memoValue = useMemo(()=>{
    const currentValue = getSelectedOption(props.options, value, valueKey, props.multiple, nullable)
    return currentValue
  },[props.options, value, valueKey, props.multiple, nullable])

  useEffect(()=>{
    if(setValue){
      setValue(memoValue)
    }
  },[setValue, memoValue])

  const handleOnChange = useCallback((e, value) => {
    if(onChange){
      onChange(e,value)
    }if(setValue){
      setValue(value)
    }
  },[onChange, setValue])

  return <ConditionalWrapper condition={loading} wrapper={children => <SkeletonAC>{children}</SkeletonAC>}>
    <Au
      {...props}
      disableClearable={!nullable}
      value={memoValue}
      loading={loading}
      onChange={handleOnChange}
    />
  </ConditionalWrapper>
}
