import React, {useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Container,
  Chip
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete } from "../../layout"
import {handleQueryString, useHandleFieldValues, keepFormValues, useFilters, FIELD_TYPES} from "../../../helpers"


export const BaseFilters = ({filters:filtersFields, applyFilters:submitFilters}) => {
  const [filters, setFilters] = useState(useFilters(filtersFields))
  const history = useHistory()
  const { handleFields, parseValues, getOptionsLabel } = useHandleFieldValues(filters, setFilters)

  useEffect(()=>{
    setFilters(prevFields => keepFormValues(prevFields, filtersFields))
  },[filtersFields])

  const applyFilters = () => {
    const parsedValues = parseValues()
    submitFilters(parsedValues)
    history.push({search:handleQueryString(parsedValues)})
  }

  const inputAdorment = (filter,key) => {
    if(true)
    {
      return(  <InputAdornment position='start'>
               <IconButton
                 size="small"
                 onClick={ () => handleFields(key, "")}
               >
              <ClearIcon/>
              </IconButton>
            </InputAdornment>
            )
    }
  }

  const createFilter = (key, index) => {
    const filter = filters[key]
    return <div className="filter" key={`filter-${index}`}>
      {filter.type === FIELD_TYPES.AUTOCOMPLETE ?
      <Autocomplete
      id={filter.name}
      options={filter.options}
      loading={filter.loading}
      multiple={filter.multiple}
      value={filter.value}
      valueKey={filter.valueKey}
      nullable={filter.nullable}
      onChange={(_, value) => {
        if (filter.multiple) {
          handleFields(key, value.map(v => v[filter.valueKey]));
        } else {
          handleFields(key, value);
        }
      }}
      getOptionLabel={option => getOptionsLabel(key, option)}
      renderInput={params => (
        <TextField {...params} fullWidth label={filter.name} />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={getOptionsLabel(key, option)}
            {...getTagProps({ index })}
            onDelete={() =>
              handleFields(
                key,
                value.filter(v => v[filter.valueKey] !== option[filter.valueKey])
              )
            }
          />
        ))
      }
    />
      :
        <TextField
          key={filter.name}
          label={filter.name}
          id={filter.name}
          fullWidth

          type={filter.type}
          value={filter.value}
          autoComplete="off"
          InputLabelProps={{
            shrink: filter.type === "date" ? true : undefined
          }}
          InputProps={{
            endAdornment: inputAdorment(filter,key)
          }}
          onChange={e => handleFields(key, e.target.value)}
        />
      }
    </div>
  }

  return (
    <Container className="base-filters-container">
      <h4>Filtros</h4>
      <div className="filters-holder">
        {Object.keys(filters).map((key, index) => createFilter(key, index))}
        <div className="apply-holder">
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={applyFilters}
          >
            Aplicar Filtro
          </Button>
        </div>
      </div>
    </Container>
  )
}
