import React, {useState, useCallback, useEffect} from "react"
import { BaseDialog, ConditionalWrapper, SkeletonAC } from '../../layout'
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {useRequests, useCustomSnackbar, getErrorMsg, useBackdrop} from "../../../helpers"
import { Stepper, Step, StepLabel, TextField, Button, List, ListItem, ListItemText, ListSubheader, Accordion, AccordionSummary, Typography, AccordionDetails, ListItemSecondaryAction, Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiDialogActions from '@mui/material/DialogActions';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    display: "flex",
    flexDirection:"column"
  },
  listHolder: {
    flex:9,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  newHolder: {
    flex:1
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  summary: {
    backgroundColor: theme.palette.primary.light
  },
  accordion: {
    marginBottom: theme.spacing(0.5)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  verifyRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  verifyListSection: {
    backgroundColor: 'inherit',
  },
  verifyUl: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  stepperActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const InstallDialog = React.memo(props => {
  const classes = useStyles();
  const [currentClient, setCurrentClient] = useState(null)
  const [installables, setInstallables] = useState({})
  const [systems, setSystems] = useState([])
  const [selectedSystems, setSelectedSystems] = useState([])
  const [selectedModules, setSelectedModules] = useState([])
  const {get, post, controller} = useRequests()
  const {eSnack, iSnack, sSnack} = useCustomSnackbar()
  const [activeStep, setActiveStep] = useState(0);
  const {show, off} = useBackdrop()
  const steps = ['Seleccionar Sistemas a Instalar', 'Seleccionar Módulos a Instalar', 'Verificar Módulos Seleccionados'];
  const [loadingInst, setLoadingInst] = useState(false)
  const [loadingSys, setLoadingSys] = useState(false)

  const handleNext = async () => {
    switch(activeStep){
      case 0:
        if(!selectedSystems.length) return iSnack("Selecciona al menos 1 sistema")
        getInstallables()
        break
      case 1:
        if(!selectedModules.length) return iSnack("Selecciona al menos 1 módulo")
        break
      case 2:
        const installed = await createInstallations()
        if(!installed) return
        break
      default:
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    switch(activeStep){
      case 1:
        setSelectedModules([])
        break
      default:
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setInstallables({})
    setSelectedSystems([])
    setSelectedModules([])
    setActiveStep(0);
  };

  const handleSelectedModules = (selected)=> () =>{
    const currentIndex = selectedModules.indexOf(selected);
    const newChecked = [...selectedModules];
    if (currentIndex === -1) {
      newChecked.push(selected);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedModules(newChecked)
  }

  const generateListItems = items => {
   return <List
     className={classes.root}
     component="nav"
     aria-labelledby="nested-list-subheader"
   >
     {items.map((system_module, index)=>{
       return <ListItem key={`system-module-${index}`} onClick={handleSelectedModules(system_module)} button>
         <ListItemText primary={`${system_module.codmodulo} - ${system_module.nommodulo}`} />
         <ListItemSecondaryAction>
           <Checkbox
             onClick={handleSelectedModules(system_module)}
             checked={selectedModules.includes(system_module)}
           />
         </ListItemSecondaryAction>
       </ListItem>
     })}
   </List>
  }

  const generateList = () => {
   const keys = Object.keys(installables)
   return keys.map((system, index)=>{
     let h_system = installables[system][0]?.h_system
     return(
       <Accordion key={`system-${index}`} className={classes.accordion}>
         <AccordionSummary
           expandIcon={<ExpandMoreIcon/>}
           className={classes.summary}
         >
           <Typography className={classes.heading}>{`${system} - ${h_system}`}</Typography>
         </AccordionSummary>
         <AccordionDetails>
           {generateListItems(installables[system])}
         </AccordionDetails>
       </Accordion>
     )
   })
  }

  const generateVerifyList = () => {
   const systemKeys = [...new Set(selectedModules.map(item => `${item.sistema} - ${item.h_system}`))]
    return(
      <List className={classes.verifyRoot} subheader={<li />}>
        {systemKeys.map((system, index) => (
          <li key={`system-${index}`} className={classes.verifyListSection}>
            <ul className={classes.verifyUl}>
              <ListSubheader>{system}</ListSubheader>
              {selectedModules.map((item, index2) => {
                if(`${item.sistema} - ${item.h_system}` === system){
                  return <ListItem key={`module-${index2}`}>
                    <ListItemText primary={`${item.codmodulo} - ${item.nommodulo}`} />
                  </ListItem>
                }
                return null
              })}
            </ul>
          </li>
        ))}
      </List>
    )
  }

  const getStepContent = step => {
   switch (step) {
     case 0:
       return (
         <ConditionalWrapper condition={loadingSys} wrapper={SkeletonAC}>
           <Autocomplete
             options={systems}
             value={selectedSystems}
             onChange={(_,value) => setSelectedSystems(value)}
             getOptionLabel={(option) => `${option.codsistema}-${option.nomsistema}`}
             multiple
             renderInput={(params) => <TextField {...params} required fullWidth label="Sistemas" />}
             disableCloseOnSelect
             />
         </ConditionalWrapper>
       );
     case 1:
       return <ConditionalWrapper condition={loadingInst} wrapper={SkeletonAC}>{generateList()}</ConditionalWrapper>
     case 2:
       return generateVerifyList()
     case steps.length:
       return (
         <div align="center">
           Instalacion Completada
         </div>
       )
     default:
       return 'Unknown step';
   }
  }

  const getStepper = () =>{
   return <Stepper activeStep={activeStep}>
     {steps.map((label, index) => {
       const stepProps = {};
       const labelProps = {};
       return (
         <Step key={label} {...stepProps}>
           <StepLabel {...labelProps}>{label}</StepLabel>
         </Step>
       );
     })}
   </Stepper>
  }

  const getInstallables = useCallback( async () =>{
    if(currentClient){
      // iSnack("Cargando Instalables")
      try{
        setLoadingInst(true)
        const response = await get(`/administration/installables`,{client:currentClient, systems:selectedSystems.map(system=>system.codsistema)})
        if(response?.ok) {
          const data = await response.json()
          setInstallables(data)
        }else{
          eSnack(await getErrorMsg(response), getInstallables)
        }
      }catch(e){
        console.log(e);
        eSnack(`Error inesperado obteniendo instalables para ${currentClient}`)
      }finally{setLoadingInst(false)}
    }
  },[get, eSnack, currentClient, selectedSystems])

  const getSystems = useCallback(async ()=>{
    try{
      // iSnack("Obteniendo Sistemas")
      setLoadingSys(true)
      const response = await get('/administration/systems/all')
      if(response?.ok){
        const systems = await response.json()
        setSystems(systems)
      }else{
        eSnack(`Sistemas: ${await getErrorMsg(response)}`, getSystems)
        console.log("Error getting systems");
      }
    }catch(e){
      eSnack("Error inesperado obteniendo sistemas", getSystems)
      console.log("Error getting systems", e);
    }finally{setLoadingSys(false)}
  },[ eSnack, get])

  const createInstallations = async () =>{
    show("Instalando Módulos")
    try{
      const installations = selectedModules.map(system_module => {
        return {
          codmodulo: system_module.codmodulo,
          codsistema: system_module.sistema,
          codcli:currentClient
        }
      })
      var response = await post("/administration/installations", {installation:installations})
      if(response?.ok){
        sSnack(`Módulos instalados`)
        return true
      }else{
        eSnack(await getErrorMsg(response))
        console.log("Error installing modules");
      }
    }catch(e){
      eSnack("Error instalando módulos")
      console.error(e);
    }finally{off()}
    return false
  }

  const handleClose = () => {
    handleReset()
    props.handleClose()
  }

  useEffect(()=>{
    if(props.dialogOpen) getSystems()
  },[props.dialogOpen, getSystems])

  useEffect(()=>{
    setCurrentClient(props.currentClient)
  },[props.currentClient])

  useEffect(() => () => controller.abort(), [controller])

  return (
    <BaseDialog
      dialogOpen={props.dialogOpen}
      handleClose={handleClose}
      title={`Instalar en ${currentClient}`}
      hideFooter
      width="md"
      alternativeFooter={
        <DialogActions>
          {getStepper()}
          {activeStep !== steps.length &&
            <>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Atras
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
                className={classes.button}
                >
                {activeStep === steps.length - 1 ? 'Instalar' : 'Siguiente'}
              </Button>
            </>
          }
        </DialogActions>
      }>
      <div className={classes.container}>
        <div className={classes.stepContent}>
          {getStepContent(activeStep)}
        </div>
      </div>
    </BaseDialog>
  )
})
