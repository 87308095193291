import {rubyBoolCheck} from '.'
export const FIELD_TYPES = Object.freeze({
  AUTOCOMPLETE: "autocomplete",
  TEXT: "text",
  DATE: "date",
  NUMBER: "number",
  HIDDEN: "hidden",
  DECIMAL: "decimal",
  TEXTAREA: "textArea"
})

export const defaultIsOptionEqualToValue = (option, selected, valueKey="value") => {
  var optionValue, selectedValue
  switch(true){
    case (selected instanceof Object && option instanceof Object):
      optionValue = option[valueKey]
      selectedValue = selected[valueKey]
      if(optionValue){
        return optionValue === selectedValue
      }else{
        return false
      }
    case (option instanceof Object && !(selected instanceof Object)):
      optionValue = option[valueKey]
      if(optionValue){
        return optionValue === selected
      }else{
        return false
      }
    default:
      return option === selected
  }
}

export const getOptionValue = (option, valueKey="value") => {
  if(option instanceof Object && !(option instanceof Array)){
    return option[valueKey]
  }else{
    return option
  }
}

export const getSelectedOption = (options, selected, valueKey="value", multiple=false, nullable=false) => {
  var selectedOption
  const selectedValue = getOptionValue(selected, valueKey)

  if (multiple){
    const parsedOptionValues = selectedValue.map( item => {
      if(item instanceof Object){
        return item[valueKey]
      }else{
        return item
      }
    })
    selectedOption = options.filter(option => parsedOptionValues.includes(option[valueKey]))
  }else{
    selectedOption = options.find(option => option[valueKey] === selectedValue)
  }
  return selectedOption || (nullable ? null : options[0] || null)
}

export const keepFormValues = (current, update) => {
  try{

    const isValidOption = (uOptions, cValue) =>{
      if( rubyBoolCheck(cValue) || cValue instanceof Array){
        return true//!options.some( option => autocompleteEQ(key, option, cValue))
      }else{
        return uOptions?.some( option => JSON.stringify(option) === JSON.stringify(cValue))
      }
    }

    const valueToKeep = (key) => {
      const updatedField = update[key]
      const currentField = current[key]
      const cValue = currentField?.value
      const uValue = updatedField?.value
      if(currentField?.type === FIELD_TYPES.AUTOCOMPLETE){
        const uOptions = updatedField?.options
        if(currentField.touched && !rubyBoolCheck(cValue)){
          return null
        }else{
          if(isValidOption(uOptions, cValue)){
            return cValue
          }else{
            return uValue
          }
        }
      }else{
        if(!currentField.touched){
          return uValue
        } else {
          return cValue
        }
      }
    }

    const newFields = {}
    Object.keys(update).forEach( key => {
      const newValue = valueToKeep(key)
      newFields[key] = {...update[key], value: newValue, touched:current[key].touched}
    })
    return newFields
  }
  catch(e){
    console.log("Error in keepFormValues function", e);
    return update
  }
}

export const useHandleFieldValues = (fields, setFields) => {
  const handleFields = (key, value) => {
    const field = fields[key] || {}
    if(field.onChange) field.onChange(value)
    setFields({
      ...fields,
      [key]:{
        ...fields[key],
        value: value,
        touched: true
      }
    })
  }

  const parseValues = () => {
    const parsedValues = {}
    const keys = Object.keys(fields)
    keys.forEach(key => {
      parsedValues[key] = getValue(key)
    })
    return parsedValues
  }

  const getValue = key => {
    const field = fields[key] || {}
    var value = field.value
    if(field.type === FIELD_TYPES.AUTOCOMPLETE){
      value = getOptionValue(value, field.valueKey)
    }
    return value
  }

  const getOptionsValue = (key) => {
    const field = fields[key] || {}
    return getSelectedOption(field.options, field.value , field.valueKey, field.multiple, field.nullable)
  }

  const getOptionsLabel = (key, option) => {
    const field = fields[key] || {}
    if(field.getLabel){
      return field?.getLabel(option)
    }else if (option.name) {
      return option?.name
    }else{
      return "Use getLabel function on your fields or name property on your options"
    }
  }

  const autocompleteEQ = (key, option, selected) => {
    const field = fields[key] || {}
    return defaultIsOptionEqualToValue(option, selected, field.valueKey)
  }

  return  {handleFields, parseValues, getOptionsLabel, autocompleteEQ, getOptionsValue}
}
