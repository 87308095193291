import React, { useState, useEffect, useRef, useCallback } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useRequests, getErrorMsg, useCustomSnackbar, EMAIL_REGEXP, useBackdrop } from '../../helpers'
import Paper from '@mui/material/Paper';
import { Box, CssBaseline, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import Background from '../../images/1.jpg'


export const RecoverPassword = () => {
  const [email, setEmail] = useState("")
  const emailError = useRef("")
  const [disableSubmit, setDisableSubmit] = useState(false)
  const { eSnack, sSnack } = useCustomSnackbar()
  const { form, controller } = useRequests()
  const { show, off } = useBackdrop()

  const handleEmail = useCallback(e => {
    const value = e.target.value
    switch (true) {
      case !EMAIL_REGEXP.test(value):
        emailError.current = "Correo inválido"
        break;
      default:
        emailError.current = null
    }
    setEmail(value)
  }, [])

  useEffect(() => {
    if (disableSubmit) {
      const disableTimeout = setTimeout(() => setDisableSubmit(false), 10000)
      return () => clearTimeout(disableTimeout)
    }
  }, [disableSubmit])

  const handleSubmit = useCallback(async e => {
    e.preventDefault()
    if (!!emailError.current) return eSnack(emailError.current)
    const data = new FormData(e.target)
    try {
      show("Enviando Correo")
      const request = await form('/forgot_password', data)
      if (request?.ok) {
        sSnack("Link enviado a su correo")
        setDisableSubmit(true)
      } else {
        eSnack(await getErrorMsg(request), handleSubmit)
        console.log("Error Enviando Correo");
      }
    } catch (exception) {
      eSnack("Error inesperado Enviando correo", handleSubmit)
      console.log(exception);
    } finally { off() }
  }, [eSnack, show, off, form, sSnack])

  useEffect(() => () => controller.abort(), [controller])

  return (
    <ThemeProvider>
      <Grid container component="main" sx={{ height: '91vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(' + Background + ')',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Recuperar Contraseña            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                error={!!emailError.current}
                label="Email"
                name="email"
                helperText={emailError.current}
                fullWidth

                value={email.toLowerCase()}
                onChange={handleEmail}
                required
              />
              <Typography variant="caption">
                Introduzca su correo y se le enviara un link para resetear su password si su cuenta existe
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={(disableSubmit || !!emailError.current || email.length < 1)}

              >
                Enviar
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
