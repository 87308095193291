import React, {useState, useEffect, useCallback} from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  DialogContentText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { BaseDialog } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, useBackdrop } from "../../../helpers"

export const ChangePasswordDialog = React.memo(({ open, data, reload, handleClose})=> {
  const { show, off } = useBackdrop ()
  const { post, controller} = useRequests()
  const { sSnack, eSnack } = useCustomSnackbar()
  const ERRORS = {
    PASSWORD_LENGTH: 'Password debe contener al menos 6 caracteres',
    PASSWORD_CONFIRMATION: 'Password y confirmacion deben ser iguales',
    WITHOUT_ERROR: false
  }
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [passwordErrors, setPasswordErrors] = useState({
    main:{
      length: ERRORS.PASSWORD_LENGTH,
    },
    confirmation:{
      matching: ERRORS.PASSWORD_CONFIRMATION
    }
  })
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleFields = e =>{
    const value = e.target.value
    const id = e.target.id
    switch(id){
      case "new-password":
        setPassword(value)
        break
      case "password_confirmation":
        setPasswordConfirmation(value)
        break
      default:
        return null
    }
    return null
  }

  useEffect(()=>{
    setPasswordErrors(()=>{
      return {
        main:{
          length: password.length >= 6 ? ERRORS.WITHOUT_ERROR : ERRORS.PASSWORD_LENGTH
        },
        confirmation:{
          matching: passwordConfirmation === password ? ERRORS.WITHOUT_ERROR : ERRORS.PASSWORD_CONFIRMATION
        }
      }
    })
  },[password, passwordConfirmation, ERRORS.WITHOUT_ERROR, ERRORS.PASSWORD_LENGTH, ERRORS.PASSWORD_CONFIRMATION])

  useEffect(()=>{
    setPassword("")
    setPasswordConfirmation("")
  },[open])

  const mainErrors = Object.values(passwordErrors.main).filter(error => error)
  const confirmationErrors = Object.values(passwordErrors.confirmation).filter(error => error)
  const canChange = ![...mainErrors, ...confirmationErrors].length

  const changePassword = useCallback(async () => {
    show("Cambiando Password")
    const user_id = data.idpers
    try{
      if(!canChange) return
      var response = await post("/administration/change_user_password",{
        id:user_id,
        new_password:password,
        new_password_confirmation:passwordConfirmation
      })
      if(response?.ok){
        reload()
        handleClose()
        sSnack(`Password cambiado para usuario ${user_id}`)
      }else{
        eSnack(await getErrorMsg(response))
        console.log("Error changing user password");
      }
    }catch(e){
      eSnack("Error cambiado password del usuario")
      console.error("Error Changing User Password",e);
    }finally{off()}
  },[show, post, sSnack, eSnack, off, reload, canChange, data.idpers, password, passwordConfirmation, handleClose])

  useEffect(() => () => controller.abort(), [controller])

  return (
    <BaseDialog
      dialogOpen={open}
      handleClose={handleClose}
      title={"Cambiar Password de Usuario"}
      confirmText={"Cambiar Password"}
      cancelText={"Cancelar"}
      confirm={changePassword}
      width="sm"
    >
      <DialogContentText>
        Usuario: {data.email || ""}
      </DialogContentText>
      <TextField
        error={!!mainErrors.length}
        autoFocus
        margin="dense"
        id="new-password"
        label="Password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handleFields}
        autoComplete="new-password"
        helperText={mainErrors[0]}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        error={!!confirmationErrors.length}
        margin="dense"
        id="password_confirmation"
        label="Confirmación"
        type="password"
        value={passwordConfirmation}
        onChange={handleFields}
        autoComplete="new-password"
        helperText={confirmationErrors[0]}
        fullWidth
      />
      </BaseDialog>
  );
})
