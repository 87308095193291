import makeStyles from '@mui/styles/makeStyles';

export const useBaseStyles = props => {
  const styles = makeStyles((theme) => ({
    formField:{
      marginBottom: theme.spacing(1),
    },
    productName:{
      flex:1
    },
    hidden:{
      display:"none"
    }
  }))()
  return styles
}
