import React from 'react'
import { OrdersList, OtDetail } from '..'
import { Route, Switch } from "react-router-dom"


export const Orders = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/`} component={OrdersList} />
        <Route exact path={`${match.path}/:idot`} component={OtDetail} />
      </Switch>
    </>
  )
}
