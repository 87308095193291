import React, {useState, useEffect, useCallback} from 'react';
import {
  TextField,
  DialogContentText,
} from "@mui/material";
import { BaseDialog, Autocomplete } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, useBackdrop } from "../../../helpers"


export const ChangeRolesDialog = React.memo(({ reload, open, data, handleClose })=> {
  const [roles, setRoles] = useState([])
  const [loadingRoles, setLoadingRoles] = useState(false)
  const [userRole, setUserRole] = useState(data.tipo)
  const {show, off} = useBackdrop ()
  const {get, post, controller} = useRequests()
  const { sSnack, iSnack, eSnack } = useCustomSnackbar()


  useEffect(()=>{
    setUserRole(roles?.find( r => r.name?.toLowerCase() === data?.tipo?.toLowerCase()) || null)
  },[data, roles])

  const getRoles = useCallback(async () =>{
    try{
      setLoadingRoles(true)
      var response = await get("/administration/user_roles")
      if(response?.ok){
        const roles = await response.json()
        setRoles(roles)
      }else{
        eSnack(await getErrorMsg(response), getRoles)
        console.error("Error Retrieving Roles");
      }
    }catch(e){
      eSnack("Error inesperado cargando roles", getRoles)
      console.error("Retrieving Roles:",e);
    }finally{ setLoadingRoles(false) }
  },[get, eSnack])

  const changeRoles = useCallback(async () => {
    show("Cambiando Rol")
    try{
      const user_id = data?.idpers
      const newRole = userRole?.value
      if(!newRole) return iSnack("Por favor Selecciona un Rol")
      var response = await post("/administration/change_user_role",{
        id: user_id,
        tipo: newRole
      })
      if(response?.ok){
        handleClose()
        reload()
        sSnack(`Rol cambiado para usuario ${user_id}`)
      }else{
        eSnack(await getErrorMsg(response))
        console.error("Error Changing User Roles:");
      }
    }catch(e){
      eSnack("Error cambiado rol de usuario")
      console.error("Changing User Roles:",e);
    }finally{off()}
  },[show, post, sSnack, eSnack, off, reload, handleClose, iSnack, data.idpers, userRole])

  useEffect(()=>{
    if(open && !roles.length){
      getRoles()
    }
  },[getRoles, open, roles.length])

  useEffect(() => () => controller.abort(), [controller])

  return (
    <BaseDialog
      dialogOpen={open}
      handleClose={handleClose}
      title={"Cambiar Roles"}
      confirmText={"Cambiar"}
      cancelText={"Cancelar"}
      confirm={changeRoles}
      width="sm"
    >
      <DialogContentText>
        Cambiar rol para el usuario: {data.email}
      </DialogContentText>
      <br/>
      <Autocomplete
        options={roles}
        nullable={false}
        value={userRole}
        setValue={setUserRole}
        loading={loadingRoles}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} required fullWidth label="Seleccionar Rol"/>}
      />
    </BaseDialog>
  );
})
