import React, { useContext, useState, createContext, useCallback } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from '@mui/styles/makeStyles';

const BackdropContext = createContext({
  //initial data of context
  openBackdrop: false,
  setOpenBackdrop: () => {},
  backdropMsg: "",
  setBackdropMsg: () => {},
  showLoading: () => {},
  hideLoading: () => {},
});

export const useBackdrop = () => useContext(BackdropContext); //exporting The hook

const useStyles = makeStyles((theme) => ({
  //styles used by the backdrop
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
}));

export const BackdropGlobal = ({ children }) => {
  //backdrop component
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMsg, setBackdropMsg] = useState("");
  const classes = useStyles();

  const showLoading = useCallback((msg) => {
    setBackdropMsg(msg);
    // console.log("showing backdrop");
    setOpenBackdrop(true);
  }, []);

  const hideLoading = useCallback(() => {
    // console.log("hiding backdrop");
    setBackdropMsg("");
    setOpenBackdrop(false);
  }, []);

  return (
    <BackdropContext.Provider
      value={{
        //what will return is declared
        openBackdrop,
        setOpenBackdrop,
        backdropMsg,
        setBackdropMsg,
        show:showLoading,
        off:hideLoading,
      }}
    >
      {children}
      <Backdrop open={openBackdrop} className={classes.backdrop}>
        <div className="backdrop-content">
          <CircularProgress color="inherit" />
          <h3>{backdropMsg}</h3>
        </div>
      </Backdrop>
    </BackdropContext.Provider>
  );
};
