import React, {useState, useCallback, useEffect} from "react"
import {
  FormControl,
  TextField
} from '@mui/material';
import {useRequests, useCustomSnackbar, getErrorMsg, useBackdrop} from "../../../helpers"
import { BaseDialog, Autocomplete }from "../../layout"

export const ActivityUserAssigned =React.memo(({ handleClose, dialogOpen, currentIdActivity, currentRow, changeIndicador,reload}) => {
  const { controller,get,post} = useRequests()
  const {eSnack, iSnack, sSnack} = useCustomSnackbar()
  const {show, off} = useBackdrop()
  const [assignables, setAssignables] = useState([])
  const [assigned, setAssigned] = useState(null)
  const [descripcion, setDesccripcion] = useState("")
  const [loadingASG, setLoadingASG] = useState(false)

  const handleFields = e =>{
    const value = e.target.value
    const id = e.target.id

    switch(id){
      case "descripcion":
        setDesccripcion(value)
        break
      default:
        return null
    }
    return null
  }

  const getAssignables = useCallback( async () =>{
    try{
      // iSnack("Cargando Usuarios Asignables")
      setLoadingASG(true)
      var response = await get("/activities/assignables_activity")
      if(response?.ok){
        const usuarios = await response.json()
        setAssignables(usuarios)
      }else{
        eSnack(await getErrorMsg(response), getAssignables)
        console.error("Error Retrieving Assignables");
      }
    }catch(e){
      eSnack("Error inesperado cargando usuarios assignables", getAssignables)
      console.error("Retrieving Assignables:",e);
    }finally{setLoadingASG(false)}
  },[get, eSnack])


  const activityUserAssignation = useCallback(async () => {
    if(!assigned) return iSnack("Debe seleccionar al menos un usuario")
    if(!descripcion) return iSnack("Debe colocar una descripción")
    show('Asignando Usuario')
    try {
      const request  = await post('/activities/assign_user_activity',{
        assignation_user:{
          idactividad:currentIdActivity,
          descasig:descripcion,
          codusu:assigned?.codusu,
        },
        change_indicador:{
          idactividad:currentRow.idactividad,
          fecasig:currentRow.fecasig,
          codusu:currentRow.codusu,
        }
      })
      if(request?.ok){
        sSnack("Usuario Asignado")
        reload()
        handleClose()
      }else{
        eSnack(await getErrorMsg(request))
        reload()
      }
    } catch (e) {
      eSnack("Error inesperado asignando usuario")
      console.log("Error", e);
    } finally { off() }
  }, [eSnack, show, off,reload, sSnack, iSnack, currentIdActivity, descripcion, assigned, currentRow, handleClose, post])

  useEffect(()=>{
    if(dialogOpen && !assignables.length) getAssignables()
  },[getAssignables, assignables.length, dialogOpen])


  useEffect(() => () => controller.abort(), [controller])

  return (
    <BaseDialog
      dialogOpen={dialogOpen}
      handleClose={handleClose}
      title={"Nueva Asignación"}
      confirmText={"Asignar"}
      cancelText={"Cerrar"}
      confirm={activityUserAssignation}
    >
      <FormControl fullWidth>
          <Autocomplete
            id ="usuarios"
            name="usuarios"
            loading={loadingASG}
            options={assignables}
            nullable={false}
            setValue={setAssigned}
            value={assigned}
            valueKey={"codusu"}
            getOptionLabel={(option) => `${option.idpers}-${option.nombpers}-${option.codusu}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Usuarios" />}
            />
        <br/>
        <TextField
          id ="descripcion"
          name="descripcion"
          label="Descripción"
          onChange={handleFields}
        />
      </FormControl>
    </BaseDialog>
  )
})
