import React from "react"
import Skeleton from '@mui/material/Skeleton';
import { SkeletonList } from "../."
import '../../../stylesheets/activity.scss'


/*
Parameters:
  children[Component] = children used to infer height
*/
export const SkeletonDetailTitle = ({children}) => {
  return <Skeleton width="100%">{children}</Skeleton>
}

/*
Parameters:
  children[Component] = children used to infer height
*/
export const SkeletonDetailContent = ({children}) => {
  return <SkeletonList number={6}></SkeletonList>
}

/*
Parameters:
  children[Component] = children used to infer height
*/
export const SkeletonDetailActivity = () => {
  return <div className="activity-skeleton-detail-wrapper">
    {[...Array(4)].map((_,index) => <Skeleton className="activity-skeleton-detail-row" key={index} ></Skeleton>)}
  </div>
}
