import React, {useState, useEffect, useCallback} from 'react'
import {Button, Paper, FormControlLabel, Switch, FormLabel, FormGroup, FormControl} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useRequests, getErrorMsg, useCustomSnackbar, useCurrentUser, mock_notification_settings, useBackdrop} from '../../../helpers'
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '90%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  textField:{
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const NotificationSettings = props => {
  const {eSnack, sSnack} = useCustomSnackbar()
  const {post, controller} = useRequests()
  const {show, off} = useBackdrop()
  const currentUser = useCurrentUser()
  const [settings, setSettings] = useState(currentUser.mail_settings || mock_notification_settings)
  const classes = useStyles();
  const { t } = useTranslation();


  useEffect(() => () => controller.abort(), [controller])

  const handleSubmit = useCallback(async e => {
    e.preventDefault()
    try{
      show("Cambiando Configuraciones")
      const request = await post('/users/change_settings', {settings:settings})
      if(request?.ok){
        sSnack("Configuraciones Cambiadas.")
      }else{
        eSnack(await getErrorMsg(request))
      }
    }catch(exception){
      eSnack("Error inesperado cambiando configuraciones")
      console.log("Error changing settings", exception);
    }finally{
      off()
    }
  },[eSnack, post, off, sSnack, show, settings])

  const handleSettings = (event, target) =>{
    setSettings(currentSettings => { return {...currentSettings, [target]:event.target.checked}})
  }

  return (
    <Paper className={classes.paper} elevation={5}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Por Correo</FormLabel>
          <FormGroup>
            {Object.keys(settings).map((key,index) => {
              const setting = settings[key]
              return(
                <FormControlLabel
                  control={<Switch
                    checked={!!setting}
                    value={setting}
                    onChange={ event =>handleSettings(event, key)}
                    name={`settings[${key}]`}
                  />}
                  label={t(key)}
                  labelPlacement="start"
                  key={`mail_setting_${index}`}
                />
              )
            })}
          </FormGroup>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          size="small"
        >
          Guardar
        </Button>
      </form>
    </Paper>
  );
}
