import React, { useEffect, useState, useCallback, useRef } from "react"

import {
  Container,
  Paper,
  Tooltip,

} from '@mui/material';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { useRequests, useCustomSnackbar, getErrorMsg, useConfirmationDialog, useBackdrop, useDialogTools } from "../../../helpers"
//import { CollapsibleRow} from '../../layout'
import { BaseTable as TicketsTable, BaseTablePagination } from '../../layout'
import { Link } from "react-router-dom"

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

//import {TicketsEstimacionTablas} from "./tickets_estimacion_tablas";
import { EstimacionTickets } from "./estimacion_tickets";

import '../../../stylesheets/adr.scss'

export const AdrTickets = React.memo(({ adr, reload, openAssign }) => {
  //const classes = useStyles()
  const { controller, get, patch } = useRequests()
  const [ticketsAdrTodos, setTicketsAdrTodos] = useState([])
  const [valueOptions, setValueOptions] = useState('asociados')
  const { eSnack, sSnack } = useCustomSnackbar()
  const { show, off } = useBackdrop()
  const { getConfirmation } = useConfirmationDialog()

  const { dialogOpen: createEstimacionOpen, openD: openEST, closeD: closeEST } = useDialogTools()
  const selectedRowIdInc = useRef(null)
  const [loadingTable, setLoadingTable] = useState(false)


  const openEstimacionTiempo = useCallback(row => {

    selectedRowIdInc.current = row.idinc

    openEST()
  }, [openEST])

  const closeCreateEstimacion = useCallback(() => {
    closeEST()
  }, [closeEST])

  const getTicketsAdrTodos = useCallback(async () => {
    try {
      setLoadingTable(true)
      const response = await get(`/administration/adr/tickets_adr_todos`, {
        codcli: adr.codcli,
      })
      if (response?.ok) {
        const data = await response.json()
        setTicketsAdrTodos(data)
      } else {
        eSnack(await getErrorMsg(response), getTicketsAdrTodos)
        /* console.log("error getting statuses"); */
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo estados", getTicketsAdrTodos)
      /*  console.log("Error getting Statuses", e); */
    } finally { setLoadingTable(false) }
  }, [get, eSnack, adr.codcli])


  useEffect(() => {
    getTicketsAdrTodos()
  }, [getTicketsAdrTodos])


  const onChangeValue = (e) => {
    setValueOptions(e.target.value);
  }

  const options = () => {
    if (JSON.stringify(adr) !== '{}') {
      if (adr.can.associate_tickets) {
        return (
          <div style={{ float: "left", marginLeft: 25, marginTop: 5 }} >
            <FormControl component="fieldset" >
              <RadioGroup defaultValue="asociados" row aria-label="opciones" name="row-radio-buttons-group" onChange={onChangeValue}>
                <FormControlLabel value="todos" control={<Radio />} label="Todos los Tickets" />
                <FormControlLabel value="asociados" control={<Radio />} label="Solo Asociados" />
              </RadioGroup>
            </FormControl>
          </div>
        )
      }
    }

  }


  const valorRdb = () => {
    if (valueOptions === 'todos') {
      return (
        ticketsAdrTodos
      )
    }
    else if (valueOptions === 'asociados') {

      return (
        adr.tickets
      )
    }
  }
  const tickets = valorRdb(); // Obtener el array de tickets
  const numTickets = tickets ? tickets.length : 0; // Obtener el tamaño del array


  const asociarTicketAdr = useCallback(async idinc => {
    show("Asociando tickets")
    try {

      const response = await patch(`/administration/adr/asociar_ticket_adr`, {
        id: {
          numadr: adr.numadr,
          codcli: adr.codcli,
          codproy: adr.codproy
        },
        idinc: idinc,
      }
      )
      if (response?.ok) {
        sSnack("ticket asociado")
        reload()
      } else {
        eSnack(await getErrorMsg(response))
        console.log("error asociando ticket ");
      }
    } catch (e) {

      eSnack("Error inesperado asociando ticket")
      console.log("Error asociando ticket", e);
    } finally {
      off()
    }
  }, [eSnack, off, patch, sSnack, show, reload, adr.codcli, adr.numadr, adr.codproy])




  const desasociarTicketAdr = useCallback(async idinc => {
    show("Desasociando tickets")
    try {

      const response = await patch(`/administration/adr/desasociar_ticket_adr`, {
        id: {
          numadr: adr.numadr,
          codcli: adr.codcli,
          codproy: adr.codproy
        },
        idinc: idinc,


      })
      if (response?.ok) {
        sSnack("ticket desasociado")
        reload()
      } else {
        eSnack(await getErrorMsg(response))
        console.log("error desasociando ticket ");
      }
    } catch (e) {

      eSnack("Error inesperado desasociando ticket")
      console.log("Error desasociando ticket", e);
    } finally {
      off()
    }
  }, [eSnack, off, patch, sSnack, show, reload, adr.codcli, adr.numadr, adr.codproy])



  const getAssignedIcon = (row) => {
    if (JSON.stringify(adr) !== '{}') {
      if (adr.can.associate_tickets) {
        if (valueOptions === 'asociados') {
          return (
            <Tooltip title="Desasociar ticket adr">
              <CheckCircleOutlineRoundedIcon color="primary" onClick={async () => (await getConfirmation({ title: "Asociar Ticket al ADR", message: `Seguro desea desasociar el ticket ${row.idinc} del ADR ${adr.numadr} ?` })) ? desasociarTicketAdr(row.idinc) : null} />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title="Asociar Ticket al adr">
              <RadioButtonUncheckedRoundedIcon color="error" onClick={async () => (await getConfirmation({ title: "Asociar Ticket al ADR", message: `Seguro desea asociar el ticket ${row.idinc} al ADR ${adr.numadr} ?` })) ? asociarTicketAdr(row.idinc) : null} />

            </Tooltip>
          )
        }
      }
    }
  }

  const tiempoEstimado = (row) => {
    if (valueOptions === 'asociados') {
      return (
        <Tooltip title="Registrar tiempo estimado">
          <AccessTimeIcon color="primary" onClick={() => openEstimacionTiempo(row)} />
        </Tooltip>
      )
    }
  }
  const statusIncidente = (row) => {
    var content
    if (row.tipinc === 'REQ') {

      switch (row.stsinc) {
        case "PEN": content = "Pendiente"
          break;
        case "CER": content = "Cerrada"
          break;
        case "PRO": content = "Proceso"
          break;
        case "REV": content = "Revision"
          break;
        case "PPI": content = "Pendiente por Información"
          break;
        case "PAP": content = "Pendiente por aprobación"
          break;
        case "RET": content = "Retirado"
          break;
        case "TRF": content = "Transferido"
          break;
        case "ASG": content = "Asignada"
          break;
        case "RES": content = "Respondido"
          break;
        case "NOT": content = "Notificado"
          break;
        case "PAN": content = "Por Analizar"
          break;
        case "APR": content = "ADR aprobado"
          break;
        case "MTO": content = "Por iniciar desarrollo (En mantenimiento)"
          break;
        default:
          content = row.stsinc
      }
      return { content: content }
    }
    if (row.tipinc === 'FUN' || row.tipinc === 'SOP') {

      switch (row.stsinc) {
        case "PEN": content = "Pendiente"
          break;
        case "ASG": content = "Asignada"
          break;
        case "ATN": content = "Atendida"
          break;
        case "RES": content = "Respondido"
          break;
        case "NOT": content = "Notificado"
          break;
        case "CER": content = "Cerrada"
          break;
        default:
          content = row.stsinc
      }
      return { content: content }
    }
    if (row.tipinc === 'MAN') {

      switch (row.stsinc) {
        case "PEN": content = "Pendiente"
          break;
        case "ASG": content = "Asignada"
          break;
        case "ATN": content = "Atendida"
          break;
        case "RES": content = "Respondido"
          break;
        case "NOT": content = "Notificado"
          break;
        case "CER": content = "Cerrada"
          break;
        case "ENV": content = "Enviada"
          break;
        default:
          content = row.stsinc
      }
      return { content: content }
    }
    if (row.tipinc === 'SUG') {
      switch (row.stsinc) {
        case "PAP": content = "Pendiente por aprobación interna"
          break;
        case "REV": content = "En revisión"
          break;
        case "ATN": content = "Atendido"
          break;
        case "PEN": content = "Respondido"
          break;
        default:
          content = row.stsinc
      }
      return { content: content }
    }
  }


  useEffect(() => () => controller.abort(), [controller])

  const mostrar = () => {
    if (JSON.stringify(adr) !== '{}') {
      return (
        <>

          <Container
            sx={{
              display: 'inline-flex',
              padding: '1%',
              overflowX: 'auto',
              marginRight: 'auto',
              marginLeft: 'auto',
              maxWidth: '1500px',
              maxHeight: "62vh", /* Agrega esta línea */
              flex: 1,
              '& > .base-filters-container': {
                flex: 2,
                display: 'flex',
                flexDirection: 'column',
                marginRight: '0.5vw',
                paddingTop: '15px',
                paddingBottom: '15px',
                minWidth: '15vw',
                '& > .filters-holder': {
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  '& > .filter': {
                    marginBottom: '1vh',
                  },
                  '& > .apply-holder': {},
                },
              },
              '& > .base-table-container': {
                flex: 8,
                '& > .table-pagination': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '0.5vh',
                },
              },
              '& > .new-base-container': {
                '& .form-field': {
                  marginBottom: '1vh',
                },
              },
            }}>

            <TicketsTable

              loading={loadingTable}
              rows={valorRdb()}
              // rowAction={row => openAdr(row)}
              headers={["Tickets", "Status", "Prioridad", "Asociar", "Estimación", "Issues Jira"]}

              collapsible={{
                visible: row => [
                  { content: <Link to={`/tickets/${row.idinc}`} target="_blank">{row.idinc}</Link>, handleCollapse: true, align: "left" },
                  statusIncidente(row),
                  {
                    content: (() => {
                      switch (row.prioridad) {
                        case 1:
                          return "Alta";
                        case 2:
                          return "Normal";
                        case 3:
                          return "Baja";
                        default:
                          return "";
                      }
                    })()
                  },
                  {
                    content: getAssignedIcon(row),
                    action: () => null,
                    disableTooltip: true
                  },
                  {
                    content: tiempoEstimado(row),

                    action: () => null,
                    disableTooltip: true
                  },
                  {
                    content: <>{row.issues ? row.issues.map((issue, index) => <a target="_blank" href={issue.remote_url} key={`remote-issue-${index}`}>{issue.remote_issue_key}</a>) : ""}</>,
                    action: null,
                    disableTooltip: true
                  }

                ],

                collapsed: row => [
                  { name: "Número ADR", content: row.numadr },
                  { name: "Problema", content: row.problema },
                  { name: "Problema", content: row.numreqcli },

                ]
              }}

              title="ADR"
            >
            </TicketsTable>
          </Container>
          <BaseTablePagination

            page={1}
            maxPage={numTickets}
            rowsPerPage={0}
            totalRows={numTickets}
          //handlePageChange={0}
          //handleChangeRowsPerPage={1}
          //openDialog={openAdrNew}
          />
        </>
      )

    }
  }

  return (
    <>

      <Paper >

        {options()}
        {mostrar()}
      </Paper>


      {/* <TicketsEstimacionTablas  dialogOpen={createEstimacionOpen}
                                  handleClose={closeCreateEstimacion}
                                  currentIdInc={selectedRowIdInc.current}
                                  adr={adr}
                                  reload={reload}                 
        />*/}

      <EstimacionTickets dialogOpen={createEstimacionOpen}
        handleClose={closeCreateEstimacion}
        currentIdInc={selectedRowIdInc.current}
        adr={adr}
        reload={reload} />
    </>
  );
})
