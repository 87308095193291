import { Avatar, Card, CardHeader, Collapse, Divider, IconButton, styled } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";



const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

 export const CardCollapse = ({ title, image, children }) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Card style={{ textAlign: "left", borderRadius: 1, backgroundColor: "transparent" }}>
      <CardHeader
        //avatar={<Avatar sx={{ borderRadius: "4px" }} src={image} />}
        title={title}
        action={
          <ExpandMore
            expand={expanded}
            onClick={() => {
              handleExpandClick();
            }}
          >
            <KeyboardArrowDownIcon sx={{ marginTop: "8px" }} />
          </ExpandMore>
        }
      />
      <Divider
        variant="middle"
        sx={{
          //bgcolor: "#009AFE",
          boxShadow: 1,
          borderRadius: 1,
          marginTop: "-15px",
          //minWidth: 300,
        }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit  sx={{width: '100%'}}>
        {children}
      </Collapse>
    </Card>
  );
};
