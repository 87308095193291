import React, { useState, useRef, useCallback, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { displayDateTime, useCurrentUser, useConfirmationDialog, getErrorMsg, useDialogTools, useBackdrop, useCustomSnackbar, useRequests, } from '../../../helpers';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
//import Tooltip from '@mui/material/Tooltip';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/styles';
import { Backdrop, Box, Card, CardActions, CardContent, CardHeader, Fab, Hidden, IconButton, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { MensajeTicketEdit } from "../."
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .MuiCardHeader-title": {
      //could also be placed inside header class
      fontSize: 12,
    },
    "& .MuiCardHeader-subheader": {
      fontSize: 12,
    }

  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 950,
  },
  loginCardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffff',
    maxHeight: 12,
  },

  adminHeader: {
    backgroundColor: "#BBDEFB",
    color: theme.palette.primary.contrastText,
    "& .MuiCardHeader-title": {
      //could also be placed inside header class
      fontSize: 12,
    },
    "& .MuiCardHeader-subheader": {
      fontSize: 11,
    }

  },
  userHeader: {
    backgroundColor: "#ffcc80",
    color: theme.palette.primary.contrastText,
    "& .MuiCardHeader-title": {
      //could also be placed inside header class
      fontSize: 12,
    },
    "& .MuiCardHeader-subheader": {
      fontSize: 11,
    }
  },
  input: {
    display: 'none'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  chipHolder: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  privateHeader: {
    backgroundColor: theme.palette.privateHeader.main,
    color: theme.palette.privateHeader.contrastText,
    "& .MuiCardHeader-title": {
      //could also be placed inside header class
      fontSize: 12,
    },
    "& .MuiCardHeader-subheader": {
      fontSize: 11,
    }
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  input: {
    display: 'none',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    p: 1,
    width: '100%',
  },


}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const TicketFollowUp = ({ status, conversation = [], sendMessage: sendMsg, setTicket, ticket_id, ticket }) => {
  const currentUser = useCurrentUser()
  const [message, setMessage] = useState("")
  const [files, setFiles] = useState([])
  const entriesRef = useRef()
  const filesRef = useRef()
  const classes = useStyles();
  const { getConfirmation } = useConfirmationDialog()
  const { show, off } = useBackdrop()
  const { patch } = useRequests()
  const { eSnack, sSnack } = useCustomSnackbar()
  const { dialogOpen: assignOpen, openD: editMensajeOpen, closeD: editMensajeClose } = useDialogTools()
  const selectedMensaje = useRef(null)
  const selectedProfile = useRef(null)
  const selectedId = useRef(null)

  const theme = useTheme();

  const openEdit = useCallback(entry => {
    selectedId.current = entry.id
    selectedMensaje.current = entry.mensaje;
    selectedProfile.current = `${entry.profile?.name} ${entry.tipo === "private_message" ? "(Privado)" : ""}`

    editMensajeOpen()
  }, [editMensajeOpen])

  const handleDelete = async (id) => {
    show("Eliminando Mensaje")
    try {
      const response = await patch(`/journals/delete_mensaje`, {
        id: id,
        ticket_id: ticket_id
      })
      if (response?.ok) {

        const conversation = await response.json()
        setTicket({ ...ticket, conversation: conversation })

        sSnack("Mensaje Eliminado")
      } else {
        eSnack(await getErrorMsg(response))
        console.log("error eliminando mensaje");
      }
    } catch (e) {
      eSnack("Error inesperado eliminando mensaje ")
      console.log("Error eliminando ", e);
    } finally {
      off()
    }

  }

  const [expanded, setExpanded] = React.useState(false);
  const [statusin, setStatus] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  }

  const sendMessage = async e => {
    e.preventDefault();
    const sent = await sendMsg(e);
    if (sent) {
      setOpen(false);
      filesRef.current.value = null;
      setFiles([...filesRef.current.files]);
      await setMessage("");
    }
  }

  const changeFiles = e => {
    const files = e.target?.files || []
    setFiles([...files])
  }

  const handleFileDelete = (e, index) => {
    const dt = new DataTransfer()
    files.forEach((file, eIndex) => {
      if (index !== eIndex) {
        dt.items.add(file)
      }
    })
    filesRef.current.files = dt.files
    setFiles([...filesRef.current.files])
  }

  const getHeaderClass = message => {
    var classNames = []
    const admin = message.profile?.admin
    classNames.push(admin ? "admin" : "user")
    if (message.tipo === "private_message") {
      classNames.push(classes.privateHeader)
    } else if (message.tipo === "public_message") {
      classNames.push(admin ? classes.adminHeader : classes.userHeader)
    }
    return classNames.join(" ")
  }
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setMessage('');
    setFiles([]);
    setOpen(false);
  };
  useEffect(() => {
    if (status === "NOT" || status === "CER" || status === "RES") {
      setStatus(false)
    }
  }, [status])

  return (
    <div className="follow-up-container">
      <div className="entries-container" ref={entriesRef}>
        {conversation.map((entry, index) => {
          const admin = entry.profile?.admin
          return (
            <>
              <Card elevation={5} key={`entry-${index}`} className={`entry-holder`}>
                <Hidden smDown>

                  <CardHeader sx={{ maxHeight: 5, }} className={`entry-header-holder ${getHeaderClass(entry)}`}
                    avatar={
                      <Avatar alt={entry.profile?.name} src={entry.profile?.avatar} sx={{ bgcolor: grey[500], maxWidth: 25, maxHeight: 25 }} aria-label="recipe">
                      </Avatar>
                    }
                    action={
                      <>
                        <IconButton
                          sx={{
                            color: theme.palette.primary.main,
                          }}
                          color="inherit"
                          size="small"
                          onClick={() => openEdit(entry)}
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          sx={{
                            '&:hover': "#ffff",
                            color: theme.palette.error.main
                          }}
                          color="inherit"
                          size="small"
                          onClick={async () => (await getConfirmation({ title: "Eliminar", message: `Seguro Desea Eliminar el mensaje seleccionado?` })) ? handleDelete(entry.id) : null}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </>
                    }
                    title={`${entry.profile?.name} ${entry.tipo === "private_message" ? "(Privado)" : ""}`}
                    subheader={displayDateTime(new Date(entry.created_at))}
                    color="primary"
                  />
                </Hidden>
                <Hidden smUp>

                  <CardHeader sx={{ maxHeight: 8, }} className={`entry-header-holder ${getHeaderClass(entry)}`}
                    avatar={
                      <Avatar alt={entry.profile?.name} src={entry.profile?.avatar} sx={{ bgcolor: grey[500], maxWidth: 25, maxHeight: 25 }} aria-label="recipe">
                      </Avatar>
                    }
                    action={
                      <>
                        <IconButton
                          sx={{
                            color: theme.palette.primary.main,
                          }}
                          color="inherit"
                          size="small"
                          onClick={() => openEdit(entry)}
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          sx={{
                            '&:hover': "#ffff",
                            color: theme.palette.error.main
                          }}
                          color="inherit"
                          size="small"
                          onClick={async () => (await getConfirmation({ title: "Eliminar", message: `Seguro Desea Eliminar el mensaje seleccionado?` })) ? handleDelete(entry.id) : null}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </>
                    }
                    title={`${entry.profile?.name} ${entry.tipo === "private_message" ? "(Privado)" : ""} ${''}${displayDateTime(new Date(entry.created_at))}`}

                    color="primary"
                  />
                </Hidden>

                <CardContent sx={{ height: '100%' }}>
                  <Typography className={`entry-content-holder ${admin ? `admin` : `user`}`} variant="body2" color="text.secondary">
                    {entry.mensaje}
                  </Typography>
                </CardContent>
                {!!entry.file_links.length && <CardActions disableSpacing>
                  <ExpandMore onClick={handleExpandClick}>
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>}

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  {!!entry.file_links.length && <CardContent>
                    {entry.file_links?.map((file, index) => {
                      return (
                        <>
                          <Chip
                            key={`message-file-${index}`}
                            label={file.filename}
                            color="primary"
                            variant='outlined'
                            clickable
                            component="a"
                            className={classes.chip}
                            href={`${file.link}`}
                          // onClick={<Link href={`${file.link}`} target="_blank"></Link>}
                          // onDelete={e => handleFileDelete(e, index)}
                          />

                        </>
                      )
                    })}
                  </CardContent>}
                </Collapse>
              </Card>

              <br />
            </>

          )
        })}
      </div>
      <form onSubmit={sendMessage}>
        <div className="new-entry-holder">

          {statusin && <Fab
            color="secondary"
            aria-label="toggle-attachments"
            onClick={handleOpen}
            sx={{ position: 'absolute', bottom: '16px', right: '16px' }}
          >
            <ChatIcon />
          </Fab>}

          <Backdrop
            className={classes.backdrop}
            open={open}
            onClick={handleClose}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Card
                onClick={(e) => e.stopPropagation()}
                className="submit-holder"
                elevation={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  width: '800px',
                  mt: 2,
                  //position: 'relative',
                }}
              >
                <IconButton >
                  <CloseIcon onClick={handleClose2} />
                </IconButton>


                <Avatar alt={currentUser.name} src={currentUser.avatar} sx={{ bgcolor: grey[250], maxWidth: 30, maxHeight: 30, marginRight: 1 }} />
                <Box sx={{ flex: 1, mr: 2 }}>
                  <TextField
                    hiddenLabel
                    multiline
                    fullWidth
                    size='small'
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    inputProps={{
                      maxLength: 3500,
                    }}
                    placeholder="Escribe un mensaje aqui..."
                    sx={{ width: '100%', minWidth: 'unset' }}
                  />
                </Box>
                {currentUser.can?.private_journal && (
                  <FormControlLabel
                    value={true}
                    control={<Checkbox color="primary" />}
                    label="Privado"
                    labelPlacement="end"
                    name="private"
                    color='primary'
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                  />
                )}
                <input
                  ref={filesRef}
                  accept="image/*,.doc,.docx,.zip,.rar,.jsx,.txt,.sql,.pkb,.pks,.sql"
                  className={classes.input}
                  name="journal_files[]"
                  id="file-input"
                  multiple
                  type="file"
                  onChange={changeFiles}
                  sx={{ display: 'none' }}
                />
                <label htmlFor="file-input">
                  <IconButton title='Adjuntar' variant="contained" color="primary" component="span">
                    <AttachFileTwoToneIcon />
                  </IconButton>
                </label>
                <Button startIcon={<SendTwoToneIcon />} variant="contained" type="submit" color="secondary" size="small" sx={{ display: { xs: 'none', md: 'flex' } }}>
                  Enviar
                </Button>
                {currentUser.can?.private_journal && (
                  <FormControlLabel
                    sx={{ display: { xs: 'flex', md: 'none' }, maxHeight: 25, maxWidth: 1 }}
                    value={true}
                    control={<Checkbox color="primary" />}
                    title="Privado"
                    label=""
                    labelPlacement="top"
                    name="private"
                  />
                )}
              </Card>
              <Box component="ul" className={classes.chipHolder}>
                {files?.map((file, index) => {
                  return (
                    <Chip
                      key={`file-chip-${index}`}
                      label={file.name}
                      color="primary"
                      //variant='outlined'
                      className={classes.chip}
                      onDelete={e => handleFileDelete(e, index)}
                    />
                  )
                })}
              </Box>
            </Box>
          </Backdrop>

          <br />
        </div>
      </form>
          <MensajeTicketEdit id={selectedId.current}
            message={selectedMensaje.current}
            profile={selectedProfile.current}
            editMensajeOpen={assignOpen}
            handleClose={editMensajeClose}
            conversation={conversation}
            ticket_id={ticket_id}
            setTicket={setTicket}
            ticket={ticket} />

    </div>
  )
}
