import React, {useState, useEffect} from 'react'
import ReactMarkdown from 'react-markdown'

export const Releases = props => {
  const [releases, setReleases] = useState("")
  useEffect(() => {
    const getReleases = async () => {
      const response = await fetch('/releases.txt')
      const text = await response.text()
      setReleases(text)
    }
    getReleases()
  },[])
  return <div className="releases-container">
    <h1 className="releases-title">Releases</h1>
    <ReactMarkdown>
      {releases}
    </ReactMarkdown>
  </div>
}
