import React, {useState, useEffect} from 'react'
import { useBaseStyles, keepFormValues, useHandleFieldValues, FIELD_TYPES } from "../../../helpers"
import { Autocomplete } from '../.'
import {
  TextField,
  Container,
  Button,
  IconButton
} from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from '@mui/material/InputAdornment';

export const FORM_SUBMIT_TYPES =  Object.freeze({
  FORM: "form",
  JSON: "json"
})

/*
  Details: Generar formas basicas
  Parameters:
    fields[OBJECT]: Json con los campos a mostrar en la forma cada key del json se utiliza como el nombre del parametro a enviar en caso de que el submitType sea JSON
      title[TEXT]: Titulo del campo
      name[TEXT]: Name del campo, en caso de submitType "form" sirve para el nombre de los campos a enviar en el submit
      id[TEXT]: Id del campo
      type[TEXT]: Tipo del campo ref: FIELD_TYPES
      required[BOOL]: si el campo es requerido o no
      options[ARRAY]: Arreglo de opciones para AutoComplete de preferencia crear los objetos con el formato {name:"Nombre de la opcion", value: "valor a enviar en la forma"}
      value[TEXT/OBJECT]: Valor inicial del campo, en caso de tipo autocomplete colocar algun valor del arreglo "options" ej user_types[0]
      loading[BOOL]: Indicador si el campo autocomplete esta cargando o no
      valueKey[TEXT]: Para tipos Autocomplete nombre de la propiedad que contiene el valor en caso de no ser "value"
      helper[TEXT]: Texto de ayuda para el campo
      upcaseValue[BOOL]: Obligar el campo a estar en mayusculas o no false por defecto
      onChange[FUNCTION]: Funcion del componente papa a ejecutar un change en el campo, util cuando las opciones de un AutoComplete depende de otro valor
    submitText[TEXT]: Texto a colocar en el boton Submit de la forma
    dialog[BOOL]: Si la forma se utiliza o no en un Dialog
    formId[TEXT]: Id de la forma, necesario en caso de utilizar "dialog:true"
    submit[FUNCTION]: Funcion a ejecutar al presionar submit
    submitType[TEXT]: Tipo de submit a ejecutar por defecto form, ref: FORM_SUBMIT_TYPES
*/

export const BaseNewForm = React.memo(({fields:parentFields, submitText, dialog, formId, submit, submitType=FORM_SUBMIT_TYPES.FORM}) => {
  const classes = useBaseStyles()
  const [fields, setFields] = useState(parentFields)
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const {handleFields, parseValues, getOptionsLabel} = useHandleFieldValues(fields, setFields)

  useEffect(()=>{
    setFields(prevFields => keepFormValues(prevFields, parentFields))
  },[parentFields])

  const textFieldInputProps = field =>{
    const inputProps = {}
    if(field.type === FIELD_TYPES.NUMBER){
      Object.assign(inputProps, {
        max: field.maxLength,
        min: field.minLength || 0
      })
    }else{
       Object.assign(inputProps, {
        maxLength: field.maxLength
      })
    }
    return inputProps
  }

  const onSubmit = e => {
    switch (submitType) {
      case FORM_SUBMIT_TYPES.FORM:
        submit(e)
        break;
      case FORM_SUBMIT_TYPES.JSON:
        e.preventDefault()
        submit(parseValues())
        break;
      default:
       return
    }
  }

  const materialInputProps = field =>{
    const inputProps = {}
    if(field.showPassword) {
      inputProps.endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            size="large">
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )
    }
    if(field.minMaxDate){
      Object.assign(inputProps,{
        inputProps:{
          ...field.minMaxDate
        }
      })
    }

    return inputProps
  }

  const createField = (key, index) => {
    const field = fields[key]
    const formField = field.type === FIELD_TYPES.AUTOCOMPLETE ?
      <div key={`autocomplete-field-${index}`} className={classes.formField}>
        <Autocomplete
          options={field.options || []}
          id={field.id}
          size="small"
          loading={field.loading}
          multiple={field.multiple}
          value={field.value}
          valueKey={field.valueKey}
          setValue={field.setValue}
          nullable={field.nullable}
          onChange={(e,value) => handleFields(key, value)}
          getOptionLabel={option => getOptionsLabel(key, option)}
          renderInput={(params) => <TextField {...params} name={field.name} fullWidth label={field.title}  required={field.required} />}
          {...field?.extraProps}
        />
      </div>
    :
    <div key={`text-field-${index}`} className={field.type !== FIELD_TYPES.HIDDEN ? classes.formField : classes.hidden}>
      <TextField
        size="small"
        label={field.title}
        name={field.name}
        id={field.id}
        fullWidth

        value={field.upcaseValue ? field.value.toUpperCase() : field.value}
        onChange={ e => handleFields(key, e.target.value)}
        required={field.required}
        type={field.type}
        autoComplete="new-password"
        helperText={field.helper}
        error={!!field.helper}
        inputProps={textFieldInputProps(field)}
        InputProps={materialInputProps(field)}
        InputLabelProps={{
          shrink: field.type === "date" ? true : undefined
        }}
        {...field?.extraProps}
      />
    </div>
    return formField
  }

  return(
    <Container className="base-form-container">
      <form onSubmit={onSubmit} id={formId}>
        {Object.keys(fields).map((key, index) => createField(key, index))}
        {!dialog && <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
        >
          {submitText || "Create"}
        </Button>}
      </form>
    </Container>
  )
})
