import {useRef} from "react"
import actioncable from "actioncable"


export const useCable = () => {
  const CableApp = useRef({})
  const token = localStorage.getItem("token")
  const email = localStorage.getItem("email")
  if(token && email){
    CableApp.current.cable = actioncable.createConsumer(`${process.env.REACT_APP_CABLE_URL}?token=${localStorage.getItem("token")}&user=${localStorage.getItem("email")}`)
  }
  return  CableApp.current
}
// var socket = {}
// export const useWebSocket = ({open, close}) => {
//   socket.onopen = open
//   socket = new WebSocket(Config.serverCableUrl);
//   return socket
// }
