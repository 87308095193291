import React, { useState, useEffect, useCallback, useRef } from 'react'
import MenuItem from '@mui/material/MenuItem';
/* import Menu from '@mui/material/Menu'; */
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
/* import NotificationsIcon from '@mui/icons-material/Notifications'; */
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { Link as RouterLink } from 'react-router-dom';
/* import makeStyles from '@mui/styles/makeStyles'; */
import { useRequests, useCustomSnackbar, useCable, useCurrentUser, useBackdrop, getErrorMsg } from '../../../helpers'
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material/styles';
import {
  alpha, Box, Button, Divider, FormControlLabel, Hidden, List, ListItem, Popover, Switch, Tooltip, Typography,

} from '@mui/material';


/* const useStyles = makeStyles({
  root: {
    width: '40vw',
    height: '40vh'
  }
}); */

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.secondary.main, 0.1)};
        color: ${theme.palette.secondary.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);
export const Alerts = React.memo(props => {
  const CableApp = useCable()
  const ref = useRef(null);
  const currentUser = useCurrentUser()
  const {show, off} = useBackdrop ()
  const {eSnack, sSnack, iSnack} = useCustomSnackbar()
  const { get, controller, post } = useRequests()
  /* const classes = useStyles() */
  const [alertAnchor, setAlertAnchor] = useState(null);
  const [alerts, setAlerts] = useState([])
  const handleMenuOpen = event => setAlertAnchor(event.currentTarget)
  const handleMenuClose = () => setAlertAnchor(null)

  const [invisible, setInvisible] = useState(false);

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
  };

  const getAlerts = useCallback(async () => {
    try {
      const response = await get('/alerts', {})
      if (response?.ok) {
        const alerts = await response.json()
        setAlerts(alerts)
      } else {
        eSnack("Error obteniendo alertas", getAlerts)
        console.log("error getting alerts");
      }
    } catch (e) {
      console.log(e);
    }
  }, [get, eSnack])

  useEffect(() => {
    getAlerts()
    return () => controller.abort()
  }, [getAlerts, controller])

  useEffect(() => {
    CableApp.room = CableApp.cable.subscriptions.create({
      channel: "AlertChannel",
      room: currentUser.idpers
    },
      {
        received: data => {
          const alert = data.alert
          const action = data.action
          if (action === "add") {
            setAlerts(currentAlerts => [alert, ...currentAlerts])
          } else if (action === "delete") {
            setAlerts(currentAlerts => {
              const newAlerts = [...currentAlerts]
              newAlerts.splice(newAlerts.findIndex(a => a.id === alert.id), 1)
              return newAlerts
            })
          }
        }
      })

    return () => {
      CableApp.room.unsubscribe()
    }
  }, [currentUser, CableApp])

  const createMenuItem = useCallback((msg, date, route, id, index) => {
    return <MenuItem key={`alert-${index}`} component={RouterLink} to={route}>
      <CardHeader
        title={msg}
        subheader={date}
        titleTypographyProps={{
          variant: 'subtitle1',
          noWrap: true
        }}
        subheaderTypographyProps={{
          variant: 'caption',
          noWrap: true
        }}
      >
      </CardHeader>
    </MenuItem>
  }, [])

  const renderAlerts = useCallback(() => {
    return alerts.map((alert, index) => {
      var msg = ""
      const date = alert.created_ago
      const target = alert.alertable_fields || {}
      const id = alert.id
      var route = `/tickets/${target.idinc}`
      switch (alert.tipo) {
        case 'ticket_comment':
          msg = `Respuesta ticket #${target.idinc}: ${target.mensaje}`
          break
        case 'ticket_new':
          msg = `Ticket #${target.idinc} creado`
          break
        case 'ticket_assignee':
          msg = `Te han asignado el ticket #${target.idinc}`
          break
        case 'ticket_assigned':
          msg = `Tu ticket #${target.idinc} ha sido asignado`
          break
        case 'ticket_resolved':
          msg = `Tu ticket #${target.idinc} ha sido marcado como resuelto`
          break
        default:
          return null
      }
      return createMenuItem(msg, date, route, id, index)
    })
  }, [alerts, createMenuItem])

  const markAllAsRead = useCallback(async () => {
    
    show('Marcando como leidas las notificaciones')
    try {
      const request  = await post('/alerts/leida',)
      if(request?.ok){
        sSnack("Marcadas como leidas ")
       getAlerts()
      }else{
        eSnack(await getErrorMsg(request))
      }
    } catch (e) {
      eSnack("Error inesperado")
      console.log("Error", e);
    } finally {
      off() 
      }
  }, [ eSnack, show, off,, sSnack, iSnack, post])

  return <>
    
    <Tooltip arrow title="Notifications">
      <IconButton color="primary" ref={ref} onClick={handleMenuOpen}>
        <NotificationsBadge
          invisible={invisible}
          badgeContent={alerts.length}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <NotificationsActiveTwoToneIcon />
        </NotificationsBadge>
      </IconButton>
    </Tooltip>



    {!!alerts.length && <Popover
      sx={{ p: 2, maxWidth: 650, maxHeight: 450, display: { xs: 'block', sm: 'flex' } }}

      anchorEl={alertAnchor}
      open={!!alertAnchor}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}


      keepMounted


    >
      <Box sx={{ p: 4 }} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">Notificaciones</Typography>
        {!!alerts.length && (
          <Button variant="contained" color="secondary" onClick={markAllAsRead}>Marcar todas como leídas</Button>
        )}
      </Box>
      <Divider />
      <List sx={{ p: 0 }}>
        <ListItem sx={{ p: 2, minWidth: 450, display: { xs: 'block', sm: 'flex' } }}>
          <Box flex="1">
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ fontWeight: 'bold' }}>
                Mensajes de la Plataforma
              </Typography>
              {/* <Typography variant="caption" sx={{ textTransform: 'none' }}>
                {formatDistance(subDays(new Date(), 3), new Date(), {
                  addSuffix: true
                })}
              </Typography> */}
            </Box>
            <Typography
              component="span"
              variant="body2"
              color="text.secondary"
            >

              {renderAlerts()}
            </Typography>
          </Box>
        </ListItem>
      </List>
    </Popover>}
  </>;
})
