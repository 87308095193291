import React from "react"
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
  } from '@mui/material';
import '../../../stylesheets/activity.scss'



const useStyles = makeStyles( theme => ({
  header: {
    backgroundColor: theme.palette.primary.light,
   // padding: "0.5%",
    color: theme.palette.primary.contrastText
  },
}));


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#83c3f7",
    color: theme.palette.primary.contrastText,
    padding: "16px",
  },
  body: {
    padding: "16px",
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);


//cabecera de table Módulos
const headersmodules = ["Código",  "Actualización"]
// const { t } = useTranslation()

const makeHeadersModules = ()=>{
  return headersmodules.map((header,index)=>{
    return <StyledTableCell key={index} align={"center"}>{header}</StyledTableCell>
  })
}


export const ActivityModule = React.memo(props => {
  const classes = useStyles();

  //cambiando estado release


  return (
    <>

      {/*JSON.stringify(props.actividad.activity_modules)*/}
     
      <Paper>
    <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
              {makeHeadersModules()}
              </TableRow>
            </TableHead>

            <TableBody>
            {props.actividad.activity_modules?.map((row, index) => {
                return <StyledTableRow key={index}>
                  <StyledTableCell align="center">{row.codmodulo}</StyledTableCell>
                  <StyledTableCell align="center">{row.idactualizacion}</StyledTableCell>
                </StyledTableRow>
               })}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
        
      




    </>
  );

})
