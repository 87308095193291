import React, { useEffect, useState, useCallback, useRef } from "react"
import { ChangePasswordDialog, ChangeRolesDialog, AssignDbUserDialog } from "../."
import { BaseTable as UsuariosTable, BaseTablePagination, BaseFilters } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, useCurrentUser, useParsedURLParams, useBackdrop, FIELD_TYPES, useConfirmationDialog } from "../../../helpers"
import { AppBar, Breadcrumbs, Container, Stack, Toolbar, Tooltip, Typography } from "@mui/material"
import { IconButtonMenu } from "../../layout/basic/IconButtonMenu"
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@mui/material/Link';
import FilterListIcon from '@mui/icons-material/FilterList';

import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import FingerprintTwoToneIcon from '@mui/icons-material/FingerprintTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import { useTranslation } from "react-i18next";
import { ChangeEmail } from "./change_email"

export const UserList = () => {
  const history = useHistory();
  const currentUser = useCurrentUser()
  const [users, setUsers] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rolesDialogOpen, setRolesDialogOpen] = useState(false);
  const [changeEmail, setchangeEmail] = useState(false);
  const [assignDbUserOpen, setAssignDbUserOpen] = useState(false);
  const dialogData = useRef({})
  const rolesDialogData = useRef({})
  const changeEmailData = useRef({})
  const assignDbDialogData = useRef({})
  const [page, setPage] = useState(0)
  const { t } = useTranslation()
  const [maxPage, setMaxPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [totalRows, setTotalRows] = useState(0)
  const { show, off } = useBackdrop()
  const [appliedFilters, setAppliedFilters] = useState(useParsedURLParams())
  const [filtroOpen, setfiltroOpen] = useState(false);
  const toggle = () => setfiltroOpen(!filtroOpen)
  const {getConfirmation} = useConfirmationDialog()
  const userFilters = {
    id: {
      name: "Id",
      value: "",
      type: FIELD_TYPES.TEXT
    },
    nombpers: {
      name: "Usuario",
      value: "",
      type: FIELD_TYPES.TEXT
    },
    cliente: {
      name: "Cliente",
      value: "",
      type: FIELD_TYPES.TEXT
    },
    email: {
      name: "Email",
      value: "",
      type: FIELD_TYPES.TEXT
    }
  }
  const { get, post, controller } = useRequests()
  const { sSnack, eSnack } = useCustomSnackbar()
  const [loadingTable, setLoadingTable] = useState(false)

  const handleClickOpen = useCallback(data => {
    if (data && Object.keys(data).length) {
      dialogData.current = data
      setDialogOpen(true);
    }
  }, []);

  const handleClickOpenRoles = useCallback(data => {
    if (data && Object.keys(data).length) {
      rolesDialogData.current = data
      setRolesDialogOpen(true);
    }
  }, [])
  const handleClickOpenEmail = useCallback(data => {
    if (data && Object.keys(data).length) {
      changeEmailData.current = data
      setchangeEmail(true);
    }
  }, [])

  const handleClickOpenAssignDbUser = useCallback(data => {
    if (data && Object.keys(data).length) {
      assignDbDialogData.current = data
      setAssignDbUserOpen(true);
    }
  }, []);

  const handleClose = useCallback(() => {
    setDialogOpen(false);
    setchangeEmail(false)
    setRolesDialogOpen(false)
    setAssignDbUserOpen(false)
  }, []);

  const getUsers = useCallback(async () => {
    // show("Cargando Usuarios")
    try {
      setLoadingTable(true)
      var response = await get("/administration/users", {
        page: page + 1,
        rowsPerPage: rowsPerPage,
        ...appliedFilters
      })
      if (response?.ok) {
        const newUsers = await response.json()
        setUsers(newUsers.users)
        setMaxPage(newUsers.maxPage)
        setTotalRows(newUsers.total)
      } else {
        eSnack(await getErrorMsg(response), getUsers)
        console.error("Error Retrieving Users");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo Usuarios")
      console.error("Error Retrieving Users", e);
    } finally { setLoadingTable(false) }
  }, [page, rowsPerPage, appliedFilters, get, eSnack])

  const block = useCallback(async user_id => {
    show("Bloqueando Usuario")
    try {
      var response = await post("/administration/block_user", {
        id: user_id
      })
      if (response?.ok) {
        getUsers()
        sSnack(`Usuario ${user_id} Bloqueado`)
      } else {
        eSnack(await getErrorMsg(response))
        console.error("Error Blocking User");
      }
    } catch (e) {
      eSnack("Error inesperado bloqueando usuario")
      console.error("Blocking User:", e);
    } finally { off() }
  }, [show, post, sSnack, eSnack, off, getUsers])

  const unblock = useCallback(async user_id => {
    show("Desloqueando Usuario")
    try {
      var response = await post("/administration/unblock_user", {
        id: user_id
      })
      if (response?.ok) {
        getUsers()
        sSnack(`Usuario ${user_id} Desbloqueado`)
      } else {
        eSnack(await getErrorMsg(response))
        console.error("Error Unblocking User");
      }
    } catch (e) {
      eSnack("Error inesperado desbloqueando usuario")
      console.error("Unblocking User:", e);
    } finally { off() }
  }, [show, post, sSnack, eSnack, off, getUsers])

  const applyFilters = filters => {
    setPage(0)
    setAppliedFilters(filters)
  }

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const handlePageChange = useCallback((e, value) => setPage(value), [])

  const handleChangeRowsPerPage = useCallback((e, value) => {
    setPage(0)
    setRowsPerPage(parseInt(e.target.value))
  }, [])

  useEffect(() => () => controller.abort(), [controller])

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      Usuarios
    </Typography>,
  ];

  const getActiveIcon = user => {
    if(user.indactivo === "active"){
      return (
        <Tooltip title="Bloquear Usuario">
          <LockTwoToneIcon
            onClick={async () => (await getConfirmation({title:"Bloquear Usuario", message:`Seguro desea bloquear el usuario ${user.email}`})) ? block(user.idpers) : null }
            color="secondary"/>
        </Tooltip>
      );
    }else{
      return (
        <Tooltip title="Desbloquear Usuario">
          <CheckCircleOutlineTwoToneIcon onClick={async () => (await getConfirmation({title:"Desbloquear Usuario", message:`Seguro desea desbloquear el usuario ${user.email}`})) ? unblock(user.idpers) : null}/>
        </Tooltip>
      );
    }
  }

  const NewUSU = () =>{

    history.push("/administration/users/new")
  }

  const acciones = (row) => {
    return (
      <>
        <Tooltip title="Cambiar Password">
          <VpnKeyTwoToneIcon onClick={() => handleClickOpen(row)} color="secondary" />
        </Tooltip>
        <Tooltip title="Cambiar Correo">
          <EmailTwoToneIcon onClick={() => handleClickOpenEmail(row)} color="secondary" />
        </Tooltip>
        {!!currentUser?.can?.change_roles &&
          <Tooltip title="Cambiar Roles">
            <FingerprintTwoToneIcon onClick={() =>  handleClickOpenRoles(row)} color="secondary" />
          </Tooltip>
        }
        {getActiveIcon(row)}
        {!!currentUser?.can?.assign_db_user &&
          <Tooltip title="Asignar usuario de Base de Datos">
            <AssignmentIndTwoToneIcon onClick={() =>  handleClickOpenAssignDbUser(row)} color="secondary" />
          </Tooltip>
        }
      </>
    )
  };

  return (
    <>

      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <IconButtonMenu
            open={filtroOpen}
            toggleMenu={toggle}
            size="small"
            color={"primary"}
            icon={<FilterListIcon />}
          >
            <BaseFilters filters={userFilters} applyFilters={applyFilters} />
          </IconButtonMenu>
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container className="base-container">
        <UsuariosTable
          loading={loadingTable}
          rows={users}
          headers={["Id", "Nombre", "Cliente", "Email", "Rol", "Activo?", "Acciones"]}
          /* rowAction={row => (row)} */
          collapsible={{
            visible: row => [
              { content: row.idpers, handleCollapse: false },
              { content: row.nombpers },
              { content: row.codcli },
              { content: row.email },
              { content: row.tipo },
              { content: t(row.indactivo) },
              {
                content: (
                  acciones(row)
                ),
                action: () => null,
                disableTooltip: true,
              },

            ],
            collapsed: row => [
              { name: "Cargo", content: row.cargo },
  
            ]
          }}
          title="Usuarios"
        >
          <BaseTablePagination
            page={page}
            maxPage={maxPage}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            openDialog={NewUSU}
            block={block}
            unblock={unblock}
         
          >

          </BaseTablePagination>
        </UsuariosTable>
      </Container>
      <ChangePasswordDialog reload={getUsers} open={dialogOpen} handleClose={handleClose} data={dialogData.current} />
      <ChangeRolesDialog reload={getUsers} open={rolesDialogOpen} handleClose={handleClose} data={rolesDialogData.current} />
      <ChangeEmail reload={getUsers} open={changeEmail} handleClose={handleClose} data={changeEmailData.current} />
      <AssignDbUserDialog reload={getUsers} open={assignDbUserOpen} handleClose={handleClose} user={assignDbDialogData.current} />
    </>
  )
}
