import React from 'react'
import Skeleton from '@mui/material/Skeleton';

/*
Parameters:
  children[Component] = children used to infer height
*/
export const SkeletonAC = ({children}) => {
  return <Skeleton width="100%">{children}</Skeleton>
}
