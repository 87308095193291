import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import {useCurrentUser} from '../../../helpers'
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '90%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  textField:{
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const UserDetails = props => {
  const classes = useStyles();
  const currentUser = useCurrentUser()

  return (
    <Paper className={classes.paper} elevation={0}>
      Correo: {currentUser.email}
    </Paper>
  );
}
