import { Grid, Paper, TextField, Typography, Divider, AppBar, Tabs, Tab, FormControlLabel, Checkbox, Breadcrumbs, Toolbar, Stack, Container, Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getErrorMsg, useBackdrop, useConfirmationDialog, useCustomSnackbar, useRequests, FIELD_TYPES, } from "../../../helpers";
import { Autocomplete, ConditionalWrapper, EditableTextField, SkeletonDetailActivity } from "../../layout";
import { Link as RouterLink, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';

import { styled } from '@mui/material/styles';
import { ActivityOtList } from "./actividad";


const TabsWrapper = styled(Tabs)(
    () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

export const OtDetail = () => {
    const { idot } = useParams()

    const [loadingclase, setLoadingclase] = useState(false)
    const [loadingOt, setloadingOt] = useState(false)
    const [loadingarea, setLoadingarea] = useState(false)
    const [loadingresponsable, setLoadingResponsable] = useState(false)
    const [loadingproyectos, setLoadingproyectos] = useState(false)
    const { show, off } = useBackdrop()
    const [order, setOrder] = useState("")

    const [clases, setClases] = useState([])
    const [currentClases, setCurrentClases] = useState(null)
    const [proyectos, setProyectos] = useState([])
    const [currentproyectos, setCurrentproyectos] = useState(null)
    const [areas, setAreas] = useState([])
    const [currentareas, setCurrentareas] = useState(null)
    const [responsables, setResponsables] = useState([])
    const [currentresponsables, setCurrentResponsables] = useState(null)
    const [isChecked, setIsChecked] = useState(false);

    const { get, patch } = useRequests()
    const { eSnack, sSnack } = useCustomSnackbar()
    const { getConfirmation } = useConfirmationDialog()
    const [message, setMessage] = useState("");
    
    const [currentTab, setCurrentTab] = useState('detalle');

    const tabs = [
        { value: 'detalle', label: 'Detalle' },
        { value: 'horas', label: 'Horas' },
        { value: 'descripcion', label: 'Descripción' },
        { value: 'actividades', label: 'Actividades' },

    ];

    const handleTabsChange = (event, value) => {
        setCurrentTab(value);
    };

    const getOrders = useCallback(async () => {
        try {
            setloadingOt(true)
            const response = await get('/administration/works_orders/show', { idot })
            if (response?.ok) {
                const order = await response.json()
                setOrder(order)
            } else {

                eSnack(await getErrorMsg(response), getOrders)
                console.log("error getting order");
            }
        } catch (e) {
            eSnack("Error inesperado obteniendo order")
            console.log("Error getting order", e);
        } finally { setloadingOt(false) }
    }, [get, eSnack, idot])

    useEffect(() => {
        getOrders()
    }, [getOrders])

    const saveField = async value => {
        /* if (typeof newValue != 'boolean' && !value) return sSnack("Debe colocar un valor en el campo") */
        show("Actualizando Datos")
        try {
            const response = await patch(`/administration/works_orders/update_ordenes_trabajo`, {
                idot,
                order: value,
                essoporte: isChecked
            })
            if (response?.ok) {
                const order = await response.json()
                sSnack("Dato actualizado")
                setOrder(order)
            } else {
                eSnack(await getErrorMsg(response))
                console.log("error updating");
            }
        } catch (e) {
            eSnack("Error inesperado actualizando ")
            console.log("Error updating ", e);
        } finally {
            off()
        }
    }


    const getClases = useCallback(async () => {
        try {
            setLoadingclase(true)
            const response = await get('/administration/works_orders/clases')
            if (response?.ok) {
                const clases = await response.json()
                setClases(clases)
            } else {
                eSnack(await getErrorMsg(response), getClases)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingclase(false) }
    }, [get, eSnack])

    useEffect(() => {
        getClases()
    }, [getClases,])

    useEffect(() => {
        if (clases.length && order.codclase) {
            setCurrentClases(clases.find(clase => clase.codclase === order.codclase))
        }
    }, [order, clases])

    const selectClase = async selectedOption => {
        const claseOT = { codclase: selectedOption?.codclase }
        saveField(claseOT)
    }

    const getProjects = useCallback(async () => {
        try {
            setLoadingproyectos(true)
            const response = await get('/administration/works_orders/projects')
            if (response?.ok) {
                const proyectos = await response.json()
                setProyectos(proyectos)
            } else {
                eSnack(await getErrorMsg(response), getProjects)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingproyectos(false) }
    }, [get, eSnack])

    useEffect(() => {
        getProjects()
    }, [getProjects])

    useEffect(() => {
        if (proyectos.length && order.codproy) {
            setCurrentproyectos(proyectos.find(proyecto => proyecto.codproy === order.codproy))
        }
    }, [order, proyectos])

    const selectProyecto = async selectedOption => {
        const proyectOt = { codproy: selectedOption?.codproy }
        saveField(proyectOt)
    }

    const getAreas = useCallback(async () => {
        try {
            setLoadingarea(true)
            const response = await get('/administration/works_orders/areas')
            if (response?.ok) {
                const areas = await response.json()
                setAreas(areas)
            } else {
                eSnack(await getErrorMsg(response), getAreas)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingarea(false) }
    }, [get, eSnack])

    useEffect(() => {
        getAreas()
    }, [getAreas,])

    useEffect(() => {
        if (areas.length && order.codarea) {
            setCurrentareas(areas.find(area => area.codarea === order.codarea))
        }
    }, [order, areas])

    const selectArea = async selectedOption => {
        const areaOT = { codarea: selectedOption?.codarea }
        saveField(areaOT)
    }

    const getAssignables = useCallback(async () => {
        try {
            setLoadingResponsable(true)
            var response = await get("/administration/works_orders/responsable");
            if (response?.ok) {
                const responsables = await response.json();
                setResponsables(responsables);
            } else {
                eSnack(await getErrorMsg(response), getAssignables);
                console.error("Error Retrieving Assignables");
            }
        } catch (e) {
            eSnack("Error inesperado cargando usuarios assignables", getAssignables);
            console.error("Retrieving Assignables:", e);
        } finally { setLoadingResponsable(false) }
    }, [get, eSnack]);

    useEffect(() => {
        getAssignables()
    }, [getAssignables,])

    useEffect(() => {
        if (responsables.length && order.usuresp) {
            setCurrentResponsables(responsables.find(responsable => responsable.codusu === order.usuresp))
        }
    }, [order, responsables])

    const selectResponsable = async selectedOption => {
        const responsableOT = { usuresp: selectedOption?.codusu }
        saveField(responsableOT)
    }
    
    useEffect(() => {
            setIsChecked(order.has_wok_order_sop)       
    }, [order.has_wok_order_sop])

    useEffect(() => {
        if(isChecked){
            setMessage("Seguro desea quitar OT como Soporte?")
        }
        else{
            setMessage("Seguro desea asignar OT como Soporte?")
        } 
}, [isChecked])
    
        const handleOnChange =  async  () => {
            setIsChecked(!isChecked) 

        const confirmed = await getConfirmation({ title: "Cambio de estado OT", message:message})
        if (confirmed) {

            if(isChecked){
                deleteOtSopCli()
            }
            if(!isChecked){
              insertOtSopCli()
            }
        }else{
            setIsChecked(order.has_wok_order_sop)
        }
        };

        const insertOtSopCli = async () => {
            show("Actualizando Datos")
            try {
                const response = await patch(`/administration/works_orders/create_ot_sop_cli`, {
                    idotsoporte: order.idot,
                    codproy: order.codproy,
                    fecinivig:order.fecinivig,
                    fecfinvig: order.fecfinvig,
                })
                if (response?.ok) {
                    sSnack("Dato actualizado")
                } else {
                    eSnack(await getErrorMsg(response))
                    console.log("error updating");
                }
            } catch (e) {
                eSnack("Error inesperado actualizando ")
                console.log("Error updating ", e);
            } finally {
                off()
            }
        }
        const deleteOtSopCli = async () => {
            show("Actualizando Datos")
            try {
                const response = await patch(`/administration/works_orders/delete_ot_sop_cli`, {
                    idotsoporte: order.idot,
                })
                if (response?.ok) {
                   // const order = await response.json()
                    sSnack("Dato actualizado")
                    //setOrder(order)
                   
                } else {
                    eSnack(await getErrorMsg(response))
                    console.log("error actualizando");
                }
            } catch (e) {
                eSnack("Error inesperado actualizando ")
                console.log("Error updating ", e);
            } finally {
                off()
            }
        }


    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
            Inicio
        </Link>,
        <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/administration/orders">
            Ordenes de Trabajo
        </Link>,
        <Typography key="3" color="text.primary">
            Detalle de la OT {order.idot}
        </Typography>,
    ];
    const Item2 = styled('div')(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(0.7),
        textAlign: 'lefth',
        color: theme.palette.text.primary,
    }));
    const Item3 = styled('div')(({ theme }) => ({
        ...theme.typography.h6,
        padding: theme.spacing(0.7),
        textAlign: 'left',
        color: theme.palette.text.primary,
      }));
    return (

        <>
            <AppBar position="static" color="transparent">
                <Toolbar variant="dense">
                    <Stack spacing={2} ml={4}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>
                </Toolbar>
            </AppBar>


            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <TabsWrapper
                    onChange={handleTabsChange}
                    value={currentTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    indicatorColor="primary"
                >
                    {tabs.map((tab) => (
                        <Tab key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                </TabsWrapper>
                <br />
                <ConditionalWrapper condition={loadingOt} wrapper={SkeletonDetailActivity}>
                {currentTab === 'detalle' && <div>
                    <Paper elevation={5} sx={{ p: 2, mb: 1 }}>
                        <Grid container spacing={1} mb={1}>
                            <Grid item xs sm  >
                                <FormControlLabel
                                    label="Es soporte?"
                                    control={
                                        <Checkbox id="idsoporte" checked={isChecked} onChange={handleOnChange} />
                                    }
                                />
                            </Grid>
                            <Grid item xs sm >
                                <Typography align="leght" variant="body1" color="black" >
                                    Nombre de la OT {order.idot}
                                </Typography>
                                <EditableTextField
                                    initialValue={order.nomot}
                                    name="nomot"
                                    save={saveField}
                                    placeholder="Nombre"
                                />
                            </Grid>

                            <Grid item xs sm>
                                <Typography align="leght" variant="body1" color="black" >
                                    Referencia
                                </Typography>
                                <EditableTextField
                                    initialValue={order.refot}
                                    name="refot"
                                    save={saveField}
                                    placeholder="referencia"
                                />
                            </Grid>



                        </Grid>


                        <Grid container spacing={2} >


                            <Grid item xs sm  >
                                <Typography align="leght" variant="body1" mb={1} color="black" >
                                    Proyecto
                                </Typography>
                                <Autocomplete
                                    /* style={{ width: 250 }} */
                                    nullable={false}
                                    loading={loadingproyectos}
                                    options={proyectos}
                                    size="small"
                                    value={currentproyectos}
                                    valueKey={"codproy"}
                                    onChange={async (_, value) => {
                                        if (value && (await getConfirmation({ title: "Asignar proyecto", message: `Cambiar Proyecto ${order.codproy} -> ${value.codproy}?` }))) {
                                            selectProyecto(value)
                                        }
                                    }
                                    }
                                    getOptionLabel={(option) => `${option.nomproy}`}
                                    renderInput={(params) => <TextField {...params} required fullWidth label="Estatus" />}

                                />
                            </Grid>
                            <Grid item xs sm >
                                <Typography align="leght" variant="body1" mb={1} color="black" >
                                    Área
                                </Typography>
                                <Autocomplete
                                    /* style={{ width: 210 }} */
                                    nullable={false}
                                    loading={loadingarea}
                                    options={areas}
                                    size="small"
                                    value={currentareas}
                                    valueKey={"codarea"}
                                    onChange={async (_, value) => {
                                        if (value && (await getConfirmation({ title: "Asignar Area", message: `Cambiar Area ${order.codarea} -> ${value.codarea}?` }))) {
                                            selectArea(value)
                                        }
                                    }
                                    }
                                    getOptionLabel={(option) => `${option.nomarea}`}
                                    renderInput={(params) => <TextField {...params} required fullWidth label="Estatus" />}

                                />
                            </Grid>
                            <Grid item xs sm >
                                <Typography align="leght" variant="body1" mb={1} color="black" >
                                    Responsable:
                                </Typography>
                                <Autocomplete
                                    /* style={{ width: 210 }} */
                                    nullable={false}
                                    loading={loadingresponsable}
                                    options={responsables}
                                    size="small"
                                    value={currentresponsables}
                                    valueKey={"codusu"}
                                    onChange={async (_, value) => {
                                        if (value && (await getConfirmation({ title: "Asignar Responsable", message: `Cambiar Responsable a -> ${value.codusu}?` }))) {
                                            selectResponsable(value)
                                        }
                                    }
                                    }
                                    getOptionLabel={(option) => `${option.nombpers}`}
                                    renderInput={(params) => <TextField {...params} required fullWidth label="Estatus" />}
                                />
                            </Grid>
                        </Grid>

                    </Paper>
                    <Paper elevation={5} sx={{ p: 1.5, my: 1 }}>
                        <Grid container spacing={2} >

                            <Grid item xs={12} md={5.6}  >
                                <Typography align="leght" variant="body1" color="black" mb={1} >
                                    Clasificación
                                </Typography>
                                <Autocomplete
                                    fullWidth
                                    /* style={{ width: 210 }} */
                                    nullable={false}
                                    loading={loadingclase}
                                    options={clases}
                                    size="small"
                                    value={currentClases}
                                    valueKey={"codclase"}
                                    onChange={async (_, value) => {
                                        if (value && (await getConfirmation({ title: "Asignar Clase", message: `Cambiar Clase ${order.codclase} -> ${value.codclase}?` }))) {
                                            selectClase(value)
                                        }
                                    }
                                    }
                                    getOptionLabel={(option) => `${option.nombre}`}
                                    renderInput={(params) => <TextField {...params} required fullWidth label="Estatus" />}

                                />
                            </Grid>

                            <Grid item xs={6} md={2} mt={0}>
                                <FormControlLabel
                                    id="indpresupuesto"
                                    value="Top"
                                    control={<Checkbox
                                        checked={currentClases?.indpresupuesto === "S"} />}
                                    label="Maneja presupuesto"
                                    labelPlacement="top"
                                />
                            </Grid>
                            <Grid item xs={6} md={2.5} mt={0} >
                                <FormControlLabel
                                    id="indfechaentrega"
                                    value="Top"
                                    control={<Checkbox
                                        checked={currentClases?.indfechaentrega === "S"} />}
                                    label="Tiene fecha de entrega"
                                    labelPlacement="top"
                                />
                            </Grid>
                            <Grid item xs md={0.8}>
                                <Typography align="leght" variant="body1" color="black" mb={1} >
                                    Naturaleza:
                                </Typography>
                                <TextField
                                    required
                                    name="naturaleza"
                                    id="naturaleza"
                                    label="Estatus"
                                    value={currentClases?.naturaleza}
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </Grid>
                            <Grid item xs md={0.8}>
                                <Typography align="leght" variant="body1" color="black" mb={1} >
                                    Facturación:
                                </Typography>
                                <TextField
                                    required
                                    name="modofacturacion"
                                    id="modofacturacion"
                                    label="Fact."
                                    value={currentClases?.modofacturacion}
                                    /*  valueKey={} */

                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </Paper>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={4} md={4}>
                            <Paper elevation={5} sx={{ p: 1 }}>
                                <Typography align="leght" variant="body1" color="black" >
                                    Fecha de Inicio
                                </Typography>
                                <EditableTextField
                                    initialValue={order.fecinivig}
                                    name="fecinivig"
                                    save={saveField}
                                    type={FIELD_TYPES.DATE}
                                    placeholder="Fecha Inicio"
                                />
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                            <Paper elevation={5} sx={{ p: 1 }}>
                                <Typography align="leght" variant="body1" color="black" >
                                    Fecha Fin
                                </Typography>
                                <EditableTextField
                                    initialValue={order.fecfinvig}
                                    name="fecfinvig"
                                    save={saveField}
                                    type={FIELD_TYPES.DATE}
                                    placeholder="Fecha Inicio"
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm>
                            <Paper elevation={5} sx={{ p: 1 }}>
                                <Typography align="leght" variant="body1" color="black" >
                                    Porcentaje Autorizado:
                                </Typography>
                                <EditableTextField
                                    initialValue={order.porcejecucionautorizado}
                                    name="porcejecucionautorizado"
                                    save={saveField}
                                    type={FIELD_TYPES.NUMBER}
                                    placeholder="Porcentaje"
                                />
                            </Paper>
                        </Grid>

                    </Grid>

                </div>}
                {currentTab === 'horas' && <div>

                    <Stack container>
                    <Box
                sx={{
                  //height: 25,
                  backgroundColor: "#BBDEFB",
     /*              '&:hover': {
                    backgroundColor: '"#BBDEFB"',
                   
                  }, */
                }}
              >
<Item3>

              <b> Horas (Estimado/Real) </b>
</Item3>
            </Box>
                       {/* <FormLabel> <b> Horas (Estimado/Real) </b></FormLabel> */}
                        <Divider />
                    </Stack>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center" spacing={2} mt={1} mb={3}>
                        <Grid item md={3}>
                            <Paper elevation={5}>
                                <Stack container>
                                    <Item2>
                                        Hora Original <Box sx={{ p: 1 }}>
                                            <EditableTextField
                                                initialValue={order.pptohhorg}
                                                name="pptohhorg"
                                                save={saveField}
                                                type={FIELD_TYPES.NUMBER}
                                                placeholder="Hora original"
                                            />
                                        </Box>
                                    </Item2>
                                </Stack>

                            </Paper>
                        </Grid>
                        <Grid item md={3}>
                            <Paper elevation={5}>
                                <Stack container>
                                    <Item2>
                                        Hora Actual: <Box sx={{ p: 1 }}>
                                            <EditableTextField
                                                initialValue={order.pptohhact}
                                                name="pptohhact"
                                                save={saveField}
                                                type={FIELD_TYPES.NUMBER}
                                                placeholder="Hora actual"
                                            />
                                        </Box>
                                    </Item2>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item md={3}>
                            <Paper elevation={5}>

                                <Stack container>
                                    <Item2>
                                        Hora Real: <Box sx={{ p: 1 }}>
                                            <EditableTextField
                                                initialValue={order.hhreal}
                                                name="hhreal"
                                                save={saveField}
                                                type={FIELD_TYPES.NUMBER}
                                                placeholder="Hora real"
                                            />
                                        </Box>
                                    </Item2>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Stack container>
                    <Box
                sx={{
                  //height: 25,
                  backgroundColor: "#BBDEFB",
     /*              '&:hover': {
                    backgroundColor: '"#BBDEFB"',
                   
                  }, */
                }}
              >
<Item3>

              <b> Horas Sub-Contratadas </b>
</Item3>
            </Box>
                        
                        {/* <FormLabel> <b> Horas Sub-Contratadas </b></FormLabel> */}
                        <Divider />
                    </Stack>
                    <Grid container direction="row"
                    justifyContent="center"                       
                        alignItems="center" spacing={2} mt={1}>
                        <Grid item md={3}>

                            <Paper elevation={5}>

                                <Stack container>
                                    <Item2>
                                        Hora Original: <Box sx={{p:1}}>
                                            <EditableTextField
                                                initialValue={order.pptohhorgext}
                                                name="pptohhorgext"
                                                save={saveField}
                                                type={FIELD_TYPES.NUMBER}
                                                placeholder="Hora original"
                                            />
                                        </Box>
                                    </Item2>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item md={3}>
                            <Paper elevation={5}>

                                <Stack container>
                                    <Item2>
                                        Hora Actual: <Box sx={{p:1}}>
                                            <EditableTextField
                                                initialValue={order.pptohhactext}
                                                name="pptohhactext"
                                                save={saveField}
                                                type={FIELD_TYPES.NUMBER}
                                                placeholder="Hora actual"
                                            />
                                        </Box>
                                    </Item2>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>}
                {currentTab === 'descripcion' && <div>
                    <Stack container>
                    <Box
                sx={{
                  //height: 25,
                  backgroundColor: "#BBDEFB",
     /*              '&:hover': {
                    backgroundColor: '"#BBDEFB"',
                   
                  }, */
                  mb: 1
                }}
              >
<Item3>

              <b> Descripción </b>
</Item3>
            </Box>
                        {/* <FormLabel> <b> Descripción </b></FormLabel> */}
                        <Divider />
                    </Stack>
                    <Paper elevation={5}>
                    <Grid container spacing={2} mt={1.5}>

                        <Grid item xs ml={1}>
                            <EditableTextField
                                initialValue={order.descot}
                                name="descot"
                                save={saveField}
                                type={FIELD_TYPES.TEXTAREA}
                                placeholder="Descripción"
                            />
                        </Grid>
                    
                    
                    
                        </Grid>
                        </Paper>
                        </div>}
                {currentTab === 'actividades' && <ActivityOtList order={order} />}
                </ConditionalWrapper>
            </Container>

        </>
    );
}
