import React, { useState, useEffect, useCallback }  from 'react';
import { BaseDialog, BaseNewForm } from '../../layout'
import { useRequests, getErrorMsg, useCustomSnackbar, FIELD_TYPES } from "../../../helpers";


export const CauseDialog = React.memo(({changeStatus, open, handleClose}) => {
  const [causes, setCauses] = useState([]);
  const [cause, setCause] = useState(null);
  const { eSnack } = useCustomSnackbar();
  const { get, controller } = useRequests();
  const [loadingAC, setloadingAC] = useState(false)

  const getCauses = useCallback(async () => {
    try {
      setloadingAC(true)
      var response = await get("/tickets/causes");
      if (response?.ok) {
        const data = await response.json();
        setCauses(data);
      } else {
        eSnack(await getErrorMsg(response), getCauses);
        console.error("Error Retrieving Causes");
      }
    } catch (e) {
      eSnack("Error inesperado cargando causas", getCauses);
      console.error("Retrieving Causes:", e);
    }finally {setloadingAC(false)}
  }, [get, eSnack]);

  const sendCause = useCallback( e =>{
    e.preventDefault()
    changeStatus("ENV", cause?.codcausa)
  },[changeStatus, cause])

  useEffect(()=>{
    if(!causes.length && open){
      getCauses()
    }
  },[open, causes, getCauses])

  useEffect(() => () => controller.abort(), [controller])

  return <BaseDialog
    dialogOpen={open}
    handleClose={handleClose}
    title="Seleccionar Causa"
    confirmText={"Cambiar Estado"}
    cancelText={"Cerrar"}
    formId={"cause_form"}
    confirm
  >
    <BaseNewForm
    fields={{
      cause:{
        title:"Causa",
        id:"cause",
        type:FIELD_TYPES.AUTOCOMPLETE,
        options:causes,
        setValue:setCause,
        valueKey:"codcausa",
        nullable: false,
        getLabel: option => `${option.codcausa} - ${option.desccausa} - ${option.respcausa}`,
        loading:loadingAC,
        extraProps:{
          disableClearable: true,
        }
      }
    }}
      dialog={true}
      formId={"cause_form"}
      submit={sendCause}
    />
  </BaseDialog>
})
