import React, { useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useRequests, getErrorMsg, useCustomSnackbar, useBackdrop, useCurrentUser } from '../../helpers'
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from "react-router-dom"
import Link from '@mui/material/Link';
import { Box, CssBaseline, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Background from '../../images/1.jpg'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright ©'}
      {new Date().getFullYear()}{' '}
      <Link color="inherit" href="https://www.kentronsistemas.com/">
        KENTRON
      </Link>
      
      {'.'}
    </Typography>
  );  
}

export const SignIn = ({ login }) => {
  const [email, setEmail] = useState("")
  const [emailError/*, setEmailError*/] = useState("")
  const [password, setPassword] = useState("")
  const [passwordError/*, setPasswordError*/] = useState("")
  const { eSnack, sSnack } = useCustomSnackbar()
  const { form, controller } = useRequests()
  const { show, off } = useBackdrop()
  const currentUser = useCurrentUser()


  const handleEmail = e => {
    const value = e.target.value
    setEmail(value)
  }

  const handlePassword = e => {
    const value = e.target.value
    setPassword(value)
  } 

  const handleSubmit = async e => {
    e.preventDefault()
    if (!!emailError || !!passwordError) return
    const data = new FormData(e.target)
    try {
      show( `Bienvenido`)
      /* 😀 */
      const request = await form('/login', data)
      if (request?.ok) {
        const response = await request.json()
        login(response.token, response.email)
        sSnack("Conectado")
      } else {
        eSnack(await getErrorMsg(request))
        console.log("Login error");
      }
    } catch (exception) {
      eSnack("Error inesperado")
      console.log(exception);
    } finally {
      off()
    }
  }
  useEffect(() => () => controller.abort(), [controller])
  return (
    <ThemeProvider>
      <Grid container component="main" sx={{ height: '91vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(' + Background + ')',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            Iniciar sesión
            </Typography>
            <form  onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                id="email"
                label="Correo Electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                error={!!emailError}
                helperText={emailError}
                fullWidth

                value={email.toLowerCase()}
                onChange={handleEmail}

              />
              <TextField
                margin="normal"
                id="password"
                autoComplete="current-password"
                error={!!passwordError}
                label="Contraseña"
                name="password"
                helperText={passwordError}
                fullWidth
                value={password}
                onChange={handlePassword}
                type="password"
                required
              />

              <Button
                sx={{ mt: 3, mb: 2 }}
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                
              >
                Iniciar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link underline="none" component={RouterLink} to="/recover_password">
                    ¿Olvido su Password?
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
