import React, {useCallback, useEffect} from "react"
import { BaseDialog, BaseNewForm} from '../../layout'
import {useRequests, useCustomSnackbar, getErrorMsg, useBackdrop, FIELD_TYPES} from "../../../helpers"

export const CreateModuleDialog = React.memo( ({ currentSystem="", handleClose, dialogOpen }) => {
  const {form, controller} = useRequests()
  const {eSnack, sSnack} = useCustomSnackbar()
  const {show, off} = useBackdrop ()
  const formId = "create_system_module"
  const clientUseOptions = [ { name:"Si", value:"S" }, { name:"No", value:"N" } ]

  const createModule = useCallback(async e => {
    e.preventDefault()
    show('Creando Módulo')
    const data = new FormData(e.target)
    try {
      const request = await form('/administration/system_modules', data)
      if(request?.ok){
        sSnack("Módulo Creado")
        handleClose()
      }else{
        eSnack(await getErrorMsg(request))
      }
    } catch (e) {
      eSnack("Error inesperado creando módulo")
      console.log("Error", e);
    } finally { off() }
  },[show, sSnack, eSnack, off, form, handleClose])



  useEffect(() => () => controller.abort(), [controller])

  return (
    <BaseDialog
      dialogOpen={dialogOpen}
      handleClose={handleClose}
      title={`Crear Modulo Para ${currentSystem}`}
      cancelText="Cancelar"
      width="sm"
      formId={formId}
      confirmText="Crear"
      confirm
    >
      <BaseNewForm
        fields={[
          {
            title:"Codigo",
            name:"system_module[codmodulo]",
            id:"code",
            type:FIELD_TYPES.TEXT,
            value:"",
            required:true,
            upcaseValue:true,
            maxLength:10
          },
          {
            title:"Nombre",
            name:"system_module[nommodulo]",
            id:"name",
            type:FIELD_TYPES.TEXT,
            value:"",
            required:true,
            maxLength:35
          },
          {
            title:"Sistema",
            name:"system_module[sistema]",
            id:"system",
            type:FIELD_TYPES.HIDDEN,
            value:currentSystem,
            required:true
          },
          {
            title:"Uso Cliente?",
            name:"system_module[indusoclientes]",
            id:"indclientuse",
            nullable:false,
            type:FIELD_TYPES.AUTOCOMPLETE,
            options:clientUseOptions,
            required:true,
            value:clientUseOptions[0]
          },
        ]}
        dialog={true}
        formId={formId}
        submit={createModule}
      />
    </BaseDialog>
  )
})
