import React, {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import {useRequests, getErrorMsg, useCustomSnackbar, useBackdrop} from '../../helpers'
import Paper from '@mui/material/Paper';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1%',
    width: '50vw'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '90%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  textField:{
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const VERIFICATION_STATES = Object.freeze({
  LOADING: "loading",
  VERIFIED: "verified",
  FAILED: "failed"
})

export const ChangeForgottenPassword = ({ match, history }) => {
  const {eSnack, sSnack} = useCustomSnackbar()
  const {post, form, controller} = useRequests()
  const classes = useStyles();
  const {show, off} = useBackdrop()
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("")
  const [verificationState, setVerificationState] = useState(VERIFICATION_STATES.LOADING)
  const handlePassword = e =>{
    const value = e.target.value
    const id = e.target.id
    switch(id){
      case "new_password":
        setNewPassword(value)
        value === newPasswordConfirmation ? setPasswordConfirmationError(false) : setPasswordConfirmationError("Confirmar password debe coincidir con Password")
        break
      case "new_password_confirmation":
        setNewPasswordConfirmation(value)
        switch(true){
          case value.length < 6:
            setPasswordConfirmationError("Password debe tener al menos 6 caracteres")
            break
          case value !== newPassword:
            setPasswordConfirmationError("Confirmar password debe coincidir con Password")
            break
          default:
           setPasswordConfirmationError(false)
           break
        }
        break
      default:
        break
    }
  }

  useEffect(()=>{
    const verifyLink = async () =>{
      show("Verificando Link")
      try{
        const request = await post('/verify_forgot_password_token', {token: match.params.token})
        if(request.ok){
          setVerificationState(VERIFICATION_STATES.VERIFIED)
          sSnack("Link Validado")
        }else{
          setVerificationState(VERIFICATION_STATES.FAILED)
          eSnack(await getErrorMsg(request))
        }
      }catch(e){
        setVerificationState(VERIFICATION_STATES.FAILED)
        eSnack("Error inesperado verificando link")
        console.log(e);
      }finally{off()}
    }
    verifyLink()
  }, [show, off, post, match.params.token, eSnack, sSnack])

  useEffect(() => () => controller.abort(), [controller])

  const handleSubmit = async e => {
    e.preventDefault()
    if(!!passwordConfirmationError) return
    const data = new FormData(e.target)
    data.append('token', match.params.token)
    try{
      show("Cambiando Password")
      const request = await form('/change_forgotten_password', data)
      if(request?.ok){
        sSnack("Password Cambiado.")
        history.push(`/sign_in`)
      }else{
        eSnack(await getErrorMsg(request))
      }
    }catch(exception){
      eSnack("Error inesperado cambiando password")
      console.log("Error changing Password", exception);
    }finally{off()}
  }

  const showContent = () => {
    switch(verificationState){
      case VERIFICATION_STATES.LOADING:
        return null
      case VERIFICATION_STATES.VERIFIED:
        return(
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              className={classes.textField}
              id="new_password"
              label="Nuevo Password"
              name="new_password"
              fullWidth

              value={newPassword}
              onChange={handlePassword}
              type="password"
              autoComplete="new-password"
              required
            />
            <TextField
              id="new_password_confirmation"
              className={classes.textField}
              error={!!passwordConfirmationError}
              helperText={passwordConfirmationError}
              label="Confirmar Nuevo Password"
              name="new_password_confirmation"
              fullWidth

              value={newPasswordConfirmation}
              onChange={handlePassword}
              type='password'
              autoComplete="new-password"
              required
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              cambiar
            </Button>
          </form>
        )
      case VERIFICATION_STATES.FAILED:
        return <div>Failed to verify Link</div>
      default:
        return <div>Invalid State</div>
    }
  }
  return (
    <Paper className={classes.paper} elevation={2}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Cambiar Password
      </Typography>
      {showContent()}
    </Paper>
  );
}
