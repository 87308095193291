import React, {useState, useCallback, useEffect} from "react"
import {useRequests, getErrorMsg, useCustomSnackbar, useBackdrop, FIELD_TYPES} from '../../helpers';
import { BaseDialog, BaseNewForm, FORM_SUBMIT_TYPES } from '../layout'

export const NewIssueDialog = ({open, handleClose, issueTitle="", issueDescription="", issuableType, issuableId, reload = ()=>{}}) => {
  const [formId] = useState("jira_form")
  const [loadingProjects, setLoadingProjects] = useState(false)
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState(0)
  const {get, post} = useRequests()
  const {eSnack, sSnack} = useCustomSnackbar()
  const {show, off} = useBackdrop()

  const getJiraProjects = useCallback(async ()=>{
    try{
      setLoadingProjects(true)
      const response = await get(`/jira/projects`)
      if(response?.ok){
        const data = await response.json()
        setProjects(data)
      }else{
        eSnack(await getErrorMsg(response), getJiraProjects)
        console.log("error getting Jira options");
      }
    }catch(e){
      eSnack("Error inesperado obteniendo opciones de Jira")
      console.log("Error getting Jira Options", e);
    }finally{ setLoadingProjects(false) }
  },[get,eSnack])

  const onProjectSelected = value => {
    const newSelected = projects.findIndex( project => JSON.stringify(project) === JSON.stringify(value))
    setSelectedProject(newSelected)
  }

  const createIssue = useCallback( async (data)=>{
    show('Creando Issue en Jira')
    data.issuable_id = issuableId
    data.issuable_type = issuableType
    try {
      const request = await post('/jira/issues',{issue:data})
      if(request?.ok){
        handleClose()
        reload()
        sSnack("Issue Creado")
      }else{
        eSnack(await getErrorMsg(request))
        console.log("Error creating issue on Jira");
      }
    } catch (e) {
      eSnack("Error inesperado creando issue en Jira")
      console.log("Error creating issue on Jira", e);
    } finally { off() }
  },[sSnack, eSnack, show, off, post, handleClose, issuableId, issuableType, reload])

  useEffect(()=>{
    if(open && !projects.length){
      getJiraProjects()
    }
  },[getJiraProjects, open, projects.length])



  return <BaseDialog
    dialogOpen={open}
    handleClose={handleClose}
    title="Nuevo Issue"
    confirmText={"Crear Issue?"}
    cancelText={"Cerrar"}
    formId={formId}
    confirm
    width="sm"
  >
    <BaseNewForm
      fields={{
        project_key: {
          title:"Projecto",
          id:"project",
          type:FIELD_TYPES.AUTOCOMPLETE,
          required:true,
          options:projects,
          value:projects[selectedProject],
          loading:loadingProjects,
          nullable:false,
          onChange: onProjectSelected,
          extraProps:{
            disableClearable:true
          }
        },
        issue_type_id:{
          title:"Tipo de Issue",
          id:"issueType",
          type:FIELD_TYPES.AUTOCOMPLETE,
          required:true,
          nullable:false,
          options:projects[selectedProject]?.issueTypes,
          value:projects[selectedProject]?.issueTypes[0],
          loading:loadingProjects,
          extraProps:{
            disableClearable:true
          }
        },
        summary:{
          title:"Titulo",
          id:"title",
          required:true,
          type:FIELD_TYPES.TEXT,
          value:issueTitle
        },
        description:{
          title:"Descripción",
          id:"name",
          required:true,
          type:FIELD_TYPES.TEXT,
          value:issueDescription
        }
      }}
      dialog={true}
      formId={formId}
      submit={createIssue}
      submitType={FORM_SUBMIT_TYPES.JSON}
    />
  </BaseDialog>
}
