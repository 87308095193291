import React, {  useState, useCallback, useEffect,  } from "react"
import { BaseDialog } from "../../layout";
import { Divider, Grid ,  Stack,} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useRequests, useCustomSnackbar, getErrorMsg, useBackdrop ,formatDate} from "../../../helpers"
import TextField from '@mui/material/TextField';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2),
        margin: 'auto'
    },
    paper: {

        padding: theme.spacing(2),
        margin: 'auto',
        /*         maxWidth: 950, */
    },
    tab: {
        "& .Mui-selected": {
            /* color: "#ff8f00", */
            fontSize: "17px"
        }
    }
}));


export const ModalEncuesta = ({reload, open, handleClose, currentIdInc,currentCodcli,currentIdEncuesta,currentUsures }) => {
   
    const { get ,patch} = useRequests()
   // const [preguntas, setPreguntas] = useState([])
    //const [respuestas, setRespuestas] = useState([])
    
    const {eSnack,sSnack} = useCustomSnackbar()
    const { show, off } = useBackdrop()

    
    const [resp1, setResPreg1] = useState("")
    const [resp2, setResPreg2] = useState("")
    const [resp3, setResPreg3] = useState("")
    const [resp4, setResPreg4] = useState("")
    const [resp5, setResPreg5] = useState("")

    //const [pregunta, setPregunta] = useState("")
    //const [respuesta, setRespuesta] = useState("")
    const [ticket, setTicket] = useState([])
    const [fecha, setFecha] = useState("")
    const [status, setSatus] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const [codcli, setCodcli] = useState("")
    const [date] = useState(new Date().toISOString().split("T")[0]);
    const [comentario, setComentario] = useState("")

    const getTicket = useCallback( async () =>{
        try{
        
          const response = await get(`/administration/encuestas/ticket`, {
            idinc: currentIdInc,
          })
          if(response?.ok){
            const data = await response.json()
            setTicket(data)
          }else{
            eSnack(await getErrorMsg(response), getTicket)
          }
        }catch(e){
          eSnack("Error inesperado obteniendo estados", getTicket)
       }finally{}
      },[get, eSnack,  currentIdInc])
    
      useEffect(()=>{
          if (open){
            getTicket()
          }
     },[getTicket,open])

     useEffect(()=>{
        if( open && JSON.stringify(ticket).length !== 0){
           setFecha(ticket.fecinginc)
           setSatus(ticket.stsinc)
           setDescripcion(ticket.problema)
           setCodcli(ticket.codcli)
        }
         },[ticket,open])
   
         const fechaTicket = () => {
            if( JSON.stringify(ticket).length !== 0){
              return (
                <>
                   {formatDate(new Date(fecha))} 
                </>
        
              )
            }
          }
          const statusTicket = () => {
            if( JSON.stringify(ticket).length !== 0){
              
              if(status==="RES"){
                return(
                  <>{"Respondido"}</>
                  )
              }else if (status==="NOT"){
                return(
                  <>{"Notificado"}</>
                  )
              }else if (status==="CER"){
                return(
                  <>{"Cerrado"}</>
                  )
              }    
              else if (status==="ENV"){
                return(
                  <>{"Enviado"}</>
                  )
              }      
            }
          }
          const descripcionTicket = () => {
            if( JSON.stringify(ticket).length !== 0){
              return (
                <>
                   {descripcion} 
                </>
        
              )
            }
          }
          const codcliTicket = () => {
            if( JSON.stringify(ticket).length !== 0){
              return (
                <>
                   {codcli} 
                </>
        
              )
            }
          }

  /*  const getPreguntas = useCallback(async () => {
    try {
      const response = await get(`/administration/encuestas/preguntas`, {
  
      })
      if (response?.ok) {
        const preguntas = await response.json()
        setPreguntas(preguntas)
      } else {

        eSnack(await getErrorMsg(response), getPreguntas)
        console.log("error getting ADR");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo ADR")
      console.log("Error getting ADR", e);
    } finally { }
  }, [get, eSnack])

  useEffect(() => {
    getPreguntas()
  }, [getPreguntas])

  const getRespuestas = useCallback(async () => {
    try {
      const response = await get(`/administration/encuestas/respuestas`, {
  
      })
      if (response?.ok) {
        const respuestas = await response.json()
        setRespuestas(respuestas)
      } else {

        eSnack(await getErrorMsg(response), getRespuestas)
        console.log("error getting ADR");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo ADR")
      console.log("Error getting ADR", e);
    } finally { }
  }, [get, eSnack])

  useEffect(() => {
    getRespuestas()
  }, [getRespuestas])
*/

    const classes = useStyles();
    function BpRadio1(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<span>👍</span>}
                icon={<span>😊</span>}
                {...props}
            />
        );
    }
    function BpRadio2(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<span>👍</span>}
                icon={<span>😃</span>}
                {...props}
            />
        );
    }
    function BpRadio3(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<span>👎</span>}
                icon={<span>😐</span>}
                {...props}
            />
        );
    }
    function BpRadio4(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<span>👎</span>}
                icon={<span>😔</span>}
                {...props}
            />
        );
    }

    const updateEncuesta = async e => {
        if (!resp1) return eSnack("Debe responder todas las preguntas ")
        if (!resp2) return eSnack("Debe responder todas las preguntas ")
        if (!resp3) return eSnack("Debe responder todas las preguntas ")
        if (!resp4) return eSnack("Debe responder todas las preguntas ")
        if (!resp5) return eSnack("Debe responder todas las preguntas ")
        e.preventDefault()
       
        show('Enviando encuesta')
        const data = {
          respregunta1: resp1,
          respregunta2: resp2,
          respregunta3: resp3,
          respregunta4: resp4,
          respregunta5: resp5,
          comentario:   comentario,
        }
        try {
          const request = await patch(`/administration/encuestas/update_encuesta`, {
            idencuesta:currentIdEncuesta,
            idinc: Math.trunc(currentIdInc),
            enc:data
          })
          if (request?.ok) {
            sSnack("Encuesta respondida")
            handleClose()
            reload()
          } else {
            eSnack(await getErrorMsg(request))
          }
        } catch (e) {
          console.log(e)
          eSnack("Error inesperado respondiendo la encuesta")
        } finally {
          off();
        }
      }

  
    
      return (
    
    <BaseDialog
        dialogOpen={open}
        handleClose={handleClose}
        title="Responder Encuesta"
        confirmText={"Enviar"}
        cancelText={"Cerrar"}
        width="md"
        confirm={updateEncuesta}
    >

    <form onSubmit={updateEncuesta} className={classes.form}>
  
    <Stack container >Información Básica del ticket</Stack>
    <Divider/>
    <Grid container spacing={1} mb={2}>

                <Grid item md={3}>
                    <FormLabel>ID Incidente: {Math.trunc(currentIdInc)}</FormLabel>
                </Grid>
                <Grid item md={3}>
                    <FormLabel>Descripción Incidente: {descripcionTicket()}</FormLabel>
                </Grid>
                <Grid item md={3}>
                    <FormLabel>Fecha Incidente: {fechaTicket()}</FormLabel>
                </Grid>
                <Grid item md={3}>
                    <FormLabel>Estatus: {statusTicket()}</FormLabel>
                </Grid>
               <Grid item md={3}>
                    <FormLabel>Cliente: {codcliTicket()}</FormLabel>
                </Grid>
            </Grid><br/>
            <Stack container >Encuesta de Satisfacción del usuario</Stack>
            <Divider/>
            <Grid container spacing={1} mb={2}>
            
                <Grid item md={4}>
                    <FormLabel>Persona Encuestada: {currentUsures}</FormLabel>
                </Grid>
                
                <Grid item md={4}>
                    <FormLabel>Fecha Encuesta:  {formatDate(new Date(date))}</FormLabel>
                </Grid>
            </Grid>

            <br/>
            <Grid container spacing={0} ml={3}>
                <Grid item lg={20} >1- ¿Mi solicitud de asistencia fue completamente atendida por el personal de KENTRON?
                <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel  onChange={e => setResPreg1(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel  onChange={e => setResPreg1(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel  onChange={e => setResPreg1(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel  onChange={e => setResPreg1(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={0}>
            <Grid item lg={20} ml={3}>2- ¿El tiempo que tomó atender mi solicitud fue adecuado?
            <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel onChange={e => setResPreg2(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg2(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg2(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel onChange={e => setResPreg2(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>         <br/><br/>
            <Grid container spacing={0}>
            <Grid item lg={20} ml={3} >3- ¿La solución a mi solicitud fue satisfactoria?
            <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel onChange={e => setResPreg3(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg3(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg3(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel onChange={e => setResPreg3(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>         <br/><br/>
            <Grid container spacing={0}>
            <Grid item lg={20} ml={3}>4- ¿La atención por parte del personal de KENTRON fue amable y eficiente?
            <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel onChange={e => setResPreg4(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg4(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg4(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel onChange={e => setResPreg4(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid><br/><br/>
            <Grid container spacing={0}>
            <Grid item lg={20} ml={3}>5- ¿Recibí información adecuada durante el proceso de asistencia?
            <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel onChange={e => setResPreg5(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg5(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg5(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel onChange={e => setResPreg5(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <br/><br/>
            <Stack container >Comentarios</Stack>
            <Divider/><br/>
              <TextField
                hiddenLabel
                size='small'
                fullWidth
                name="comentario"
                id="comentario"
                onChange={e => setComentario(e.target.value)}
                inputProps={{
                  maxLength: 3500,
                }}
                placeholder="Ingresa tu comentario aqui..."
              />
    </form>


    </BaseDialog>
    )
}
