import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const coerce = (val, min, max) => {
  return (val > min) ? ((val < max) ? val : max) : min;
}

export const displayDateTime = date => {

  return `${date.toLocaleDateString('es-VE', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', 'time':'numeric' })} ${date.toLocaleTimeString('es-VE')}`
}

export const parseRailsEnum = railsEnum => {
  if(railsEnum){
    const keys = Object.keys(railsEnum)
    const options = keys.map(key => { return {name:key, value:railsEnum[key]}})
    return options
  }
  return []
}
/*
  Details: Convertir querystring en un json
*/
export const useParsedURLParams = () => {
  try {
    const location = useLocation();
    const parsed = queryString.parse(location.search, true);
    return parsed
  }catch(e){
    console.log(e);
    return {}
  }
}

/*
  Details: Hacer merge profundo de objetos manteniendo las propiedades originales no encontradas en el objeto a unir ej let a = {animal: "cat", colors:{eyes:"blue", fur:"black"}}, let b = {colors:{fur:"gray"}}
    {...a, ...b} = "{\"animal\":\"cat\",\"colors\":{\"fur\":\"gray\"}}"
    mergeObjects(a,b) = "{\"animal\":\"cat\",\"colors\":{\"eyes\":\"blue\",\"fur\":\"gray\"}}"
  Parameters:
    current[OBJECT]: Json original al cual aplicar actualizaciones
    updates[OBJECT]: Json actualizado el cual contiene propiedades nuevas a utilizar
    options[OBJECT]:
      restricted[BOOL]: restringir si se desea o no que el objeto update cree propiedades que no existen actualmente en current por defecto se crean propiedades nuevas
*/

export const mergeObjects = (current, updates, options={restricted:false}) => {
  try {
    for (const key of Object.keys(updates)) {
      if(!(options.restricted && !current.hasOwnProperty(key))){
        if (!current.hasOwnProperty(key) || typeof updates[key] !== 'object'){
          current[key] = updates[key]
        }
        else mergeObjects(current[key], updates[key]);
      }
    }
    return current;
  }catch(e){
    console.log("Error in mergeObjects function", e);
    return {}
  }
}

export const formatDate= date => {

  return `${date.toLocaleDateString('es-VE', {
    timeZone:"UTC",
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  })}`
}

export const formatDateTime= date => {

  return `${date.toLocaleDateString('es-VE', {
    timeZone:"UTC",
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  })}
  ${date.toLocaleTimeString('es-VE')}`
}

export const sleep = async time =>{
  await new Promise(r => setTimeout(r, time))
}

export const formatCurrency = number =>{

  //return `${new Intl.NumberFormat().format(number)}`
  return `${new Intl.NumberFormat('de-DE').format(number)}`
  }