import React from 'react'
import {Route, Switch, Redirect} from "react-router-dom"
import {SignIn, RecoverPassword, ChangeForgottenPassword} from '.'
import '../../stylesheets/sessions.scss'
export const Sessions = props => {
  return (
/*     <div className="sessions-container">
      <div className="session-form-container">
        <div className="session-form"> */
        <div>
          <Switch>
            <Route path="/sign_in" render={() => <SignIn login={props.login} />}/>
            <Route path="/recover_password" render={() => <RecoverPassword />}/>
            <Route path="/new_password/:token" render={p => <ChangeForgottenPassword {...props} {...p}/>}/>
            <Redirect from="/" to="/sign_in"/>
          </Switch>
          </div>
/*         </div>
      </div>
    </div> */
  )
}
