import { DialogContentText, Divider, TextField, Grid } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react"
import { Autocomplete, BaseDialog } from '../../layout';

import { useRequests, getErrorMsg, useCustomSnackbar, useBackdrop, FIELD_TYPES } from "../../../helpers";
import '../../../stylesheets/adr.scss'

export const AssigAutoActivity = ({ open, handleClose, adr, reload }) => {

  const [loadingClass, setloadingClass] = useState(false)
  const [loadingAC, setloadingAC] = useState(false)
  const [date] = useState(new Date().toISOString().split("T")[0]);

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);

  const [descact, setDescact] = useState("");
  const [refact, setReferenciaOt] = useState("");
  const [originalHoras, setOriginalHoras] = useState("");
  const [actualHoras, setActualHoras] = useState("");
  const [realHoras, setRealHoras] = useState("");

  const { get, post } = useRequests()
  const { eSnack, sSnack, iSnack } = useCustomSnackbar()
  const { show, off } = useBackdrop()
  const [startDate, setStartDate] = useState("")
  const [finDate, setFinDate] = useState("")
  const [requeridaDate, setRequeridaDate] = useState("")

  const [nomact, setNomact] = useState("");
  const [clases, setclases] = useState([])
  const [clasesitas, setclasesitas] = useState(null)

  const getAssignables = useCallback(async () => {
    try {
      setloadingAC(true)
      var response = await get("/administration/adr/responsable");
      if (response?.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        eSnack(await getErrorMsg(response), getAssignables);
        console.error("Error Retrieving Assignables");
      }
    } catch (e) {
      eSnack("Error inesperado cargando usuarios assignables", getAssignables);
      console.error("Retrieving Assignables:", e);
    } finally { setloadingAC(false) }
  }, [get, eSnack]);

  useEffect(() => {
    if (open && !users.length)
      getAssignables()

  }, [getAssignables, open, users])

  useEffect(() => {
    getAssignables()
  }, [getAssignables])

  const assignationActivity = async e => {
    e.preventDefault()
    if (!nomact) return iSnack("Debe colocar un Nombre a la actividad")
    if (!user?.codusu) return iSnack("Debe colocar un Usuario a la actividad")
    if (!startDate) return iSnack("Debe colocar una fecha de inicio a la actividad")
    show("Asignando Actividad")
    try {
      const request = await post('/administration/adr/assign_activity', {

        nomact: nomact,
        refact: refact,
        usuresp: user?.codusu,
        codclase: clasesitas?.codclase,
        fecinivig: startDate,
        fecfinvig: finDate,
        fecreq: requeridaDate,
        pptoorghh: originalHoras,
        pptoacthh: actualHoras,
        hhreal: realHoras,
        descact: descact,

        id: {
          numadr: adr.numadr,
          codcli: adr.codcli,
          codproy: adr.codproy,
        }
      }
      )
      if (request?.ok) {
        sSnack("Actividad Asignada");
        reload();
        handleClose();
      } else {
        eSnack(await getErrorMsg(request));
      }
    } catch (e) {
      eSnack("Error inesperado asignando actividad");
      console.log("Error",);
    } finally {
      off();
    }
  }

  const getClass = useCallback(async () => {
    try {
      setloadingClass(true)
      const response = await get('/administration/adr/clases_act')
      if (response?.ok) {
        const data = await response.json()
        setclases(data)
      } else {
        eSnack(await getErrorMsg(response), getClass)
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo ordenes de trabajo", getClass)

    } finally { setloadingClass(false) }
  }, [get, eSnack])

  useEffect(() => {
    if (open && !clases.length) {
      getClass()
    }
  }, [getClass, open, clases])

  useEffect(() => {
    getClass()
  }, [getClass])

  const handleForm = e => {
    const { value, id } = e.target
    switch (id) {
      case 'refact':
        setReferenciaOt(value)
        break
      case 'descact':
        setDescact(value)
        break
      case 'fecinivig':
        setStartDate(value)
        break

      case 'fecfinvig':
        setFinDate(value)
        break
      case 'fecreq':
        setRequeridaDate(value)
        break

      case 'pptoorghh':
        setOriginalHoras(value)
        break
      case 'pptoacthh':
        setActualHoras(value)
        break
      case 'hhreal':
        setRealHoras(value)
        break
      default:
        break
    }
  }

  useEffect(() => {
      setNomact(`Desarrollo ADR#${adr.numadr} - ${adr.codcli}`)
  }, [adr])

  return <BaseDialog
    dialogOpen={open}
    handleClose={handleClose}
    title="Asignación automática de actividad"
    confirmText={"¿Asignar Actividad?"}
    cancelText={"Cerrar"}
    confirm={assignationActivity}
    width="sm"
  >

    <form onSubmit={assignationActivity}>
      <DialogContentText> Datos de la Actividad</DialogContentText>
      <Divider variant="middle" />
      <div className="grid3">
        <div> <br />
          <TextField
            required
            name="nomact"
            id="nomact"
            label="Nombre Actividad"
            value={`Desarrollo ADR#${adr.numadr} - ${adr.codcli}`}
            defaultValue={`Desarrollo ADR#${adr.numadr} - ${adr.codcli}`}
            fullWidth
            size="small"
            onChange={e => setNomact(e.target.value)}
          /></div>
        <div><br /><TextField
          required
          name="refact"
          id="refact"
          label="Referencia"

          value={refact}
          fullWidth
          size="small"
          onChange={handleForm}
        /></div>
        <div> <br /> <Autocomplete
          name="usuresp"
          id="usuresp"
          loading={loadingAC}
          options={users}
          size="small"
          value={user}
          setValue={setUser}
          valueKey={"codusu"}
          getOptionLabel={(option) => `${option.nombpers}`}
          renderInput={(params) => <TextField {...params} required fullWidth label="Responsable" />}
        /> </div>
        <div> <br /> <Autocomplete
          name="codclase"
          id="codclase"
          options={clases}
          size="small"
          value={clasesitas}
          setValue={setclasesitas}
          loading={loadingClass}
          valueKey={"codclase"}
          getOptionLabel={(option) => `${option.codclase} - ${option.nombclase}`}
          renderInput={(params) => <TextField {...params} required fullWidth label="Clases" />}
        /> </div>
      </div>
      <br />
      <DialogContentText> Fechas</DialogContentText>
      <Divider variant="middle" />
      <br />
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
          name="fecinivig"
          id="fecinivig"
          type={FIELD_TYPES.DATE}
          label="Inicio"
          value={startDate}
          size="small"
          onChange={handleForm}
          fullWidth
          InputProps={{ inputProps: { min: date } }}
          InputLabelProps={{
            shrink: FIELD_TYPES.DATE === "date" ? true : undefined
          }}
        />
        </Grid>
        <Grid item xs ={4}>
         <TextField
          name="fecfinvig"
          id="fecfinvig"
          type={FIELD_TYPES.DATE}
          label="Fin"
          value={finDate}
          title="Fecha inicio"
          size="small"
          onChange={handleForm}
          InputProps={{ inputProps: { min: startDate } }}
          fullWidth
          InputLabelProps={{
            shrink: FIELD_TYPES.DATE === "date" ? true : undefined
          }}
        />
        </Grid>
        <Grid item xs ={4}>
         <TextField
          name="fecreq"
          id="fecreq"
          type={FIELD_TYPES.DATE}
          label="Requerida"
          value={requeridaDate}
          title="Fecha inicio"
          size="small"
          onChange={handleForm}
          fullWidth
          InputLabelProps={{
            shrink: FIELD_TYPES.DATE === "date" ? true : undefined
          }}
        />
        </Grid>
      </Grid>
      <br />
      <DialogContentText> Horas(Estimado/Real)</DialogContentText>
      <Divider variant="middle" />
      <br />
      <div className="wrapper">
        <div ><TextField
          name="pptoorghh"
          id="pptoorghh"
          label="Original"
          type={FIELD_TYPES.NUMBER}
          size="small"
          value={originalHoras}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          //onChange={e => setOriginalHoras(e.target.value)}
          onChange={handleForm}
        /></div>
        <div > <TextField
          initialValue={adr.hhtotales}
          name="pptoacthh"
          id="pptoacthh"
          label="Actual"
          type={FIELD_TYPES.NUMBER}
          size="small"
          value={actualHoras}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          //onChange={e => setActualHoras(e.target.value)}
          onChange={handleForm}
        /></div>
        <div > <TextField
          id="hhreal"
          name="hhreal"
          label="Real"
          type={FIELD_TYPES.NUMBER}
          size="small"
          value={realHoras}
          InputLabelProps={{
            shrink: true,
          }}
          //onChange={e => setRealHoras(e.target.value)}
          onChange={handleForm}
          fullWidth
        /></div>
      </div>
      <br />
      <div>
        <TextField
          id="descact"
          label="Descripción"
          multiline
          value={descact}
          onChange={handleForm}
          fullWidth
        />
      </div>
    </form >
  </BaseDialog>
}
