import React, { useEffect, useState, useCallback ,useRef} from "react"
import { AppBar, Breadcrumbs, Stack, Toolbar, Typography,  Container, Tooltip,Grid} from "@mui/material"
import { IconButtonMenu } from "../../layout/basic/IconButtonMenu"
import { BaseTable as EncuestasTable, BaseTablePagination } from '../../layout'
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FIELD_TYPES, getErrorMsg, useCustomSnackbar, useDialogTools, useParsedURLParams, useRequests, useTableTools ,formatDate} from "../../../helpers";
import { BaseFilters } from "../../layout";
import QuizIcon from '@mui/icons-material/Quiz';
import {ModalEncuesta} from "./encuesta";
import {Filtro} from "./filtro";
import BarChartIcon from '@mui/icons-material/BarChart';
import Fab from '@mui/material/Fab';


export const QuizList = ({ match }) => {

  const [filtroOpen, setfiltroOpen] = useState(false);
  const { dialogOpen: EncuestaNew, openD: openEncuestaNew, closeD: closeEncuestaNew } = useDialogTools()
  const { dialogOpen: filtro, openD: openFiltro, closeD: closeFiltro } = useDialogTools()

  const toggle = () => setfiltroOpen(!filtroOpen)
  const { get } = useRequests()
  const [encuesta, setEncuesta] = useState([])
  const { page, rowsPerPage, total, setTotal, maxPage, setMaxPage, changePage, changeRows } = useTableTools()
  const [loadingTable, setLoadingTable] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState(useParsedURLParams())
  const { eSnack } = useCustomSnackbar()
 

  const defectsFilters = {
    idinc: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Ticket"
    },
   codcli: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Cliente"
    },
     status: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Status"
    },
    fechacreaciondesde: {
        type: FIELD_TYPES.DATE,
        value: "",
        name: "Fecha Creación Desde"
      },
      fechacreacionhasta: {
        type: FIELD_TYPES.DATE,
        value: "",
        name: "Fecha Creación Hasta"
      },
      fecharespondidadesde: {
        type: FIELD_TYPES.DATE,
        value: "",
        name: "Fecha Respuesta Desde"
      },
      fecharespondidahasta: {
        type: FIELD_TYPES.DATE,
        value: "",
        name: "Fecha Respuesta Hasta"
      },
    

  }

  const getEncuestas = useCallback(async () => {
    try {
      setLoadingTable(true)
      const response = await get('/administration/encuestas', {
        page: page + 1,
        rowsPerPage: rowsPerPage,
        ...appliedFilters
      })
      if (response?.ok) {
        const data = await response.json()
        setEncuesta(data.encuestaslist)
        setMaxPage(data.maxPage)
        setTotal(data.total)
      } else {
        eSnack(await getErrorMsg(response), getEncuestas)
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo encuestas")
    } finally { setLoadingTable(false) }
  }, [get, page, rowsPerPage, eSnack, setTotal, setMaxPage, appliedFilters,])

  useEffect(() => {
    getEncuestas()
  }, [getEncuestas])

  const applyFilters = filters => {
    changePage(null, 0)
    setAppliedFilters(filters)
  }
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      Encuestas
    </Typography>,
  ];



  const fechaCreacion = (row) => {
    if (row.fechacreacion === null) {
      return { content: row.fechacreacion };
    } else {
      return { content: formatDate(new Date(row.fechacreacion)) };
    }
  };

  const fechaRespuesta = (row) => {
    if (row.fecharespondida === null) {
      return { content: row.fecharespondida };
    } else {
      return { content: formatDate(new Date(row.fecharespondida)) };
    }
  };

  const responderEncuesta = (row) => {
    if (row.status === "Pendiente") {
        return (
            <Tooltip title="Responder Encuesta">
              <QuizIcon color="primary" onClick = { () => openEncuesta(row) }/>
            </Tooltip>
          )
        } 
  }


  const selectedRowIdInc = useRef(null)
  const selectedRowCliente= useRef(null)
  const selectedRowIdEncuesta= useRef(null)
  const selectedRowUsuRes= useRef(null)
  
  const openEncuesta = useCallback(row => {

    selectedRowIdInc.current = row.idinc
    selectedRowCliente.current = row.codcli
    selectedRowIdEncuesta.current = row.idencuesta
    selectedRowUsuRes.current = row.usures
   openEncuestaNew()
  },[openEncuestaNew])

  const closeEncuesta= useCallback(() => {
    closeEncuestaNew()

  },[closeEncuestaNew])



  const openFiltros = useCallback(row => {
    openFiltro()
  
  },[openFiltro])


  return (
    <>



       <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <IconButtonMenu
            open={filtroOpen}
            toggleMenu={toggle}
            size="small"
            color={"primary"}
            icon={<FilterListIcon />}
          >
            <BaseFilters filters={defectsFilters} applyFilters={applyFilters} />
          </IconButtonMenu>
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container className="base-container">
        <EncuestasTable
          loading={loadingTable}
          rows={encuesta}
          headers={["Ticket ", "Cliente", "Satatus", "Fecha Creación", "Fecha Respuesta","Acciones"]}
          collapsible={{
            visible: row => [
              { content: Math.trunc(row.idinc)},
              { content: row.codcli },
              { content: row.status },
                fechaCreacion(row),
                fechaRespuesta(row),
              { content: responderEncuesta(row), disableTooltip: true },
            ],

            collapsed: row => [
             
            ]
          }}
          title="Encuestas"
        >

          <Grid container spacing={1} mb={2}>

                <Grid item md={3}>
                <Tooltip title="Generar Estadisticas">
                    <Fab color="secondary">
                    < BarChartIcon onClick={() => openFiltros() }></BarChartIcon>
                    </Fab>
                </Tooltip>
               </Grid>
              <Grid item md={9}>
              <BaseTablePagination
                  page={page}
                  maxPage={maxPage}
                  rowsPerPage={rowsPerPage}
                  totalRows={total}
                  handlePageChange={changePage}
                  handleChangeRowsPerPage={changeRows}
                  //openDialog={openOtNew}
                />
                </Grid>  
        </Grid>




        </EncuestasTable>
      </Container>
      <ModalEncuesta  open={EncuestaNew} 
                      handleClose={closeEncuesta} 
                      currentIdEncuesta={selectedRowIdEncuesta.current}
                      currentIdInc={selectedRowIdInc.current}
                      currentCodcli={selectedRowCliente.current}
                      currentUsures={selectedRowUsuRes.current}
                      reload={getEncuestas}/>

     <Filtro openF={filtro} handleCloseF={closeFiltro} /> 

    </>
  )
}
