import React from 'react'
import '../../stylesheets/tickets.scss'
import {TicketList, NewTicket, TicketDetail} from '.'
import {Route, Switch, Redirect} from "react-router-dom"

export const Tickets = ({match}) =>{
  return (
  /*   <div className="tickets-container"> */
      <Switch>
        <Route path={`${match.path}/list`} component={TicketList}/>
        <Route path={`${match.path}/new`} component={NewTicket}/>
        <Route path={`${match.path}/:id`} component={TicketDetail}/>
        <Redirect from={`${match.path}/`} to={`${match.path}/list`}/>
      </Switch>
/*     </div> */
  )
}
