import { Box} from "@mui/material"


export const TitleBar = ({
  children,
  startIcon,
  ...restProps
}) => {
  return (
    <Box
      {...restProps}
      sx={{
          height: '38px',
          backgroundColor: "#008EEB",
          color: '#fff',
          borderRadius: 1,
          padding: '5px 20px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '17px',
          fontWeight: 550,
      }}>
      {startIcon ?
        <span style={{ marginRight: '20px' }}>
          {startIcon}
        </span>
      : null}
      {children}
    </Box>
  );
};