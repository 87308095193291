import React, {useState} from 'react';
import {
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip
}  from '@mui/material';
import BorderOuterRoundedIcon from '@mui/icons-material/BorderOuterRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {makeStyles} from '@mui/styles';
import {BaseDialog} from '../../layout'

const useStyles = makeStyles({
  columnsHolder: {
    display: "flex",
    flex:1,
    flexWrap: "wrap"
  }
});

export const ExportDialog = React.memo(({handleClose, exportOpen, exportTickets}) => {
  const classes = useStyles()
  const [columns, setColumns] = useState({
    fecinginc: {
      name: "Fecha Ingreso Incidente",
      selected: true
    },
    usuario: {
      name: "Usuario",
      selected: true
    },
    codmodulo: {
      name: "Id Modulo",
      selected: true
    },
    prioridad: {
      name: "Prioridad",
      selected: true
    },
    tipinc: {
      name: "Tipo Incidente",
      selected: true
    },
    problema: {
      name: "Problema",
      selected: true
    },
    respuesta: {
      name: "Respuesta",
      selected: true
    },
    stsinc: {
      name: "Estatus Incidente",
      selected: true
    },
    codcli: {
      name: "Cliente",
      selected: true
    },
    nombpers: {
      name: "Nombre Persona",
      selected: true
    }
  })

  const handleCheckBoxes = e => setColumns({...columns, [e.target.name]: {...columns[e.target.name], selected:e.target.checked}})

  const columnsToExport = () => Object.keys(columns).filter(column => columns[column].selected)

  const changeAll = checked => {
    const newSelected = {...columns}
    Object.keys(newSelected).forEach(column => newSelected[column].selected = checked)
    setColumns(newSelected)
  }

  return (
    <BaseDialog
      dialogOpen={exportOpen}
      handleClose={handleClose}
      title={"Exportar Tickets"}
      confirmText={"Exportar"}
      cancelText={"Cerrar"}
      confirm={()=> exportTickets(columnsToExport())}
      width="sm"
    >
      <Typography gutterBottom>
       <b>Selecciona las columnas a exportar</b> 
      </Typography>
      <div className={classes.columnsHolder}>
        {Object.keys(columns).map((column, index)=>{
          return <div key={`checkbox-${index}`} className="checkbox-holder">
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns[column].selected}
                  onChange={handleCheckBoxes}
                  name={column}
                  color="secondary"
                />
              }
              label={columns[column].name ? columns[column].name : column}
            />
          </div>
        })}
      </div>
      <Tooltip title="Limpiar todo">
        <IconButton onClick={()=>changeAll(false)} size="large">
          <BorderOuterRoundedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Seleccionar todo">
        <IconButton onClick={()=>changeAll(true)} size="large">
          <CheckCircleRoundedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <br/>
      <Typography color="secondary" variant="caption" gutterBottom>
        * Se exportaran todos los tickets filtrados actualmente
      </Typography>
    </BaseDialog>
  );
})
