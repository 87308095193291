import React, { useEffect } from 'react'
import { Paper, Grid, Divider, Stack, styled, Box, CardContent, Typography, Card, Container } from '@mui/material'
import { FIELD_TYPES, useRequests } from "../../../helpers"
import { EditableTextField } from '../../layout'
import '../../../stylesheets/adr.scss'
import { CardWithBar } from '../../layout/basic/CardWithBar'
import CardButton from '../../layout/basic/MDBox/CardButton'



export const AdrFechasFacturacion = React.memo(({ adr, saveField, Item2 }) => {

  const { controller } = useRequests()



  const analisis = () => {

    return (
      <Box mt={1}>
        <EditableTextField
          initialValue={adr.fecanalisis}
          name="fecanalisis"
          save={saveField}
          type={FIELD_TYPES.DATE}
          placeholder="Análisis"
        />
      </Box>
    )
  }
  const envio = () => {
    return (
      <Box mt={1}>

        <EditableTextField
          initialValue={adr.fecnotifcli}
          name="fecnotifcli"
          save={saveField}
          type={FIELD_TYPES.DATE}
          placeholder="Envío"
        />
      </Box>
    )
  }

  const vencimiento = () => {

    return (
      <Box mt={1}>

        <EditableTextField
          initialValue={adr.fecvenapr}
          name="fecvenapr"
          save={saveField}
          type={FIELD_TYPES.DATE}
          placeholder="Vencimiento"
        />
      </Box>
    )

  }

  const aprobacion = () => {

    return (
      <Box mt={1}>

        <EditableTextField
          initialValue={adr.fecaprobacion}
          name="fecaprobacion"
          save={saveField}
          type={FIELD_TYPES.DATE}
          placeholder="Aprobacion"
        />
      </Box>
    )
  }

  const mantenimiento = () => {

    return (
      <Box mt={1}>
        <EditableTextField
          initialValue={adr.fecentman}
          name="fecentman"
          save={saveField}
          type={FIELD_TYPES.DATE}
          placeholder="Mantenimiento"
        />
      </Box>

    )

  }

  const entrega = () => {

    return (
      <Box mt={1}>

        <EditableTextField
          initialValue={adr.fecentcli}
          name="fecentcli"
          save={saveField}
          type={FIELD_TYPES.DATE}
          placeholder="Entrega"
        />
      </Box>
    )

  }

  const cierre = () => {

    return (
      <Box mt={1}>

        <EditableTextField
          initialValue={adr.feccierre}
          name="feccierre"
          save={saveField}
          type={FIELD_TYPES.DATE}
          placeholder="Cierre"
        />
      </Box>
    )

  }

  const cerradoPor = () => {
    return (
      <Box mt={1}>

        <EditableTextField
          initialValue={adr.usucierre}
          name="usucierre"
          save={saveField}
          type={FIELD_TYPES.TEXT}
          placeholder="Cerrado por"

        />
      </Box>
    )
  }

  useEffect(() => () => controller.abort(), [controller])
  return (
    <>

      <CardWithBar title={"Fechas"}>
        <Container sx={{ marginY: 2 }}>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>


              <CardButton>
                <Typography variant="subtitle1">
                  Análisis: {analisis()}
                </Typography>
              </CardButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>

                <Typography variant="subtitle1">
                  Envío al cliente: {envio()}
                </Typography>
              </CardButton>

            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>
                <Typography variant="subtitle1">
                  Vencimiento: {vencimiento()}
                </Typography>
              </CardButton>

            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>

                <Typography variant="subtitle1">
                  Aprobación: {aprobacion()}
                </Typography>
              </CardButton>

            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>

                <Typography variant="subtitle1">
                  Entrega a Mantenimiento: {mantenimiento()}
                </Typography>
              </CardButton>

            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>

                <Typography variant="subtitle1">
                  Entrega al cliente: {entrega()}
                </Typography>
              </CardButton>

            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>

                <Typography variant="subtitle1">
                  Cierre: {cierre()}
                </Typography>
              </CardButton>

            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>

                <Typography variant="subtitle1">
                  Cerrado por: {cerradoPor()}
                </Typography>
              </CardButton>

            </Grid>
          </Grid>
        </Container>

      </CardWithBar>

    </>
  );

})
