import React, {useState, useEffect} from 'react'
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import {useRequests, getErrorMsg, useCustomSnackbar, useBackdrop} from '../../../helpers'
import { BaseDialog } from '../../layout';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '90%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  textField:{
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const ChangePasswordForm = ({ open, handleClose }) => {
  const {eSnack, sSnack} = useCustomSnackbar()
  const {post, controller} = useRequests()
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("")
  const {show, off} = useBackdrop()
  const handlePassword = e =>{
    const value = e.target.value
    const id = e.target.id
    switch(id){
      case "password":
        setPassword(value)
        break
      case "new_password":
        setNewPassword(value)
        value === newPasswordConfirmation ? setPasswordConfirmationError(false) : setPasswordConfirmationError("Confirmar password debe coincidir con Password")
        break
      case "new_password_confirmation":
        setNewPasswordConfirmation(value)
        switch(true){
          case value.length < 6:
            setPasswordConfirmationError("Password debe tener al menos 6 caracteres")
            break
          case value !== newPassword:
            setPasswordConfirmationError("Confirmar password debe coincidir con Password")
            break
          default:
           setPasswordConfirmationError(false)
           break
        }
        break
      default:
        break
    }
  }

  useEffect(() => () => controller.abort(), [controller])

  const handleSubmit = async e => {
    e.preventDefault()
    if(!!passwordConfirmationError) return
    const data = new FormData(e.target)
    try{
      show("Cambiando Password")
      const request = await post('/users/change_password', data)
      if(request?.ok){
        sSnack("Password Cambiado.")
      }else{
        eSnack(await getErrorMsg(request))
      }
    }catch(exception){
      eSnack("Error inesperado cambiando password")
      console.log("Error changing Password", exception);
    }finally{
      off()
    }
  }
  return (
    <BaseDialog
      dialogOpen={open}
      handleClose={handleClose}
      title={"Cambiar Password de Usuario"}
      confirmText={"Cambiar Password"}
      cancelText={"Cancelar"}
      confirm={handleSubmit}
      width="sm"
    >
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          className={classes.textField}
          label="Password Actual"
          name="password"
          id="password"
          fullWidth
          size='small'
          
          value={password}
          onChange={handlePassword}
          type="password"
          autoComplete="new-password"
          required
        />
        <TextField
          className={classes.textField}
          id="new_password"
          label="Nuevo Password"
          name="new_password"
          fullWidth
          size='small'
          
          value={newPassword}
          onChange={handlePassword}
          type="password"
          autoComplete="new-password"
          required
        />
        <TextField
          id="new_password_confirmation"
          className={classes.textField}
          error={!!passwordConfirmationError}
          helperText={passwordConfirmationError}
          label="Confirmar Nuevo Password"
          name="new_password_confirmation"
          fullWidth
          size='small'
          
          value={newPasswordConfirmation}
          onChange={handlePassword}
          type='password'
          autoComplete="new-password"
          required
        />
      </form>
    </BaseDialog>
  );
}
