import React, { useState, useCallback, useEffect, } from "react"
import { BaseDialog, Autocomplete } from "../../layout";
import { useRequests, useCustomSnackbar, getErrorMsg, useBackdrop, FIELD_TYPES } from "../../../helpers"
import {  Divider, FormLabel, Grid,   TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    margin: 'auto'
  },
  paper: {

    padding: theme.spacing(2),
    margin: 'auto',
    /*         maxWidth: 950, */
  },
  tab: {
    "& .Mui-selected": {
      /* color: "#ff8f00", */
      fontSize: "17px"
    }
  }
}));

export const ModalDefets = ({ open, handleClose,idactividad }) => {


  const classes = useStyles();
  const { get, post } = useRequests()
  const { eSnack, sSnack } = useCustomSnackbar()
  const { show, off } = useBackdrop() 
  const history = useHistory();


  const [descripcion, setDescripcion] = useState("")
  const [referencia, setReferencia] = useState("")
  const [version, setVersion] = useState("")
  const [tipoobjeto, setTipoobjeto] = useState("")
/*   const [actividad, setActividad] = useState("") */
  const [startDate, setStartDate] = useState("")
  const [date] = useState(new Date().toISOString().split("T")[0]);


  const [modulos, setModulos] = useState([])
  const [currentmodulos, setCurrentmodulos] = useState(null)
  const [loadingmodulos, setLoadingmodulos] = useState(false)

  const [objetos, setObjetos] = useState([])
  const [currentobjetos, setCurrentobjetos] = useState(null)
  const [loadingobjetos, setLoadingobjetos] = useState(false)


  const [tipos, setTipos] = useState([])
  const [tipo, setTipo] = useState("")
  const [loadingtipo, setLoadingtipo] = useState(false)

  const [importancias, setImportancias] = useState([])
  const [importancia, setImportancia] = useState(null)
  
  const [actividades, setActividades] = useState([])
  const [actividad, setActividad] = useState(null)
  const [loadingimportancias, setLoadingimportancias] = useState(false)
  const [loadingactividades, setLoadingactividades] = useState(false)


  const handleForm = e => {
    const { value, id } = e.target
    switch (id) {
      case 'referdef':
        setReferencia(value)
        break
      case 'descdefec':
        setDescripcion(value)
        break
      case 'versobjeto':
          setVersion(value)
          break
      case 'tipoobjeto':
          setTipoobjeto(value)
          break
      case 'actividad':
          setActividad(value)
          break
      case 'fecdetec':
        setStartDate(value)
            break
      default:
        break
    }
  }


  const createDefects = async e => {
    e.preventDefault()
    if (descripcion === "") return eSnack("Debe colocar una Descripcion")
    if (startDate === "") return eSnack("Debe colocar una Fecha")
    show('Creando Defecto')
    const data = {
      referdef: referencia,
      descdefec: descripcion,
      versobjeto: version,
      tipoobjeto: tipoobjeto,
      idactasig: actasig,//actividad?.idactividad,
      idactdetec: actdetect,//actividad?.idactividad,
      idactintrod: actividad?.idactividad,
      fecdetec: startDate,
      codmodulo: currentmodulos?.codmodulo,
      nomobjeto: currentobjetos?.nomobjeto,
      codtipdef: tipo?.codtipodef,
      codimpor: importancia?.codimpor,
    }
    try {
      const request = await post(`/administration/defects`, {
      defecto: data,
      })
      if (request?.ok) {
        sSnack("Defecto Creado")
        handleClose()
      const {iddefecto} = await request.json()
      history.push(`/administration/defects/${iddefecto}`)
     /*    reload()
        handleClose() */
        /* window.location.reload('') */
      } else {
        eSnack(await getErrorMsg(request))
      }
    } catch (e) {
      console.log(e)
      eSnack("Error inesperado creando Defecto")
    } finally {
      off();
    }
  }
 
  const getModulos = useCallback(async () => {
    try {
      setLoadingmodulos(true)
      const response = await get('/administration/defects/modulo')
      if (response?.ok) {
        const modulos = await response.json()
        setModulos(modulos)
      } else {
        eSnack(await getErrorMsg(response), getModulos)
      }
    } catch (e) {
      console.log(e);
    } finally { setLoadingmodulos(false) }
  }, [get, eSnack])

  useEffect(() => {
    if (open && !modulos.length)
      getModulos()
  }, [getModulos, open, modulos.length])



  const getImportancias = useCallback(async () => {
    try {
      setLoadingimportancias(true)
      const response = await get('/administration/defects/importancia')
      if (response?.ok) {
        const importancias = await response.json()
        setImportancias(importancias)
      } else {
        eSnack(await getErrorMsg(response), getImportancias)
      }
    } catch (e) {
      console.log(e);
    } finally { setLoadingimportancias(false) }
  }, [get, eSnack])

  useEffect(() => {
  if (open && !importancias.length)
    getImportancias()
  }, [getImportancias,open, importancias.length])

  const getActividad = useCallback(async () => {
    try {
      setLoadingactividades(true)
      const response = await get('/administration/defects/actividad')
      if (response?.ok) {
        const actividades = await response.json()
        setActividades(actividades)
      } else {
        eSnack(await getErrorMsg(response), getActividad)
      }
    } catch (e) {
      console.log(e);
    } finally { setLoadingactividades(false) }
  }, [get, eSnack])

  useEffect(() => {
  if (open && !actividades.length)
    getActividad()
  }, [getActividad,open, actividades.length])

  const getObjetos = useCallback(async () => {
    const selectedModulo = currentmodulos?.codmodulo
    try {
      setLoadingobjetos(true)
      const response = await get(`/administration/defects/objeto`, { selectedModulo: selectedModulo })
      if (response?.ok) {
        const objetos = await response.json()
        setObjetos(objetos)
      } else {
        eSnack(`objetos: ${await getErrorMsg(response)}`, getObjetos)
        console.log("Error getting objetos");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo objetos", getObjetos)
      console.log("Error getting objetos", e);
    } finally { setLoadingobjetos(false) }
  }, [eSnack, get, currentmodulos?.codmodulo])


  useEffect(() => {
    if (modulos) 
      getObjetos()
  }, [getObjetos, modulos])




//Tipos defecto#######################################################################

const getTipos = useCallback(async () => {
  try {
    setLoadingtipo(true)
    const response = await get('/administration/defects/tipo_defectos')
    if (response?.ok) {
      const data = await response.json()
      setTipos(data)
    } else {
      eSnack(await getErrorMsg(response), getTipos)
    }
  } catch (e) {
    console.log(e);
  } finally { setLoadingtipo(false) }
}, [get, eSnack])

useEffect(() => {
  if (open && !tipos.length) {
    getTipos()
  }
}, [getTipos, open, tipos.length])

const [actasig, setIdactasig] = useState("")
const [actdetect, setIdactdetec] = useState("")


useEffect(() => {
  if (!idactividad) {
    setIdactasig(actividad?.idactividad)
    setIdactdetec(actividad?.idactividad)
  }
  else{
    setIdactasig(idactividad)
    setIdactdetec(idactividad)
  }
}, [idactividad,actividad?.idactividad])

const actividadeslist = () => {
  if(!idactividad)
  return (
    <>
        <Autocomplete
            name="idactsig"
            id="idactsig"
            options={actividades}
            size="small"
            value={actividad}
            setValue={setActividad}
            valueKey={"idactividad"}
            loading={loadingactividades}
            onChange={e => setIdactasig(e.target.value)}
            getOptionLabel={(option) => `${option.idactividad} - ${option.nomactividad}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Actividad" />}
          />
    </>

  )

}

  return <BaseDialog
          dialogOpen={open}
          handleClose={handleClose}
          title="Nuevo Defecto"
          confirmText={"Crear Defecto"}
          cancelText={"Cerrar"}
          width="sm"
          confirm={createDefects}
        >
    <form onSubmit={createDefects} className={classes.form}>
      <FormLabel>Defecto</FormLabel>
      <Divider />
      <br/>
      <Grid container spacing={2}>
      <Grid item xs={6} lg={4} md={4}>
        <Autocomplete
            name="codtipodef"
            id="codtipodef"
            options={tipos}
            size="small"
            value={tipo}
            setValue={setTipo}
            valueKey={"codtipodef"}
            loading={loadingtipo}
            getOptionLabel={(option) => `${option.nomtipodef}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Tipo de Defectos" />}
          />
          
      </Grid>
        <Grid item xs >
          <TextField
            required
            placeholder=""
            id="referdef"
            label="Referencia"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
            onChange={handleForm}
          />
         
        </Grid>
      </Grid>

      <Grid container spacing={1} marginTop={1} marginBottom={3}>
        <Grid item xs >
          <TextField
            multiline
            rows={4}
            required
            placeholder=""
            id="descdefec"
            label="Descripción"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
            onChange={handleForm}
            style={{ width: "100%" }}

            
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={6} md={4} >
          <Autocomplete
            /* style={{ width: 210 }} */
            name="codmodulo"
            id="codmodulo"
            options={modulos}
            size="small"
            value={currentmodulos}
            setValue={setCurrentmodulos}
            loading={loadingmodulos}
            valueKey={"codmodulo"}
            getOptionLabel={(option) => `${option.nommodulo}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Modulo" />}
          />

        </Grid>
        <Grid item xs={6} lg={4} md={4}>
          <Autocomplete
            /* style={{ width: 210 }} */
            nullable={false}
            loading={loadingobjetos}
            options={objetos}
            size="small"
            value={currentobjetos}
            setValue={setCurrentobjetos}
            valueKey={"nomobjeto"}
            getOptionLabel={(option) => `${option.nomobjeto}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Objeto" />}

          />

        </Grid>
        
        <Grid item xs={6} md={4}>
          <TextField
            required
            placeholder=""
            name="versobjeto"
            id="versobjeto"
            label="Versión"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
            onChange={handleForm}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            required
            placeholder=""
            name="tipoobjeto"
            id="tipoobjeto"
            label="Tipo de Objeto"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            size="small"
            onChange={handleForm}
          />
        </Grid>
        <Grid item xs={6} md={4}>
         
          <Autocomplete
            name="descimpor"
            id="descimpor"
            options={importancias}
            size="small"
            value={importancia}
            setValue={setImportancia}
            valueKey={"descimpor"}
            loading={loadingimportancias}
            getOptionLabel={(option) => `${option.descimpor}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Importancia" />}
          />
        </Grid>

        <Grid item xs={6} md={4} >
        <TextField
            name="fecdetec"
            id="fecdetec"
            type={FIELD_TYPES.DATE}
            label="Fecha"
            value={startDate}
            title="Fecha"
            size="small"
            onChange={handleForm}
            fullWidth
            InputProps={{ inputProps: { min: date } }}
            InputLabelProps={{
              shrink: FIELD_TYPES.DATE === "date" ? true : undefined
            }}
          />
          
        </Grid>

      </Grid>
      <Grid container spacing={1} marginTop={2}>
       
    
        <Grid item xs={12} md={12}>
           {actividadeslist()}
        </Grid>
        
      </Grid>

    </form>


  </BaseDialog>
}
