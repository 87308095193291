import React, { useState, useCallback, useEffect } from "react"
import {
  /*DialogContentText,*/
  Button,
  /* Box,*/

  /* alertClasses,*/
  Divider,
  Paper, Grid, TextField, Typography, Fab
} from '@mui/material';
import { useRequests, useCustomSnackbar, getErrorMsg, useBackdrop,/* FIELD_TYPES, formatDate */ } from "../../../helpers"
import { BaseDialog, /*EditableTextField */ } from "../../layout"
/*import Divider from '@mui/material/Divider';*/
import '../../../stylesheets/tickets_estimacion.scss'
import { Link } from "react-router-dom"
import Stack from '@mui/material/Stack';
import MaterialTable from 'material-table'

import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
//import Check from '@mui/icons-material/Check';
import SaveIcon from "@mui/icons-material/Save";
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { forwardRef } from "react";

import AddIcon from '@mui/icons-material/Add';


const tableIcons = {
  Add: forwardRef((props, ref) => (
    <Fab color="secondary"  {...props} ref={ref}>
      <AddIcon />
    </Fab>
  )),
  Check: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


export const EstimacionTickets = React.memo(({ adr, reload, handleClose, open, dialogOpen, currentIdInc }) => {
  const { get, controller, patch } = useRequests()
  const { eSnack, sSnack } = useCustomSnackbar()
  const { show, off } = useBackdrop()

  const [ticket, setTicket] = useState({})
  const getTicket = useCallback(async () => {
    try {
      //setLoadingStates(true)
      const response = await get(`/administration/adr/ticket_estimacion`, {
        idinc: currentIdInc,
      })
      if (response?.ok) {
        const data = await response.json()
        setTicket(data)
        //reload()
      } else {
        eSnack(await getErrorMsg(response), getTicket)
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo tickets", getTicket)
    } finally {/*setLoadingStates(false)*/ }
  }, [get, eSnack, currentIdInc])


  useEffect(() => {
    if (dialogOpen === true && currentIdInc != null) getTicket()
  }, [getTicket, dialogOpen, currentIdInc])
  //######################################################################
  const [ticketTabla, setTicketTabla] = useState([])
  const getTicketTabla = useCallback(async () => {
    try {
      //setLoadingStates(true)
      const response = await get(`/administration/adr/ticket_tabla`, {
        id: currentIdInc,

      })
      if (response?.ok) {
        const data = await response.json()
        setTicketTabla(data)
      } else {
        eSnack(await getErrorMsg(response), getTicketTabla)
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo tickets", getTicketTabla)
    } finally {/*setLoadingStates(false)*/ }
  }, [get, eSnack, currentIdInc])

  useEffect(() => {
    if (dialogOpen === true && currentIdInc != null)
      getTicketTabla()
  }, [getTicketTabla, dialogOpen, currentIdInc])

  //#####################################################################
  const actividad = () => {
    if (ticket.idactividad != null) {
      return (
        <Button variant="contained" color="secondary" >Detalle Actividad </Button>
      )
    }
  }
  useEffect(() => () => controller.abort(), [controller])
  //#################################################################
  const createEstimacion = async newRow => {
    // (!value) return sSnack("Debe colocar un valor en el campo")

    show("Registrando Datos")
    try {
      const response = await patch(`/administration/adr/create_estimacion`, {

        descripcion: newRow.descripcion,
        cantidad: newRow.cantidad,
        complejidad: newRow.complejidad,
        numadr: adr.numadr,
        codcli: adr.codcli,
        codproy: adr.codproy,
        ticket: currentIdInc,
        tipo: newRow.tipo
      })
      if (response?.ok) {
        sSnack("Dato registrado")
        getTicketTabla()
        //reload()

      } else {
        eSnack(await getErrorMsg(response))
        console.log("error updating");
      }
    } catch (e) {
      eSnack("Error inesperado registrando ")
      console.log("Error updating ", e);
    } finally {
      off()
    }
  }
  //UPDATE TABLA#################################################################
  const updateTabla = async newRow => {
    // (!value) return sSnack("Debe colocar un valor en el campo")

    show("Actualizando Datos")
    try {
      const response = await patch(`/administration/adr/update_estimaciones`, {
        tipo: newRow.tipo,
        descripcion: newRow.descripcion,
        cantidad: newRow.cantidad,
        complejidad: newRow.complejidad,
        id: newRow.idestimacion,
        numadr: adr.numadr,
        codcli: adr.codcli,
        codproy: adr.codproy,

      })
      if (response?.ok) {
        sSnack("Dato actualizado")
        getTicketTabla()
        //reload()

      } else {
        eSnack(await getErrorMsg(response))
        console.log("error updating");
      }
    } catch (e) {
      eSnack("Error inesperado actualizando ")
      console.log("Error updating ", e);
    } finally {
      off()
    }
  }
  //DELETE TABLA#################################################################
  const deleteEstimacion = async selectedRow => {
    // (!value) return sSnack("Debe colocar un valor en el campo")

    show("Borrando Datos")
    try {
      const response = await patch(`/administration/adr/delete_estimacion`, {
        id: selectedRow.idestimacion,
      })
      if (response?.ok) {
        sSnack("Dato actualizado")
        getTicketTabla()
        //reload()

      } else {
        eSnack(await getErrorMsg(response))
        console.log("error updating");
      }
    } catch (e) {
      eSnack("Error inesperado actualizando ")
      console.log("Error updating ", e);
    } finally {
      off()
    }
  }



  const cantidad = ticketTabla.reduce((prev, next) => prev + next.cantidad, 0);
  const horas = ticketTabla.reduce((prev, next) => (parseFloat(prev)) + (parseFloat(next.hh)), 0)

  const columns = [
    {
      title: 'Tipo',
      field: 'tipo',
      lookup: { 'TABLAS': 'Tablas', 'PANTALLAS': 'Pantallas', 'REPORTES': 'Reportes', 'PROCESOS': 'Procesos' },
      minWidth: 20,
      align: 'left',
     
    },
    {
      title: 'Descripción',
      field: 'descripcion',
      sorting: false,
      filtering: false,
      minWidth: 300,
      align: 'left',
      multiline: true,
      editComponent: props => (
        <TextField
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
          multiline
        />
      )
    },
    {
      title: 'Complejidad',
      field: 'complejidad',
      lookup: { 'AJ': 'Ajuste', 'S': 'Sencillo', 'M': 'Moderado', 'C': 'Complejo', 'MC': 'Muy Complejo' },
      minWidth: 80,
      align: 'left',
    },

    {
      title: "Cantidad",
      field: "cantidad",
      type: 'numeric',
      minWidth: 20,
      align: 'left',
    },
    {
      title: "HH",
      field: "hh",
      editable: 'never',
      minWidth: 20,
      align: 'left',
    },
  ]

  const data = ticketTabla.map((row) => ({
    idestimacion: row.idestimacion,
    descripcion: row.descripcion,
    complejidad: row.complejidad,
    tipo: row.tipo,
    cantidad: row.cantidad,
    hh: row.hh,
  }))

  const getEditableOptions = () => {
    if (adr && adr.can && adr.can.associate_tickets) {
      return {
        onRowAdd: (newRow) => new Promise((resolve, reject) => {
          createEstimacion(newRow)
          setTimeout(() => resolve(), 500)
        }),
        onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
          updateTabla(newRow)
          setTimeout(() => resolve(), 500)
        }),
        onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
          deleteEstimacion(selectedRow)
  
          setTimeout(() => resolve(), 1000)
  
        })
      };
    } else {
      return {};
    }
  };

  return (
    <BaseDialog
      dialogOpen={dialogOpen}
      handleClose={handleClose}
      title={`Tiempo Estimado Ticket  ${ticket.idinc}`}
      cancelText="Cerrar"
      width="md"
    >

      <MaterialTable
        icons={tableIcons}
        columns={columns}

        data={data}
        editable={getEditableOptions()} // Llamando a la función que devuelve las opciones de edición

        options={{
          title: {
            fontSize: 16, // Cambia el tamaño de fuente del título
            height: 0, // Quita la altura del título
          },
          search: false,
          columnsButton: false,
          exportAllData: true,
          addRowPosition: "first",
          actionsColumnIndex: -1,
          showSelectAllCheckbox: false,
          selection: false,
          rowStyle: (data, index) => index % 1 === 0 ? { background: "#f5f5f5", fontSize: '13px'  } : null,
          headerStyle: { background: "#64b5f6", fontSize: '13px' },
          rowHeight: 60, // altura de la fila en píxeles
        }}


        localization={{
          header: {
            actions: 'Acciones',
          },
          body: {
            emptyDataSourceMessage: 'Nada para mostrar',
            addTooltip: 'Agregar',
            editTooltip: 'Editar',
            deleteTooltip: 'Eliminar',

            editRow: {
              deleteText: 'Seguro desea Eliminar la información seleccionada?',
            }
          },
          pagination: {
            labelRowsPerPage: 'Filas por página',
            labelRowsSelect: 'Filas'
          }
        }}
        title=""

      />
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={5} md={1.3}>
          <Stack container>
            <Typography variant="h6" marginTop={1} ><b>Totales:</b></Typography>
          </Stack>
        </Grid>
        <Grid item xs={5} md={1}>
          <Stack container justifyContent="center">
            <Typography textAlign="center" variant="subtitle1"> <b> {cantidad} </b></Typography>
            <Typography textAlign="center" variant="subtitle2">Cantidad</Typography>
          </Stack>
        </Grid>
        <Grid item xs={5} md={1}>
          <Stack container justifyContent="center">
            <Typography textAlign="center" variant="subtitle1"><b>{horas} </b></Typography>
            <Typography textAlign="center" variant="subtitle2">Horas</Typography>
          </Stack>
        </Grid>
      </Grid>
    </BaseDialog>


  )
})
