// const MIGRATION_TICKETS = Object.freeze([])
// const getModulo = text => (text.match(/^[^-]*[^ -]/) || [])[0]
// const onlyUnique = (value, index, self) => self.indexOf(value) === index
// const emails = MIGRATION_TICKETS.map( a => a.guest_email)
// const uniqueEmails = emails.filter(onlyUnique);
// const ticket_interface = ()=>{
//   const newTickets = MIGRATION_TICKETS.map(ticket => {
//     return {
//       email: ticket.guest_email,
//       descprobinc: `Ticket migrado`,
//       codcli: 'user.codcli',
//       nombpers: 'user.nombpers',
//       tipinc: 'SOP',
//       problema: ticket.subject,
//       codsistema: 'KERUX',
//       codmodulo: getModulo(ticket.cust1),
//       prioridad: ticket.priority_id
//     }
//   })
//   return newTickets
// }

export const mock_notification_settings = {
  ticket_created: {
    value: true,
    description: "Ticket sea creado",
    type:'boolean'
  },
  ticket_comment: {
    value: true,
    description: "Mensaje recibido en tu ticket",
    type:'boolean'
  },
  ticket_asignee: {
    value: true,
    description: "Ticket asignado a ti",
    type:'boolean'
  },
  ticket_asigned: {
    value: false,
    description: "Ticket sea asignado",
    type:'boolean'
  }
}
