import React, { useState, useCallback, useEffect } from "react"
import { Paper, Grid, Divider, Stack, styled, Hidden, TextField, Container, Typography, Card, CardContent } from '@mui/material'
import { FIELD_TYPES, formatCurrency, getErrorMsg, useRequests, useCustomSnackbar, useConfirmationDialog } from "../../../helpers"
import { EditableTextField, Autocomplete } from '../../layout'
import '../../../stylesheets/adr.scss'
import { Box } from "@mui/system"
import { CardWithBar } from "../../layout/basic/CardWithBar"
import CardButton from "../../layout/basic/MDBox/CardButton"



export const AdrHorasMontos = React.memo(({ adr, saveField, loadingADR }) => {


  const { get, controller } = useRequests()
  const { eSnack } = useCustomSnackbar()
  const { getConfirmation } = useConfirmationDialog()

  const [loadingSTSFAC, setLoadingSTSFAC] = useState(false)
  const [statusfact, setStatusfact] = useState([])
  const [currentStatusFact, setCurrentStatusFact] = useState(null)
  //Seccion de Horas
  const hhTotales = () => {
    return (

      <>
        <Box mt={1} mb={1} color="#7C7CA3">
          <b>{adr.hhtotales}</b>
        </Box>

      </>

    )
  }
  const cargoAdicionales = () => {
    return (
      <Box p={1}>
        <EditableTextField
          initialValue={adr.hhadaptaciones}
          name="hhadaptaciones"
          save={saveField}
          type={FIELD_TYPES.DECIMAL}
          placeholder="Cargo Adicionales"
        />
      </Box>)
  }

  const cargoSoporte = () => {
    return (
      <Box p={1}>
        <EditableTextField
          initialValue={adr.hhsoporte}
          name="hhsoporte"
          save={saveField}
          type={FIELD_TYPES.DECIMAL}
          placeholder="Cargo a soporte"
        />
      </Box>)
  }
  const afactuRar = () => {

    const total = adr.hhtotales - (parseFloat(adr.hhadaptaciones) + parseFloat(adr.hhsoporte))

    return (
      <>
        <Box mt={1} mb={1} color="#7C7CA3">
          <b>{total ? total.toFixed(2) : '0.00'}</b>
        </Box>

      </>
    )
  }

  const tarifa = () => {
    return (

      <Box>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <span style={{color: "#7C7CA3"}}>Bs.</span>
          </Grid>
          <Grid item xs={9} md={9}>
            <EditableTextField
              initialValue={adr.tarifa}
              name="tarifa"
              save={saveField}
              type={FIELD_TYPES.DECIMAL}
              placeholder="tarifa"
              formatter={formatCurrency}
            />
          </Grid>
        </Grid>
      </Box>
    )
  }

  const montoNeto = () => {
    const total = adr.hhtotales - (parseFloat(adr.hhadaptaciones) + parseFloat(adr.hhsoporte))

    const montoNeto = (total * adr.tarifa)

    return (

      <Box color="#7C7CA3">
        <b>Bs. {formatCurrency(montoNeto)}</b>
      </Box>
    )
  }

  const iva = () => {
    const intiva = parseInt(adr.iva) || 0;


    return (
      <Box p={1}>
        <Grid container alignItems="center" spacing={0}>
          <Grid item >
            <EditableTextField
              initialValue={intiva}
              name="iva"
              save={saveField}
              type={FIELD_TYPES.DECIMAL}
              placeholder="Iva"
            />
          </Grid>

        </Grid>
      </Box>
    );
  };


  const ivaBs = () => {
    const total = adr.hhtotales - (parseFloat(adr.hhadaptaciones) + parseFloat(adr.hhsoporte))
    const montoNeto = (total * adr.tarifa)
    const intiva = parseInt(adr.iva) || 0
    const ivaBs = (montoNeto * intiva) / 100
    return (
      <Box marginLeft={-17}>
        <b style={{ color: '#7C7CA3', textAlign: 'left' }}> Bs. {formatCurrency(ivaBs)}</b>
      </Box>
    )
  }
  const montoBruto = () => {
    const total = adr.hhtotales - (parseFloat(adr.hhadaptaciones) + parseFloat(adr.hhsoporte))
    const montoNeto = (total * adr.tarifa)
    const intiva = parseInt(adr.iva) || 0
    const ivaBs = (montoNeto * intiva) / 100
    const montoBruto = montoNeto + ivaBs

    return (
      <Box color="#7C7CA3">
        <b>Bs. {formatCurrency(montoBruto)}</b>
      </Box>
    )
  }

  //#FACTURACION
  const getAdrStatusesFact = useCallback(async () => {
    try {
      setLoadingSTSFAC(true)
      const response = await get(`/administration/adr/stsfacturacion`, {
      })
      if (response?.ok) {
        const data = await response.json()
        setStatusfact(data)
      } else {
        eSnack(await getErrorMsg(response), getAdrStatusesFact)
        /* console.log("error getting statuses"); */
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo estatus de facturación", getAdrStatusesFact)
      /*  console.log("Error getting Statuses", e); */
    } finally { setLoadingSTSFAC(false) }
  }, [get, eSnack])

  useEffect(() => {
    getAdrStatusesFact()
  }, [getAdrStatusesFact])

  useEffect(() => {
    if (statusfact.length && adr.stsfacturacion) {
      setCurrentStatusFact(statusfact.find(status => status.name === adr.stsfacturacion))
    }
  }, [adr, statusfact])

  const stsFacturacion = () => {
    return (
      <Autocomplete
        fullWidth
        nullable={false}
        options={statusfact}
        size="small"
        value={currentStatusFact}
        loading={loadingSTSFAC}
        onChange={async (_, value) => {

          if (value.name && (await getConfirmation({ title: "Cambiar Estatus de Facturación", message: `Cambiar estatus de ${adr.stsfacturacion} -> ${value.name}?` }))) {
            selectStatusFact(value)
          }
        }}
        getOptionLabel={(option) => `${option.name}`}
        renderInput={(params) => <TextField {...params} required fullWidth label="Estatus Facturación" />}
      />
    );
  }

  const selectStatusFact = async selectedOption => {
    const statusfactura = { stsfacturacion: selectedOption?.value }
    saveField(statusfactura)
  }

  const nroFactura = () => {
    return (
      <Box mt={3}>

        <EditableTextField
          fullWidth
          initialValue={adr.nrofactura}
          name="nrofactura"
          save={saveField}
          type="string"
          placeholder="Nro Factura"
        />
      </Box>
    )
  }
  useEffect(() => () => controller.abort(), [controller])



  return (
    <>
      <CardWithBar title={"Horas"}>
        <Container >
          <Grid container spacing={2} >
            <Grid item xs={12} md={3} >
              <CardButton>
                <Typography variant="subtitle1">
                  Totales: {hhTotales()}
                </Typography>
              </CardButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>
                <Typography variant="subtitle1">
                  Cargo a "Adicionales":  {cargoAdicionales()}
                </Typography>
              </CardButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>
                <Typography variant="subtitle1">
                  Cargo a "Soporte": {cargoSoporte()}
                </Typography>
              </CardButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>
                <Typography variant="subtitle1">
                  A facturar por separado:
                  {afactuRar()}
                </Typography>
              </CardButton>
            </Grid>

          </Grid>
        </Container>
      </CardWithBar>
      <br />
      <CardWithBar title={"Montos a facturar"}>
        <Container >

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CardButton>
                <Typography variant="subtitle1">
                  Tarifa: {tarifa()}
                </Typography>
              </CardButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>
                <Typography variant="subtitle1">
                  Monto Neto: {montoNeto()}
                </Typography>
              </CardButton>

            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>
                <Typography variant="subtitle1">
                  Iva:  {iva()}

                  {ivaBs()}

                </Typography>
              </CardButton>
            </Grid>
            <Grid item xs={12} md={3}>
              <CardButton>
                <Typography variant="subtitle1">
                  Monto Bruto: {montoBruto()}
                </Typography>
              </CardButton>

            </Grid>
          </Grid>
        </Container>
      </CardWithBar>
      <br />

      <CardWithBar title={"Facturación"}>
        <Container sx={{ marginY: 2 }}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>

              <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                Facturación:
              </Typography>

              {stsFacturacion()}

            </Grid>
            <Grid item xs={12} md={6}>

              <Typography variant="subtitle1" >
                Factura: {nroFactura()}
              </Typography>

            </Grid>
          </Grid>
        </Container>
      </CardWithBar>


    </>
  );

})
