import React, {useState, useCallback, useEffect} from "react"
import { useHistory } from "react-router-dom"
import { BaseDialog, FORM_SUBMIT_TYPES, BaseNewForm } from "../../layout";
import {useRequests, useCustomSnackbar, getErrorMsg,   useBackdrop, FIELD_TYPES} from "../../../helpers"

export const NewAdr = ({open, handleClose}) => {
  const [formId] = useState("adr_form")
  const history = useHistory()
  const {get, post} = useRequests()
  const {eSnack, sSnack} = useCustomSnackbar()
  const {show, off} = useBackdrop()
  const [loadingClients, setLoadingClients] = useState(false)
  const [loadingProyectos, setLoadingProyectos] = useState(false)
  const [loadingDirector, setLoadingDirector] = useState(false)
  const [clients, setClients] = useState([])
  const [directorp, setDirectorp] = useState([])
  const [projects, setProjects] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)

  const createAdr = useCallback( async (data)=>{
    show('Creando ADR')
    try {
    const request = await post(`/administration/adr`, {adr:data})
    if(request?.ok){
      sSnack("ADR Creado")
      const {numadr,codcli,codproy} = await request.json()
      history.push(`${history.location.pathname}/${numadr}/${codcli}/${codproy}`)
    }else{
      eSnack(await getErrorMsg(request))
    }
    } catch (e) {
      console.log(e)
      eSnack("Error inesperado creando ADR")
    } finally { off() }
  },[sSnack, eSnack, show, off, post, history])


  const getClients = useCallback( async () =>{
    try{
      setLoadingClients(true)
      const response = await get('/administration/adr/clients')
      if(response?.ok) {
        const data = await response.json()
        setClients(data)
      }else{
        eSnack(await getErrorMsg(response), getClients)
      }
    }catch(e){
      console.log(e);
      eSnack("Error inesperado obteniendo clientes")
    }finally{ setLoadingClients(false)}
  },[get,  eSnack])

  const onClientSelected = value => {
    setSelectedClient(value?.value)
  }
  const getProjects = useCallback(async ()=>{
    try{
      setLoadingProyectos(true)
      const response = await get(`/administration/adr/projects`, {selectedClient:selectedClient})
      if(response?.ok){
        const projects = await response.json()
        setProjects(projects)
      }else{
        eSnack(`Projects: ${await getErrorMsg(response)}`, getProjects)
        console.log("Error getting projects");
      }
    }catch(e){
      eSnack("Error inesperado obteniendo projects", getProjects)
      console.log("Error getting projects", e);
    }finally{ setLoadingProyectos(false)}
  },[eSnack, get, selectedClient])

  const getDirectorp = useCallback( async () =>{
    try{
      setLoadingDirector(true)
      const response = await get('/administration/adr/directorp')
      if(response?.ok) {
        const data = await response.json()
        setDirectorp(data)

      }else{
        eSnack(await getErrorMsg(response), getDirectorp)
      }
    }catch(e){
      console.log(e);
    }finally{ setLoadingDirector(false)}
  },[get,  eSnack])


  useEffect(()=>{
    if(selectedClient){
      setProjects([])
      getProjects()
    }
  },[getProjects, selectedClient])

  useEffect(()=>{
    if(open && !clients.length) {
      getClients()
    }
  },[getClients, open, clients.length])

  useEffect(()=>{
    if(clients.length){
      setSelectedClient(clients[0].value)
    }
  },[clients])

  useEffect(()=>{
    if (open && !directorp.length){
      getDirectorp()
    }
 },[getDirectorp,open, directorp.length])


  return <BaseDialog
    dialogOpen={open}
    handleClose={handleClose}
    title="Nuevo ADR"
    confirmText={"Crear ADR"}
    cancelText={"Cerrar"}
    formId={formId}
    confirm
    width="md"
  >
    <BaseNewForm
      fields={{
         codcli:{
                title:"Clientes",
                id:"cliente",
                type:FIELD_TYPES.AUTOCOMPLETE,
                required:true,
                options:clients,
                value:clients[0],
                getLabel: option => `${option.value} - ${option.name}`,
                loading:loadingClients,
                onChange: onClientSelected,
                extraProps:{
                  disableClearable: true
                }
        },
         codproy:{
                title:"Proyecto",
                id:"codproy",
                type:FIELD_TYPES.AUTOCOMPLETE,
                required:true,
                options:projects,
                value:projects[0],
                getLabel: option => `${option.value} - ${option.name}`,
                loading:loadingProyectos,
                extraProps:{
                  disableClearable: true
                }
         },
         nomdirproy:{
                title:"Director del Proyecto",
                id:"nomdirproy",
                type:FIELD_TYPES.AUTOCOMPLETE,
                required:true,
                options:directorp,
                loading:loadingDirector,
                value:null,
                extraProps:{
                  disableClearable: true
                }
        },
         nomcliente:{
                title:"Nombre Cliente",
                id:"nomcli",
                type:FIELD_TYPES.TEXT,
                required:false,
                value:"",
        },
         nombre:{
                title:"Nombre ADR",
                id:"title",
                required:true,
                type:FIELD_TYPES.TEXT,
                value:""

        },
      }}
      dialog={true}
      formId={formId}
      submit={createAdr}
      submitType={FORM_SUBMIT_TYPES.JSON}
    />
  </BaseDialog>
}
