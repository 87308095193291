import React from "react"
import {Route, Redirect, Switch} from "react-router-dom"
import {Users, Clients, Systems, Orders, Adr, Defects, Quiz} from "."
import {useCurrentUser} from "../../helpers"
import '../../stylesheets/administration.scss';

export const Administration = ({match}) => {
  const currentUser = useCurrentUser()
  return (
    <Switch>
      <Route path={`${match.path}/users`} component={Users}/>
      <Route path={`${match.path}/defects`} component={Defects}/>
      <Route path={`${match.path}/clients`} component={Clients}/>
      {currentUser.can?.check_systems && <Route path={`${match.path}/systems`} component={Systems}/>}
      <Route path={`${match.path}/orders`} component={Orders}/>
      <Route path={`${match.path}/adr`} component={Adr}/>
      <Route path={`${match.path}/quiz`} component={Quiz}/>
      <Redirect from={`${match.path}/`} to={`${match.path}/users`}/>
    </Switch>
  )
}
