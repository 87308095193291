import withStyles from '@mui/styles/withStyles';
import { TableCell } from '@mui/material'

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  body: {
    fontSize: 14,
    width:"auto",
    overflow:"hidden",
    textOverflow:"ellipsis",
    maxWidth:"26.5vw",
    whiteSpace:"nowrap"
  },
}))(TableCell);
