import React, { useCallback, useEffect, useState } from "react";
import { Grid, Paper, TextField, Typography, Divider, AppBar, Tabs, Tab, FormControlLabel, Breadcrumbs, Toolbar, Stack, FormLabel, Tooltip, Checkbox, Container } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { getErrorMsg, useBackdrop, useConfirmationDialog, useCustomSnackbar, useRequests, FIELD_TYPES, formatDate } from "../../../helpers";
import { Autocomplete, ConditionalWrapper, EditableTextField, SkeletonDetailActivity } from "../../layout";
import { Link as RouterLink, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
/* import AssignmentTwoToneIcon from '@mui/icons-material/Assignment'; */
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";


const TabsWrapper = styled(Tabs)(
    () => `
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }
  `
);

const useStyles = makeStyles((theme) => ({
    inputpt: {
        "& .MuiInputBase-input": {
            font: "inherit",
            letterSpacing: " inherit",
            padding: 0,
            border: 0,
        },
        "& .MuiInput-input": {
            font: "inherit",
            letterSpacing: " inherit",
            padding: 0,
            border: 0,
        }
    }

}));

export const DefectsDetail = () => {
    const { iddefecto } = useParams()
    const classes = useStyles();
    const [loadingdefecto, setLoadingdefecto] = useState(false)

    const [defecto, setdefecto] = useState("")
    const { show, off } = useBackdrop()

    const { get, patch } = useRequests()
    const { eSnack, sSnack } = useCustomSnackbar()
    const { getConfirmation } = useConfirmationDialog()
    const [personas, setPersonas] = useState([])
    const [loadingpersona, setLoadingpersona] = useState(false)
    const [currentpersonas, setCurrentpersonas] = useState(null)
    // const [currentpersonasCorr, setCurrentpersonasCorr] = useState(null)
    const [currentpersonasIntrod, setCurrentpersonasIntrod] = useState(null)

    const [currentTab, setCurrentTab] = useState('detalle');

    const tabs = [
        { value: 'detalle', label: 'Detalle' },
        { value: 'analisis', label: 'Análisis y corrección del defecto' },

    ];

    const handleTabsChange = (event, value) => {
        setCurrentTab(value);
    };

    const [actividades, setActividades] = useState([])
    const [currentactividad, setCurrentActividad] = useState(null)
    const [loadingactividad, setloadingActividad] = useState(false)

    const [tiposdefectos, setTiposdefectos] = useState([])
    const [currenttiposdefectos, setCurrenttiposdefectos] = useState(null)
    const [loadingtiposdef, setLoadingtiposdef] = useState(false)


    const [modulos, setModulos] = useState([])
    const [currentmodulos, setCurrentmodulos] = useState(null)
    const [loadingmodulos, setLoadingmodulos] = useState(false)

    const [importancias, setImportancias] = useState([])
    const [currentimportancias, setCurrentimportancias] = useState(null)
    const [loadingimportancias, setLoadingimportancias] = useState(false)

    const [objetos, setObjetos] = useState([])
    const [currentobjetos, setCurrentobjetos] = useState(null)
    const [loadingobjetos, setLoadingobjetos] = useState(false)
    const [selectedModulo, setSelectedModulo] = useState(defecto.codmodulo)

    const [clasificacion, setClasificacion] = useState([])
    const [loadingclasif, setLoadingclasif] = useState(false)
    const [currentclasif, setCurrentclasif] = useState(null)


    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedVerif, setIsCheckedVerif] = useState(false);
    const [isCheckedListo, setIsCheckedListo] = useState(false);
    const [isCheckedNC, setIsCheckedNC] = useState(false);

    const [contador, setContador] = useState(0);
    //  const [contadornc, setContadornc] = useState(null);
    const [date] = useState(new Date().toISOString().split("T")[0]);
    // const [feccorr, setFeccorr] = useState(null);
    //  const [fecverificado, setFecverificado] = useState(null);

    const [loadingSTSDDEF, setLoadingSTSDEF] = useState(false)
    const [statusdefecto, setStatusDefecto] = useState([])
    const [currentStatusdefecto, setCurrentStatusdefecto] = useState(null)
    //###################################################################  
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
            Inicio
        </Link>,
        <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/administration/defects">
            Defectos
        </Link>,
        <Typography key="3" color="text.primary">
            Detalle del Defecto
        </Typography>,
    ];


    //GET DEFECTOS###################################################################  
    const getDefects = useCallback(async () => {
        try {
            setLoadingdefecto(true)
            const response = await get('/administration/defects/show', { iddefecto })
            if (response?.ok) {
                const defecto = await response.json()
                setdefecto(defecto)
            } else {

                eSnack(await getErrorMsg(response), getDefects)
                console.log("error getting defecto");
            }
        } catch (e) {
            eSnack("Error inesperado obteniendo defecto")
            console.log("Error getting defecto", e);
        } finally { setLoadingdefecto(false) }
    }, [get, eSnack, iddefecto])

    useEffect(() => {
        getDefects()
    }, [getDefects])
    //SAVE FIELD###############################################################
    const saveField = async (value) => {
        if (value === !value) return eSnack("Campo no puede estar vacio")
        show("Actualizando Datos")
        try {
            const response = await patch(`/administration/defects/update`, {
                iddefecto,
                // feccorr: feccorr,
                // fecverificado: fecverificado,
                // contadornc: contadornc,
                defecto: value,
            })
            if (response?.ok) {
                const defecto = await response.json()
                sSnack("Dato actualizado")
                setdefecto(defecto)
            } else {
                eSnack(await getErrorMsg(response))
                console.log("error updating");
            }
        } catch (e) {
            eSnack("Error inesperado actualizando ")
            console.log("Error updating ", e);
        } finally {
            off()
        }
    }
    //MODULOS###################################################################  

    useEffect(() => {
        if (modulos.length && defecto.codmodulo) {
            setCurrentmodulos(modulos.find(modulo => modulo.codmodulo === defecto.codmodulo))
        }
    }, [defecto, modulos])


    const getModulos = useCallback(async () => {
        try {
            setLoadingmodulos(true)
            const response = await get('/administration/defects/modulo')
            if (response?.ok) {
                const modulos = await response.json()
                setModulos(modulos)
            } else {
                eSnack(await getErrorMsg(response), getModulos)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingmodulos(false) }
    }, [get, eSnack])


    useEffect(() => {
        getModulos()
    }, [getModulos,])

    const selectmodulo = async selectedOption => {
        const codmodulo = { codmodulo: selectedOption?.codmodulo }
        saveField(codmodulo)
        setSelectedModulo(selectedOption?.codmodulo)
    }

    //OBJETOS###############################################################
    useEffect(() => {
        setSelectedModulo(defecto.codmodulo)
    }, [defecto.codmodulo])
    useEffect(() => {
        if (objetos.length && defecto.nomobjeto) {
            setCurrentobjetos(objetos.find(objeto => objeto.nomobjeto === defecto.nomobjeto))

        }
    }, [defecto, objetos])

    const getObjetos = useCallback(async () => {
        try {
            setLoadingobjetos(true)
            const response = await get(`/administration/defects/objeto`, {
                selectedModulo: selectedModulo
            })
            if (response?.ok) {
                const objetos = await response.json()
                setObjetos(objetos)
            } else {
                eSnack(`objetos: ${await getErrorMsg(response)}`, getObjetos)
                console.log("Error getting objetos");
            }
        } catch (e) {
            eSnack("Error inesperado obteniendo objetos", getObjetos)
            console.log("Error getting objetos", e);
        } finally { setLoadingobjetos(false) }
    }, [eSnack, get, selectedModulo])

    useEffect(() => {
        getObjetos()
    }, [getObjetos,])


    const selectobjeto = async selectedOption => {
        const nomobjeto = { nomobjeto: selectedOption?.nomobjeto }
        saveField(nomobjeto)
    }
    useEffect(() => {
        if (selectedModulo) {
            getObjetos([])
            getObjetos()
        }
    }, [getObjetos, selectedModulo])


    //IMPORTANCIAS###################################################################  
    useEffect(() => {
        if (importancias.length && defecto.codimpor) {
            setCurrentimportancias(importancias.find(modulo => modulo.codimpor === defecto.codimpor))
        }
    }, [defecto, importancias])

    const getImportancias = useCallback(async () => {
        try {
            setLoadingimportancias(true)
            const response = await get('/administration/defects/importancia')
            if (response?.ok) {
                const importancias = await response.json()
                setImportancias(importancias)
            } else {
                eSnack(await getErrorMsg(response), getImportancias)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingimportancias(false) }
    }, [get, eSnack])

    useEffect(() => {
        getImportancias()
    }, [getImportancias,])

    const selectimportancia = async selectedOption => {
        const codimpor = { codimpor: selectedOption?.codimpor }
        saveField(codimpor)
    }

    //PERSONAS###############################################################
    useEffect(() => {
        if (personas.length && defecto.codpersdetec) {
            setCurrentpersonas(personas.find(persona => persona.codusu === defecto.codpersdetec))
        }
    }, [defecto, personas])

    useEffect(() => {
        if (personas.length && defecto.codpersdetec) {
            setCurrentpersonasIntrod(personas.find(persona => persona.codusu === defecto.codpersintrod))
        }
    }, [defecto, personas])

    /* useEffect(() => {
         if (personas.length && defecto.codpersdetec) {
             setCurrentpersonasCorr(personas.find(persona => persona.codusu === defecto.codperscorr))
         }
     }, [defecto, personas])*/

    const getPersonas = useCallback(async () => {
        try {
            setLoadingpersona(true)
            const response = await get('/administration/defects/persona')
            if (response?.ok) {
                const personas = await response.json()
                setPersonas(personas)
            } else {
                eSnack(await getErrorMsg(response), getPersonas)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingpersona(false) }
    }, [get, eSnack])

    useEffect(() => {
        getPersonas()
    }, [getPersonas,])

    const selectpersona = async selectedOption => {
        const codpersdetec = { codpersdetec: selectedOption?.codusu }
        saveField(codpersdetec)
    }
    const selectpersonaIntrod = async selectedOption => {
        const codpersintrod = { codpersintrod: selectedOption?.codusu }
        saveField(codpersintrod)
    }
    /*const selectpersonaCorr = async selectedOption => {
        const codperscorr = { codperscorr: selectedOption?.codusu }
        saveField(codperscorr)
    }*/

    //#######################################################################
    const fecha = () => {
        if (defecto.fechorini != null) {
            return (
                <>
                    <FormLabel id="demo-controlled-radio-buttons-group" ><b> {formatDate(new Date(defecto.fechorini))} </b></FormLabel>
                </>
            )
        }
        else {
            return (
                <>
                    <FormLabel id="demo-controlled-radio-buttons-group" ><b> {defecto.fechorini} </b></FormLabel>
                </>
            )
        }
    }
    const ckeckFecha = () => {
        if (defecto.fechorini != null) {
            return (
                <>
                    <FormControlLabel
                        label=""
                        control={<Checkbox id="inicorreccion" checked={true} />} />

                </>
            )
        }
        else {
            return (
                <>
                    <FormControlLabel
                        label=""
                        control={<Checkbox id="inicorreccion" checked={isChecked} onChange={handleOnChange} />} />

                </>
            )
        }
    }
    const handleOnChange = async () => {
        setIsChecked(isChecked)
        const fechorini = { fechorini: date }
        const confirmed = await getConfirmation({ title: "Asignar Fecha corrección", message: "Seguro desea asignar fecha" })
        if (confirmed) {
            saveField(fechorini)
        }
    };
    //CHECK LISTO#####################################################################################
    useEffect(() => {
        if (defecto.feccorr != null) {
            setIsCheckedListo(true)
        }

    }, [defecto.feccorr])

    const ckeckListo = () => {
        return (
            <>
                <FormControlLabel
                    label=""
                    control={<Checkbox id="inicorreccion" checked={isCheckedListo} onChange={handleOnChangelisto} />} />
            </>
        )
    }

    const handleOnChangelisto = async () => {

        setIsCheckedListo(!isCheckedListo)
        const confirmed = await getConfirmation({ title: "Corrección Lista", message: "Seguro desea cambiar la corrección ?" })
        if (confirmed) {

            if (!isCheckedListo) {
                listoSelect()
                window.location.reload()
            }
            if (isCheckedListo) {
                listoDesm()
            }
        } else {
            setIsCheckedListo(false)
        }
    };
    const listoSelect = async (value) => {
        show("Actualizando Datos")
        const resta = Math.round((new Date().getTime() - new Date(defecto.fechorini).getTime()) / (1000 * 60 * 60 * 24))

        try {
            const response = await patch(`/administration/defects/update_listo_select`, {
                iddefecto,
                tiempocorr: resta
            })
            if (response?.ok) {
                //const defecto = await response.json()
                sSnack("Dato actualizado")
                //  setdefecto(defecto)
                getDefects()
            } else {
                eSnack(await getErrorMsg(response))
                console.log("error updating");
            }
        } catch (e) {
            eSnack("Error inesperado actualizando ")
            console.log("Error updating ", e);
        } finally {
            off()
        }
    }
    const listoDesm = async (value) => {
        show("Actualizando Datos")
        try {
            const response = await patch(`/administration/defects/update_listo_desm`, {
                iddefecto,
            })
            if (response?.ok) {
                //const defecto = await response.json()
                sSnack("Dato actualizado")
                //  setdefecto(defecto)
                getDefects()

            } else {
                eSnack(await getErrorMsg(response))
                console.log("error updating");
            }
        } catch (e) {
            eSnack("Error inesperado actualizando ")
            console.log("Error updating ", e);
        } finally {
            off()
        }
    }
    //CHECK VERIFICADO####################################################################################
    useEffect(() => {
        if (defecto.fecverificado != null) {

            setIsCheckedVerif(true)
        }

    }, [defecto.fecverificado])

    const ckeckVerificado = () => {

        return (
            <>
                <FormControlLabel
                    label=""
                    control={<Checkbox id="inicorreccion" checked={isCheckedVerif} onChange={handleOnChangeVerificado} />} />
            </>
        )
    }
    const handleOnChangeVerificado = async () => {
        setIsCheckedVerif(!isCheckedVerif)
        const confirmed = await getConfirmation({ title: "Corrección Verificada", message: "Seguro desea cambiar corrección?" })
        if (confirmed) {

            if (!isCheckedVerif) {
                verificadoSelect()

            }
            if (isCheckedVerif) {
                verificadoDesm()
            }
        } else {
            setIsCheckedVerif(false)
        }
    };

    const verificadoSelect = async (value) => {
        show("Actualizando Datos")
        try {
            const response = await patch(`/administration/defects/update_verificado_select`, {
                iddefecto,
            })
            if (response?.ok) {
                //  const defecto = await response.json()
                sSnack("Dato actualizado")
                //  setdefecto(defecto)
                getDefects()

            } else {
                eSnack(await getErrorMsg(response))
                console.log("error updating");
            }
        } catch (e) {
            eSnack("Error inesperado actualizando ")
            console.log("Error updating ", e);
        } finally {
            off()
        }
    }
    const verificadoDesm = async (value) => {
        show("Actualizando Datos")
        try {
            const response = await patch(`/administration/defects/update_verificado_desm`, {
                iddefecto,
            })
            if (response?.ok) {
                //  const defecto = await response.json()
                sSnack("Dato actualizado")
                //  setdefecto(defecto)
                getDefects()

            } else {
                eSnack(await getErrorMsg(response))
                console.log("error updating");
            }
        } catch (e) {
            eSnack("Error inesperado actualizando ")
            console.log("Error updating ", e);
        } finally {
            off()
        }
    }
    //NO CORREGIDO###########################################################################
    useEffect(() => {
        if (defecto.feccorr === null & contador > 0) {
            setIsCheckedNC(true)
        }

    }, [defecto.feccorr, contador])

    const ckeckNoCorregido = () => {
        return (
            <>
                <FormControlLabel
                    label=""
                    control={<Checkbox id="inicorreccion" checked={isCheckedNC} onChange={handleOnChangecont} />} />

            </>
        )
    }
    useEffect(() => {
        setContador(defecto.contnocorreccion)
    }, [defecto.contnocorreccion])

    const handleOnChangecont = async () => {
        //  setIsChecked(isChecked)


        const confirmed = await getConfirmation({ title: "Contador", message: "Desea Colocar el defecto como no corregido?" })
        if (confirmed) {
            noCorregido()
            window.location.reload()
        }
        else {
            setIsCheckedNC(false)
        }
    }

    const noCorregido = async (value) => {
        show("Actualizando Datos")
        const contador = defecto.contnocorreccion + 1
        try {
            const response = await patch(`/administration/defects/update_NC`, {
                iddefecto,
                contnocorreccion: contador
            })
            if (response?.ok) {
                // const defecto = await response.json()
                sSnack("Dato actualizado")
                //setdefecto(defecto)
                getDefects()

            } else {
                eSnack(await getErrorMsg(response))
                console.log("error updating");
            }
        } catch (e) {
            eSnack("Error inesperado actualizando ")
            console.log("Error updating ", e);
        } finally {
            off()
        }
    }

    //#########################################################################################

    const FechaOR = () => {
        if (defecto.fechorfin === null) {
            return (<FormLabel id="demo-controlled-radio-buttons-group" > Fecha Original:
                <b>{defecto.fechorfin}</b></FormLabel>)
        } else {
            return (

                <FormLabel id="demo-controlled-radio-buttons-group" > Fecha Original:
                    <b>{formatDate(new Date(defecto.fechorfin))}</b></FormLabel>
            )
        }

    }

    const FechaVer = () => {
        if (defecto.fecverificado === null) {
            return (<FormLabel id="demo-controlled-radio-buttons-group" > Fecha Verificada:
                <b>{defecto.fecverificado}</b></FormLabel>)
        } else {
            return (

                <FormLabel id="demo-controlled-radio-buttons-group" > Fecha Verificado: <b>{formatDate(new Date(defecto.fecverificado))}</b></FormLabel>

            )
        }

    }
    //TIPOS DEFECTOS#######################################################################

    useEffect(() => {
        if (tiposdefectos.length && defecto.codtipdef) {
            setCurrenttiposdefectos(tiposdefectos.find(tipo => tipo.codtipodef === defecto.codtipdef))
        }
    }, [defecto, tiposdefectos])

    const getTipos = useCallback(async () => {
        try {
            setLoadingtiposdef(true)
            const response = await get('/administration/defects/tipo_defectos')
            if (response?.ok) {
                const tipos = await response.json()
                setTiposdefectos(tipos)
            } else {
                eSnack(await getErrorMsg(response), getTipos)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingtiposdef(false) }
    }, [get, eSnack])

    useEffect(() => {
        getTipos()
    }, [getTipos,])

    const selecttiposdefectos = async selectedOption => {
        const codtipdef = { codtipdef: selectedOption?.codtipodef }
        saveField(codtipdef)
    }
    //CLASIFICACION#######################################################################
    useEffect(() => {
        if (clasificacion.length && defecto.codclasifdefecto) {
            setCurrentclasif(clasificacion.find(clasif => clasif.codclasifdefecto === defecto.codclasifdefecto))
        }
    }, [defecto, clasificacion])

    const getClasificacion = useCallback(async () => {
        try {
            setLoadingclasif(true)
            const response = await get('/administration/defects/clasif_causa_defectos')
            if (response?.ok) {
                const clasificaion = await response.json()
                setClasificacion(clasificaion)
            } else {
                eSnack(await getErrorMsg(response), getClasificacion)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingclasif(false) }
    }, [get, eSnack])

    useEffect(() => {
        getClasificacion()
    }, [getClasificacion,])

    const selectclasif = async selectedOption => {
        const codclasifdefecto = { codclasifdefecto: selectedOption?.codclasifdefecto }
        saveField(codclasifdefecto)
    }
    //Satus defecto#######################################################################
    useEffect(() => {
        if (statusdefecto.length && defecto.stsdefecto) {
            setCurrentStatusdefecto(statusdefecto.find(status => status.name === defecto.stsdefecto))
        }
    }, [defecto, statusdefecto])

    const getStatus = useCallback(async () => {
        try {
            setLoadingSTSDEF(true)
            const response = await get('/administration/defects/stsdefecto')
            if (response?.ok) {
                const status = await response.json()
                setStatusDefecto(status)
            } else {
                eSnack(await getErrorMsg(response), getStatus)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingSTSDEF(false) }
    }, [get, eSnack])

    useEffect(() => {
        getStatus()
    }, [getStatus,])

    const selectStatusdefecto = async selectedOption => {
        const stsdefecto = { stsdefecto: selectedOption?.value }
        saveField(stsdefecto)
    }
    //ACTIVIDAD#######################################################################
    useEffect(() => {
        if (actividades.length && defecto.idactdetec) {
            setCurrentActividad(actividades.find(actividad => actividad.idactividad === defecto.idactdetec))
        }
    }, [defecto, actividades])


    const getActividad = useCallback(async () => {
        try {
            setloadingActividad(true)
            const response = await get('/administration/defects/actividad')
            if (response?.ok) {
                const actividades = await response.json()
                setActividades(actividades)
            } else {
                eSnack(await getErrorMsg(response), getActividad)
            }
        } catch (e) {
            console.log(e);
        } finally { setloadingActividad(false) }
    }, [get, eSnack])

    useEffect(() => {

        getActividad()
    }, [getActividad])

    const selectactividad = async selectedOption => {
        const idactividad = { idactividad: selectedOption?.idactividad }
        saveField(idactividad)
    }

    const Item = styled('div')(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(0.7),
        textAlign: 'lefth',
        color: theme.palette.text.primary,
    }));
    const Item2 = styled('div')(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(0.3),
        textAlign: 'left',
        color: theme.palette.text.primary,
    }));
    const Item3 = styled('div')(({ theme }) => ({
        ...theme.typography.h6,
        padding: theme.spacing(0.7),
        textAlign: 'left',
        color: theme.palette.text.primary,
    }));
    return (
        <>
            {/*JSON.stringify( Math.round((new Date().getTime() - new Date(defecto.fechorini).getTime())/(1000*60*60*24)))*/}

            <AppBar position="static" color="transparent">
                <Toolbar variant="dense">
                    <Stack spacing={2} ml={4}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>
                </Toolbar>
            </AppBar>
            <br />

            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12}  >

                        <TabsWrapper
                            onChange={handleTabsChange}
                            value={currentTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </TabsWrapper>
                        <br />
                        <ConditionalWrapper condition={loadingdefecto} wrapper={SkeletonDetailActivity}>
                            {currentTab === 'detalle' && <div> <Box
                                sx={{
                                    // height: 25,
                                    alignItems: "center",
                                    backgroundColor: "#BBDEFB",
                                    /*              '&:hover': {
                                                   backgroundColor: '"#BBDEFB"',
                                                  
                                                 }, */
                                }}
                            >
                                <Item3>

                                    <b>  Defecto </b>
                                </Item3>
                            </Box>
                                {/* <FormLabel> <b> Defecto </b></FormLabel> */}
                                <Divider />
                                <Grid container spacing={1} mb={1}>
                                    <Grid item xs={12} md={7}>
                                        <Paper sx={{ p: 1.5, mt: 1 }} elevation={5}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={4} md={4}>
                                                    <TextField
                                                        id=""
                                                        /* sx={{ width: "50%" }} */
                                                        label="id defecto"
                                                        value={defecto.iddefecto}
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} >
                                                    <Autocomplete
                                                        /* style={{ width: 150 }} */
                                                        fullWidth
                                                        /* nullable={false} */
                                                        options={statusdefecto}
                                                        size="small"
                                                        value={currentStatusdefecto}
                                                        valueKey="name"
                                                        loading={loadingSTSDDEF}
                                                        onChange={async (_, value) => {

                                                            selectStatusdefecto(value)

                                                        }

                                                        }
                                                        getOptionLabel={(option) => `${option.name}`}
                                                        renderInput={(params) => <TextField {...params} required fullWidth label="Estatus" />}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4}>
                                                    <Autocomplete
                                                        /* style={{ width: 150 }} */
                                                        /* nullable={false} */
                                                        loading={loadingtiposdef}
                                                        options={tiposdefectos}
                                                        size="small"
                                                        value={currenttiposdefectos}
                                                        valueKey={"codtipodef"}
                                                        onChange={async (_, value) => {
                                                            selecttiposdefectos(value)

                                                        }
                                                        }
                                                        getOptionLabel={(option) => ` ${option.nomtipodef}`}
                                                        renderInput={(params) => <TextField {...params} required fullWidth label="Tipos de Defectos" />}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Paper sx={{ p: 2.5, mt: 1 }} elevation={5}>
                                            <Grid container>

                                                <Grid item md={6} >

                                                    <FormLabel >Referencia del defecto:</FormLabel>
                                                </Grid>
                                                <Grid item md={6} xs={12} >
                                                    <EditableTextField
                                                        initialValue={defecto.referdef}
                                                        name="referdef"
                                                        save={saveField}
                                                        type={FIELD_TYPES.TEXT}
                                                        placeholder="Referencia"
                                                    />
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Paper elevation={5} sx={{ p: 1, mb: 1 }}>
                                    <Grid container spacing={2} >

                                        <Grid item xs={6} md={3}>
                                            <Autocomplete
                                                /* style={{ width: 210 }} */
                                                /* nullable={false} */
                                                loading={loadingmodulos}
                                                options={modulos}
                                                size="small"
                                                value={currentmodulos}
                                                valueKey={"codmodulo"}
                                                onChange={async (_, value) => {

                                                    selectmodulo(value)
                                                }

                                                }
                                                getOptionLabel={(option) => `${option.codmodulo}-${option.nommodulo}`}
                                                renderInput={(params) => <TextField {...params} required fullWidth label="Modulo" />}
                                            />

                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <Autocomplete
                                                /* style={{ width: 210 }} */
                                                /* nullable={false} */
                                                loading={loadingobjetos}
                                                options={objetos}
                                                size="small"
                                                value={currentobjetos}
                                                valueKey={"nomobjeto"}
                                                onChange={async (_, value) => {
                                                    selectobjeto(value)

                                                }
                                                }
                                                getOptionLabel={(option) => `${option.nomobjeto}`}
                                                renderInput={(params) => <TextField {...params} required fullWidth label="Objetos" />}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <TextField
                                                id=""
                                                /* sx={{ width: "50%" }} */
                                                label="Tipo de objeto"
                                                value={defecto.tipoobjeto}
                                                fullWidth
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={1}>
                                            <TextField
                                                id=""
                                                /* sx={{ width: "50%" }} */
                                                label="Version"
                                                value={defecto.versobjeto}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>

                                            <Autocomplete
                                                /* style={{ width: 210 }} */
                                                /* nullable={false} */
                                                loading={loadingimportancias}
                                                options={importancias}
                                                size="small"
                                                value={currentimportancias}
                                                valueKey={"codimpor"}
                                                onChange={async (_, value) => {
                                                    selectimportancia(value)

                                                }
                                                }
                                                getOptionLabel={(option) => `${option.descimpor}`}
                                                renderInput={(params) => <TextField {...params} required fullWidth label="Importancia" />}

                                            />

                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Box
                                    sx={{
                                        //height: 25,
                                        alignItems: "center",
                                        backgroundColor: "#BBDEFB",
                                        /*              '&:hover': {
                                                       backgroundColor: '"#BBDEFB"',
                                                      
                                                     }, */
                                    }}
                                >
                                    <Item3>

                                        <b>  Detección </b>
                                    </Item3>
                                </Box>
                                <Grid container spacing={0.5}>
                                    <Grid item xs={12} md={6}>
                                        {/*  <FormLabel> <b> Detección</b> </FormLabel> */}
                                        <Divider />
                                        <Stack container sx={{ mt: 1 }}>
                                            <Paper elevation={5} sx={{ p: 4 }}>
                                                <Item>
                                                    <Autocomplete
                                                        /* style={{ width: 210 }} */
                                                        fullWidth
                                                        /* nullable={false} */
                                                        loading={loadingpersona}
                                                        options={personas}
                                                        size="small"
                                                        value={currentpersonas}
                                                        valueKey={"codusu"}
                                                        onChange={async (_, value) => {
                                                            selectpersona(value)

                                                        }
                                                        }
                                                        getOptionLabel={(option) => `${option.codusu}`}
                                                        renderInput={(params) => <TextField {...params} required fullWidth label="Personas" />}
                                                    />

                                                </Item>
                                                <Item>
                                                    <Grid container spacing={2}>

                                                        <Grid item xs={11} md={11} >
                                                            <Autocomplete
                                                                /* style={{ width: 300 }} */
                                                                fullWidth
                                                                /* nullable={false} */
                                                                loading={loadingactividad}
                                                                options={actividades}
                                                                size="small"
                                                                value={currentactividad}
                                                                valueKey={"idactividad"}
                                                                onChange={async (_, value) => {
                                                                    selectactividad(value)

                                                                }
                                                                }
                                                                getOptionLabel={(option) => `${option.idactividad} - ${option.nomactividad}`}
                                                                renderInput={(params) => <TextField {...params} required fullWidth label="Actividad" />}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={1} md={1} mt={1}>
                                                            <Tooltip title={`Abrir Actividad ${defecto.idactdetec}`}>
                                                                <Link component={RouterLink} to={`/activities/${defecto.idactdetec}`}>
                                                                    <AssignmentTwoToneIcon color="secondary" />
                                                                </Link>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Paper>
                                        </Stack>

                                    </Grid>
                                    <Grid item xs={12} md={6}>



                                        {/*  <FormLabel > <b>  Descripción del defecto </b></FormLabel> */}
                                        <Divider />
                                        <Stack container sx={{ mt: 0.7 }}>

                                            <Item2>
                                                <Paper sx={{ p: 2.5, }} elevation={5}>
                                                    Descripción del defecto
                                                    <Grid container spacing={1} marginBottom={0} >
                                                        <Grid item xs ml={2} >
                                                            <EditableTextField
                                                                initialValue={defecto.descdefec}
                                                                name="descdefec"
                                                                save={saveField}
                                                                type={FIELD_TYPES.TEXTAREA}
                                                                placeholder="Descripcion"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Item2>

                                            <Item2>

                                                <Paper elevation={5} sx={{ p: 1.5 }}>
                                                    <Stack sx={{ ml: 2 }}>
                                                        <Item2>

                                                            Fecha: <Box sx={{ p: 0 }}>
                                                                <EditableTextField
                                                                    initialValue={defecto.fecdetec}
                                                                    name="fecdetec"
                                                                    save={saveField}
                                                                    type={FIELD_TYPES.DATE}
                                                                    placeholder="Fecha Detectada"
                                                                    title="Fecha"
                                                                />
                                                            </Box>
                                                        </Item2>
                                                    </Stack>
                                                </Paper>
                                            </Item2>
                                        </Stack>

                                    </Grid>
                                </Grid>
                                <Stack>
                                </Stack>

                            </div>}
                            {currentTab === 'analisis' && <div>
                                <Box
                                    sx={{
                                        //height: 25,
                                        alignItems: "center",
                                        marginBottom: 1,
                                        backgroundColor: "#BBDEFB",
                                        /*              '&:hover': {
                                                       backgroundColor: '"#BBDEFB"',
                                                      
                                                     }, */
                                    }}
                                >
                                    <Item3>

                                        <b>  Introducción </b>
                                    </Item3>
                                </Box>
                                {/* <FormLabel id="demo-controlled-radio-buttons-group"> <b> Introducción </b> </FormLabel> */}
                                <Divider />
                                <Paper elevation={5} sx={{ p: 1.5 }}>
                                    <Grid container spacing={2} >

                                        <Grid item xs={4} md={1.8} mt={1} >
                                            <FormLabel id="demo-controlled-radio-buttons-group" >Inicio de corrección</FormLabel>
                                        </Grid>
                                        <Grid item xs={4} md={1.8}  >
                                            {ckeckFecha()}

                                        </Grid>
                                        <Grid item xs={4} md={3.2} mt={1} >
                                            {fecha()}
                                        </Grid>
                                        <Grid item xs={6} md={2.4} mt={1} >
                                            <FormLabel id="demo-controlled-radio-buttons-group" >Tiempo Estimado de corrección</FormLabel>
                                        </Grid>
                                        <Grid item xs={6} md={2.4} mt={1}>
                                            <EditableTextField
                                                initialValue={defecto.tiempocorrest}
                                                /* style={{ width: 100 }} */
                                                fullWidth
                                                name="tiempocorrest"
                                                save={saveField}
                                                type={FIELD_TYPES.TEXT}
                                                placeholder="Tiempo Estimado"
                                                title="Tiempo Estimado "
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>


                                <Grid container spacing={0.5}>

                                    <Grid item xs={12} md={12}>

                                        <Paper elevation={5} sx={{ p: 1, mt: 1 }}>
                                            <Stack container>
                                                <Item>
                                                    <Grid container spacing={2}>



                                                        <Grid item xs={6} md={6}>
                                                            <Autocomplete
                                                                /* style={{ width: 210 }} */
                                                                fullWidth
                                                                /* nullable={false} */
                                                                loading={loadingpersona}
                                                                options={personas}
                                                                size="small"
                                                                value={currentpersonasIntrod}
                                                                valueKey={"codusu"}
                                                                onChange={async (_, value) => {
                                                                    selectpersonaIntrod(value)

                                                                }
                                                                }
                                                                getOptionLabel={(option) => `${option.codusu}`}
                                                                renderInput={(params) => <TextField {...params} required fullWidth label="Asignar Persona" />}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={12} md={6} mt={1.5}>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={2.5} md={2.5} >
                                                                    <FormLabel id="demo-controlled-radio-buttons-group" > Asignar Fecha:
                                                                    </FormLabel>
                                                                </Grid>
                                                                <Grid item xs={9.5} md={9.5}>


                                                                    <EditableTextField
                                                                        className={classes.inputpt}
                                                                        initialValue={defecto.fecasig}
                                                                        name="fecasig"
                                                                        save={saveField}
                                                                        type={FIELD_TYPES.DATE}
                                                                        placeholder="Fecha asignada"

                                                                    />


                                                                </Grid>

                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                </Item>
                                                <Stack container>
                                                    <Item>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} md={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={11} md={11} >
                                                                        <TextField

                                                                            id=""
                                                                            label="Actividad Asignada"
                                                                            value={`${currentactividad?.idactividad} - ${currentactividad?.nomactividad}`}
                                                                            fullWidth
                                                                            size="small"


                                                                        />

                                                                    </Grid>

                                                                    {!!defecto.idactasig &&
                                                                        <Grid item xs={1} md={1} mt={1}>
                                                                            <Tooltip title={`Abrir Actividad ${defecto.idactasig}`}>
                                                                                <Link component={RouterLink} to={`/activities/${defecto.idactasig}`}>
                                                                                    <AssignmentTwoToneIcon color="secondary" />
                                                                                </Link>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    }
                                                                </Grid>

                                                            </Grid>


                                                        </Grid>


                                                    </Item>

                                                </Stack>


                                                <Stack container>
                                                    <Item>


                                                    </Item>
                                                </Stack>
                                            </Stack>
                                        </Paper>

                                    </Grid>

                                </Grid>

                                <Grid container spacing={2} marginY={0.4}>
                                    <Grid item xs={2} md={2} mt={1.3} >
                                        <FormLabel id="demo-controlled-radio-buttons-group" >Corrección Lista</FormLabel>
                                    </Grid>
                                    <Grid item xs={2} md={2} >
                                        {ckeckListo()}
                                    </Grid>


                                    <Grid item xs={2} md={2} mt={1.3} >
                                        <FormLabel id="demo-controlled-radio-buttons-group" >Corrección Verificada</FormLabel>
                                    </Grid>
                                    <Grid item xs={2} md={2} >
                                        {ckeckVerificado()}
                                    </Grid>

                                    <Grid item xs={2} md={2} mt={1.3} >
                                        <FormLabel id="demo-controlled-radio-buttons-group" >No Corregido</FormLabel>
                                    </Grid>
                                    <Grid item xs={2} md={2} >
                                        {ckeckNoCorregido()}
                                    </Grid>

                                </Grid>
                                <Paper elevation={5} sx={{ marginY: 1, p: 2.5 }}>

                                    <Grid container spacing={2}>
                                        <Grid item xs={4} md={2.4}  >
                                            {FechaOR()}
                                            {/*                                     <FormLabel id="demo-controlled-radio-buttons-group" > Fecha Original: 
                                    <b>{formatDate(new Date(defecto.fechorfin))}</b></FormLabel> */}
                                        </Grid>

                                        <Grid item xs={4} md={2.4} >
                                            {FechaVer()}
                                            {/*                                     <FormLabel id="demo-controlled-radio-buttons-group" > Fecha Verificado: <b>{formatDate(new Date(defecto.fecverificado))}</b></FormLabel>
                             */}    </Grid>
                                        <Grid item xs={4} md={2.4}  >
                                            <FormLabel id="demo-controlled-radio-buttons-group" > Contador: < b>{defecto.contnocorreccion}</b></FormLabel>
                                        </Grid>

                                        <Grid item xs={6} md={2.4} >
                                            <FormLabel id="demo-controlled-radio-buttons-group" >Tiempo real de corrección</FormLabel>
                                        </Grid>
                                        <Grid item xs={6} md={2.4} >
                                            <EditableTextField
                                                initialValue={defecto.tiempocorr}
                                                fullWidth
                                                name="tiempocorr"
                                                save={saveField}
                                                type={FIELD_TYPES.TEXT}
                                                placeholder="Tiempo real"
                                                title="Tiempo Real de corrección "
                                            />
                                        </Grid>

                                    </Grid>

                                </Paper>
                                <Box
                                    sx={{
                                       //height: 25,
                                        alignItems: "center",
                                        /* marginBottom: 1, */
                                        backgroundColor: "#BBDEFB",
                                        /*              '&:hover': {
                                                       backgroundColor: '"#BBDEFB"',
                                                      
                                                     }, */
                                    }}
                                >
                                    <Item3>

                                        <b>  Causa </b>
                                    </Item3>
                                </Box>

                                {/*  <FormLabel id="demo-controlled-radio-buttons-group"> <b>Causa:</b></FormLabel> */}
                                <Divider />
                                <Paper elevation={5} sx={{ mt: 1, p: 1.5 }}>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6} md={6} mt={1}>
                                            <Autocomplete
                                                fullWidth
                                                /* nullable={false} */
                                                loading={loadingclasif}
                                                options={clasificacion}
                                                size="small"
                                                value={currentclasif}
                                                valueKey={"codclasifdefecto"}
                                                onChange={async (_, value) => {
                                                    selectclasif(value)

                                                }
                                                }
                                                getOptionLabel={(option) => `${option.codclasifdefecto}-${option.desclasifdefecto}`}
                                                renderInput={(params) => <TextField {...params} required fullWidth label="Clasificación Causa" />}

                                            />

                                        </Grid>
                                        <Grid item xs={6} md={6}  >
                                            <Item2>
                                                Descripción Causa
                                            </Item2>
                                            <EditableTextField

                                                initialValue={defecto.desccausadef}
                                                label="Causa"
                                                name="desccausadef"
                                                save={saveField}
                                                type={FIELD_TYPES.TEXTAREA}
                                                placeholder="Causa"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>}
                        </ConditionalWrapper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
