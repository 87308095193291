import {useCallback, useRef} from "react"
function defaultHeaders({contentType = false} = {}){
  return {
    // "X-CSRF-TOKEN": document.querySelector('[name="csrf-token"]').content,
    "Authorization": localStorage.getItem("token") || "",
    "Auth-User": localStorage.getItem("email") || "",
    ...(!!contentType && {"Content-Type": contentType})//"application/x-www-form-urlencoded"//"application/json"
  };
}

const handleData = (data = null)=>{
  switch (true) {
    case data instanceof FormData:
      return JSON.stringify(Object.fromEntries(data))
    case data instanceof Object:
      return JSON.stringify(data)
    default:
      return null
  }
}

export const handleQueryString = (data = null)=>{
  switch (true) {
    case data instanceof FormData:
      return ""
    case data instanceof Object:
      return `?${Object.keys(data).map( key => {
        switch (true) {
          case !data[key]:
            return null
          case data[key] instanceof Array:
            return data[key].map(arrayValue => `${key}[]=${encodeURIComponent(arrayValue)}`).join("&")
          case data[key] instanceof Object:
            return Object.keys(data[key]).map(paramKey => `${key}[${paramKey}]=${encodeURIComponent(data[key][paramKey])}`).join("&")
          default:
            return `${key}=${encodeURIComponent(data[key])}`
        }
      }).filter(Boolean).join("&")}`
    default:
      return ""
  }
}

export function postJson(url, data = null, controller){
  return fetch(`${process.env.REACT_APP_API_URL}${url}`,{
    signal: controller && controller.signal,
    method: "POST",
    body: handleData(data),
    headers:defaultHeaders({contentType:'application/json'})
  }).catch(e => {
    if(e.name !== "AbortError"){
      console.error("Catched Error", e);
    }else{
      console.log("cancelled");
    }
    return null
  })
}

export function patchJson(url, data = null, controller){
  return fetch(`${process.env.REACT_APP_API_URL}${url}`,{
    signal: controller && controller.signal,
    method: "PATCH",
    body: handleData(data),
    headers:defaultHeaders({contentType:'application/json'})
  }).catch(e => {
    if(e.name !== "AbortError"){
      console.error("Catched Error", e);
    }else{
      console.log("cancelled");
    }
    return null
  })
}

export function postForm(url, data = null, controller){
  return fetch(`${process.env.REACT_APP_API_URL}${url}`,{
    signal: controller && controller.signal,
    method: "POST",
    body: data,
    headers: defaultHeaders()
  }).catch(e => {
    if(e.name !== "AbortError"){
      console.error("Catched Error", e);
    }else{
      console.log("cancelled");
    }
    return null
  })
}

export function getJson(url,data=null,controller){
  return fetch(`${process.env.REACT_APP_API_URL}${url}${handleQueryString(data)}`,{
    signal: controller && controller.signal,
    method: "GET",
    headers:defaultHeaders()
  }).catch(e => {
    if(e.name !== "AbortError"){
      console.error("Catched Error", e);
    }else{
      console.log("cancelled");
    }
    return null
  })
}

export function destroyJson(url,data=null,controller){
  return fetch(`${process.env.REACT_APP_API_URL}${url}${handleQueryString(data)}`,{
    signal: controller && controller.signal,
    method: "DELETE",
    headers:defaultHeaders()
  }).catch(e => {
    if(e.name !== "AbortError"){
      console.error("Catched Error", e);
    }else{
      console.log("cancelled");
    }
    return null
  })
}

export const useRequests = () => {
  const controller = useRef(new AbortController())
  const get = useCallback((url, data) => getJson(url, data, controller.current),[])
  const post = useCallback((url, data) => postJson(url, data, controller.current),[])
  const patch = useCallback((url, data) => patchJson(url, data, controller.current),[])
  const destroy = useCallback((url, data) => destroyJson(url, data, controller.current),[])
  const form = useCallback((url, data) => postForm(url, data, controller.current),[])
  return {get, post, patch, form, destroy, controller: controller.current}
}


export const getErrorMsg = async response => {
  var msg = null
  try{
    const json = await response.clone().json()
    msg = json.msg || json.message || json.error
  }catch(e){
    try{
      msg = response?.statusText
      msg = msg || "cancelled_request"
    }catch(e){
      console.log(e);
      try {
        msg = "Error no manejado"
      }catch(e){}
    }
  }
  return msg
}

window.testGet = getJson
