import React from 'react'
import {ClientsList} from '../.'
import {Route, Switch} from "react-router-dom"

export const Clients = ({match}) =>{
  return (
    < /* className="base-container" */>
      <Switch>
        <Route exact path={`${match.path}/`} component={ClientsList}/>
      </Switch>
    </>
  )
}
