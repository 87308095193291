import React, { useState, useEffect, useCallback } from "react";
import { BaseDialog } from "../layout"
import { FORM_SUBMIT_TYPES, BaseNewForm} from "../layout/basic/new_form_v2";
import { useRequests, getErrorMsg, useCustomSnackbar, useBackdrop, FIELD_TYPES } from "../../helpers";

export const AssignTicketDialog = React.memo(({assignTicketOpen, ticket={}, reload, handleClose}) => {
  const [assignables, setAssignables] = useState([]);
  const { show, off } = useBackdrop(); //this is the hook of backdrop
  const { eSnack, sSnack, iSnack } = useCustomSnackbar();
  const { get, post, controller } = useRequests();
  const [loadingAC, setloadingAC] = useState(false)
  const formId = "asignar"

  const getAssignables = useCallback(async () => {
    try {
      // iSnack("Cargando Usuarios Asignables");
      setloadingAC(true)
      var response = await get("/tickets/assignables");
      if (response?.ok) {
        const data = await response.json();
        setAssignables(data);
      } else {
        eSnack(await getErrorMsg(response), getAssignables);
        console.error("Error Retrieving Assignables");
      }
    } catch (e) {
      eSnack("Error inesperado cargando usuarios assignables", getAssignables);
      console.error("Retrieving Assignables:", e);
    }finally {setloadingAC(false)}
  }, [get, eSnack]);

  const assignTicket = async data => {
    const assigned = data.assigned
    if (ticket) {
      show("Asignando Ticket");
      try {
        if (!assigned || assigned.length < 1) {
          iSnack("Por favor selecciona al menos un usuario");
          return;
        }
        var response = await post("/tickets/assign", {
          id: ticket?.idinc,
          type: ticket?.tipinc,
          assignations: assigned.map((user) => {
            return { idpers: user.idpers };
          }),
        });
        if (response?.ok) {
          const ticket = await response.json()
          handleClose();
          reload(ticket);
          sSnack(`Ticket Asignado`);
        } else {
          eSnack(await getErrorMsg(response));
          console.error("Error assigning ticket:");
        }
      } catch (e) {
        eSnack("Error asignando ticket");
        console.error("Assigning Ticket:", e);
      } finally {
        off();
      }
    } else {
      eSnack("Error interno ticket no seleccionado");
    }
  };

  useEffect(() => {
    if (assignTicketOpen && !assignables.length) {
      getAssignables();
    }
  }, [getAssignables, assignTicketOpen, assignables.length]);

  useEffect(() => () => controller.abort(), [controller]);

  return (
    <BaseDialog
      dialogOpen={assignTicketOpen}
      handleClose={handleClose}
      title={`Asignar Ticket ${ticket.idinc}`}
      confirmText={"Asignar"}
      cancelText={"Cerrar"}
      formId={formId}
      confirm
      size="sm"
    >
      <BaseNewForm
        fields={{
          assigned:{
            title:"Asignados",
            id:"asignado",
            type:FIELD_TYPES.AUTOCOMPLETE,
            options:assignables,
            value:ticket?.assigned_users,
            valueKey:"idpers",
            getLabel: option => `${option.idpers}-${option.nombpers}-${option.codusu}`,
            loading:loadingAC,
            multiple: true,
            extraProps:{
              disableClearable: true,
              disableCloseOnSelect: true
            }
          }
        }}
        dialog={true}
        formId={formId}
        submit={assignTicket}
        submitType={FORM_SUBMIT_TYPES.JSON}
      />
    </BaseDialog>
  );
});
