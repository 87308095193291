import React, { useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Toolbar, IconButton, Typography, List, ListItem, ListItemText, Collapse, Divider, ListItemIcon, Drawer } from '@mui/material'
/* import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; */
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import logo from '../../images/kentron_original.png';
import { Alerts } from "."
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useConfirmationDialog, useCurrentUser } from "../../helpers";
import MenuIcon from '@mui/icons-material/Menu';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TopicIcon from '@mui/icons-material/Topic';

import HandymanIcon from '@mui/icons-material/Handyman';
import PeopleIcon from '@mui/icons-material/People';
import QuizIcon from '@mui/icons-material/Quiz';


import {
  Avatar,
  Box,
  Button,

  Hidden,
  lighten,

  Popover,

} from '@mui/material';


import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';


const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.primary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.primary.main, 0.5)}
`
);



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    // width:"100%"
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'flex',
    marginLeft: "auto"
  },
  logo: {
    maxHeight: 60
  },
  linkHolder: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
    padding: 5,
    borderRadius: "20%"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },

}));
const drawerWidth = 240;

export const Navbar = React.memo(({ log_out, suffix, links, loggedIn }) => {
  const classes = useStyles();
  const [openAdministration, setOpenAdministration] = useState(false);
  const [openRegistro, setOpenRegistro] = useState(false);
  const currentUser = useCurrentUser()

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { getConfirmation } = useConfirmationDialog();

  const onClick = async () => {
    if (await getConfirmation({ title: "Cerrar Sesión!", message: "Seguro desea cerrar Sesión?", })) log_out()
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem button onClick={() => setOpenRegistro(!openRegistro)}>
          <ListItemText primary="Registro" />
          {openRegistro ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openRegistro} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} component={RouterLink} to="/tickets/list">
              <ListItemIcon>
                <StickyNote2Icon />
              </ListItemIcon>
              <ListItemText primary={"Tickets"} />
            </ListItem>
            {(currentUser.can?.see_activities) &&
              <ListItem button className={classes.nested} component={RouterLink} to="/activities/list">
                <ListItemIcon>
                  <TopicIcon />
                </ListItemIcon>
                <ListItemText primary={"Actividades"} />

              </ListItem>}
            {currentUser?.can?.see_adr && <ListItem button className={classes.nested} component={RouterLink} to="/administration/Adr">
              <ListItemIcon>
                <BubbleChartIcon />
              </ListItemIcon>
              <ListItemText primary="ADR" />
            </ListItem>}
            {currentUser?.can?.see_wo && <ListItem button className={classes.nested} component={RouterLink} to="/administration/orders">
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="Órdenes de Trabajo" />
            </ListItem>}
            {currentUser?.can?.see_wo && <ListItem button className={classes.nested} component={RouterLink} to="/administration/defects">
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary="Defectos" />
            </ListItem>}
            {currentUser?.can?.see_wo && <ListItem button className={classes.nested} component={RouterLink} to="/administration/quiz">
              <ListItemIcon>
                <QuizIcon />
              </ListItemIcon>
              <ListItemText primary="Encuestas" />
            </ListItem>}
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => setOpenAdministration(!openAdministration)}>
          <ListItemText primary="Configuración" />
          {openAdministration ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openAdministration} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          {currentUser?.can?.check_systems &&  <ListItem button className={classes.nested} component={RouterLink} to="/administration/clients">
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
            </ListItem>}
            {currentUser?.can?.check_systems && <ListItem button className={classes.nested} component={RouterLink} to="/administration/systems">
              <ListItemIcon>
                <HandymanIcon />
              </ListItemIcon>
              <ListItemText primary="Sistemas" />
            </ListItem>}
            <ListItem button className={classes.nested} component={RouterLink} to="/administration/users">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={"Usuarios"} />
            </ListItem>
            {/*             <ListItem button className={classes.nested} component={RouterLink} to="/users">
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary={"Perfil"} />
            </ListItem> */}
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar className={classes.root}>
          <Link underline="none" component={RouterLink} to="/">
            <img src={logo} alt="Logo" className={classes.logo} />
          </Link>
          <Typography className={classes.title} variant="h6" noWrap>
            <Link color="textPrimary" underline="none" component={RouterLink} to="/">
              Soporte {suffix}
            </Link>
          </Typography>
          {links?.map((route, index) => {
            return (
              <Link color="textPrimary" key={`route-${index}`} underline="none" component={RouterLink} to={route.route}>
                <div className={classes.linkHolder}>
                  <Typography variant="button" noWrap >
                    {route.title}
                  </Typography>
                </div>
              </Link>)
          })}
          {loggedIn &&
            <>
              <div className={classes.sectionDesktop}>

                <Box sx={{ mx: .5, mt: 1 }} component="span">
                  <Alerts />
                </Box>

                <Box sx={{ mx: .5 }}>

                  <UserBoxButton color="primary" ref={ref} onClick={handleOpen} size="small">
                    <Avatar variant="rounded" alt={currentUser?.name} src={currentUser?.avatar} sx={{ width: 36, height: 36 }} />
                    <Hidden mdDown>
                      <UserBoxText size="small">
                        <UserBoxLabel variant="body2">{currentUser?.name}</UserBoxLabel>
                        <UserBoxDescription variant="overline">
                          {currentUser?.client}
                        </UserBoxDescription>
                      </UserBoxText>
                    </Hidden>
                    <Hidden smDown>
                      <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
                    </Hidden>
                  </UserBoxButton>
                  <Popover
                    anchorEl={ref.current}
                    onClose={handleClose}
                    open={isOpen}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <MenuUserBox /* sx={{ minWidth: 210 }} */ display="flex">
                      <Avatar variant="rounded" alt={currentUser?.name} src={currentUser?.avatar} />
                      <UserBoxText>
                        <UserBoxLabel variant="body1">{currentUser?.name}</UserBoxLabel>
                        <UserBoxDescription variant="body2">
                          {currentUser?.client}
                        </UserBoxDescription>
                      </UserBoxText>
                    </MenuUserBox>
                    <Divider sx={{ mb: 0 }} />
                    <List sx={{ p: 1 }} component="nav">
                      <ListItem button component={RouterLink} to="/users">
                        <AccountBoxTwoToneIcon fontSize="small" />
                        <ListItemText primary="Mi Perfil" />
                      </ListItem>
                      <ListItem
                        button
                        component={RouterLink} to="/users/configurations"
                      >
                        <AccountTreeTwoToneIcon fontSize="small" />
                        <ListItemText primary="Configuracion" />
                      </ListItem>
                    </List>
                    <Divider />
                    <Box sx={{ m: 1 }}>
                      <Button color="primary" fullWidth onClick={onClick}>
                        <LockOpenTwoToneIcon sx={{ mr: 1 }} />
                        Cerrar Sesion
                      </Button>
                    </Box>
                  </Popover>
                </Box>
                <Box sx={{ mx: .5 }}>
                  <IconButton
                    sx={{ color: "primary" }}
                    edge="end"
                    aria-label="open drawer"
                    aria-haspopup="true"
                    onClick={handleDrawerToggle}
                    color="primary"
                    size="large">
                    <MenuIcon />
                  </IconButton>
                </Box>
                {/* <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={onClick}
                  color="inherit"
                  size="large">
                  <ExitToAppIcon />
                </IconButton> */}
              </div>
            </>}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      {loggedIn}
    </div>
  );
})
