import React, {useState, useCallback, useEffect} from "react"
import { BaseDialog } from '../../layout'
import makeStyles from '@mui/styles/makeStyles';
import {useRequests, useCustomSnackbar, getErrorMsg, useCurrentUser, useBackdrop, useConfirmationDialog} from "../../../helpers"
import {ConditionalWrapper, SkeletonList} from "../../layout"
import { List, ListItem, ListItemText, Accordion, AccordionSummary, Typography, AccordionDetails, ListItemSecondaryAction, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    display: "flex",
    flexDirection:"column"
  },
  listHolder: {
    flex:9,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  newHolder: {
    flex:1
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  summary: {
    backgroundColor: theme.palette.primary.light
  },
  accordion: {
    marginBottom: theme.spacing(0.5)
  }
}));

export const InstalledDialog = React.memo(({ currentClient=null, dialogOpen, handleClose:closeD })=> {
  const classes = useStyles();
  const currentUser = useCurrentUser()
  const [installed, setInstalled] = useState({})
  const {get, controller, destroy} = useRequests()
  const {eSnack, sSnack} = useCustomSnackbar()
  const {show, off} = useBackdrop()
  const [loadingList, setLoadingList] = useState(false)
  const {getConfirmation} = useConfirmationDialog()

  const getInstalled = useCallback( async () =>{
    if(currentClient){
      // iSnack("Cargando Instalaciones")
      try{
        setLoadingList(true)
        const response = await get(`/administration/installations/${currentClient}`)
        if(response?.ok) {
          const data = await response.json()
          setInstalled(data)
        }else{
          eSnack(await getErrorMsg(response), getInstalled)
        }
      }catch(e){
        console.log(e);
        eSnack(`Error inesperado obteniendo instalaciones para ${currentClient}`)
      }finally{setLoadingList(false)}
    }
  },[get, eSnack, currentClient])

  useEffect(()=>{
    if(dialogOpen) getInstalled()
  },[currentClient, dialogOpen, getInstalled])

  const handleClose = () => {
    setInstalled({})
    closeD()
  }

  const handleDesinstallation = useCallback(async system_module =>{
    show("Desinstalando Módulo")
    try{
      var response = await destroy(`/administration/installations/${system_module.id}`, {client: currentClient})
      if(response?.ok){
        const data = await response.json()
        setInstalled(data)
        sSnack(`Módulo desinstalado`)
      }else{
        eSnack(await getErrorMsg(response))
        console.log("Error desinstalling modules");
      }
    }catch(e){
      eSnack("Error desinstalando módulos")
      console.error(e);
    }finally{off()}
  },[sSnack, eSnack, destroy, off, show, currentClient])


  useEffect(() => () => controller.abort(), [controller])

  const generateListItems = items => {
    return (
      <List
          className={classes.root}
          component="nav"
          aria-labelledby="nested-list-subheader"
      >
        {items.map((system_module, index)=>{
          return (
            <ListItem key={`system-module-${index}`} button>
              <ListItemText primary={`${system_module.codmodulo} - ${system_module.h_system_module}`} />
                <ListItemSecondaryAction>
                  <IconButton size="large">
                    <CheckCircleTwoToneIcon color="secondary"/>
                  </IconButton>
                  {currentUser.can?.destroy_installations && <IconButton
                    onClick={async () => {
                      if(await getConfirmation({title: "Desinstalar Módulo", message:`Seguro desea Desinstalar Modulo ${system_module.codmodulo} - ${system_module.h_system_module}?`})){
                        handleDesinstallation(system_module)
                      }
                    }}
                    size="large">
                    <DeleteForeverTwoToneIcon color="secondary"/>
                  </IconButton>}
                </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }



  const generateList = () => {
    const keys = Object.keys(installed)
    return keys.map((system, index)=>{
      let h_system = installed[system][0]?.h_system
      return(
        <Accordion key={`system-${index}`} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            className={classes.summary}
          >
            <Typography className={classes.heading}>{`${system} - ${h_system}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {generateListItems(installed[system])}
          </AccordionDetails>
        </Accordion>
      )
    })
  }

  return (
    <BaseDialog
      dialogOpen={dialogOpen}
      handleClose={handleClose}
      title={`Instalaciones ${currentClient}`}
      cancelText="Cerrar"
      width="sm"
      >
      <div className={classes.container}>
        <div className={classes.listHolder}>
          <ConditionalWrapper condition={loadingList} wrapper={c => <SkeletonList number={5}>{c}</SkeletonList>}>
            {generateList()}
          </ConditionalWrapper>
        </div>
      </div>
    </BaseDialog>
  )
})
