import React, {useEffect} from 'react'
import {Box} from '@mui/material';
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {useBackdrop} from '../../helpers'


export const PRIORITIES = Object.freeze({
  1: "HIGH",
  2: "NORMAL",
  3: "LOW"
})


export const Error404 = props => {
  return(
    <div>404 Page Not Found</div>
  )
}

/*
Parameters:
  condition[Bool]: true/false for wrapping the children parameter with the wrapper
  wrapper[function]: function used to wrap the children parameter if conition is true Example wrapper={children => <Tooltip title={vRow.tooltip || vRow.content || ""}>{children}</Tooltip>}
  children[Component]: Component that would be wrapped by wrapper parameter aka props.children is by default the component being wrapped by ConditionalWrapper
*/
export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

export const TabPanel =  ({ children, value, index, priority, ...other }) => {

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const PriorityDisplay = ({priority, text}) => {
  var icon
  switch(PRIORITIES[priority]){
    case "HIGH":
      icon = <ArrowUpwardSharpIcon color="error"/>
    break;
    case "NORMAL":
      icon = <LinearScaleIcon color="action"/>
    break;
    case "LOW":
      icon = <ArrowDownwardSharpIcon color="primary"/>
    break;
    default:
      icon = ""
  }
  return <div className="priority-holder">{icon} <b>{text}</b></div>
}


/*
CheckedUnchecked: recibe como parametro validate[Bool]: true/false;
 si es true retorna el icono de ✔ en azul;
  en caso contrario una x roja
*/
export const CheckedUnchecked = ({validate}) => {
  var icon

    if(validate === true){
      icon = <CheckIcon size = "small" color="primary"/>
    }else{
      icon = <CloseIcon size = "small" color="error"/>
    }

  return <div className="order-holder">{icon}</div>
}

export const CheckingVersion = props => {
  const {show, off} = useBackdrop()
  useEffect(()=>{
    show("Verificando Versión De la Aplicación")
    return () => off()
  },[show, off])
  return <></>
}
