import { Button } from '@mui/material';
import React from 'react';

const CardButton = ({children}) => {
  return (
    <div style={{textAlign: 'left'}}>
    <Button style={{backgroundColor: '#f5f5f5', color: '#ff8c00', fontWeight: 'bold', margin: '25px auto', display: 'block', width: '250px', height: '100px'}}>
      {children}
    </Button>
  </div>
  
  );
};

export default CardButton;
