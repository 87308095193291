import React, {useState, useEffect} from 'react'
import '../../stylesheets/users.scss'
import {Configurations, Profile} from '.'
import {TabPanel} from '../layout'
import {
  AppBar,
  Toolbar,
  Stack,
  Breadcrumbs,
  Typography,
  Container
} from '@mui/material';
import { Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';

const DEFAULT_MAP = Object.freeze({
  profile: 0,
  configurations: 1
})
export const Users = ({match}) =>{
  const [tab, setTab] = useState(DEFAULT_MAP[match.params.default] || 0)

  useEffect(()=>{
    setTab(DEFAULT_MAP[match.params.default] || 0)
  },[match.params.default])

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      Perfil de Usuario
    </Typography>,
  ];

  return (
    <>
<AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>

  
<Container maxWidth="xl">

        <TabPanel value={tab} index={0}>
          <Profile />
        </TabPanel>
</Container>
<Container maxWidth="md">
  
        <TabPanel value={tab} index={1}>
          <Configurations />
        </TabPanel>
</Container>
    </>
  )
}
