import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useRequests, useCustomSnackbar, getErrorMsg, useCurrentUser, useBackdrop } from '../../../helpers';
import { Autocomplete, BaseDialog } from "../../layout";
import { DraftDescription } from "../."
import { EditorState, convertToRaw } from 'draft-js';
import {
  TextField,
  Typography,
  Paper,
  Chip,
  Box,
  Grid,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
//import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  input: {
    display: 'none'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  chipHolder: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    marginBottom: theme.spacing(0.5)
  },

  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 850,
  },

}));

export const NewTicket = ({  open, handleClose, reload }) => {
  const [subject, setSubject] = useState("")
  const history = useHistory();
  // const [description, setDescription] = useState("")
  const [descriptionState, setDescriptionState] = useState(() => EditorState.createEmpty())
  const [systems, setSystems] = useState([])
  const [system, setSystem] = useState(null)
  const [users, setUsers] = useState([])
  const [inNameOf, setInNameOf] = useState(null)
  const [tipos/*, setTipos*/] = useState([{ value: "SOP", name: "Soporte" }, { value: "REQ", name: "Requerimiento" }])
  const [tipo, setTipo] = useState(tipos[0])
  const [systemModules, setSystemModules] = useState([])
  const [systemModule, setSystemModule] = useState(null)
  const [form, setForm] = useState("")
  const [version, setVersion] = useState("")
  const [priorities, setPriorities] = useState([])
  const [priority, setPriority] = useState(null)
  const { show, off } = useBackdrop()
  const { eSnack, sSnack } = useCustomSnackbar()
  const { get, form: requestForm, controller } = useRequests()
  const [files, setFiles] = useState([])
  const filesRef = useRef()
  const classes = useStyles();
  const currentUser = useCurrentUser()
  const [loadingUS, setloadingUS] = useState(false);
  const [loadingGN, setloadingGN] = useState(false);
  const [loadingMD, setloadingMD] = useState(false);
  const [loadingPP, setloadingPP] = useState(false);



  const handleSubmit = async e => {
    e.preventDefault()
    show('Creando Ticket')
    const data = {
      descprobinc: subject,
      problema: descriptionState.getCurrentContent().getPlainText('\u0001'),
      fproblema: JSON.stringify(convertToRaw(descriptionState.getCurrentContent())),
      codsistema: system?.codsistema,
      tipinc: tipo?.value,
      codmodulo: systemModule?.codmodulo,
      referencia: form,
      version: version,
      prioridad: priority?.prioridad,
    }
    const formData = new FormData()
    Object.keys(data).forEach(key => formData.append(`ticket[${key}]`, data[key]))
    files.forEach(file => formData.append("ticket[files][]", file))
    if (inNameOf) formData.append("in_name_of", inNameOf.idpers)
    try {
      const request = await requestForm('/tickets', formData)
      if (request?.ok) {
              const {idinc} = await request.json()
              history.push(`/tickets/${idinc}`)
        sSnack("Ticket creado.")
        reload()
        handleClose()
      } else {
        eSnack(await getErrorMsg(request))
        console.log("Error creating ticket");
      }
    } catch (e) {
      eSnack("Error inesperado creando ticket")
      console.log("Error creating ticket", e);
    } finally {
      off()
    }
  }

  const handleForm = e => {
    const { value, id } = e.target
    switch (id) {
      case 'subject':
        setSubject(value)
        break
      // case 'description':
      //   setDescription(value)
      //   break
      case 'form':
        setForm(value)
        break
      case 'version':
        setVersion(value)
        break
      default:
        break
    }
  }

  const getSystems = useCallback(async () => {
    try {
      setloadingGN(true);
      setSystem(null)
      const client = inNameOf?.codcli || ""
      // iSnack("Obteniendo Sistemas")
      const response = await get(`/administration/systems/installed/${client}`)
      if (response?.ok) {
        const systems = await response.json()
        setSystems(systems)
      } else {
        eSnack(`Sistemas: ${await getErrorMsg(response)}`, getSystems)
        console.log("Error getting systems");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo sistemas", getSystems)
      console.log("Error getting systems", e);
    } finally { setloadingGN(false); }
  }, [eSnack, get, inNameOf])

  const getUsers = useCallback(async () => {
    /*    show("Cargando Usuarios") */
    try {
      setloadingUS(true);
      var response = await get("/tickets/users")
      if (response?.ok) {
        const newUsers = await response.json()
        setUsers(newUsers)
      } else {
        eSnack(await getErrorMsg(response), getUsers)
        console.error("Error Retrieving Users");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo Usuarios")
      console.error("Error Retrieving Users", e);
    } finally { setloadingUS(false); }
  }, [get, eSnack])

  const getPriorities = useCallback(async () => {
    // iSnack("Obteniendo Prioridades")
    try {
      setloadingPP(true);
      const response = await get('/priorities')
      if (response?.ok) {
        const priorities = await response.json()
        setPriorities(priorities)
      } else {
        eSnack(`Prioridades: ${await getErrorMsg(response)}`, getPriorities)
        console.log("Error getting priorities");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo prioridades", getPriorities)
      console.log("Error getting priorities", e);
    } finally {
      setloadingPP(false);
    }
  }, [eSnack, get])

  const getSystemModules = useCallback(async () => {
    // iSnack("Obteniendo Módulos del Sistema")
    const selectedSystem = system?.codsistema || ""
    const client = inNameOf?.codcli || ""
    try {
      setloadingMD(true);
      const response = await get(`/administration/system_modules/installed/${selectedSystem}/${client}`)
      if (response?.ok) {
        const system_modules = await response.json()
        setSystemModules(system_modules)
      } else {
        eSnack(`Módulos: ${await getErrorMsg(response)}`, getSystemModules)
        console.log("Error getting modules");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo módulos", getSystemModules)
      console.log("Error getting modules", e);
    } finally {
      setloadingMD(false);
    }

  }, [system, eSnack, get, inNameOf])

  useEffect(() => {
    getSystems()
  }, [getSystems])

  useEffect(() => {
    getPriorities()
  }, [getPriorities])

  useEffect(() => {
    if (system) {
      getSystemModules()
    }
  }, [getSystemModules, system])

  useEffect(() => {
    if (currentUser.can?.create_in_name_of) getUsers()
  }, [getUsers, currentUser.can])

  useEffect(() => () => controller.abort(), [controller])

  const changeFiles = e => {
    const files = e.target?.files || []
    setFiles([...files])
  }

  const handleFileDelete = (e, index) => {
    const dt = new DataTransfer()
    files.forEach((file, eIndex) => {
      if (index !== eIndex) {
        dt.items.add(file)
      }
    })
    filesRef.current.files = dt.files
    setFiles([...filesRef.current.files])
  }
  return (
    <>
      <BaseDialog
        dialogOpen={open}
        handleClose={handleClose}
        title="Nuevo Ticket"
        confirmText={"Crear Ticket"}
        cancelText={"Cerrar"}
        width="md"
        confirm={handleSubmit}
      >
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={1}>
            {!!currentUser.can?.create_in_name_of && !currentUser.ca ?
             <Grid item xs={4} md={4}>
              <Box>
                <Autocomplete
                  size="small"
                  options={users}
                  loading={loadingUS}
                  value={inNameOf}
                  valueKey={"idpers"}
                  setValue={setInNameOf}
                  groupBy={(option) => option.codcli}
                  nullable={true}
                  getOptionLabel={(option) => `${option.nombpers} - ${option.codcli}`}
                  renderInput={(params) => <TextField {...params} fullWidth label="En nombre de" />}
                />
              </Box>
              
              

            </Grid>:

            <Grid item xs={3.5} md={3.5} mt={1.5} ml={1}>
              
            <Typography variant="body1" color="initial">
            Registrando: <b >{currentUser.name}</b>
          </Typography>
            </Grid>
            
            
            }

            <Grid item xs={8} md={8}>
              <TextField
                label="Asunto"
                name="subject"
                id='subject'
                size="small"
                fullWidth
                value={subject}
                onChange={handleForm}
                required
                inputProps={{ maxLength: 100 }} // cambio para que solo permita 100 caracteres
              />
            </Grid>
          </Grid>
          <br />
          <Grid container >
            <Grid item xs={12} md={12}>
              <DraftDescription descriptionState={descriptionState} setDescriptionState={setDescriptionState} files={files} setFiles={setFiles} />
            </Grid>
            <Grid item xs={0} md={0}>
            <div className="form-field">
              <input
                ref={filesRef}
                accept="image/*,.doc,.docx,.zip,.rar"
                className={classes.input}
                name="journal_files[]"
                id="file-input"
                multiple
                type="file"
                onChange={changeFiles}
              />
            
            </div>
          </Grid >
          </Grid >
          <Grid container spacing={1} mt={1.5}>
            <Grid item xs={4} md={4}>
              <Autocomplete
                size="small"
                id="Tipo"
                options={tipos}
                value={tipo}
                nullable={false}
                setValue={setTipo}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => <TextField {...params} required fullWidth label="Tipo" />}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Autocomplete
                size="small"
                id="Sistema"
                loading={loadingGN}
                options={systems}
                value={system}
                nullable={false}
                valueKey={"codsistema"}
                setValue={setSystem}
                getOptionLabel={(option) => `${option.codsistema}-${option.nomsistema}`}
                renderInput={(params) => <TextField {...params} required fullWidth label="Sistema" />}
              />

            </Grid>
            <Grid item xs={4} md={4} >
              <Autocomplete
                size="small"
                id="modulo"
                loading={loadingMD}
                options={systemModules}
                value={systemModule}
                nullable={false}
                valueKey={"codmodulo"}
                setValue={setSystemModule}
                getOptionLabel={(option) => `${option.codmodulo}-${option.nommodulo}`}
                renderInput={(params) => <TextField {...params} required fullWidth label="Modulo" />}
              />
            </Grid>

          </Grid>
          <Grid container spacing={1} mt={1.5}>
            <Grid item xs={4} md={4} >
              <TextField
                label="Pantalla o Reporte"
                name="form"
                id='form'
                size="small"
                fullWidth
                value={form}
                onChange={handleForm}
              />
            </Grid>
            <Grid item xs={4} md={4}>

              <Autocomplete
                size="small"
                id="priority"
                options={priorities}
                value={priority}
                valueKey={"prioridad"}
                loading={loadingPP}
                nullable={false}
                setValue={setPriority}
                getOptionLabel={(option) => option.descpri}
                renderInput={(params) => <TextField {...params} fullWidth required label="Prioridad" />}
                required
              />
            </Grid>
            <Grid item xs={4} md={4} >
              <TextField
                label="Versión"
                name="version"
                id='version'
                size="small"
                fullWidth
                inputProps={{ maxLength: 15 }} // cambio para que solo permita 15 caracteres
                value={version}
                onChange={handleForm} />
            </Grid>
          </Grid>

          <Paper component="ul" className={classes.chipHolder} elevation={0}>

            <Typography>

            </Typography>
            {files?.map((file, index) => {
              return (
                <Chip
                  key={`file-chip-${index}`}
                  label={file.name}
                  color="secondary"
                  className={classes.chip}
                  variant="outlined"
                  onDelete={e => handleFileDelete(e, index)}
                />
              )
            })}
          </Paper>

        </form>

      </BaseDialog>

    </>

  )
}
