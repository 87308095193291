import React, {useState, useCallback, useEffect} from "react"
import { BaseDialog, BaseTable } from '../../layout'
import makeStyles from '@mui/styles/makeStyles';
import {useRequests, useCustomSnackbar, getErrorMsg} from "../../../helpers"
// import {List, ListItem, ListItemText} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    display: "flex",
    flexDirection:"column"
  },
  listHolder: {
    flex:9
  },
  newHolder: {
    flex:1
  }
}));


export const ModulesDialog = React.memo( ({ currentSystem, dialogOpen, handleClose:closeD }) => {
  const classes = useStyles();
  const [modules, setModules] = useState([])
  const {get, controller} = useRequests()
  const {eSnack} = useCustomSnackbar()
  const [loadingMd, setLoadingMd] = useState(false)

  const getModules = useCallback( async () =>{
    if(currentSystem){
      // iSnack("Cargando Modulos")
      setLoadingMd(true)
      try{
        const response = await get(`/administration/system_modules/${currentSystem}`)
        if(response?.ok) {
          const data = await response.json()
          setModules(data)
        }else{
          eSnack(await getErrorMsg(response), getModules)
        }
      }catch(e){
        console.log(e);
        eSnack(`Error inesperado obteniendo modulos para ${currentSystem}`)

      }finally {setLoadingMd(false)}
    }
  },[get, eSnack, currentSystem])

  useEffect(()=>{
    if(dialogOpen) getModules()
  },[currentSystem, getModules, dialogOpen])


  const handleClose = () => {
    setModules([])
    closeD()
  }
  useEffect(() => () => controller.abort(), [controller])
  return (
    <BaseDialog
      dialogOpen={dialogOpen}
      handleClose={handleClose}
      title="Modulos"
      cancelText="Cerrar"
      width="md"
    >
      <div className={classes.container}>
        <div className={classes.listHolder}>
          <BaseTable
            loading={loadingMd}
            rows={modules}
          >
          </BaseTable>
        </div>

      </div>
    </BaseDialog>
  )
})
