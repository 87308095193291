import React, {useState, useEffect} from 'react'
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Table, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Link} from "react-router-dom"
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import { useTranslation } from 'react-i18next';
import {CollapsibleRow, SkeletonTable, ConditionalWrapper} from "../."
import { coerce } from '../../../helpers';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#83c3f7",
    color: theme.palette.primary.contrastText,
  },
  body: {
    fontSize: 16,
    width:"auto",
    maxWidth:"50px",
    overflow:"hidden",
    textOverflow:"ellipsis"
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
  container: {
    maxHeight: "90%",
    height: "90%"
  },
  actions:{
    // display:"flex",
    // justifyContent:"space-between"
  }
});

export const BaseTable = React.memo(({ rows, showAction, headers:parentHeaders, extraActions, rowAction, title, loading, collapsible, children })=> {
  const classes = useStyles();
  const { t } = useTranslation();
  const [columns, setColumns] = useState([])
  const [headers, setHeaders] = useState([])

  useEffect(()=>{
    const reject_columns = ["created_at", "updated_at"]
    const newColumns = Object.keys(rows[0] || {}).filter(column => !reject_columns.includes(column))
    const newHeaders = Object.keys(rows[0] || {}).filter(column => !reject_columns.includes(column))
    if(!!showAction || !!extraActions?.length) newHeaders.push("Acciones")
    setColumns(parentHeaders || newColumns)
    setHeaders(parentHeaders || newHeaders)
  },[rows, parentHeaders, extraActions, showAction])

  const makeHeaders = ()=>{
    return headers.map((header,index)=>{
      return <StyledTableCell align="center" key={index}>{t(header)}</StyledTableCell>
    })
  }
  const handleRowAction = row => {
    if(rowAction){
      return () => rowAction(row)
    }else{
      return false
    }
  }
  return(
    <div className="base-table-container">
      {/*<Typography variant="h5">{title}</Typography>*/}
      <TableContainer elevation={5} component={Paper} className={classes.container}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow hover>
              {makeHeaders()}
            </TableRow>
          </TableHead>
          <TableBody>
            <ConditionalWrapper condition={loading} wrapper={children => <SkeletonTable columns={coerce(headers.length, 1, 100)} rows={10}/>}>
              {rows.map((row, index) => {
                return collapsible ?
                <CollapsibleRow
                  key={index}
                  rowAction={handleRowAction(row)}
                  visible={collapsible.visible(row)}
                  collapsed={collapsible.collapsed(row)}
                />
                  :
                <StyledTableRow hover key={index}>
                  {columns.map((column, index)=> <Tooltip title={t(row[column]) || ""} key={`column-tooltip-${index}`}>
                      <StyledTableCell align="center">{t(row[column])}</StyledTableCell>
                    </Tooltip>
                  )}
                  {(!!showAction || !!extraActions?.length) && <StyledTableCell align="center" className={classes.actions}>
                    {showAction && <Tooltip title="Abrir">
                      <Link to={`${row.id}`} >
                        <MenuOpenRoundedIcon color="secondary"/>
                      </Link>
                    </Tooltip>}
                    {extraActions?.map((action, index)=>{
                      return <Tooltip title={action.title} key={`extra-action-${index}`}>
                        {action.content(row)}
                      </Tooltip>
                    })}
                  </StyledTableCell>}
                </StyledTableRow>
              })}
            </ConditionalWrapper>
          </TableBody>
        </Table>
      </TableContainer>
      {children}
    </div>
  )
})
