import React from 'react'
import { TableRow } from '@mui/material'
import Skeleton from '@mui/material/Skeleton';
import { StyledTableCell } from '../.'
/*
Parameters:
  rows[INT]: number of rows to display
  columns[INT]: number of columns to display
*/
export const SkeletonTable = ({rows, columns}) => {
  return [...Array(rows)].map((_, rIndex) => {
    return <TableRow key={`skeleton-row-${rIndex}`}>
      {[...Array(columns)].map((_, cIndex) => <StyledTableCell align='center' key={`skeleton-column-${cIndex}`}><Skeleton/></StyledTableCell>)}
    </TableRow>
  })
}
