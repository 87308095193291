import React, { useEffect, useState, useCallback } from 'react'
import {
  Paper,

  TextField,
  Grid,
 
  Stack,
 
  Divider,
  Button

} from '@mui/material';
import { useRequests, getErrorMsg, useCustomSnackbar, formatDate/*,formatCurrency*/, useBackdrop } from '../../../helpers';
import '../../../stylesheets/adr.scss'
import '../../../stylesheets/activity.scss'
import {  useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useHistory } from "react-router-dom"

export const FormDetail = (match) => {
  const { get, patch, controller } = useRequests()
  const { idinc } = useParams()
  const [ticket, setTicket] = useState([])
  const {eSnack,sSnack} = useCustomSnackbar()
  const { show, off } = useBackdrop()

  const [fecha, setFecha] = useState("")
  const [status, setSatus] = useState("")
  const [descripcion, setDescripcion] = useState("")
  const [codcli, setCodcli] = useState("")
  const [date] = useState(new Date().toISOString().split("T")[0]);
  const [comentario, setComentario] = useState("")
  const [resp1, setResPreg1] = useState("")
  const [resp2, setResPreg2] = useState("")
  const [resp3, setResPreg3] = useState("")
  const [resp4, setResPreg4] = useState("")
  const [resp5, setResPreg5] = useState("")
  const history = useHistory();

  useEffect(() => () => controller.abort(), [controller])

   const getTicket = useCallback( async () =>{
        try{
        
          const response = await get(`/administration/encuestas/ticket`, {
            idinc: idinc,
          })
          if(response?.ok){
            const data = await response.json()
            setTicket(data)
          }else{
            eSnack(await getErrorMsg(response), getTicket)
          }
        }catch(e){
          eSnack("Error inesperado obteniendo estados", getTicket)
       }finally{}
      },[get, eSnack,idinc])
    
      useEffect(()=>{
            getTicket()     
     },[getTicket])

     useEffect(()=>{
        if(JSON.stringify(ticket).length !== 0){
           setFecha(ticket.fecinginc)
           setSatus(ticket.stsinc)
           setDescripcion(ticket.problema)
           setCodcli(ticket.codcli)
        }
    },[ticket])

    const fechaTicket = () => {
        if( JSON.stringify(ticket).length !== 0){
          return (
            <>
               {formatDate(new Date(fecha))} 
            </>
    
          )
        }
      }
      const statusTicket = () => {
        if( JSON.stringify(ticket).length !== 0){
          
          if(status==="RES"){
            return(
              <>{"Respondido"}</>
              )
          }else if (status==="NOT"){
            return(
              <>{"Notificado"}</>
              )
          }else if (status==="CER"){
            return(
              <>{"Cerrado"}</>
              )
          }    
          else if (status==="ENV"){
            return(
              <>{"Enviado"}</>
              )
          }      
        }
      }
      const descripcionTicket = () => {
        if( JSON.stringify(ticket).length !== 0){
          return (
            <>
               {descripcion} 
            </>
    
          )
        }
      }
      const codcliTicket = () => {
        if( JSON.stringify(ticket).length !== 0){
          return (
            <>
               {codcli} 
            </>
    
          )
        }
      }

      function BpRadio1(props) {
          return (
              <Radio
                  sx={{
                      '&:hover': {
                          bgcolor: 'transparent',
                      },
                  }}
                  disableRipple
                  color="default"
                  checkedIcon={<span>👍</span>}
                  icon={<span>😊</span>}
                  {...props}
              />
          );
      }
      function BpRadio2(props) {
          return (
              <Radio
                  sx={{
                      '&:hover': {
                          bgcolor: 'transparent',
                      },
                  }}
                  disableRipple
                  color="default"
                  checkedIcon={<span>👍</span>}
                  icon={<span>😃</span>}
                  {...props}
              />
          );
      }
      function BpRadio3(props) {
          return (
              <Radio
                  sx={{
                      '&:hover': {
                          bgcolor: 'transparent',
                      },
                  }}
                  disableRipple
                  color="default"
                  checkedIcon={<span>👎</span>}
                  icon={<span>😐</span>}
                  {...props}
              />
          );
      }
      function BpRadio4(props) {
          return (
              <Radio
                  sx={{
                      '&:hover': {
                          bgcolor: 'transparent',
                      },
                  }}
                  disableRipple
                  color="default"
                  checkedIcon={<span>👎</span>}
                  icon={<span>😔</span>}
                  {...props}
              />
          );
      }

      const updateEncuesta = async e => {
        e.preventDefault()
        if (!resp1) return eSnack("Debe responder todas las preguntas ")
        if (!resp2) return eSnack("Debe responder todas las preguntas ")
        if (!resp3) return eSnack("Debe responder todas las preguntas ")
        if (!resp4) return eSnack("Debe responder todas las preguntas ")
        if (!resp5) return eSnack("Debe responder todas las preguntas ")
      
        show('Enviando encuesta')
        const data = {
          respregunta1: resp1,
          respregunta2: resp2,
          respregunta3: resp3,
          respregunta4: resp4,
          respregunta5: resp5,
          comentario:   comentario,
        }
        try {
          const request = await patch(`/administration/encuestas/update_encuesta`, {
          //  idencuesta:currentIdEncuesta,
            idinc: Math.trunc(idinc),
            enc:data
          })
          if (request?.ok) {
            sSnack("Encuesta respondida")
            history.push(`/administration/quiz`)
           // handleClose()
            //reload()
          } else {
            eSnack(await getErrorMsg(request))
          }
        } catch (e) {
          console.log(e)
          eSnack("Error inesperado respondiendo la encuesta")
        } finally {
          off();
        }
      }
   return (
    <> 
    <form onSubmit={updateEncuesta}>
        <div style={{marginLeft: "250px", marginRight: "250px", backgroundColor:"blue"}} >
        <Paper elevation={5}  >
            <Box sx={{ backgroundColor: "#BBDEFB", height:"20px", padding:"5px"}}> 
                  <b>  Responder Encuesta </b> 
            </Box>

            <Stack container ml={1} mt={1}>Información Básica del ticket</Stack>
             <Divider/>
             <Grid container spacing={1} mb={2} ml={2} mt={1}>
                <Grid item lg={3}>
                    <FormLabel>ID Incidente: {Math.trunc(idinc)}</FormLabel>
                </Grid>
                <Grid item lg={5}>
                    <FormLabel>Descripción Incidente: {descripcionTicket()}</FormLabel>
                </Grid>
                <Grid item md={3}>
                    <FormLabel>Fecha Incidente: {fechaTicket()}</FormLabel>
                </Grid>
                <Grid item md={3}>
                    <FormLabel>Estatus: {statusTicket()}</FormLabel>
                </Grid>
               <Grid item md={3}>
                    <FormLabel>Cliente: {codcliTicket()}</FormLabel>
                </Grid>
            </Grid><Divider/> <br/> 
            
            <Stack container ml={1}>Encuesta de Satisfacción del usuario</Stack>
            <Divider/>
            <Grid container spacing={1} mb={2} ml={2} mt={1}>
            
                <Grid item lg={6}>
                    <FormLabel>Persona Encuestada: {ticket.nombpers}</FormLabel>
                </Grid>
               
                <Grid item lg={4}>
                    <FormLabel>Fecha Encuesta:  {formatDate(new Date(date))}</FormLabel>
                </Grid>
            </Grid>
            <Divider/>
            <br/>
            <Grid container spacing={0} ml={3} mt={1}>
                <Grid item lg={20} >1- ¿Mi solicitud de asistencia fue completamente atendida por el personal de KENTRON?
                <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel  onChange={e => setResPreg1(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel  onChange={e => setResPreg1(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel  onChange={e => setResPreg1(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel  onChange={e => setResPreg1(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <br/><br/>
            <Grid container spacing={0}>
            <Grid item lg={20} ml={3}>2- ¿El tiempo que tomó atender mi solicitud fue adecuado?
            <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel onChange={e => setResPreg2(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg2(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg2(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel onChange={e => setResPreg2(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>         <br/><br/>
            <Grid container spacing={0}>
            <Grid item lg={20} ml={3} >3- ¿La solución a mi solicitud fue satisfactoria?
            <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel onChange={e => setResPreg3(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg3(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg3(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel onChange={e => setResPreg3(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>         <br/><br/>
            <Grid container spacing={0}>
            <Grid item lg={20} ml={3}>4- ¿La atención por parte del personal de KENTRON fue amable y eficiente?
            <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel onChange={e => setResPreg4(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg4(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg4(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel onChange={e => setResPreg4(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid><br/><br/>
            <Grid container spacing={0}>
            <Grid item lg={20} ml={3}>5- ¿Recibí información adecuada durante el proceso de asistencia?
            <Divider/>
                <Grid item lg={20} mt={2} >
                    <FormControl >
                       
                        <RadioGroup
                            //defaultValue="female"
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                            row
                        >
                            <FormControlLabel onChange={e => setResPreg5(e.target.value)} value="1" control={<BpRadio4 />} label="Totalmente en desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg5(e.target.value)} value="2" control={<BpRadio3 />} label="En desacuerdo" />
                            <FormControlLabel onChange={e => setResPreg5(e.target.value)} value="3" control={<BpRadio2 />} label="De Acuerdo" />
                            <FormControlLabel onChange={e => setResPreg5(e.target.value)} value="4" control={<BpRadio1 />} label="Totalmente de acuerdo" />
                        </RadioGroup>
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <br/><br/>
            <Stack container ml={1}>Comentarios</Stack>
            <Divider/><br/>
            <Grid item lg={11} ml={3}>
              <TextField
                hiddenLabel
                size='small'
                fullWidth
                name="comentario"
                id="comentario"
                onChange={e => setComentario(e.target.value)}
                inputProps={{
                  maxLength: 3500,
                }}
                placeholder="Ingresa tu comentario aqui..."
              /></Grid><br/><Divider/> 
             <br/>

             <Grid container spacing={2} direction="row"  justifyContent="center" alignItems="center">
           <Grid item lg={2} >
           <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
         // disabled={!!passwordError}
        >
          Enviar
        </Button>
           </Grid>
        </Grid>  <br/>

        </Paper>
        </div>
    </form>
     
     
       
   
    </>


  )
}
