import React from "react"
import { useRequests, useCustomSnackbar, getErrorMsg,  useBackdrop } from "../../../helpers"
import { Tooltip } from "@mui/material"
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone';

export const Printadr = React.memo(({adr, getAdrId }) => {
  const {get} = useRequests ()
  const {show, off} = useBackdrop ()
  const {eSnack, sSnack} = useCustomSnackbar ()
 

  const report_detail = async (row) => {
    show("Imprimiendo ADR");
    try{
      const response = await get(`/administration/adr/report_detail`, {
      id:getAdrId()
      });
      if (response?.ok) {
        var blob = await response.blob()
        var file = new Blob([blob], {type: 'application/pdf'});
        var cfileURL = URL.createObjectURL(file);
        /* link.href = cfileURL; */
        /*const date = new Date(); */
        /* const link = document.createElement("a"); */
        /*const filename = `ADR_${row.numadr}_${row.codcli}_${date.getDate()}_${
          date.getMonth() + 1
        }_${date.getFullYear()}.pdf`;
        link.download = filename */
        window.open(cfileURL, "_blank") 
        /* w.focus(); */
        /* link.click(); */
        sSnack("ADR Impreso")
        console.log("exported successfully");
      } else {
        eSnack(await getErrorMsg(response))
      }
    } catch (e) {
      eSnack("Error inesperado Imprimiendo ADR");
      console.log("Error exporting", e);
    } finally { off() }
  }

  return <>  
     <Tooltip title={"Imprimir"}>
      <PrintTwoToneIcon color="secondary" onClick = { ()=> report_detail(adr)} />
    </Tooltip>    
    </>;
})
