import React, { useEffect, useState, useCallback } from 'react'
import {
  Paper,
  AppBar,
  Tabs,
  Tab,
  TextField,
  Grid,
  Tooltip,
  Toolbar,
  Stack,
  Breadcrumbs,
  Container,
  Fab,
  Card,
  CardContent,


} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useRequests, getErrorMsg, useCustomSnackbar, formatDate/*,formatCurrency*/, useConfirmationDialog, useBackdrop, FIELD_TYPES, useDialogTools } from '../../../helpers';
import { SkeletonDetailActivity, Autocomplete, EditableTextField, ConditionalWrapper, SkeletonList } from '../../layout';
import { AdrObservaciones, AdrTickets, AdrHorasMontos, AdrFechasFacturacion, EstimacionTotal } from "../."
import '../../../stylesheets/adr.scss'
import '../../../stylesheets/activity.scss'
import { Link as RouterLink, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedIn';
import { AssigAutoActivity } from './asisign_auto_activity';
import { CardCollapse } from '../../layout/basic/CardCollapse';
import PersonIcon from "@mui/icons-material/Person";

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);


export const AdrDetail = () => {
  const { numadr, codcli, codproy } = useParams()
  const [loadingADR, setLoadingADR] = useState(false)
  const [adr, setAdr] = useState({})
  const { show, off } = useBackdrop()

  const { get, patch, controller } = useRequests()
  const { eSnack, sSnack } = useCustomSnackbar()

  const { getConfirmation } = useConfirmationDialog()

  const { dialogOpen: assignOpen, openD: openAssign, closeD: closeAssign } = useDialogTools()
  const [loadingOTLISt, setloadingOTLISt] = useState(false)
  const [listOrdenes, setlistOrdenes] = useState([{}])
  const [currentListOrden, setcurrentListOrden] = useState(null)
  //const [loadingClientes, setLoadingClientes] = useState(false)
  // const [clientes, setClientes] = useState([])
  //const [currentClientes, setCurrentClientes] = useState(null)

  //const [loadingProyectos, setLoadingProyectos] = useState(false)
  // const [proyectos, setProyectos] = useState([])
  // const [currentProyectos, setCurrentProyectos] = useState(null)

  const [loadingDirector, setLoadingDirector] = useState(false)
  const [director, setDirector] = useState([])
  const [currentDirector, setCurrentDirector] = useState(null)

  //const [loadingSTSADR, setLoadingSTSADR] = useState(false)
  //const [statusadr, setStatusAdr] = useState([])
  //const [currentStatusadr, setCurrentStatusadr] = useState(null)

  const [currentTab, setCurrentTab] = useState('incidentes');

  const [actividad, setActividad] = useState({})


  const tabs = [
    { value: 'incidentes', label: 'Tikects' },
    { value: 'fechas', label: 'Fechas' },
    { value: 'facturacion', label: 'Facturación' },
    { value: 'observaciones', label: 'Observaciones' },
    { value: 'total', label: 'Estimacion Total' },

    /* { value: 'defects', label: 'Defectos' } */
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };



  //######################################################
  //para traer los datos de BD de la actividad
  const getAdr = useCallback(async () => {
    try {
      setLoadingADR(true)
      const response = await get(`/administration/adr/show`, {
        id: {
          numadr,
          codcli,
          codproy
        }
      })
      if (response?.ok) {
        const adr = await response.json()
        setAdr(adr)
      } else {

        eSnack(await getErrorMsg(response), getAdr)
        console.log("error getting ADR");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo ADR")
      console.log("Error getting ADR", e);
    } finally { setLoadingADR(false) }
  }, [get, eSnack, numadr, codcli, codproy])

  useEffect(() => {
    getAdr()
  }, [getAdr])


  const saveField = async value => {
    if (!value) return sSnack("Debe colocar un valor en el campo")

    show("Actualizando Datos")
    try {
      const response = await patch(`/administration/adr/update`, {
        id: {
          numadr: adr.numadr,
          codcli: adr.codcli,
          codproy: adr.codproy
        },
        adr: value
      })
      if (response?.ok) {
        const adr = await response.json()
        sSnack("Dato actualizado")
        setAdr(adr)
      } else {
        eSnack(await getErrorMsg(response))
        console.log("error updating");
      }
    } catch (e) {
      eSnack("Error inesperado actualizando ")
      console.log("Error updating ", e);
    } finally {
      off()
    }
  }


  const fechaAdr = () => {
    if (adr.stsadr === "Elaborado") {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item lg={4}>
              Fecha ADR:
            </Grid>
            <Grid item lg={5}>
              <EditableTextField
                className="fechas"
                initialValue={adr.fecadr}
                name="fecadr"
                save={saveField}
                type={FIELD_TYPES.DATE}
                placeholder="Fecha ADR"
              />
            </Grid>
          </Grid>
        </>
      )
    } else if (adr.fecadr === null) {
      return null;
    } else {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              Fecha ADR:
            </Grid>
            <Grid item xs={8}>
              <b>{formatDate(new Date(adr.fecadr))}</b>
            </Grid>
          </Grid>
        </>
      )
    }
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/administration/Adr">
      Analisis de Requerimiento
    </Link>,
    <Typography key="3" color="text.primary">
      Detalle ADR {adr.numadr}
    </Typography>,
  ];

  const estatusAdr = () => (
    <Grid container spacing={1}>
      <Grid item lg={4}  >
        Status ADR:
      </Grid>
      <Grid item lg={8}>

        <b>
          {adr.stsadr}
        </b>

      </Grid>
    </Grid>
  );

  //###FECHA ESTATUS

  const fechaStatus = () => {
    if (adr.fecsts !== null) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={4} mt={adr.stsadr === "Elaborado" ? 2.3 : 0}>
            Fecha Status:
          </Grid>
          <Grid item xs={8} mt={adr.stsadr === "Elaborado" ? 2.3 : 0}>
            <b>{formatDate(new Date(adr.fecsts))}</b>
          </Grid>
        </Grid>
      );
    }
    return null;
  }

  //###NOMBRE ADR
  const nombreAdr = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item lg={4}>
            Nombre ADR:
          </Grid>
          {adr.stsadr === "Elaborado" ?
            <Grid item lg={8}>
              <EditableTextField
                initialValue={adr.nombre}
                name="nombre"
                save={saveField}
                type="string"
                placeholder="Nombre ADR"
              />
            </Grid>
            :
            <Grid item lg={8}>
              <b>{adr.nombre}</b>
            </Grid>
          }
        </Grid>
      </>
    );
  }

  //###CLIENTE

  //############################################
  const nombreCliente = () => {
    return (
      <Grid container spacing={1} ml={0}>
        <Grid item lg={4}>
          Nombre CLTE:
        </Grid>
        <Grid item xs={7}>
          {adr.stsadr === "Elaborado" ? (
            <EditableTextField
              initialValue={adr.nomcliente}
              name="nomcliente"
              save={saveField}
              type="string"
              placeholder="Nombre Cliente"
            />
          ) : (
            <Grid item xs={7}>
              <b>{adr.nomcliente}</b>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  //#########################################
  //##########PARA OBTENER Y EDITAR EL PROYECTO


  const proyectosSelect = () => {

    return (
      <>
        <Grid container spacing={1}>

          <Grid item lg={4} >
            Proyecto:
          </Grid>
          <Grid item lg={8}>
            <b> {adr.codproy}-{adr.h_project}</b>
          </Grid>
        </Grid>
      </>
    )

    // }
  }


  //##########PARA OBTENER Y EDITAR EL director
  useEffect(() => {
    if (director.length && adr.nomdirproy) {
      setCurrentDirector(director.find(dir => dir.nombpers === adr.nomdirproy))
    }
  }, [adr, director])

  const getDirectorp = useCallback(async () => {
    try {
      setLoadingDirector(true)
      const response = await get('/administration/adr/director')
      if (response?.ok) {
        const data = await response.json()
        setDirector(data)

      } else {
        eSnack(await getErrorMsg(response), getDirectorp)
      }
    } catch (e) {
      console.log(e);
    } finally { setLoadingDirector(false) }
  }, [get, eSnack])

  useEffect(() => {
    getDirectorp()
  }, [getDirectorp])

  const directorSelect = () => {
    if (adr.stsadr === "Elaborado") {
      return (
        <>
          <Grid container spacing={1} my={1}>
            <Grid item xs={3} md={3}>
              Director:
            </Grid>
            <Grid item xs={9} md={9}>
              <Autocomplete
                fullWidth
                nullable={false}
                loading={loadingDirector}
                options={director}
                value={currentDirector}
                size="small"
                valueKey={"nombpers"}
                onChange={async (_, value) => {
                  if (value.nombpers && (await getConfirmation({ title: "Cambiar Director", message: `Cambiar Director de ${adr.nomdirproy} -> ${value.nombpers}?` }))) {
                    selectDirector(value)
                  }
                }}
                getOptionLabel={(option) => option.nombpers}
                renderInput={(params) => <TextField {...params} required fullWidth label="Director" />}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid container spacing={1}>
          <Grid item lg={4}>
            Director:
          </Grid>
          <Grid item xs={8}>
            <b>{adr.nomdirproy}</b>
          </Grid>
        </Grid>
      </>
    );
  }


  const selectDirector = async selectedOption => {
    const nomdirproy = { nomdirproy: selectedOption?.nombpers }
    saveField(nomdirproy)
  }

  const [loadingUsuelab, setLoadingUsuela] = useState(false)
  const [usuelaborado, setUsuelaborado] = useState([])
  const [currentUsuario, setCurrentUsuario] = useState(null)

  //################################

  useEffect(() => {
    if (usuelaborado.length && adr.usuelaboracion) {
      setCurrentUsuario(usuelaborado.find(dir => dir.codusu === adr.usuelaboracion))
    }
  }, [adr, usuelaborado])


  const getUsuelabo = useCallback(async () => {
    try {
      setLoadingUsuela(true)
      const response = await get('/administration/adr/responsable')
      if (response?.ok) {
        const data = await response.json()
        setUsuelaborado(data)

      } else {
        eSnack(await getErrorMsg(response), getUsuelabo)
      }
    } catch (e) {
      console.log(e);
    } finally { setLoadingUsuela(false) }
  }, [get, eSnack])

  useEffect(() => {
    getUsuelabo()
  }, [getUsuelabo])
  //########ELABORADO POR

  const elaboradoPor = () => {
    // Si el estado del ADR es "Elaborado", mostrar un EditableTextField para permitir editar el valor del campo.
    if (adr.stsadr === "Elaborado") {
      return (
        <>
          <Grid container spacing={1} my={1} >
            <Grid item lg={3} md={3}>
              Elaborado:
            </Grid>
            <Grid item lg={9} md={9}>
              <Autocomplete
                fullWidth
                nullable={false}
                loading={loadingUsuelab}
                options={usuelaborado}
                value={currentUsuario}
                inputValue={currentUsuario ? currentUsuario.nombpers : ''}

                size="small"
                valueKey={"nombpers"}
                onChange={async (_, value) => {
                  if (value.nombpers && (await getConfirmation({ title: "Cambiar Usuario Elaboracion", message: `Cambiar de ${currentUsuario ? currentUsuario.nombpers : 'No asignado aun'} -> ${value.nombpers}?` }))) {
                    selectusuelaboradp(value)
                  }
                }}
                getOptionLabel={(option) => option.nombpers}
                renderInput={(params) => <TextField {...params} required fullWidth label="Elaborado Por" />}
              />
            </Grid>
          </Grid>
        </>
      );
    } else {
      // Si el estado del ADR no es "Elaborado", mostrar el valor del campo como texto.
      return (
        <>
          <Grid container spacing={1}>
            <Grid item lg={4}>
              Elaborado:
            </Grid>
            <Grid item xs={8}>
              <b>{adr.usuelaboracion}</b>
            </Grid>
          </Grid>
        </>
      );
    }
  }
  const selectusuelaboradp = async selectedOption => {
    console.log(selectedOption)
    const usuelaboracion = { usuelaboracion: selectedOption?.codusu }
    saveField(usuelaboracion)
  }
  //#################



  //###############################################################
  useEffect(() => {
    if (listOrdenes.length && adr.idot) {
      setcurrentListOrden(listOrdenes.find(ordenes => ordenes.value === adr.idot))
    }
  }, [adr, listOrdenes])

  const getOrdenesTrabajo = useCallback(async () => {
    try {
      setloadingOTLISt(true)

      const response = await get(`/administration/adr/ordenes_trabajo`, {

        codproy: codproy,
      })

      if (response?.ok) {
        const ordenes = await response.json()
        setlistOrdenes(ordenes)
      } else {
        eSnack(await getErrorMsg(response), getOrdenesTrabajo)
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo ordenes de trabajo", getOrdenesTrabajo)

    } finally { setloadingOTLISt(false) }
  }, [get, eSnack, codproy])

  useEffect(() => {
    getOrdenesTrabajo()
  }, [getOrdenesTrabajo])

  const ordenesdeTrabajo = () => {
    if (adr.stsadr === "Elaborado" || adr.stsadr === "Enviado") {
      return (
        <Grid container spacing={1} my={1}>
          <Grid item xs={3} >
            OT:
          </Grid>
          <Grid item xs={9}>
            <Autocomplete
              fullWidth
              nullable={true}
              options={listOrdenes}
              size="small"
              value={currentListOrden}
              loading={loadingOTLISt}
              onChange={async (_, value) => {

                if (value && (await getConfirmation({ title: "Asignar Orden de trabajo", message: `Cambiar OT ${adr.idot} -> ${value.value}?` }))) {
                  selectOT(value)
                }
              }

              }
              getOptionLabel={(option) => `${option.value} - ${option.name}`}
              renderInput={(params) => <TextField {...params} required fullWidth label="ID-OT" />}
            />
          </Grid>

        </Grid>


      )
    }
    else {
      return (
        <Grid container spacing={1}>
          <Grid item lg={4} >
            OT:
          </Grid>
          <Grid item lg={8}>
            <b>{adr.idot}</b>
          </Grid>
        </Grid>
      )
    }
  }
  const selectOT = async selectedOption => {
    const ordenT = { idot: selectedOption?.value }
    saveField(ordenT)
  }

  const asignacionAutAct = () => {
    if (adr["activity_auto_assignable?"]) {
      return (
        <>
          <Fab size="small" color="secondary" aria-label="add" title=" Asignar Actividad">
            <AssignmentTurnedInRoundedIcon color='white' onClick={() => openAssign()} type="submit" />
          </Fab>
        </>

      )
    }
  }
  const getNombreActividad = useCallback(async () => {
    try {
      const response = await get(`/administration/adr/actividad`, {
        idactividad: adr.actividadTicket?.idactividad,
      })

      if (response?.ok) {
        const act = await response.json()
        setActividad(act)
      } else {
        eSnack(await getErrorMsg(response), getNombreActividad)
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo el nombre de la actividad", getNombreActividad)

    } finally { }
  }, [get, eSnack, adr.actividadTicket?.idactividad])

  useEffect(() => {
    if (JSON.stringify(adr) !== '{}') {
      getNombreActividad()
    }
  }, [getNombreActividad, adr])

  const verActividad = () => {
    if (adr.actividadTicket != null && adr.actividadTicket?.idactividad != null) {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item lg={4} md={2} >
              Actividad:
            </Grid>
            <Grid item lg={8} md={10} >

              <Tooltip title={`Abrir Actividad ${adr.actividadTicket?.idactividad}`}>
                <Link component={RouterLink} to={`/activities/${adr.actividadTicket?.idactividad}`}>
                  <b>{adr.actividadTicket?.idactividad} - {actividad.nomactividad}</b>
                </Link>
              </Tooltip>

            </Grid>
          </Grid>
        </>

      )
    }
  }

  const Item2 = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.7),
    textAlign: 'lefth',
    color: theme.palette.text.primary,
  }));

  useEffect(() => () => controller.abort(), [controller])
  return (
    <>

      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          mt={1}
          style={{ height: "82vh" }}
        >
          <Grid item xs={12} lg={3.5} style={{ height: "100%" }}>
            <ConditionalWrapper condition={loadingADR} wrapper={children => <SkeletonList number={1}>{children}</SkeletonList>}>
              <Paper
                elevation={24}
                sx={{
                  boxShadow: "none",
                  overflowX: "scroll",
                  overflowY: "scroll",
                  height: "100%",
               
                }}
              >
                <Card style={{ width: '100%',  margin: 0, }}>
                  <CardContent style={{ height: "100%" }}>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {adr.h_avatar ? (
                        <img
                          alt=""
                          src={adr.h_avatar}
                          style={{ width: "120px", height: "120px" }}
                        />
                      ) : (
                        <PersonIcon
                          style={{
                            color: "#009AFE",
                            width: "120px",
                            height: "120px",

                          }}
                        />
                      )}
                    </div>
                    <Typography variant="h6" align="center" sx={{ marginTop: 2, color: "#009AFE" }}>
                      <b>{adr.codcli} - {adr.h_client}</b>
                    </Typography>

                    <Typography align="left" sx={{ fontSize: "14px" }}>
                      {nombreCliente()}
                    </Typography>
                  </CardContent>
                  <CardCollapse title={"Detalles"} >
                    <Container>
                      <br />
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        <Grid container spacing={1}>
                          <Grid item lg={4} >
                            Número ADR:
                          </Grid>
                          <Grid item lg={8}>
                            <b>{adr.numadr}</b>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {nombreAdr()}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {fechaAdr()}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {estatusAdr()}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {fechaStatus()}
                      </Typography>
                      <br />
                    </Container>
                  </CardCollapse>
                  <CardCollapse title={"Proyecto"} >
                    <Container>
                      <br />
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {proyectosSelect()}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {directorSelect()}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {elaboradoPor()}
                      </Typography>
                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {ordenesdeTrabajo()}
                      </Typography>
                      <Typography align="center" sx={{ fontSize: "14px" }}>
                        {asignacionAutAct()}
                      </Typography>

                      <Typography align="left" sx={{ fontSize: "14px" }}>
                        {verActividad()}
                      </Typography>
                      <br />
                    </Container>
                  </CardCollapse>
                </Card>

              </Paper>
            </ConditionalWrapper>

          </Grid>
          <Grid item xs={12} lg={8.5} style={{ height: "100%" }}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
            <br />
            <ConditionalWrapper condition={loadingADR} wrapper={SkeletonDetailActivity}>
              {currentTab === 'incidentes' &&
                <AdrTickets adr={adr} reload={getAdr} openAssign={openAssign} />}
              {currentTab === 'fechas' && <AdrFechasFacturacion Item2={Item2} adr={adr} reload={getAdr} saveField={saveField} loadingADR={loadingADR} />}
              {currentTab === 'facturacion' && <AdrHorasMontos Item2={Item2} adr={adr} reload={getAdr} saveField={saveField} />}
              {currentTab === 'observaciones' && <AdrObservaciones adr={adr} saveField={saveField} />}
              {/*currentTab === 'total' && <EstimacionTotal adr={adr} />*/}
              {currentTab === 'total' && <EstimacionTotal adr={adr} reload={getAdr} />}


            </ConditionalWrapper>
          </Grid>
        </Grid>
      </Container>

      <AssigAutoActivity open={assignOpen} handleClose={closeAssign} adr={adr} reload={getAdr} />

    </>
  )
}
