export * from './tickets'
export * from './assign_ticket_dialog'
export * from './list/index'
export * from './list/export_dialog'
export * from './list/filtro_pdf'
export * from './list/auto_activity_dialog'
export * from './new/index'
export * from './new/draft_description'
export * from './detail/index'
export * from './detail/follow_up'
export * from './detail/files_dialog'
export * from './detail/resolve_ticket_dialog'
export * from './detail/cause_dialog'
export * from './mensaje_ticket'

export const TICKET_PRIORITIES = Object.freeze({
  1: "secondary",
  2: "default",
  3: "primary"
})

export const TICKET_TYPE_STATUSES = Object.freeze({
    'ALL':[
      {
        "stsinc": "PEN",
        "descstatus": "Pendiente"
      },
      {
        "stsinc": "ASG",
        "descstatus": "Asignada"
      },
      {
        "stsinc": "ATN",
        "descstatus": "Atendida"
      },
      {
        "stsinc": "RES",
        "descstatus": "Respondido"
      },
      {
        "stsinc": "NOT",
        "descstatus": "Notificado"
      },
      {
        "stsinc": "CER",
        "descstatus": "Cerrada"
      },
      {
        "stsinc": "PRO",
        "descstatus": "Proceso"
      },
      {
        "stsinc": "REV",
        "descstatus": "Revision"
      },
      {
        "stsinc": "PPI",
        "descstatus": "Pendiente por Informacion"
      },
      {
        "stsinc": "PAP",
        "descstatus": "Pendiente por aprobacion"
      },
      {
        "stsinc": "RET",
        "descstatus": "Retirado"
      },
      {
        "stsinc": "TRF",
        "descstatus": "Transferido"
      },
      {
        "stsinc": "PAN",
        "descstatus": "Por Analizar"
      },
      {
        "stsinc": "APR",
        "descstatus": "ADR aprobado"
      },
      {
        "stsinc": "MTO",
        "descstatus": "Por iniciar desarrollo (En mantenimiento)"
      },
      {
        "stsinc": "ENV",
        "descstatus": "Enviada"
      }
    ],
    "REQ": [{
        "stsinc": "PEN",
        "descstatus": "Pendiente"
      },
      {
        "stsinc": "CER",
        "descstatus": "Cerrada"
      },
      {
        "stsinc": "PRO",
        "descstatus": "Proceso"
      },
      {
        "stsinc": "REV",
        "descstatus": "Revision"
      },
      {
        "stsinc": "PPI",
        "descstatus": "Pendiente por Informacion"
      },
      {
        "stsinc": "PAP",
        "descstatus": "Pendiente por aprobacion"
      },
      {
        "stsinc": "RET",
        "descstatus": "Retirado"
      },
      {
        "stsinc": "TRF",
        "descstatus": "Transferido"
      },
      {
        "stsinc": "ASG",
        "descstatus": "Asignada"
      },
      {
        "stsinc": "RES",
        "descstatus": "Respondido"
      },
      {
        "stsinc": "NOT",
        "descstatus": "Notificado"
      },
      {
        "stsinc": "PAN",
        "descstatus": "Por Analizar"
      },
      {
        "stsinc": "APR",
        "descstatus": "ADR aprobado"
      },
      {
        "stsinc": "MTO",
        "descstatus": "Por iniciar desarrollo (En mantenimiento)"
      }
    ],
    "SOP": [
      {
        "stsinc": "PEN",
        "descstatus": "Pendiente"
      },
      {
        "stsinc": "ASG",
        "descstatus": "Asignada"
      },
      {
        "stsinc": "ATN",
        "descstatus": "Atendida"
      },
      {
        "stsinc": "RES",
        "descstatus": "Respondido"
      },
      {
        "stsinc": "NOT",
        "descstatus": "Notificado"
      },
      {
        "stsinc": "CER",
        "descstatus": "Cerrada (viejas)"
      }
    ],
    "MAN": [
      {
        "stsinc": "PEN",
        "descstatus": "Pendiente"
      },
      {
        "stsinc": "ASG",
        "descstatus": "Asignada"
      },
      {
        "stsinc": "ATN",
        "descstatus": "Atendida"
      },
      {
        "stsinc": "RES",
        "descstatus": "Respondido"
      },
      {
        "stsinc": "NOT",
        "descstatus": "Notificado"
      },
      {
        "stsinc": "CER",
        "descstatus": "Cerrado"
      },
      {
        "stsinc": "ENV",
        "descstatus": "Enviada"
      }
    ],
    "FUN": [
      {
        "stsinc": "PEN",
        "descstatus": "Pendiente"
      },
      {
        "stsinc": "ASG",
        "descstatus": "Asignada"
      },
      {
        "stsinc": "ATN",
        "descstatus": "Atendida"
      },
      {
        "stsinc": "RES",
        "descstatus": "Respondido"
      },
      {
        "stsinc": "NOT",
        "descstatus": "Notificado"
      },
      {
        "stsinc": "CER",
        "descstatus": "Cerrado"
      }
    ],
    "SUG": [
      {
        "stsinc": "PAP",
        "descstatus": "Pendiente por aprobacion interna"
      },
      {
        "stsinc": "REV",
        "descstatus": "En revision"
      },
      {
        "stsinc": "ATN",
        "descstatus": "Atendido"
      },
      {
        "stsinc": "PEN",
        "descstatus": "Pendiente"
      }
    ],
    "REC": []
  }
)

export const TICKET_TYPES = Object.freeze([
  {
    "tipinc": "REQ",
    "desctipinc": "Requerimientos"
  },
  {
    "tipinc": "SOP",
    "desctipinc": "Soporte"
  },
  {
    "tipinc": "MAN",
    "desctipinc": "Mantenimiento"
  },
  {
    "tipinc": "FUN",
    "desctipinc": "Funcional"
  },
  {
    "tipinc": "SUG",
    "desctipinc": "SUGERENCIAS MEJORAS"
  },
  {
    "tipinc": "REC",
    "desctipinc": "Reclamo"
  }
])
