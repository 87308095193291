import React from "react"
import { useRequests, useCustomSnackbar, getErrorMsg,  useConfirmationDialog, useBackdrop } from "../../../helpers"
import { Tooltip } from "@mui/material"
import BuildCircleTwoToneIcon from '@mui/icons-material/BuildCircleTwoTone';
import BackspaceTwoToneIcon from '@mui/icons-material/BackspaceTwoTone';
import BeenhereTwoToneIcon from '@mui/icons-material/BeenhereTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import DriveFileMoveTwoToneIcon from '@mui/icons-material/DriveFileMoveTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { Printadr } from "..";


export const ChangeStatus = React.memo(({reload, adr}) => {
  const {patch} = useRequests ()
  const {getConfirmation} = useConfirmationDialog ()
  const {show, off} = useBackdrop ()
  const {eSnack, sSnack} = useCustomSnackbar ()
 

  const getAdrId = () => {
    const id = {
         numadr: adr.numadr,
         codcli: adr.codcli,
         codproy: adr.codproy
     }
  return id
  }
  const rechazar = async row => {
    show("Rechazando ADR")
    try{
      const response = await patch(`/administration/adr/rechazar`, {
      id:getAdrId()
      })
      if(response?.ok){
        sSnack("ADR rechazado")
        reload();
        }else{
        eSnack(await getErrorMsg(response))
      /*   console.log("error updating"); */
      }
    }catch(e){
      eSnack("Error inesperado rechazando ")
      console.log("Error updating ", e);
    }finally{off()}
  }
  const enviar = async row => {
    show("Enviando ADR")
    try{
      const response = await patch(`/administration/adr/enviar`, {
      id:getAdrId()
      })
      if(response?.ok){
        sSnack("ADR Enviado")
        reload();
         }else{
        eSnack(await getErrorMsg(response))
      }
    }catch(e){
      eSnack("Error inesperado Enviando")
      console.log("Error updating ", e);
    }finally{off()}
  }
  const aprobar = async row => {
    show("Aprobando ADR")
    try{
      const response = await patch(`/administration/adr/aprobar`, {
      id:getAdrId()
      })
      if(response?.ok){
        sSnack("ADR Aprobado")
        reload();
        }else{
        eSnack(await getErrorMsg(response))
      }
    }catch(e){
      eSnack("Error inesperado al Aprobar ADR ")
      console.log("Error updating ", e);
    }finally{off()}
  }
  const mantenimiento = async row => {
    show("Enviando a Mantenimiento ADR")
    try{
      const response = await patch(`/administration/adr/mantenimiento`, {
      id:getAdrId()
      })
      if(response?.ok){
        sSnack("ADR Enviado a Mantenimiento")
        reload();
        }else{
        eSnack(await getErrorMsg(response))
      }
    }catch(e){
      eSnack("Error inesperado enviando ADR a mantenimiento")
      console.log("Error updating ", e);
    }finally{off()}
  }
  const entregar = async row => {
    show("Entregrando ADR")
    try{
      const response = await patch(`/administration/adr/entregar`, {
      id:getAdrId()
      })
      if(response?.ok){
        sSnack("ADR Entregado")
        reload();
        }else{
        eSnack(await getErrorMsg(response))
      }
    }catch(e){
      eSnack("Error inesperado al entregar ADR")
      console.log("Error updating ", e);
    }finally{off()}
  }
  const cerrar = async row => {
    show("Cerrando ADR")
    try{
      const response = await patch(`/administration/adr/cerrar`, {
      id:getAdrId()
      })
      if(response?.ok){
        sSnack("ADR Cerrado")
        reload();
      }else{
        eSnack(await getErrorMsg(response))
      }
    }catch(e){
      eSnack("Error inesperado Cerrando ADR ")
      console.log("Error updating ", e);
    }finally{off()}
  }



  return <>
     {adr.can?.change_to_apr && <Tooltip title={"Aprobar"}>
      <BeenhereTwoToneIcon color="secondary" onClick={async () => (await getConfirmation({ title: "Aprobar ADR", message: `Seguro desea Cambiar el estado del ADR ${adr.numadr}` })) ? aprobar(adr) : null} />
    </Tooltip>}
     {adr.can?.change_to_nap && <Tooltip title={"Retirar"}>
      <BackspaceTwoToneIcon color="secondary" onClick={async () => (await getConfirmation({ title: "Rechazar ADR", message: `Seguro desea Cambiar el estado del ADR ${adr.numadr}` })) ? rechazar(adr) : null} />
    </Tooltip>}
     {adr.can?.change_to_mto && <Tooltip title={"En Mantenimiento"}>
      <BuildCircleTwoToneIcon color="secondary" onClick={async () => (await getConfirmation({ title: "Enviar ADR a Mantenimiento", message: `Seguro desea Cambiar el estado del ADR ${adr.numadr}` })) ? mantenimiento(adr) : null} />
    </Tooltip>}
     {adr.can?.change_to_cer && <Tooltip title={"Cerrar"}>
      <CancelTwoToneIcon color="secondary" onClick={async () => (await getConfirmation({ title: "Cerrar ADR", message: `Seguro desea Cambiar el estado del ADR ${adr.numadr}` })) ? cerrar(adr) : null} />
    </Tooltip>}
     {adr.can?.change_to_env && <Tooltip title={"Enviar"}>
      <SendTwoToneIcon color="secondary" onClick={async () => (await getConfirmation({ title: "Enviar ADR", message: `Seguro desea Cambiar el estado del ADR ${adr.numadr}` })) ? enviar(adr) : null} />
    </Tooltip>}
     {adr.can?.change_to_ent && <Tooltip title={"Entregar"}>
      <DriveFileMoveTwoToneIcon color="secondary" onClick={async () => (await getConfirmation({ title: "Entregar ADR", message: `Seguro desea Cambiar el estado del ADR ${adr.numadr}` })) ? entregar(adr) : null} />
    </Tooltip>}      
    <Printadr getAdrId={getAdrId}/>
    </>;
})
