import { Paper, Grid, Divider, Stack, styled, Container, TextField, Button } from '@mui/material'
import { Box } from '@mui/system';


import React, { useState, useCallback, useEffect } from "react"
import { useRequests, useCustomSnackbar, getErrorMsg, FIELD_TYPES, /* formatDate */ useBackdrop } from "../../../helpers"
import { SkeletonDetailActivity, ConditionalWrapper } from '../../layout';
import { CardWithBar } from '../../layout/basic/CardWithBar';




export const EstimacionTotal = React.memo(({ adr, reload }) => {
  const { get, patch, controller } = useRequests()
  const { eSnack, sSnack } = useCustomSnackbar()
  const { show, off } = useBackdrop()


  const [analisis, setAnalisis] = useState("")
  const [implementacion, setImplementacion] = useState("")
  const [pruebas, setPruebas] = useState("")
  const [documentacion, setDocumentacion] = useState("")
  const [implantacion, setImplantacion] = useState("")
  const [gerencia, setGerencia] = useState("")
  const [ambiente, setAmbiente] = useState("")
  const [imprevistos, setImprevistos] = useState("")
  const [mensaje, setMensaje] = useState('')
  const [mensaje2, setMensaje2] = useState('')
  const [suma, setSuma] = useState()
  const [totalAD, setTotalAD] = useState()
  const [totalPB, setTotalPB] = useState()
  const [totalDOC, setTotalDOC] = useState()
  const [totalIMPL, setTotalIMPL] = useState()
  const [totalGER, setTotalGER] = useState()
  const [totalAMB, setTotalAMB] = useState()
  const [totalHoras, setTotalHoras] = useState()
  const [totalImprevistos, setTotalImprevistos] = useState()
  const [totalSuma, setTotalSuma] = useState()





  const Item2 = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.7),
    textAlign: 'left',
    color: theme.palette.text.primary,
  }));
  const Item3 = styled('div')(({ theme }) => ({
    ...theme.typography.h6,
    padding: theme.spacing(0.7),
    textAlign: 'left',
    color: theme.palette.text.primary,
  }));
  const Item4 = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.7),
    textAlign: 'left',
    color: theme.palette.text.primary,
  }));


  //Me trae las estimaciones tabla Estimaciones########################################################################################################
  const [ticketEstimacion, setTicketEstimacion] = useState([])
  const [loadingticketEstimacion, setLoadingticketEstimacion] = useState(false)
  const getTicketEstimacion = useCallback(async () => {
    try {
      setLoadingticketEstimacion(true)
      const response = await get(`/administration/adr/ticket_estimacion_total`, {
        idest: {
          numadr: adr.numadr,
          codcli: adr.codcli,
          codproy: adr.codproy
        },
      })
      if (response?.ok) {
        const data = await response.json()
        setTicketEstimacion(data)
      } else {
        eSnack(await getErrorMsg(response), getTicketEstimacion)
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo estimación total", getTicketEstimacion)
    } finally { setLoadingticketEstimacion(false) }
  }, [get, eSnack, adr.numadr, adr.codcli, adr.codproy])

  useEffect(() => {
    getTicketEstimacion()
  }, [getTicketEstimacion])

  //me traeestimaciones tabla EstimacionTotal########################################################################################################
  const [estimacionTotal, setEstimacionTotal] = useState([])
  const getEstimacionTotal = useCallback(async () => {
    try {
      //setLoadingStates(true)
      const response = await get(`/administration/adr/estimacion_total`, {

        idest: {
          adr: adr.numadr,
          codcli: adr.codcli,
          codproy: adr.codproy
        }

      })
      if (response?.ok) {
        const data = await response.json()
        setEstimacionTotal(data)
      } else {
        eSnack(await getErrorMsg(response), getEstimacionTotal)
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo estimacion", getEstimacionTotal)
    } finally {/*setLoadingStates(false)*/ }
  }, [get, eSnack, adr.numadr, adr.codcli, adr.codproy])

  useEffect(() => {
    getEstimacionTotal()
  }, [getEstimacionTotal])

  const horas = ticketEstimacion.reduce((prev, next) => (parseFloat(prev)) + (parseFloat(next.hh)), 0)

  useEffect(() => {
    if (suma) {
      const porc = parseInt(analisis) || 0
      const porcImplementacion = parseInt(implementacion) || 0
      const total = ((porc * horas) / porcImplementacion) || 0
      setTotalAD(total.toFixed(2))
    }
  }, [suma, analisis, horas, implementacion])

  useEffect(() => {
    if (suma) {
      const porc = parseInt(pruebas) || 0
      const porcImplementacion = parseInt(implementacion) || 0
      const total = ((porc * horas) / porcImplementacion) || 0
      setTotalPB(total.toFixed(2))
    }
  }, [suma, pruebas, horas, implementacion])

  useEffect(() => {
    if (suma) {
      const porc = parseInt(documentacion) || 0
      const porcImplementacion = parseInt(implementacion) || 0
      const total = ((porc * horas) / porcImplementacion) || 0
      setTotalDOC(total.toFixed(2))
    }
  }, [suma, documentacion, horas, implementacion])


  useEffect(() => {
    if (suma) {
      const porc = parseInt(implantacion) || 0
      const porcImplementacion = parseInt(implementacion) || 0
      const total = ((porc * horas) / porcImplementacion) || 0
      setTotalIMPL(total.toFixed(2))
    }
  }, [suma, implantacion, horas, implementacion])


  useEffect(() => {
    if (suma) {
      const porc = parseInt(gerencia) || 0
      const porcImplementacion = parseInt(implementacion) || 0
      const total = ((porc * horas) / porcImplementacion) || 0
      setTotalGER(total.toFixed(2))
    }
  }, [suma, gerencia, horas, implementacion])

  useEffect(() => {
    if (suma) {
      const porc = parseInt(ambiente) || 0
      const porcImplementacion = parseInt(implementacion) || 0
      const total = ((porc * horas) / porcImplementacion) || 0
      setTotalAMB(total.toFixed(2))
    }
  }, [suma, ambiente, horas, implementacion])


  useEffect(() => {
    if (suma) {
      const porcImplementacion = parseInt(implementacion) || 0

      const porcad = parseInt(analisis) || 0
      const totalad = ((porcad * horas) / porcImplementacion) || 0

      const porcpb = parseInt(pruebas) || 0
      const totalpb = ((porcpb * horas) / porcImplementacion) || 0

      const porcdoc = parseInt(documentacion) || 0
      const totaldoc = ((porcdoc * horas) / porcImplementacion) || 0

      const porcimpl = parseInt(implantacion) || 0
      const totalimpl = ((porcimpl * horas) / porcImplementacion) || 0

      const porcger = parseInt(gerencia) || 0
      const totalger = ((porcger * horas) / porcImplementacion) || 0

      const porcamb = parseInt(ambiente) || 0
      const totalamb = ((porcamb * horas) / porcImplementacion) || 0

      const totalsuma = (totalad + totalpb + totaldoc + totalimpl + totalger + totalamb + horas)

      setTotalHoras(totalsuma.toFixed(2))
    }
  }, [suma, implementacion, horas, analisis, pruebas, documentacion, implantacion, ambiente, gerencia])

  useEffect(() => {
    if (suma) {
      const porcImplementacion = parseInt(implementacion) || 0

      const porcad = parseInt(analisis) || 0
      const totalad = ((porcad * horas) / porcImplementacion) || 0

      const porcpb = parseInt(pruebas) || 0
      const totalpb = ((porcpb * horas) / porcImplementacion) || 0

      const porcdoc = parseInt(documentacion) || 0
      const totaldoc = ((porcdoc * horas) / porcImplementacion) || 0

      const porcimpl = parseInt(implantacion) || 0
      const totalimpl = ((porcimpl * horas) / porcImplementacion) || 0

      const porcger = parseInt(gerencia) || 0
      const totalger = ((porcger * horas) / porcImplementacion) || 0

      const porcamb = parseInt(ambiente) || 0
      const totalamb = ((porcamb * horas) / porcImplementacion) || 0

      const totalsuma = (totalad + totalpb + totaldoc + totalimpl + totalger + totalamb + horas)
      const totalimprevisto = (totalsuma * imprevistos) / 100

      setTotalImprevistos(totalimprevisto.toFixed(2))
    }
  }, [suma, imprevistos, implementacion, horas, analisis, pruebas, documentacion, implantacion, ambiente, gerencia])

  useEffect(() => {
    if (suma || totalImprevistos) {
      const porcImplementacion = parseInt(implementacion) || 0

      const porcad = parseInt(analisis) || 0
      const totalad = ((porcad * horas) / porcImplementacion) || 0

      const porcpb = parseInt(pruebas) || 0
      const totalpb = ((porcpb * horas) / porcImplementacion) || 0

      const porcdoc = parseInt(documentacion) || 0
      const totaldoc = ((porcdoc * horas) / porcImplementacion) || 0

      const porcimpl = parseInt(implantacion) || 0
      const totalimpl = ((porcimpl * horas) / porcImplementacion) || 0

      const porcger = parseInt(gerencia) || 0
      const totalger = ((porcger * horas) / porcImplementacion) || 0

      const porcamb = parseInt(ambiente) || 0
      const totalamb = ((porcamb * horas) / porcImplementacion) || 0

      const totalsuma = (totalad + totalpb + totaldoc + totalimpl + totalger + totalamb + horas)
      const totalimprevisto = (totalsuma * imprevistos) / 100
      const totalgeneral = totalsuma + totalimprevisto

      setTotalSuma(Math.round(totalgeneral))
    }
  }, [suma, totalImprevistos, imprevistos, implementacion, horas, analisis, pruebas, documentacion, implantacion, ambiente, gerencia])



  //########################################################################################################

  useEffect(() => {
    if (Object.keys(adr.tickets).length === 0)
      setMensaje('Este ADR no posee tickets Asociados')
  }, [adr.tickets])

  useEffect(() => {
    if (JSON.stringify(ticketEstimacion) === '[]' && Object.keys(adr.tickets).length > 0)
      setMensaje2('No se han estimados los tickets asociados ')
  }, [ticketEstimacion, adr.tickets])


  useEffect(() => {
    if (JSON.stringify(ticketEstimacion) !== '[]') {
      const ad = parseInt(estimacionTotal.ad) || 0
      const im = parseInt(estimacionTotal.im) || 0
      const pb = parseInt(estimacionTotal.pb) || 0
      const doc = parseInt(estimacionTotal.doc) || 0
      const impl = parseInt(estimacionTotal.impl) || 0
      const ger = parseInt(estimacionTotal.ger) || 0
      const amb = parseInt(estimacionTotal.amb) || 0
      const totalporc = (ad + im + pb + doc + impl + ger + amb)
      setSuma(totalporc)
    }

  }, [ticketEstimacion, estimacionTotal])


  useEffect(() => {
    if (JSON.stringify(ticketEstimacion) !== '[]') {
      setAnalisis(parseInt(estimacionTotal.ad))
      setImplementacion(parseInt(estimacionTotal.im))
      setPruebas(parseInt(estimacionTotal.pb))
      setDocumentacion(parseInt(estimacionTotal.doc))
      setImplantacion(parseInt(estimacionTotal.impl))
      setGerencia(parseInt(estimacionTotal.ger))
      setAmbiente(parseInt(estimacionTotal.amb))
      setImprevistos(parseInt(estimacionTotal.imprevistos))
    }

  }, [estimacionTotal, ticketEstimacion])
  const updateTotal = async e => {
    e.preventDefault()

    show('Guardando datos')
    const data = {
      ad: analisis,
      im: implementacion,
      pb: pruebas,
      doc: documentacion,
      impl: implantacion,
      ger: gerencia,
      amb: ambiente,
      imprevistos: imprevistos,

    }
    try {
      const request = await patch(`/administration/adr/update_etimacion_total`, {
        idest: {
          adr: adr.numadr,
          codcli: adr.codcli,
          codproy: adr.codproy
        },
        id: {
          numadr: adr.numadr,
          codcli: adr.codcli,
          codproy: adr.codproy
        },
        estim: data,
        porc: suma,
        total: totalSuma
      })
      if (request?.ok) {
        sSnack("Datos Guardados")
        reload()
      } else {
        eSnack(await getErrorMsg(request))
      }
    } catch (e) {
      console.log(e)
      eSnack("Error inesperado creando OT")
    } finally {
      off();
    }
  }

  useEffect(() => () => controller.abort(), [controller])


  const mostrar = () => {
    if (Object.keys(adr.tickets).length === 0) {
      return (

        <Paper>
          <Stack container>
            <Item2> {mensaje}</Item2>
          </Stack>
        </Paper>
      )
    }
    else if (JSON.stringify(ticketEstimacion) === '[]' && Object.keys(adr.tickets).length > 0) {
      return (
        <ConditionalWrapper condition={loadingticketEstimacion} wrapper={SkeletonDetailActivity}>

          <Paper>
            <Stack container>
              <Item2> {mensaje2}</Item2>
            </Stack>
          </Paper>

        </ConditionalWrapper>)
    }
  }
  const mostrarTotal = () => {
    if (JSON.stringify(ticketEstimacion) !== '[]') {
      return (
        <>

            <form onSubmit={updateTotal}>
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item lg={1} ml={1.5}>

                </Grid>
                <Grid item lg={1} ml={4.6}  > <Item4 > <b>%</b></Item4>
                </Grid>
                <Grid item lg={1} > <Item4> <b>HH</b></Item4>
                </Grid>
              </Grid>

              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      Análisis y diseño:
                    </Item2>
                  </Stack>
                  <Divider />
                </Grid>
                <Grid item lg={1} ml={3} >

                  <TextField
                    style={{
                      width: "75%",
                    }}
                     disabled={!adr.can.associate_tickets}
                  
                    id="analisis"
                    value={analisis}
                    variant="standard"
                    type={FIELD_TYPES.NUMBER}
                    InputProps={{ inputProps: { min: 0 } }}
                    //onChange={handleForm}
                    onMouseUp={() => setSuma((parseInt(analisis) || 0) + parseInt((implementacion) || 0) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}
                    onKeyUpCapture={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}
                    onChange={e => setAnalisis(e.target.value)}
                    size="small"
                  />
                </Grid>

                <Grid item xs={2} > <Item2> <b>{totalAD}</b></Item2></Grid>
              </Grid><br />
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      Implementación:
                    </Item2>
                  </Stack>
                  <Divider />
                </Grid>
                <Grid item lg={1} ml={3}>
                  <TextField
                    style={{
                      width: "75%",
                    }}
                     disabled={!adr.can.associate_tickets}
                    id="implementacion"
                    label=""
                    value={implementacion}
                    type={FIELD_TYPES.NUMBER}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    size="small"
                    variant="standard"
                    onChange={e => setImplementacion(e.target.value)}
                    onKeyUpCapture={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}
                    onMouseUp={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}
                  />
                </Grid>

                <Grid item xs={2} > <Item2> <b>{horas.toFixed(2)}</b></Item2> </Grid>
              </Grid>
              <br />
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      Pruebas:
                    </Item2>
                  </Stack>
                  <Divider />
                </Grid>
                <Grid item lg={1} ml={3}>
                  <TextField
                    style={{
                      width: "75%",
                    }}
                     disabled={!adr.can.associate_tickets}
                    id="pruebas"
                    label=""
                    value={pruebas}
                    type={FIELD_TYPES.NUMBER}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    size="small"
                    variant="standard"
                    onChange={e => setPruebas(e.target.value)}
                    onKeyUpCapture={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}
                    onMouseUp={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}

                  />
                </Grid>

                <Grid item xs={2} > <Item2> <b>{totalPB}</b></Item2>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      Documentación:
                    </Item2>
                  </Stack>
                  <Divider />
                </Grid>
                <Grid item lg={1} ml={3}>
                  <TextField
                    style={{
                      width: "75%",
                    }}
                     disabled={!adr.can.associate_tickets}
                    id="documentacion"
                    label=""
                    value={documentacion}
                    type={FIELD_TYPES.NUMBER}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    size="small"
                    variant="standard"
                    onChange={e => setDocumentacion(e.target.value)}
                    onMouseUp={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}
                    onKeyUpCapture={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}

                  />
                </Grid>

                <Grid item xs={2} > <Item2> <b>{totalDOC}</b></Item2>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      Implantación:
                    </Item2>
                  </Stack>
                  <Divider />
                </Grid>
                <Grid item lg={1} ml={3}>
                  <TextField
                    style={{
                      width: "75%",
                    }}
                     disabled={!adr.can.associate_tickets}
                    id="implantacion"
                    label=""
                    value={implantacion}
                    type={FIELD_TYPES.NUMBER}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    size="small"
                    variant="standard"
                    onChange={e => setImplantacion(e.target.value)}
                    onMouseUp={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}
                    onKeyUpCapture={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}

                  />
                </Grid>

                <Grid item xs={2} > <Item2> <b>{totalIMPL}</b></Item2>
                </Grid>
              </Grid> <br />
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      Gerencia:
                    </Item2>
                  </Stack>
                  <Divider />
                </Grid>
                <Grid item lg={1} ml={3}>
                  <TextField
                    style={{
                      width: "75%",
                    }}
                     disabled={!adr.can.associate_tickets}
                    id="gerencia"
                    label=""
                    value={gerencia}
                    type={FIELD_TYPES.NUMBER}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    size="small"
                    variant="standard"
                    onChange={e => setGerencia(e.target.value)}
                    onMouseUp={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}
                    onKeyUpCapture={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}

                  />
                </Grid>

                <Grid item xs={2} > <Item2> <b>{totalGER}</b></Item2>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      Ambiente:
                    </Item2>
                  </Stack>
                  <Divider />
                </Grid>
                <Grid item lg={1} ml={3}>
                  <TextField
                    style={{
                      width: "75%",
                    }}
                     disabled={!adr.can.associate_tickets}
                    id="ambiente"
                    label=""
                    value={ambiente}
                    type={FIELD_TYPES.NUMBER}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    size="small"
                    variant="standard"
                    onChange={e => setAmbiente(e.target.value)}
                    onMouseUp={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}
                    onKeyUpCapture={() => setSuma(parseInt(analisis) + parseInt(implementacion) + parseInt(pruebas) + parseInt(documentacion) + parseInt(implantacion) + parseInt(gerencia) + parseInt(ambiente))}

                  />
                </Grid>
                <Grid item xs={2} > <Item2> <b>{totalAMB}</b></Item2>
                </Grid>
              </Grid>  <br />
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      <b> Total %</b>
                    </Item2>
                  </Stack>

                </Grid>
                <Grid item lg={0.9} ml={2.5}>
                  <Stack container bgcolor={"#BBDEFB"} >
                    <Item2>
                      <b>{suma}</b>
                    </Item2>
                  </Stack>

                </Grid>
                <Grid item xs={2} ml={1} > <Item2> <b>{totalHoras}</b></Item2>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      Imprevistos:
                    </Item2>
                  </Stack>
                  <Divider />
                </Grid>
                <Grid item lg={1} ml={3}>
                  <TextField
                    style={{
                      width: "75%",
                    }}
                     disabled={!adr.can.associate_tickets}
                    id="imprevistos"
                    label=""
                    value={imprevistos}
                    type={FIELD_TYPES.NUMBER}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    size="small"
                    variant="standard"
                    onChange={e => setImprevistos(e.target.value)}
                    onMouseUp={() => setTotalImprevistos(((totalHoras * imprevistos) / 100).toFixed(2))}
                    onKeyUpCapture={() => setTotalImprevistos(((totalHoras * imprevistos) / 100).toFixed(2))}
                  />
                </Grid>
                <Grid item xs={2} > <Item2> <b>{totalImprevistos}</b></Item2>
                </Grid>
              </Grid>  <br />

              <br />
              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={2} ml={3}>
                  <Stack container>
                    <Item2>
                      <b> Total HH:</b>
                    </Item2>
                  </Stack>

                </Grid>
                <Grid item lg={1} ml={3}>

                </Grid>
                <Grid item xs={2}  >
                  <Stack container bgcolor={"#BBDEFB"} mr={10}>
                    <Item2 > <b>{totalSuma}</b></Item2>
                  </Stack>
                </Grid>
              </Grid>  <br />

              <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item lg={2} >
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={!adr.can.associate_tickets}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>  
            </form>
   
        </>
      )
    }
  }





  return (


    <CardWithBar title={"Total General:"}>
      <Container sx={{ marginY: 2 }}>

        {mostrar()}
        {mostrarTotal()}
      </Container>
    </CardWithBar>
  )
})