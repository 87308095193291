import React, {useEffect, useState, useCallback, useContext} from 'react'
import {
  TextField,
  Typography,
  Container,
  Button,
  InputAdornment,
  IconButton
} from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Autocomplete } from '../../../layout';
import {useRequests, getErrorMsg, useCustomSnackbar, UserContext, useBackdrop} from '../../../../helpers';

export const NewUser = React.memo(props => {
  const currentUser = useContext(UserContext)
  const [nombpers, setNombpers] = useState({id:"nombpers",name:"Nombre", value:"", required:true})
  const [cipers, setCipers] = useState({id:"cipers",name:"Cédula", value:""})
  const [nrotelofic, setNrotelofic] = useState({id:"nrotelofic",name:"Teléfono Oficina", value:""})
  const [nrotelmovil, setNrotelmovil] = useState({id:"nrotelmovil",name:"Teléfono Móvil", value:""})
  const [nrotelhab, setNrotelhab] = useState({id:"nrotelhab",name:"Teléfono Habitación", value:""})
  const [email, setEmail] = useState({id:"email",name:"Email", value:"", required:true})
  const [clave, setClave] = useState({id:"clave",name:"Password", password:true, value:"", required:true})
  const [passwordError, setPasswordError] = useState("Password debe tener al menos 6 caracteres")
  const [confirmation, setConfirmation] = useState({id:"confirmation",name:"Confirmar Password", helper:passwordError, password:true, value:"", required:true})
  const [roles, setRoles] = useState([])
  const [activeIndicators, setActiveIndicators] = useState([])
  const [clients, setClients]= useState([])
  const [tipo, setTipo] = useState(null)
  const [codcli, setCodcli] = useState(null)
  const [indactivo, setIndactivo] = useState(null)
  const {show, off} = useBackdrop ()
  const {get, post, controller} = useRequests()
  const {eSnack, sSnack} = useCustomSnackbar()
  const textFields = [nombpers, email, clave, confirmation, cipers, nrotelofic, nrotelmovil, nrotelhab]
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const isAdmin = currentUser.role === "admin"
  const [Loadinglist, setLoadinglist] = useState(false)

  useEffect(()=>{
    if(!isAdmin){
      setCodcli(clients.find(client => client?.value === currentUser.client) || null)
    }
  },[clients, currentUser, isAdmin])

  useEffect(()=>{
    if(!isAdmin){
      setTipo(roles.find(role => role?.value === "USER") || null)
    }
  },[roles, currentUser, isAdmin])

  const handleSubmit = async e => {
    e.preventDefault()
    show('Creando Usuario')
    try {
      const request = await post('/administration/users',{
        user:{
          nombpers:nombpers.value,
          cipers:cipers.value,
          nrotelofic:nrotelofic.value,
          nrotelmovil:nrotelmovil.value,
          nrotelhab:nrotelhab.value,
          email:email.value,
          password:clave.value,
          codcli:codcli?.value,
          tipo:tipo?.value,
          indactivo:indactivo?.value
        }
      })
      if(request?.ok){
        sSnack("Usuario Creado")
        props.history.push(`/administration/users`)
      }else{
        eSnack(await getErrorMsg(request))
        console.log("Error creating user");
      }
    } catch (e) {
      eSnack("Error inesperado creando usuario")
      console.log("Error creating user", e);
    } finally {
      off()
    }
  }

  const getAssociations = useCallback(async ()=>{
    /*  iSnack("Cargando Listas") */
    try{
      setLoadinglist(true)
      const response = await get('/administration/user_associations')
      if(response?.ok){
        const associations = await response.json()
        setRoles(associations.roles)
        setActiveIndicators(associations.active_indicators)
        setClients(associations.clients)
      }else{
        eSnack(await getErrorMsg(response))
        console.log("Error getting associations");
      }
    }catch(e){
      eSnack("Error inesperado cargando listas")
      console.log("Error getting associations", e);
    }finally {setLoadinglist(false)}
  },[ get, eSnack])

  useEffect(()=>{
    getAssociations()
  },[getAssociations])

  useEffect(() => () => controller.abort(), [controller])

  const handleForm = e => {
    const {value, id} = e.target
    switch(id){
      case "nombpers":
        setNombpers({...nombpers, value:value})
        break
      case "cipers":
        setCipers({...cipers, value:value})
        break
      case "nrotelofic":
        setNrotelofic({...nrotelofic, value:value})
        break
      case "nrotelmovil":
        setNrotelmovil({...nrotelmovil, value:value})
        break
      case "nrotelhab":
        setNrotelhab({...nrotelhab, value:value})
        break
      case "email":
        setEmail({...email, value:value.toLowerCase()})
        break
      case "clave":
        setClave({...clave, value:value})
        value === confirmation.value ? setPasswordError(false) : setPasswordError("Confirmar password debe coincidir con Password")
        break
      case "confirmation":
        setConfirmation({...confirmation, value:value})
        switch(true){
          case value.length < 6:
            setPasswordError("Password debe tener al menos 6 caracteres")
            break
          case value !== clave.value:
            setPasswordError("Confirmar password debe coincidir con Password")
            break
          default:
           setPasswordError(false)
           break
        }
        break
      default:
        break
    }
  }

  useEffect(()=> {
    setConfirmation(prevState => {
      return {...prevState, helper:passwordError}
    })
  },[passwordError])

  return (
    <Container className="new-user-container">
      <Typography component="h1" variant="h5">
        Nuevo Usuario
      </Typography>
      <form onSubmit={handleSubmit}>
        {textFields.map((field, index)=> {
          return (
            <div key={`new-user-field-${index}`} className="form-field">
              <TextField
                label={field.name}
                name={field.id}
                id={field.id}
                fullWidth

                value={field.value}
                onChange={handleForm}
                required={field.required}
                type={field.password && !showPassword ? "password" : "text"}
                autoComplete="new-password"
                helperText={field.helper}
                error={!!field.helper}
                InputProps={field.password ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="large">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                } : {}}
              />
            </div>
          );
        })}
        <div className="form-field">
          <Autocomplete
            options={roles}
            value={tipo}
            setValue={setTipo}
            loading={Loadinglist}
            getOptionLabel={(option) => option.name}
            disabled={!isAdmin}
            renderInput={(params) => <TextField {...params} required fullWidth label="Rol"  />}
          />
        </div>
        <div className="form-field">
          <Autocomplete
            options={clients}
            value={codcli}
            setValue={setCodcli}
            loading={Loadinglist}
            getOptionLabel={(option) => `${option.name} - ${option.value}`}
            disabled={!isAdmin}
            renderInput={(params) => <TextField {...params} required fullWidth label="Cliente"  />}
          />
        </div>
        <div className="form-field">
          <Autocomplete
            options={activeIndicators}
            value={indactivo}
            setValue={setIndactivo}
            loading={Loadinglist}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} required fullWidth label="Estado"  />}
          />
        </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          disabled={!!passwordError}
        >
          Crear
        </Button>
      </form>
    </Container>
  );
})
