import React, { useEffect, useState, useCallback } from "react"
import { BaseTable as ActivityOrdersTable, BaseTablePagination, BaseFilters, CheckedUnchecked } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, useTableTools, formatDate, useParsedURLParams, FIELD_TYPES } from "../../../helpers"
import {/*  Tooltip, */ Typography, AppBar, Toolbar, Stack, Breadcrumbs, Container } from '@mui/material';
/* import AssignmentRoundedIcon from '@mui/icons-material/Assignment'; */
/* import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone'; */
/* import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'; */
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@mui/material/Link';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButtonMenu } from "../../layout/basic/IconButtonMenu";

export const ActivityList = props => {
  const { get, controller } = useRequests()
  const [listActivityOrder, setListActivityOrder] = useState([])
  const [appliedFilters, setAppliedFilters] = useState(useParsedURLParams())
  const [ActivityorderFilters] = useState({
    idact: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Id Actividad"
    },
    idot: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Id Ot"
    },
    nomact: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Nombre"
    },
    refact: {
      type: FIELD_TYPES.TEXT,
      value: "",
      name: "Referencia"
    },
    fecinivigdesde: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Inicio Desde"
    },
    fecinivighasta: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Inicio Hasta"

    },
    fecfinvigdesde: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Fin Desde"
    },
    fecfinghasta: {
      type: FIELD_TYPES.DATE,
      value: "",
      name: "Fecha Fin Hasta"
    }
  })


  const [date] = useState(new Date().toISOString().split("T")[0]);
  const { eSnack } = useCustomSnackbar()
  const { page, rowsPerPage, total, setTotal, maxPage, setMaxPage, changePage, changeRows } = useTableTools()
  const [loadingTable, setLoadingTable] = useState(false)
  const [filtroOpen, setfiltroOpen] = useState(false);
  const history = useHistory();
  const toggle = () => setfiltroOpen(!filtroOpen)


  const getActivityOrders = useCallback(async () => {
    try {
      setLoadingTable(true)
      const response = await get('/activities', {
        page: page + 1,
        rowsPerPage: rowsPerPage,
        ...appliedFilters
      })
      if (response?.ok) {
        const data = await response.json()
        setListActivityOrder(data.list_activity_orders)
        setMaxPage(data.maxPage)
        setTotal(data.total)
      } else {
        eSnack(await getErrorMsg(response), getActivityOrders)
      }
    } catch (e) {
      console.log(e);
      eSnack("Error inesperado obteniendo las Actividades de órdenes de trabajo")
    } finally { setLoadingTable(false) }
  }, [get, page, rowsPerPage, eSnack, setTotal, setMaxPage, appliedFilters])


  useEffect(() => {
    getActivityOrders()
  }, [getActivityOrders])

  const applyFilters = filters => {
    changePage(null, 0)
    setAppliedFilters(filters)
  }

  const fechaInicioVig = (row) => {
    if (row.fecinivig === null) {
      return { content: row.fecinivig };
    } else {
      return { content: formatDate(new Date(row.fecinivig)) };
    }
  };

  const fechaFinVig = (row) => {
    if (row.fecfinvig === null) {
      return { content: row.fecfinvig };
    } else {
      return { content: formatDate(new Date(row.fecfinvig)) };
    }
  };

  const fechaInicio = (fecinivig) => {
    if (fecinivig === null) {
      return { name: "Fecha Inicio", content: "Aún no registrada" };
    } else {
      return { name: "Fecha Inicio", content: formatDate(new Date(fecinivig)) };
    }
  };
  const fechaFin = (fecfinvig) => {
    if (fecfinvig === null) {
      return { name: "Fecha Fin", content: "Aún no registrada" };
    } else {
      return { name: "Fecha Fin", content: formatDate(new Date(fecfinvig)) };
    }
  };

  const vigenciaAcT = (row) => (
    <CheckedUnchecked validate={row.fecfinvig > date} />
  );

  const idOt = (idot) => {
    if (idot === null) {
      return { name: "ID Ot", content: "Aún no registrada" };
    } else {
      return { name: "ID Ot", content: idot };
    }
  };

  const horaOriginal = (pptoorghh) => {
    if (pptoorghh === null) {
      return { name: "Hora Original", content: "Aún no registrada" };
    } else {
      return { name: "Hora Original", content: pptoorghh };
    }
  };

  const horaActual = (pptoacthh) => {
    if (pptoacthh === null) {
      return { name: "Hora Actual", content: "Aún no registrada" };
    } else {
      return { name: "Hora Actual", content: pptoacthh };
    }
  };

  const horaReal = (hhreal) => {
    if (hhreal === null) {
      return { name: "Hora Real", content: "Aún no registrada" };
    } else {
      return { name: "Hora Real", content: hhreal };
    }
  };

  const fechaRequerida = (fecreq) => {
    if (fecreq === null) {
      return { name: "Fecha Requerida", content: "Aún no registrada" };
    } else {
      return { name: "Fecha Requerida", content: formatDate(new Date(fecreq)) };
    }
  };

  const responsable = (usucresp) => {
    if (usucresp === null) {
      return { name: "Fecha Fin", content: "Aún no registrado" };
    } else {
      return { name: "Fecha Fin", content: usucresp };
    }
  };

  const claseActividad = (h_clase) => {
    if (h_clase === null) {
      return { name: "Clase", content: "Aún no registrada" };
    } else {
      return { name: "Clase", content: h_clase };
    }
  };

  const descripcion = (descact) => {
    if (descact === null) {
      return { name: "Fecha Fin", content: "Aún no registrada" };
    } else {
      return { name: "Descripción", content: descact };
    }
  };

  const openActivity = ({ idactividad }) => {
    history.push(`/activities/${idactividad}`)
  }

/*   const acciones = (row) => {
    return(
      <>
      <Tooltip title={`Abrir Actividad ${row.idactividad}`}>
      <Link component={RouterLink} to={`/activities/${row.idactividad}`}>
        <AssignmentTwoToneIcon color="secondary" />
      </Link>
    </Tooltip>
    
    <Tooltip title={`Abrir defectos para la actividad ${row.idactividad}`}>
    <Link component={RouterLink} to={`/administration/defects?idactividad=${row.idactividad}`}>
      <BookmarkBorderOutlinedIcon color="secondary" />
    </Link>
  </Tooltip>
  </>
    )
  }; */


  useEffect(() => () => controller.abort(), [controller])

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,

    <Typography key="3" color="text.primary">
      Actividades
    </Typography>,
  ];

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <IconButtonMenu
            open={filtroOpen}
            toggleMenu={toggle}
            size="small"
            color={"primary"}
            icon={<FilterListIcon />}
          >
            <BaseFilters filters={ActivityorderFilters} applyFilters={applyFilters} />
          </IconButtonMenu>
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container className="base-container">
        <ActivityOrdersTable
          loading={loadingTable}
          rows={listActivityOrder}
          rowAction={row => openActivity(row)}
          headers={[
            "Id ",
            "Nombre",
            "Referencia",
            "Fecha inicio",
            "Fecha Fin",
          ]}
          collapsible={{
            visible: (row) => [
              { content: row.idactividad, handleCollapse: true, align: "left" },
              { content: row.nomactividad },
              { content: row.activity_work_order.refact },
              fechaInicioVig(row.activity_work_order),
              fechaFinVig(row.activity_work_order),
/*               {
                content: (
                  acciones(row)
                ),
                action: () => null,
                disableTooltip: true,
              },
               */
                             
            ],

            collapsed: (row) => [
              fechaInicio(row.activity_work_order?.fecinivig),
              fechaFin(row.activity_work_order?.fecfinvig),
              { name: "Vigente", content: vigenciaAcT(row.activity_work_order) },
              idOt(row.activity_work_order?.idot),
              horaOriginal(row.activity_work_order?.pptoorghh),
              horaActual(row.activity_work_order?.pptoacthh),
              horaReal(row.activity_work_order?.hhreal),
              fechaRequerida(row.activity_work_order?.fecreq),
              responsable(row.activity_work_order?.usucresp),
              claseActividad(row.h_clase),
              descripcion(row.activity_work_order?.descact),
            ],
          }}
          title="Actividades"
        >
          <BaseTablePagination
            page={page}
            maxPage={maxPage}
            rowsPerPage={rowsPerPage}
            totalRows={total}
            handlePageChange={changePage}
            handleChangeRowsPerPage={changeRows}
          />
        </ActivityOrdersTable>
      </Container>
    </>
  );
}

