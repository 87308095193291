import React, { useEffect, useState, useCallback } from 'react'
import { useRequests, getErrorMsg, useCustomSnackbar, formatDate, useConfirmationDialog, useBackdrop, FIELD_TYPES } from '../../../helpers';
import { ConditionalWrapper, PriorityDisplay, SkeletonList, SkeletonDetailActivity, Autocomplete, EditableTextField } from '../../layout';
import {
  Paper,
  Box,
  TextField,
  Tabs,
  Tab,
  Typography,
  AppBar,
  Toolbar,
  Stack,
  Breadcrumbs,
  Container,
  Grid
  // Button,
} from '@mui/material';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { ActivityObject, ActivityAssignation, ActivityModule } from "../."
import '../../../stylesheets/activity.scss'
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { DefectsActivity } from './defectos';


const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

export const ActivityDetail = props => {

  const { get, post, patch, controller } = useRequests()

  const [actividad, setActividad] = useState({})
  const { show, off } = useBackdrop()
  const { getConfirmation } = useConfirmationDialog()

  const [statuses, setStatuses] = useState([])
  const [currentStatus, setCurrentStatus] = useState(null)
  const [types, setTypes] = useState([])
  const [currentType, setCurrentType] = useState(null)
  const [priorities, setPriorities] = useState([])
  const [currentPriority, setCurrentPriority] = useState(null)

  const { eSnack, sSnack } = useCustomSnackbar()


  const [currentTab, setCurrentTab] = useState('activity');

  const tabs = [
    { value: 'activity', label: 'Actividad' },
    { value: 'edit_profile', label: 'Asignación/Usuarios' },
    { value: 'notifications', label: 'Módulos' },
    { value: 'security', label: 'Objetos' },
    { value: 'defects', label: 'Defectos' }
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };


  const [loadingACT, setLoadingACT] = useState(false)
  const [loadingSTS, setLoadingSTS] = useState(false)
  const [loadingTYP, setLoadingTYP] = useState(false)
  const [loadingPRI, setLoadingPRI] = useState(false)



  const getStatuses = useCallback(async () => {
    // iSnack("Cargando Estados")
    try {
      setLoadingSTS(true)
      const response = await get(`/activities/statuses_activity`, {
      })
      if (response?.ok) {
        const data = await response.json()
        setStatuses(data)
      } else {
        eSnack(await getErrorMsg(response), getStatuses)
        console.log("error getting statuses");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo estados", getStatuses)
      console.log("Error getting Statuses", e);
    } finally { setLoadingSTS(false) }
  }, [get, eSnack])


  useEffect(() => {
    getStatuses()
  }, [getStatuses])

  useEffect(() => {
    if (statuses.length && actividad.stsact) {
      setCurrentStatus(statuses.find(status => status.codsts === actividad.stsact))
    }
  }, [actividad, statuses])

  //#######################################################
  //para traer los tipos de actividad

  const getTypes = useCallback(async () => {
    // iSnack("Cargando Tipos")
    try {
      setLoadingTYP(true)
      const response = await get(`/activities/types_activity`, {
      })
      if (response?.ok) {
        const data = await response.json()
        setTypes(data)
      } else {
        eSnack(await getErrorMsg(response), getTypes)
        console.log("error getting types");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo tipos", getTypes)
      console.log("Error getting types", e);
    } finally { setLoadingTYP(false) }
  }, [get, eSnack])

  useEffect(() => {
    getTypes()
  }, [getTypes])

  useEffect(() => {
    if (types.length && actividad.codtipoact) {
      setCurrentType(types.find(type => type.codtipoact === actividad.codtipoact))
    }
  }, [actividad, types])

  //####################################types###################
  //para traer las prioridades de actividad

  const getPriorities = useCallback(async () => {
    // iSnack("Cargando Prioridades")
    try {
      setLoadingPRI(true)
      const response = await get('/activities/priorities_activity')
      if (response?.ok) {
        const priorities = await response.json()
        setPriorities(priorities)
      } else {
        eSnack(`${await getErrorMsg(response)}`, getPriorities)
        console.log("Error getting priorities");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo prioridades", getPriorities)
      console.log("Error getting priorities", e);
    } finally { setLoadingPRI(false) }
  }, [eSnack, get])

  useEffect(() => {
    getPriorities()
  }, [getPriorities])

  useEffect(() => {

    if (priorities.length && actividad.prioridad) {

      setCurrentPriority(priorities.find(priority => priority.prioridad === parseInt(actividad.prioridad)))
    }
  }, [actividad, priorities])

  //######################################################
  //para traer los datos de BD de la actividad
  const getActividad = useCallback(async () => {
    // show("Cargando Actividad")
    try {
      setLoadingACT(true)
      const response = await get(`/activities/${props.match.params.id}`)
      if (response?.ok) {
        const actividad = await response.json()
        // debugger
        setActividad(actividad)
      } else {
        eSnack(await getErrorMsg(response), getActividad)
        console.log("error getting actividad");
      }
    } catch (e) {
      eSnack("Error inesperado obteniendo actividad")
      console.log("Error getting Actividad", e);
    } finally { setLoadingACT(false) }
  }, [get, props.match.params.id, eSnack])

  useEffect(() => {
    getActividad()
  }, [getActividad])

  //######################################################
  //paramostrar lo check

  const getAssignedIcon = () => {
    if (actividad.is_for_release) {
      return <CheckCircleOutlineRoundedIcon color="secondary" onClick={async () => (await getConfirmation({ title: "Indicador Pase Release", message: `Seguro desea cambiar el indicador de la activadad ${actividad.idactividad}` })) ? changueEstadoRelease("N") : null} />;
    } else {
      return <RadioButtonUncheckedRoundedIcon color="error" onClick={async () => (await getConfirmation({ title: "Indicador Pase Release", message: `Seguro desea cambiar el indicador de la activadad ${actividad.idactividad}` })) ? changueEstadoRelease("S") : null} />;
    }
  }

  const saveField = async value => {
    if (!value) return sSnack("Debe colocar un valor en el campo")

    show("Actualizando Datos")
    try {
      const response = await patch(`/activities/update`, {

        id: actividad.idactividad,
        actividad: value
      })
      if (response?.ok) {

        const actividad = await response.json()
        sSnack("Dato actualizado")
        setActividad(actividad)
      } else {

        eSnack(await getErrorMsg(response))
        console.log("error actualizando daros");
      }
    } catch (e) {

      eSnack("Error inesperado actualizando Datos")
      console.log("Error changing datos", e);
    } finally {
      off()
    }
  }

  const saveFieldActOt = async value => {
    if (!value) return sSnack("Debe colocar un valor en el campo")

    show("Actualizando Datos")
    try {
      const response = await patch(`/activities/updateActOt`, {

        id: actividad.idactividad,
        actividad: value
      })
      if (response?.ok) {
        sSnack("Datos actualizados")
        getActividad()
      } else {

        eSnack(await getErrorMsg(response))
        console.log("error actualizando datos");
      }
    } catch (e) {

      eSnack("Error inesperado actualizando los datos")
      console.log("Error changing datos", e);
    } finally {
      off()
    }
  }

  //si las fechas vienen null
  const fechaStatus = () => {
    if (actividad.fecsts === null) {
      return (<Box   >Fecha Status: <b>{actividad.fecsts}</b></Box>

      )
    } else {
      return (
        <Box   >Fecha Status: <b>{formatDate(new Date(actividad.fecsts))}</b></Box>
      )
    }
  }
  const fechaPlanificada = () => {
    if (actividad.stsact === "CER") {
      if (actividad.fecentplan === null) {
        return (

          <Box p={1} ><b>{actividad.fecentplan}</b></Box>
        )
      }
      else {
        return (
          <Box p={1} ><b>{formatDate(new Date(actividad.fecentplan))}</b></Box>
        )
      }
    }
  }
  const ActfechaPlanificada = () => {
    if (actividad.stsact === "PEN" || actividad.stsact === "EJE" || actividad.stsact === "ASI" || actividad.stsact === "DOC" || actividad.stsact === "REV") {
      return (
        <Box p={1} >
          <EditableTextField
            
            initialValue={actividad.fecentplan}
            name="fecentplan"
            save={saveField}
            type={FIELD_TYPES.DATE}
            placeholder="Fecha Planificada"
          />
        </Box>

      )
    }
  }

  const fechaInicio = () => {
    if (actividad.stsact === "CER") {
      if (actividad.fecinicio === null) {
        return (
          <Box p={1} > <b>{actividad.fecinicio}</b></Box>
        )
      }
      else {
        return (
          <Box p={1} ><b>{formatDate(new Date(actividad.fecinicio))}</b></Box>
        )
      }
    }
  }

  const ActfechaInicio = () => {
    if (actividad.stsact === "PEN" || actividad.stsact === "EJE" || actividad.stsact === "ASI" || actividad.stsact === "DOC" || actividad.stsact === "REV") {
      return (

        /*  <TextField
          size="small"
          label="Cambiar Fecha Inicio"
          id="fecinicio"
          fullWidth
          type="date"
    
          onChange={handleDates}
          defaultValue={actividad.fecinicio}
          InputLabelProps={{
            shrink: true,
          }}
    
       />*/
        <Box p={1}>
          <EditableTextField
            initialValue={actividad.fecinicio}
            name="fecinicio"
            save={saveField}
            type={FIELD_TYPES.DATE}
            placeholder="Fecha Inicio"
          />
        </Box>
      )
    }
  }
  const fechaEinicial = () => {
    if (actividad.stsact === "CER") {
      if (actividad.fecentini === null) {
        return (
          <Box p={1} ><b>{actividad.fecentini}</b></Box>
        )
      }
      else {
        return (
          <Box p={1} ><b>{formatDate(new Date(actividad.fecentini))}</b></Box>
        )
      }
    }

  }

  const ActfechaEinicial = () => {
    if (actividad.stsact === "PEN" || actividad.stsact === "EJE" || actividad.stsact === "ASI" || actividad.stsact === "DOC" || actividad.stsact === "REV") {
      return (
        /*<TextField
        size="small"
             label="Cambiar Fecha Ent. Inicial"
             id="fecentini"
             fullWidth
             type="date"
  
             onChange={handleDates}
             defaultValue={actividad.fecentini}
             InputLabelProps={{
               shrink: true,
             }}
  
          />*/
        <Box p={1}>
          <EditableTextField
            initialValue={actividad.fecentini}
            name="fecentini"
            save={saveField}
            type={FIELD_TYPES.DATE}
            placeholder="Fecha Ent. Inicial"
          /> </Box>
      );
    }
  }

  const fechaEfinal = () => {
    if (actividad.stsact === "CER") {
      if (actividad.fecentfin === null) {
        return (
          <Box p={1}><b>{actividad.fecentfin}</b></Box>
        )
      }
      else {
        return (
          <Box p={1}><b>{formatDate(new Date(actividad.fecentfin))}</b></Box>
        )
      }
    }
  }

  const ActfechaEfinal = () => {
    if (actividad.stsact === "PEN" || actividad.stsact === "EJE" || actividad.stsact === "ASI" || actividad.stsact === "DOC" || actividad.stsact === "REV") {
      return (
        /* <TextField
          size="small"
               label="Cambiar Fecha Ent. final"
               id="fecentfin"
               fullWidth
               type="date"
  
               onChange={handleDates}
               defaultValue={actividad.fecentfin}
               InputLabelProps={{
                 shrink: true,
               }}
            />*/
        <Box p={1}>


          <EditableTextField
            initialValue={actividad.fecentfin}
            name="fecentfin"
            save={saveField}
            type={FIELD_TYPES.DATE}
            placeholder="Fecha Ent. final"
          /> </Box>
      );
    }
  }

  const fechaCierre = () => {
    if (actividad.stsact === "CER") {
      if (actividad.feccierre === null) {
        return (
          <Box p={1}><b>{actividad.feccierre}</b></Box>
        )
      }
      else {
        return (
          <Box p={1}><b>{formatDate(new Date(actividad.feccierre))}</b></Box>
        )
      }
    }
  }
  const ActfechaCierre = () => {
    if (actividad.stsact === "PEN" || actividad.stsact === "EJE" || actividad.stsact === "ASI" || actividad.stsact === "DOC" || actividad.stsact === "REV") {
      return (
        /*  <TextField
               size="small"
               label="Cambiar Fecha Cierre"
               id="feccierre"
               fullWidth
               type="date"
    
               onChange={handleDates}
               defaultValue={actividad.feccierre}
               InputLabelProps={{
                 shrink: true,
               }}
            />*/
        <Box p={1}>
          <EditableTextField
           
            initialValue={actividad.feccierre}
            name="feccierre"
            save={saveField}
            type={FIELD_TYPES.DATE}
            placeholder="Fecha Cierre"
          /> </Box>
      )
    }
  }

  //#################################################
  //cambiando status
  const changeStatus = async newStatus => {
    show("Cambiando Estatus")
    try {
      const response = await post(`/activities/change_status_activity`, {
        id: actividad.idactividad,
        new_status: newStatus

      })

      if (response?.ok) {
        const actividad = await response.json()
        sSnack("Status actualizados")
        setActividad(actividad)
      } else {

        eSnack(await getErrorMsg(response))
        console.log("error changing status");
      }
    } catch (e) {

      eSnack("Error inesperado cambiando estatus")
      console.log("Error changing status", e);
    } finally {
      off()
    }
  }
  //#################################################
  //cambiando estado release
  const changueEstadoRelease = async newEstado => {
    show("Cambiando Estado release")
    try {

      const response = await post(`/activities/change_estado_release`, {

        id: actividad.idactividad,
        new_estado: newEstado

      })

      if (response?.ok) {
        const actividad = await response.json()
        sSnack("Release actualizado")
        setActividad(actividad)

      } else {

        eSnack(await getErrorMsg(response))
        console.log("error changing estado");
      }
    } catch (e) {

      eSnack("Error inesperado cambiando estado")
      console.log("Error changing estado", e);
    } finally {
      off()
    }
  }
  //#################################################
  //cambiando tipo

  const changeType = async newType => {
    show("Cambiando Tipo de Actividad")
    try {
      const response = await post(`/activities/change_type_activity`, {
        id: actividad.idactividad,
        new_type: newType
      })
      if (response?.ok) {
        const actividad = await response.json()
        sSnack("Tipo actualizado")
        setActividad(actividad)
      } else {
        eSnack(await getErrorMsg(response))
        console.log("error changing type Activity");
      }
    } catch (e) {
      eSnack("Error inesperado cambiando tipo de Actividad")
      console.log("Error changing type", e);
    } finally {
      off()
    }
  }


  const changePriority = async newPriority => {
    show("Cambiando Prioridad")
    try {
      const response = await post(`/activities/change_priority_activity`, {
        id: actividad.idactividad,
        new_priority: newPriority
      })
      if (response?.ok) {
        const actividad = await response.json()
        sSnack("Prioridad actualizada")
        setActividad(actividad)
      } else {
        eSnack(await getErrorMsg(response))
        console.log("error changing priority");
      }
    } catch (e) {
      eSnack("Error inesperado cambiando prioridad")
      console.log("Error changing priority", e);
    } finally {
      off()
    }
  }

  const status = () => {
    if (actividad.stsact === "CER") {
      return (<Box > Status: <b>{actividad.h_status}</b></Box>)
    } else {
      return (
        <Box >
          <Autocomplete
            /* style={{ width: 210 }} */
            fullWidth
            nullable={false}
            loading={loadingSTS}
            options={statuses}
            size="small"
            value={currentStatus}
            valueKey={"codsts"}
            onChange={async (_, value) => {
              if (value.codsts && (await getConfirmation({ title: "Cambiar Estatus", message: `Cambiar estatus de ${actividad.h_status} -> ${value.codsts}?` }))) {
                changeStatus(value.codsts)
              }
            }}
            getOptionLabel={(option) => `${option.nomsts}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Estatus" />}
          />
        </Box>
      );
    }
  }
  const tipos = () => {
    if (actividad.stsact === "CER") {
      return (<Box > Tipo: <b>{actividad.h_desctipoact}</b></Box>)
    } else {
      return (
        <Box >
          <Autocomplete
            fullWidth
            nullable={false}
            loading={loadingTYP}
            options={types}
            size="small"
            value={currentType}
            valueKey="codtipoact"
            onChange={async (_, value) => {
              if (value.codtipoact && (await getConfirmation({ title: "Cambiar Tipo", message: `Cambiar tipo de Actividad ${actividad.codtipoact} -> ${value.codtipoact}?` }))) {
                changeType(value.codtipoact)
              }
            }}
            getOptionLabel={(option) => `${option.codtipoact}-${option.nomtipoact}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Tipo" />}
          />
        </Box>
      );
    }
  }
  const priority = () => {
    if (actividad.stsact === "CER") {
      return (<Box > <div className="priority-container">Prioridad: <PriorityDisplay priority={actividad.prioridad} text={actividad.h_priority} /></div></Box>)
    } else {
      return (
        <Box >
          <Autocomplete
            fullWidth
            nullable={false}
            options={priorities}
            loading={loadingPRI}
            size="small"
            value={currentPriority}
            valueKey="prioridad"
            onChange={async (_, value) => {
              if (value.prioridad && (await getConfirmation({ title: "Cambiar Prioridad", message: `Cambiar prioridad de ${actividad.h_priority} -> ${value.descpri}?` }))) {
                changePriority(value.prioridad)
              }
            }}
            getOptionLabel={(option) => `${option.descpri}`}
            renderInput={(params) => <TextField {...params} required fullWidth label="Prioridad" />}
          />
        </Box>
      );
    }
  }

  const horaOriginal = () => {
    if (actividad.stsact === "PEN" || actividad.stsact === "EJE" || actividad.stsact === "ASI" || actividad.stsact === "DOC" || actividad.stsact === "REV") {
      return (
        <Box p={1} >
          <EditableTextField
            
            initialValue={actividad.activity_work_order?.pptoorghh}
            name="pptoorghh"
            save={saveFieldActOt}
            type={FIELD_TYPES.DECIMAL}
            placeholder="Hora Original"
          />
        </Box>

      )
    }
  }
  const horaActual = () => {
    if (actividad.stsact === "PEN" || actividad.stsact === "EJE" || actividad.stsact === "ASI" || actividad.stsact === "DOC" || actividad.stsact === "REV") {
      return (
        <Box p={1} >
          <EditableTextField
            
            initialValue={actividad.activity_work_order?.pptoacthh}
            name="pptoacthh"
            save={saveFieldActOt}
            type={FIELD_TYPES.DECIMAL}
            placeholder="Hora Actual"
          />
        </Box>

      )
    }
  }
  const horaReal = () => {
    if (actividad.stsact === "PEN" || actividad.stsact === "EJE" || actividad.stsact === "ASI" || actividad.stsact === "DOC" || actividad.stsact === "REV") {
      return (
        <Box p={1} >
          <EditableTextField
            
            initialValue={actividad.activity_work_order?.hhreal}
            name="hhreal"
            save={saveFieldActOt}
            type={FIELD_TYPES.DECIMAL}
            placeholder="Hora Actual"
          />
        </Box>

      )
    }
  }

  useEffect(() => () => controller.abort(), [controller])

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
      Inicio
    </Link>,
    <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/activities/list">
      Actividad
    </Link>,
    <Typography key="3" color="text.primary">
      Detalle de la Actividad {actividad.idactividad}
    </Typography>,
  ];


  const Item2 = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.7),
    textAlign: 'lefth',
    color: theme.palette.text.primary,
  }));
  const Item3 = styled('div')(({ theme }) => ({
    ...theme.typography.h6,
    padding: theme.spacing(0.7),
    textAlign: 'left',
    color: theme.palette.text.primary,
  }));

  return (

    <>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <Stack spacing={2} ml={4}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Toolbar>
      </AppBar>



      <Container maxWidth="xl">
        <Grid
          container
          /*    direction="row"
             justifyContent="center"
             alignItems="stretch" */
          spacing={2}
          mt={1}
        >
          <Grid item xs={12} md={3.5}>

            <ConditionalWrapper condition={loadingACT} wrapper={children => <SkeletonList number={1}>{children}</SkeletonList>}>

              <Paper elevation={5}>

                <Stack container ml={1}>
                  <Item2>
                    Proyecto: <b> {actividad.h_codproject} -  {actividad.h_nomproject}</b>
                  </Item2>
                  <Item2>
                    Cliente:<b> {actividad.h_codcliproject} - {actividad.h_nomcliproject}</b>
                  </Item2>
                  <Item2>
                    Usuario Responsable: <b>{actividad.codpersresp}</b>
                  </Item2>
                </Stack>
                <Stack container ml={1}>
                  <Item2>
                    Actividad:<b> {actividad.idactividad} - {actividad.nomactividad}</b>
                  </Item2>
                </Stack>
                <Stack container ml={1}>

                  <Item2>
                    {status()}
                  </Item2>
                </Stack>
                <Stack container ml={1}>

                  <Item2>
                    {fechaStatus()}
                  </Item2>
                </Stack>
                <Stack>
                  <Item2>
                    <Grid container spacing={0} ml={1}>
                      <Grid item xs={6} md={11}>  Pase Release:</Grid>
                      <Grid item xs={6} md={1}>{getAssignedIcon()}</Grid>
                    </Grid>
                  </Item2>
                </Stack>


              </Paper>

            </ConditionalWrapper>

          </Grid>
          <Grid item xs={12} md={8.5}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
            <br />
            <ConditionalWrapper condition={loadingACT} wrapper={SkeletonDetailActivity}>
              {currentTab === 'activity' && <div>

                <Paper elevation={2}>
                  <Stack container>
                    <Item2>
                      {tipos()}
                    </Item2>
                  </Stack>
                </Paper>
                <Paper elevation={2} sx={{ mt: 1 }}>

                  <Stack container >
                    <Item2>
                      {priority()}
                    </Item2>
                  </Stack>
                </Paper>



                <Stack container>
                  <Item3>
                  <Box
                sx={{
                  //height: 20,
                  backgroundColor: "#BBDEFB",
     /*              '&:hover': {
                    backgroundColor: '"#BBDEFB"',
                   
                  }, */
                }}
              >

              <b>  Fechas </b>
            </Box>
                  </Item3>
                </Stack>
                <Grid container spacing={2} >
                  <Grid item md={4} >
                    <Paper elevation={5} >
                      <Stack container>
                        <Item2>
                          Fecha Planificada: {fechaPlanificada()}{ActfechaPlanificada()}
                        </Item2>
                      </Stack>

                    </Paper>
                  </Grid>
                  <Grid item md={4}>
                    <Paper elevation={5}>
                      <Stack container>
                        <Item2>
                          Fecha Inicio: {fechaInicio()}{ActfechaInicio()}
                        </Item2>
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item md={4}>
                    <Paper elevation={5}>

                      <Stack container>
                        <Item2>
                          Fecha Entrega Inicial: {fechaEinicial()}{ActfechaEinicial()}
                        </Item2>
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item md={4}>
                    <Paper elevation={5}>

                      <Stack container>
                        <Item2>
                          Fecha Entrega Final: {fechaEfinal()}{ActfechaEfinal()}
                        </Item2>
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item md={4}>
                    <Paper elevation={5}>

                      <Stack container>
                        <Item2>
                          Fecha Cierre: {fechaCierre()}{ActfechaCierre()}
                        </Item2>
                      </Stack>
                    </Paper>
                  </Grid>
                </Grid>

                <Stack container>
                  <Item3>
                  <Box
                sx={{
                  //height: 20,
                  backgroundColor: "#BBDEFB",
     /*              '&:hover': {
                    backgroundColor: '"#BBDEFB"',
                   
                  }, */
                }}
              >

              <b>  Horas </b>
            </Box>
                  </Item3>
                </Stack>
                <Grid container spacing={2} >
                  <Grid item md={4} >
                    <Paper elevation={5} >
                      <Stack container>
                        <Item2>
                          Original: {horaOriginal()}
                        </Item2>
                      </Stack>

                    </Paper>
                  </Grid>
                  <Grid item md={4}>
                    <Paper elevation={5}>
                      <Stack container>
                        <Item2>
                          Actual: {horaActual()}
                        </Item2>
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item md={4}>
                    <Paper elevation={5}>

                      <Stack container>
                        <Item2>
                          Real: {horaReal()}
                        </Item2>
                      </Stack>
                    </Paper>
                  </Grid>
                
                 
                </Grid>

              </div>}
              {currentTab === 'edit_profile' && <ActivityAssignation actividad={actividad} reload={getActividad} />}
              {currentTab === 'notifications' && <ActivityModule actividad={actividad} reload={getActividad} />}
              {currentTab === 'security' && <ActivityObject actividad={actividad} />}
              {currentTab === 'defects' && <DefectsActivity actividad={actividad} />}
            </ConditionalWrapper>
          </Grid>
        </Grid>
      </Container>






















    </>
  )
}
