import React, { useState, useCallback, useEffect } from "react"
import { BaseDialog, ConditionalWrapper, SkeletonAC } from '../../layout'
import { useRequests, useCustomSnackbar, getErrorMsg, useBackdrop } from "../../../helpers"
import { IconButton } from '@mui/material';


import PersonIcon from "@mui/icons-material/Person";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

export const ModalLogo = React.memo(props => {

    const { get, patch, post } = useRequests()
    const { eSnack, iSnack, sSnack } = useCustomSnackbar()
    const [activeStep, setActiveStep] = useState(0);
    const { show, off } = useBackdrop()
    const [filePreview, setFilePreview] = useState(null);
    const [cliente, setCliente] = useState("")
    const [loading, setLoading] = useState(false)
    const [currentClient, setCurrentClient] = useState(null)

    useEffect(() => {
        setCurrentClient(props.currentClient)
    }, [props.currentClient])

    const onChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = handleFileReaderLoad;
        reader.readAsDataURL(file);
    };

    const handleFileReaderLoad = (event) => {
        setFilePreview(event.target.result);
    };

    const handleClose = () => {
        //handleReset()
        props.handleClose()
    }

    const getClientAvatar = useCallback(async () => {
        try {
            // iSnack("Obteniendo Avatar")
            setLoading(true)
            const response = await get(`/administration/client_avatar/${currentClient}`)
            if (response?.ok) {
                const avatar = await response.json()
                setCliente(avatar)
            } else {
                eSnack(`Avatar: ${await getErrorMsg(response)}`, getClientAvatar)
                console.log("Error getting avatar");
            }
        } catch (e) {
            eSnack("Error inesperado obteniendo avatar", getClientAvatar)
            console.log("Error getting avatar", e);
        } finally { setLoading(false) }
    }, [eSnack, get, currentClient])

    useEffect(() => {
        getClientAvatar()
    }, [getClientAvatar])

    useEffect(() => {
        if (cliente) {
            setFilePreview(cliente.avatar)
        }
    }, [cliente])


    const updateAvatar = async (e) => {
        e.preventDefault()
        try {
            const data = { avatar: filePreview }
            const response = await post(`/administration/update_avatar/${currentClient}`, data)
            if (response?.ok) {
                sSnack("Avatar Actualizado")
                getClientAvatar()
            } else {
                eSnack(`Error actualizando avatar: ${await getErrorMsg(response)}`)
            }
        } catch (e) {
            eSnack("Error inesperado actualizando avatar")
            console.log("Error updating avatar", e)
        }
    }


    return (
        <BaseDialog
            dialogOpen={props.dialogOpen}
            handleClose={handleClose}
            title={`Cambiar en ${currentClient}`}
            width="sm"
            confirmText="Guardar"
            confirm={updateAvatar}
        >
            <ConditionalWrapper condition={loading} wrapper={SkeletonAC}>

                <div
                    style={{
                        marginLeft: "40%",
                        borderRadius: "10px",
                        position: "relative",
                        backgroundColor: "#92A7C0",
                        height: "89px",
                        width: "89px",
                    }}
                >
                    {filePreview ? (
                        <img
                            alt=""
                            src={filePreview}
                            style={{ width: "89px", height: "89px" }}
                        />
                    ) : (
                        <PersonIcon
                            style={{
                                color: "#FFFFFF",
                                width: "35px",
                                height: "35px",
                                marginLeft: "28px",
                                marginTop: "25px",
                            }}
                        />
                    )}

                    <div
                        style={{
                            position: "absolute",
                            top: "55px",
                            right: "0",
                            height: "34px",
                            width: "34px",
                        }}
                    >
                        <input
                            accept="image/*"
                            id="icon-button-file"
                            type="file"
                            style={{ display: "none" }}
                            onChange={onChange}
                        />

                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            sx={{
                                backgroundColor: "#007CD6",
                                width: "34px",
                                height: "34px",
                                left: "8px",
                                top: "4px",
                            }}
                        >
                            <label htmlFor="icon-button-file">
                                <CameraAltOutlinedIcon
                                    style={{
                                        color: "#FFFFFF",
                                        borderRadius: "50%" /* backgroundColor: "#007CD6" */,
                                    }}
                                />
                            </label>
                        </IconButton>
                    </div>
                </div>
            </ConditionalWrapper>
        </BaseDialog>
    )
})
