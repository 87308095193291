import React, { useState, useEffect, useCallback } from "react";
import { useRequests, getErrorMsg, useCustomSnackbar, useBackdrop, FIELD_TYPES } from "../../../helpers";
import { BaseDialog, BaseNewForm } from "../../layout";

export const AutoActivityDialog = React.memo(({ handleClose, autoActivityOpen, ticket, loading, reload }) => {
    const initialName = useCallback( () => `${ticket.codmodulo} -  - ${ticket.codcli}`, [ticket] );
    const [description, setDescription] = useState(initialName());
    const [date] = useState(new Date().toISOString().split("T")[0]);
    const { form, controller } = useRequests();
    const { sSnack, eSnack } = useCustomSnackbar();
    const { show, off } = useBackdrop(); //this is the hook of backdrop
    const formId = "activityForm";

    useEffect(() => {
      setDescription(initialName());
    }, [initialName]);

    const automaticAssignation = useCallback(
      async (e) => {
        e.preventDefault();
        show("Asignando Actividad");
        const data = new FormData(e.target);
        data.append("id", ticket.idinc);
        try {
          const request = await form("/activities/auto_assign", data);
          if (request?.ok) {
            sSnack("Actividad Asignada");
            reload();
            handleClose();
          } else {
            eSnack(await getErrorMsg(request));
          }
        } catch (e) {
          eSnack("Error inesperado asignando actividad");
          console.log("Error", e);
        } finally {
          off();
        }
      },
      [sSnack, handleClose, eSnack, form, ticket.idinc, reload, show, off]
    );

    useEffect(() => () => controller.abort(), [controller]);

    return (
      <BaseDialog
        dialogOpen={autoActivityOpen}
        handleClose={handleClose}
        title={`Asignar Actividad a Ticket: ${ticket.idinc}`}
        cancelText="Cerrar"
        width="md"
        formId={formId}
        confirm
        confirmText="Asignación automática"
      >
        <BaseNewForm
          fields={[
            {
              title: "Nombre Actividad",
              name: "activity[name]",
              id: "name",
              type: FIELD_TYPES.TEXT,
              value: description,
            },
            {
              title: "Fecha de Planificación",
              name: "activity[date]",
              id: "date",
              type: FIELD_TYPES.DATE,
              value: date,
            },
          ]}
          dialog={true}
          formId={formId}
          submit={automaticAssignation}
        />
      </BaseDialog>
    );
  }
);
