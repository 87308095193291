import React from 'react';
import {BaseDialog} from '../../layout'
import {  PDFViewer } from "@react-pdf/renderer";
import {DocuPDF} from  "./docu_pdf";

export const PDF = React.memo(({openPDF, handleClosePDF,data,fecha,clientex,desdex,hastax,totalEncuestadosx,
  resp1Preg1x,resp2Preg1x,resp3Preg1x,resp4Preg1x,resp1Preg2x,resp2Preg2x,resp3Preg2x,resp4Preg2x
  ,resp1Preg3x,resp2Preg3x,resp3Preg3x,resp4Preg3x,resp1Preg4x,resp2Preg4x,resp3Preg4x,resp4Preg4x, resp1Preg5x,resp2Preg5x,resp3Preg5x,resp4Preg5x}) => {

  return (
    <BaseDialog
    dialogOpen={openPDF}
    handleClose={handleClosePDF}
    title={"PDF"}
    confirmText={"Generar PDF"}
    cancelText={"Cerrar"}
   //   confirm={pdf}
    
    > 
     <PDFViewer style={{ width: "100%", height: "90vh" }}>
         <DocuPDF  
        
         date={fecha} 
         cliente={clientex} 
         desde={desdex} 
         hasta={hastax}
         totalEncuestados={totalEncuestadosx} 
         resp1Preg1 = {resp1Preg1x}
         resp2Preg1 = {resp2Preg1x}
         resp3Preg1 = {resp3Preg1x}
         resp4Preg1 = {resp4Preg1x}
         resp1Preg2 = {resp1Preg2x}
         resp2Preg2 = {resp2Preg2x}
         resp3Preg2 = {resp3Preg2x}
         resp4Preg2 = {resp4Preg2x}
         resp1Preg3 = {resp1Preg3x}
         resp2Preg3 = {resp2Preg3x}
         resp3Preg3 = {resp3Preg3x}
         resp4Preg3 = {resp4Preg3x}
         resp1Preg4 = {resp1Preg4x}
         resp2Preg4 = {resp2Preg4x}
         resp3Preg4 = {resp3Preg4x}
         resp4Preg4 = {resp4Preg4x}
         resp1Preg5 = {resp1Preg5x}
         resp2Preg5 = {resp2Preg5x}
         resp3Preg5 = {resp3Preg5x}
         resp4Preg5 = {resp4Preg5x}
         />
     </PDFViewer>
    </BaseDialog>
  );
})
