import React from "react"
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Fab from '@mui/material/Fab';
import TablePagination from '@mui/material/TablePagination';
import {Link} from "react-router-dom"

export const BaseTablePagination = ({ openDialog, buttonTip, dialogIcon, newRoute, totalRows, rowsPerPage, page, handlePageChange, handleChangeRowsPerPage,exportDialog }) =>{
  return (
    <div className="table-pagination">
      <div className="extra-actions">
        {openDialog && <Tooltip title={buttonTip || "Nuevo"} aria-label="nuevo">
          <Fab color="secondary" onClick={openDialog}>
            {dialogIcon || <AddIcon/>}
          </Fab>
        </Tooltip>}
        {newRoute && <Tooltip title="Nuevo" aria-label="nuevo">
          <Link to={`${newRoute}`}>
            <Fab color="secondary">
              <AddIcon/>
            </Fab>
          </Link>
        </Tooltip>}
        {exportDialog && <Tooltip title={buttonTip || "export"} aria-label="export">
          <Fab color="secondary" onClick={exportDialog}>
            {dialogIcon || <ImportExportIcon/>}
          </Fab>
        </Tooltip>}
        {newRoute && <Tooltip title="export" aria-label="export">
          <Link to={`${newRoute}`}>
            <Fab color="secondary">
              <ImportExportIcon/>
            </Fab>
          </Link>
        </Tooltip>}
      </div>
      <TablePagination component={"div"}
        rowsPerPageOptions={[5, 10, 25]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'Productos por página' },
          native: true,
        }}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
