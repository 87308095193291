import React, {useState, useEffect, useCallback} from 'react';
import{
  FormControl,
  TextField
} from '@mui/material';
import {useRequests, getErrorMsg, useCustomSnackbar, useBackdrop} from '../../../helpers'
import { Autocomplete, BaseDialog } from '../../layout';

export const AssignDbUserDialog = React.memo(({ open, user, reload, handleClose:closeD }) => {
  const [assignables, setAssignables] = useState([])
  const [assigned, setAssigned] = useState(null)
  const {show, off} = useBackdrop ()
  const {eSnack, sSnack} = useCustomSnackbar()
  const {get, post, controller} = useRequests()
  const [LoadingDB, setLoadingDB] = useState(false)

  useEffect(()=>{
    if(open){
      setAssigned(assignables?.find( dbu => dbu.codusu?.toLowerCase() === user?.codusu?.toLowerCase()) || null)
    }
  },[user, assignables, open])

  const getDbAssignables = useCallback(async () =>{
    try{
      setLoadingDB(true)
      // iSnack("Cargando Usuarios DB Asignables")
      var response = await get("/administration/dbusers")
      if(response?.ok){
        const data = await response.json()
        setAssignables(data)
      }else{
        eSnack(await getErrorMsg(response), getDbAssignables)
        console.error("Error Retrieving Assignables");
      }
    }catch(e){
      eSnack("Error inesperado cargando usuarios DB assignables", getDbAssignables)
      console.error("Retrieving Assignables:",e);
    }finally {setLoadingDB(false)}
  },[get, eSnack])

  const assignDbUser = async () => {
    if(user){
      show("Asignando Usuario DB")
      try{
        var response = await post("/administration/assign_db_user",{
          id:user?.idpers,
          assigned:assigned?.codusu
        })
        if(response?.ok){
          handleClose()
          reload()
          sSnack(`Usuario DB Asignado`)
        }else{
          eSnack(await getErrorMsg(response))
          console.error("Error assigning DB User:");
        }
      }catch(e){
        eSnack("Error asignando usuario DB")
        console.error("Assigning DB User:",e);
      }finally{off()}
    }else{
      eSnack("Error interno usuario no seleccionado")
    }
  }

  const handleClose = ()=> {
    setAssigned(null)
    closeD()
  }

  useEffect(()=>{
    if(open && !assignables.length){
      getDbAssignables()
    }
  },[getDbAssignables, open, assignables.length])

  useEffect(() => () => controller.abort(), [controller])

  return (
    <BaseDialog
      dialogOpen={open}
      handleClose={handleClose}
      title={"Asignar Usuario DB"}
      confirmText={"Asignar"}
      cancelText={"Cancelar"}
      confirm={assignDbUser}
      width="sm"
    >
      <FormControl fullWidth>
        <Autocomplete
          options={assignables}
          value={assigned}
          valueKey="codusu"
          setValue={setAssigned}
          loading={LoadingDB}
          getOptionLabel={(option) => `${option.nompers}-${option.codusu}`}
          renderInput={(params) => <TextField {...params} required fullWidth label="Usuario DB"/>}
        />
      </FormControl>
    </BaseDialog>
  );
})
