import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { DECIMALS_REGEXP, useCustomSnackbar, mergeObjects, FIELD_TYPES } from "../../../helpers"


const useStyles = makeStyles(theme => ({
  disabled: {
    fontWeight: "bold",
    color: "black",
    fontSize: 14,
    borderBottom: 0,
    "&:before": {
      borderBottom: 0
    },
  },
  textField: {
    /*   marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1), */
    /* padding:0, */
    /* height:"100%" */
    // marginTop: theme.spacing(0),
    // width: 227,
    // height:0,
    color: "red",
    fontSize: 14,
    opacity: 2,
    borderBottom: 0,
    "&:before": {
      borderBottom: 0
    },
    "& .MuiInputBase-input": {
      font: "inherit",
      letterSpacing: " inherit",
      padding: 0,
      border: 0,
    },
    "& .MuiInput-input": {
      font: "inherit",
      letterSpacing: " inherit",
      padding: 0,
      border: 0,
    }


  }
}));

//initialValue: valor que viene de BD
export const EditableTextField = React.memo(({ initialValue, save, name, type, extraProps = {}, placeholder, formatter }) => {

  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState(initialValue ?? "")
  const [editable, setEditable] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const { eSnack } = useCustomSnackbar()


  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  const handleMouseOver = (event) => {
    if (!mouseOver) {
      setMouseOver(true);
    }
  };

  const handleMouseOut = (event) => {

    if (mouseOver) {
      setMouseOver(false);
    }
  };

  const handleClick = () => {
    setEditable(!editable);
    setMouseOver(false);
  };

  const onCancel = () => {
    setEditable(false);
    setCurrentValue(initialValue || "")
    setMouseOver(true)
  };

  const onSave = () => {
    switch (type) {
      case "decimal":
        if (!DECIMALS_REGEXP.test(currentValue)) {
          return eSnack("Ingrese un valor entero o decimal")
        }
        break;
      default:

    }
    save({ [name]: currentValue })
    setEditable(false)
  }

  useEffect(() => {
    setCurrentValue(initialValue ?? "")
  }, [initialValue])

  const edit = () => {
    if (editable === false) {
      return (
        <IconButton onClick={handleClick} size="small">
          <EditIcon />
        </IconButton>
      );
    }

  }

  const editTransparent = () => {//Metodo que se usa para manejar el moviviemto de texto largo

    if (!editable && type === FIELD_TYPES.TEXTAREA) {
      return (
        <IconButton size="small" >
          <EditIcon style={{ fill: "#FFFFFFFF" }} />
        </IconButton>

      );
    }
  }

  const saveCancel = () => {
    if (editable === true) {
      return <>
        <IconButton
          onClick={onSave}
          disabled={currentValue === initialValue || currentValue === ""}
          size="small">
          <SaveIcon />
        </IconButton>

        <IconButton onClick={onCancel} size="small">
          <CancelIcon />
        </IconButton>

      </>;
    }
  }

  const getFieldProps = () => {
    const fieldProps = {
      name: name,
      value: (formatter && !editable) ? formatter(currentValue) : currentValue,
      //margin:"normal",
      onChange: handleChange,
      disabled: !editable,
      className: classes.textField,
      onMouseEnter: handleMouseOver,
      onPointerLeave: handleMouseOut,
      variant: "standard",
      InputProps: {
        classes: {
          disabled: classes.disabled
        },
        endAdornment: (
          <InputAdornment position="end">
            {mouseOver ? edit() : editTransparent()}
            {saveCancel()}
          </InputAdornment>

        )
      },
      inputProps: {
        placeholder: placeholder
      }

    }
      ;
    switch (type) {
      case FIELD_TYPES.DATE:
        fieldProps.type = "date"


        break;

      case FIELD_TYPES.TEXTAREA:
        /* fieldProps.rows="4" */
        fieldProps.multiline = true
        fieldProps.style = { width: "100%" }

        break;
      default:
        break;
    }
    return mergeObjects(fieldProps, extraProps);

  };

  return <TextField {...getFieldProps()} />



})
