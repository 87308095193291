import React from 'react';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Dialog,
  Typography,
  IconButton
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';


const styles = (theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});


export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.dialogTitle} {...other} component="span">
      <Typography variant="title">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles(styles)((props)=>{/*Hacky way to resolve paddingTop being overridden by emotion after mui 5 migration */
  const {children, ...other} = props
  const theme = useTheme()
  return (
    <MuiDialogContent style={{paddingTop:theme.spacing(2)}} {...other}>{children}</MuiDialogContent>
  )
});

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const BaseDialog = React.memo(({ width, dialogOpen, handleClose, title, description, children, alternativeFooter, cancelText, confirm, formId, confirmText}) => {

  return (
    <Dialog fullWidth maxWidth={width || "lg"} onClose={handleClose} open={dialogOpen}>
      <DialogTitle onClose={handleClose}>
        {title || "Nameless"}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {description}
        </Typography>
        {children}
      </DialogContent>
      {alternativeFooter ? alternativeFooter :
        <DialogActions>
        <Button onClick={handleClose} variant="contained" color="secondary">
          {cancelText || "Cancel"}
        </Button>
        {confirm && (formId ?
          <Button type="submit" form={formId} variant="contained" color="secondary">
            {confirmText || "Save"}
          </Button>
          :
          <Button onClick={confirm} variant="contained" color="secondary">
            {confirmText || "Save"}
          </Button>
        )}
        </DialogActions>}
    </Dialog>
  );
})
