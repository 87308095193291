import { IconButton, Menu } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';


export const IconButtonMenu = ({
  icon,
  children,
  menuProps,
  open,
  toggleMenu,
  ...iconButtonProps
})=> {
  const menuRef = useRef(null);
  const [localOpen, setLocalOpen] = useState(false);

  const defaultMenuProps = {};
  if (menuProps) Object.assign(defaultMenuProps, menuProps);

  useEffect(() => {
    if (typeof open === 'boolean') setLocalOpen(open);
  }, [open]);

  return (
    <>
      <IconButton
        ref={menuRef}
        onClick={() => {
          if (toggleMenu) toggleMenu(true);
          else setLocalOpen(true);
        }}
        {...iconButtonProps}
      >
        {icon}
      </IconButton>
      <Menu
        anchorEl={menuRef.current}
        keepMounted
        open={localOpen}
        onClose={() => {
          if (toggleMenu) toggleMenu(false);
          else setLocalOpen(false);
        }}
        {...defaultMenuProps}
      >
        {children}
      </Menu>
    </>
  );
};

IconButtonMenu.defaultProps = {
  menuProps: {
    keepMounted: true,
  },
};
