import React, { useEffect } from "react"
import { Container } from '@mui/material';
import '../../../stylesheets/activity.scss'
import { BaseTable, BaseTablePagination } from "../../layout";
import {  useRequests } from "../../../helpers";
import { useHistory } from 'react-router-dom';


export const ActivityOtList = React.memo(props => {
  const { controller } = useRequests()
  const history = useHistory();

  useEffect(() => () => controller.abort(), [controller])

    const openActivity = ({ idact }) => {
      history.push(`/activities/${idact}`)
    }

   // console.log("muestrameee",props.order.activity_work_orders)
  return (
    <>
      <Container maxWidth="lg">
        <BaseTable
          loading={false}
          rows={props.order.activity_work_orders}
          headers={[
            "IdOT ",
            "IdACT",
            "Referencia",
            "Fecha inicio",
            "Fecha Fin",
          ]}
          rowAction={row => openActivity(row)}
          collapsible={{
            visible: (row) => [
              { content: row.idot, handleCollapse: true, align: "left" },
              { content: row.idact },
              { content: row.refact },
              { content: row.fecinivig },
              { content: row.fecfinvig },

            ],

            collapsed: (row) => [
              { name: "Nombre", content: row.nomact },
              { name: "Descripcion", content: row.descact },
            ],
          }}
          title="Actividades"
        >
          <BaseTablePagination
            page={0}
            maxPage={5}
            rowsPerPage={5}
            totalRows={props.order.activity_work_orders.length}
            handlePageChange={props.order.activity_work_orders.length}
            handleChangeRowsPerPage={props.order.activity_work_orders.length}
          />
        </BaseTable>
      </Container>


    </>
  );

})
