import {
    Box,
    Typography,
    Card,
    CardHeader,
    Divider,
    Avatar,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { getErrorMsg, useCustomSnackbar, useRequests } from '../../../helpers';
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';
import MarkEmailUnreadTwoToneIcon from '@mui/icons-material/MarkEmailUnreadTwoTone';
import MarkunreadTwoToneIcon from '@mui/icons-material/MarkunreadTwoTone';
import { ConditionalWrapper, SkeletonList } from '../../layout';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
        
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
  `
);

export const RecentActivity = () => {
    const [total, settotal] = useState([])
    const [loadingtotal, setLoadingtotal] = useState(false)
    const [pendiente, setpendiente] = useState([])
    const [cerrado, setcerrado] = useState([])
    const { get } = useRequests()
    const { eSnack } = useCustomSnackbar()

    const getTotal = useCallback(async () => {
        try {
            setLoadingtotal(true)
            const response = await get('/users/tickets_total')
            if (response?.ok) {
                const total = await response.json()
                settotal(total)
                //console.log(total)
            } else {
                eSnack(await getErrorMsg(response), getTotal)
            }
        } catch (e) {
            console.log(e);
        } finally { setLoadingtotal(false) }
    }, [get, eSnack])

    useEffect(() => {

        getTotal()
    }, [getTotal])

    const getPendiente = useCallback(async () => {
        try {
           
            const response = await get('/users/tickets_pen')
            if (response?.ok) {
                const pendiente = await response.json()
                setpendiente(pendiente)
                //console.log(pendiente)
            } else {
                eSnack(await getErrorMsg(response), getPendiente)
            }
        } catch (e) {
            console.log(e);
        } finally {  }
    }, [get, eSnack])

    useEffect(() => {

        getPendiente()
    }, [getPendiente])

    const getCerrado = useCallback(async () => {
        try {
            
            const response = await get('/users/tickets_cer')
            if (response?.ok) {
                const cerrado = await response.json()
                setcerrado(cerrado)
                //console.log(cerrado)
            } else {
                eSnack(await getErrorMsg(response), getCerrado)
            }
        } catch (e) {
            console.log(e);
        } finally { }
    }, [get, eSnack])

    useEffect(() => {

        getCerrado()
    }, [getCerrado])

    const theme = useTheme();

    return (
        <Box>
<ConditionalWrapper condition={loadingtotal} wrapper={children => <SkeletonList number={5}>{children}</SkeletonList>}>
            <Card>
                <CardHeader title="Historial" />
                <Divider />
                <Box px={2} py={4} display="flex" alignItems="flex-start">
                    <AvatarPrimary sx={{ bgcolor: "#ff8f00" }}>
                        <MarkunreadTwoToneIcon />
                    </AvatarPrimary>
                    <Box pl={2} flex={1}>
                        <Typography variant="h4">Tickets</Typography>

                        <Box pt={2} display="flex">
                            <Box pr={8}>
                                <Typography
                                    gutterBottom
                                    variant="caption"
                                    sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
                                >
                                    Total
                                </Typography>
                                <Typography variant="h3">{total.length}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box px={2} py={4} display="flex" alignItems="flex-start">
                    <AvatarPrimary sx={{ bgcolor: "#ff8f00" }}>
                        <MarkEmailUnreadTwoToneIcon />
                    </AvatarPrimary>
                    <Box pl={2} flex={1}>
                        <Typography variant="h4">Pendientes</Typography>

                        <Box pt={2} display="flex">
                            <Box pr={8}>
                                <Typography
                                    gutterBottom
                                    variant="caption"
                                    sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
                                >
                                    Total
                                </Typography>
                                <Typography variant="h3">{pendiente.length}</Typography>
                            </Box>

                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box px={2} py={4} display="flex" alignItems="flex-start">
                    <AvatarPrimary sx={{ bgcolor: "#ff8f00" }}>
                        <MarkEmailReadTwoToneIcon />
                    </AvatarPrimary>
                    <Box pl={2} flex={1}>
                        <Typography variant="h4">Cerrados</Typography>

                        <Box pt={2} display="flex">
                            <Box pr={8}>
                                <Typography
                                    gutterBottom
                                    variant="caption"
                                    sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
                                >
                                    Total
                                </Typography>
                                <Typography variant="h3">{cerrado.length}</Typography>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Card>
            </ConditionalWrapper>
        </Box>
    );
}

