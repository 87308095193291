import React from 'react'
import Avatar from '@mui/material/Avatar';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '90%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  textField:{
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    height: '100%'
  }
}));

export const Maintenance = props => {
  const classes = useStyles();

  return (
    <div className="maintenance-container">
      <Container>
        <Paper className={classes.paper} elevation={12}>
          <Avatar className={classes.avatar}>
            <TimerOffIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Disculpe los inconvenientes la aplicación se encuentra en mantenimiento en estos momentos
          </Typography>
        </Paper>
      </Container>
    </div>
  );
}
