import React from 'react'
import {SystemsList} from '../.'
import {Route, Switch} from "react-router-dom"

export const Systems = ({match}) =>{
  return (
    < >
      <Switch>
        <Route exact path={`${match.path}/`} component={SystemsList}/>
      </Switch>
    </>
  )
}
