import React from 'react'
import {DefectsList, DefectsDetail} from '../.'
import {Route, Switch} from "react-router-dom"


export const Defects = ({match}) =>{
  return (
    < >
      <Switch>
        <Route exact path={`${match.path}/`} component={DefectsList}/>
        <Route exact path={`${match.path}/:iddefecto`} component={DefectsDetail} />
      </Switch>
    </>
  )
}
